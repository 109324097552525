define("a24-ember-staffshift-core/services/navigation", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        // This flag is being set from the abstractRoute.js route
        bPageLoading: false,
        //If you want to do something each time the route changes then observe the iRouteTransitions and not the
        //sCurrentUrl, since the sCurrentUrl will not change when quickly going back to the same route before the new route
        //finished loading, e.g when navigating to candidate welcome page and then to agency welcome and then hitting the
        //back button before the agency welcome finishes transition the sCurrentUrl will not fire an observes since it would
        //have been set to the same value(candidate url), but the iRouteTransitions would have incremented since the
        //candidate welcome route was loaded again.
        iRouteTransitions: 0, //This value will change each time a route transition completed(set from router:didTransition)
        sCurrentUrl: null, //The full current url excluding the domain e.g /candidate/welcome(set from router:didTransition)
        sCurrentRouteName: null, //The current route name e.g agency.agency-contract-list (set from router:didTransition)
        objRouteParams: null, //The params for route sections (set from abstractRoute:model)
        //e.g "agency/1234/agency-contract-list/4567/B23/9876" will have an object of:
        //{
        //    agency: {
        //        context_id: "1234"
        //    },
        //    agency.agency-contract-list: {  //This key will be the route name, so note agency.agency-contract-list
        //        contract_id: "4567",     //These keys will be what was configured in the route.js for the path section
        //        contract_num: "B23",
        //        contract_bloep: "9876"
        //    }
        //}
        objApplicationController: null, //This gets set from the application route beforeModel() function
        objRouteQueryParams: null, // The params set on the ? hook
        objPrevTrans: null, //This will hold a previous transition that can be retried later
        sContext: null, //This is the context part in the url set by the abstractRoute class for internal pages
        sContextId: null, //This is the context id part in the url set by the abstractRoute class for internal pages

        sAppContext: Ember.computed.readOnly("env.sAppContext"),
        sTriageBaseUrl: Ember.computed.readOnly("env.sTriageBaseUrl"),
        sEmberBaseAppUrl: Ember.computed.readOnly("env.sEmberBaseAppUrl"),

        //we expect that once this flag goes to true that it wont go back to false since navigation to GWT would
        //end this ember app instance.
        bNavigatingToGWT: false,

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * This function makes it easier to navigate around within the same context eliminating having to include
         * the current context and context id for the navigation url.
         * E.g instead of having to send in agency.example.view-page, you would only send in example.view-page and only
         * supply data for these routes in the objParams and objQueryParams
         *
         * @param sRoute - The inner route to navigate to, e.g instead of agency.order-view it will be just order-view
         * @param objParams - These are the params needed by the routes e.g
         *     URL in browser = /agency/24234VE424/order-view/12345/SSDe/7890
         *     objParams will be: {
         *         agency: ["24234VE424"],
         *         order-view: ["12345", "SSDe", "7890"]
         *     }
         * @param objQueryParams - The query params for the route, these would be the ones following the ? in the url e.g
         *     {
         *         page: 5,
         *         siteName: "someSite"
         *     }
         *     NOTE: These query params will most likely not show up in the url unless you specifically specify it in the
         *     controller, they will however still be accessible in the code(in the transition object in the route)
         * @param bReturnUrl - Whether the function should return the built up url, when true it will just return the url
         *     and NOT navigate to it, when false it will build up the url and navigate to it.
         * @param bKeepHistory - Whether to keep the browser history
         */
        navigateWithinContext: function navigateWithinContext(sRoute, objParams, objQueryParams, bReturnUrl, bKeepHistory) {
            var sCID = this.get("sContextId");
            var sContext = this.get("sContext");
            var sContextBaseUrl = "";

            // context can be null when in the agency sub domain
            if (sContext !== null) {
                sContextBaseUrl += "/" + sContext;
            }

            //Context Id can be null in candidate and systemAdmin context
            if (sCID != null) {
                sContextBaseUrl += "/" + sCID;
            }

            if (a24Core.isEmpty(bKeepHistory) || bKeepHistory) {
                return this._doNavigate(sContextBaseUrl, sRoute, objParams, objQueryParams, bReturnUrl);
            } else {
                this._doLocationReplace(this._doNavigate(sContextBaseUrl, sRoute, objParams, objQueryParams, true), false);
            }
        },
        /**
         * This will do the same as navigateToWithinContext, except that you need to specify the context in the
         * sRoute and also supply needed details for it in the objParams if needed for that context, systemAdmin and
         * candidate do no need context id for example.
         * This can also be used to navigate to public pages (pages without context)
         *
         * @param sRoute - See navigateToWithinContext
         * @param objParams - See navigateToWithinContext
         * @param objQueryParams - See navigateToWithinContext
         * @param bReturnUrl - Whether the function should return the built up url, when true it will just return the url
         *     and NOT navigate to it, when false it will build up the url and navigate to it.
         * @param bEmberMainDomain - Whether to navigate to the main ember app
         */
        navigateOutsideContext: function navigateOutsideContext(sRoute, objParams, objQueryParams, bReturnUrl, bEmberMainDomain) {
            //possibly need to add terms and condition etc here aswell later
            if ((sRoute === "404" || sRoute === "error") && !a24Core.isEmpty(this.get("sAppContext"))) {
                var sNewContextUrl = this.get("sEmberBaseAppUrl") + "/404";
                this._doLocationReplace(sNewContextUrl, true);
            } else {
                return this._doNavigate("", sRoute, objParams, objQueryParams, bReturnUrl, bEmberMainDomain);
            }
        },
        _doNavigate: function _doNavigate(sFinalUrl, sRoute, objParams, objQueryParams, bReturnUrl, bEmberMainDomain) {
            var arrRoutes = sRoute.split(".");
            if (a24Core.isEmpty(objParams)) {
                for (var i = 0; i < arrRoutes.length; i++) {
                    sFinalUrl += "/" + arrRoutes[i];
                }
            } else {
                for (var j = 0; j < arrRoutes.length; j++) {
                    sFinalUrl += "/" + arrRoutes[j];
                    if (!a24Core.isEmpty(objParams[arrRoutes[j]])) {
                        for (var k = 0; k < objParams[arrRoutes[j]].length; k++) {
                            sFinalUrl += "/" + objParams[arrRoutes[j]][k];
                        }
                    }
                }
            }
            if (!a24Core.isEmpty(objQueryParams)) {
                sFinalUrl += "?";
                for (var sKey in objQueryParams) {
                    // skip loop if the property is from prototype
                    if (!objQueryParams.hasOwnProperty(sKey)) {
                        continue;
                    }

                    sFinalUrl += sKey + "=" + encodeURIComponent(objQueryParams[sKey]) + "&";
                }
                //Remove last trailing &
                sFinalUrl = sFinalUrl.substring(0, sFinalUrl.length - 1);
            }

            if (bEmberMainDomain) {
                sFinalUrl = this.get("sEmberBaseAppUrl") + sFinalUrl;
            }

            if (bReturnUrl) {
                return "#" + sFinalUrl;
            } else {
                if (bEmberMainDomain) {
                    this._doLocationReplace(sFinalUrl, true);
                } else {
                    this.get("objApplicationController").transitionTo(sFinalUrl);
                }
            }
        },
        _doLocationChange: function _doLocationChange(sLocation) {
            window.location.href = sLocation;
        },
        _doLocationReplace: function _doLocationReplace(sLocation, bKeepHistory) {
            if (bKeepHistory) {
                this._doLocationChange(sLocation);
            } else {
                window.location.replace(sLocation);
            }
        },
        _getEncodedCurrentUrl: function _getEncodedCurrentUrl() {
            return encodeURIComponent(window.btoa(window.location));
        },
        /**
         * This function will redirect to the GWT login page
         *
         * @param bRegisterPage - Whether we should go to the register page with the login on it and a message saying
         *     login is required to view page or go to the home page that has login on it
         * @param bKeepHistory - Whether to keep the browser history
         */
        navigateToGWTLogin: function navigateToGWTLogin(bRegisterPage, bKeepHistory) {
            this.set("bNavigatingToGWT", true);
            if (bRegisterPage) {
                //We need to double encode the url since it seems GWT double decodes when retrieving query params
                this._doLocationReplace(this.get("sTriageBaseUrl") + "/views#registration;" + "logInMessage=loginRequiredMessage;" + "originalUrl=" + this._getEncodedCurrentUrl(), bKeepHistory);
            } else {
                this._doLocationReplace(this.get("sTriageBaseUrl"), bKeepHistory);
            }
        },
        /**
         * This function will redirect to the GWT context provided
         *
         * @param sContextId - The reference id of the context you wish to switch to
         * @param bKeepHistory - Whether to keep the browser history
         */
        navigateToGWTContext: function navigateToGWTContext(sContextId, bKeepHistory) {
            this.set("bNavigatingToGWT", true);
            var sNewContextUrl = this.get("sTriageBaseUrl") + "/views#welcome;cId=" + sContextId;
            this._doLocationReplace(sNewContextUrl, bKeepHistory);
        },
        /**
         * This function will redirect to a GWT page
         *
         * @param sUrl - The url to navigate to, starting from the nameplace e.g agency-orders;cId=1234123412341234
         * @param bKeepHistory - Whether to keep the browser history
         */
        navigateToGWTPage: function navigateToGWTPage(sUrl, bKeepHistory) {
            this.set("bNavigatingToGWT", true);
            var sNewContextUrl = this.get("sTriageBaseUrl") + "/views#" + sUrl;
            this._doLocationReplace(sNewContextUrl, bKeepHistory);
        },

        /**
         * This function will navigate back to the preview page
         */
        navigateBack: function navigateBack() {
            window.history.back();
        }
    });
});
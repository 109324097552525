define("a24-ember-candidate-profile/components/candidate-other-names", ["exports", "a24-ember-candidate-profile/templates/components/candidate-other-names", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element"], function (exports, _candidateOtherNames, _didRenderChangesMixin, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateOtherNames.default,
        objSettingsRestService: null,
        objOtherNamesRestService: null,
        sCandidateId: null,
        _arrCandidateData: null,
        _objCandidateOtherNamesPromise: null,
        _bLoading: false,
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _bAllowEdit: true,
        _sHeaderPopup: null,
        _bShowEditPopup: false,
        _arrHeaderActions: null,
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objSpecialEditPopupAction: null,
        _bPopupLoading: true,
        _objOriginalData: null,

        _bServerError: false,
        _arrReloadActions: null,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.getOtherNameDetails();
                }
            }]);

            if (!this.get("objSettingsRestService.objSettings.profile_editable")) {
                this.set("_bAllowEdit", false);
            }

            var objValidationConfig = {};

            objValidationConfig["objChildren.known_as"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 2);
                a24Validation.addMaxLength(objMixin, 100);
            };
            objValidationConfig["objChildren.middle_name"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 2);
                a24Validation.addMaxLength(objMixin, 100);
            };
            objValidationConfig["objChildren.maiden_name"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 2);
                a24Validation.addMaxLength(objMixin, 100);
            };

            this.set("objChildConfig", objValidationConfig);

            if (a24Core.isEmpty(this.get("_arrCandidateData"))) {
                this.set("_arrCandidateData", []);
            }
            if (a24Core.isEmpty(this.get("_arrHeaderActions"))) {
                this.set("_arrHeaderActions", []);
            }

            this.set("_objSpecialEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupEditCancel");
                }
            });

            this.getOtherNameDetails();
        },
        getOtherNameDetails: function getOtherNameDetails() {
            var _this2 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);

            this.get("objOtherNamesRestService").getOtherNames(this, "_objCandidateOtherNamesPromise", this.get("sCandidateId"), function (objData) {
                if (!a24Core.isEmpty(objData)) {
                    _this2.set("_arrCandidateData", [{
                        arrItems: [{
                            sLabel: _this2.get("_objCandidateProfileStringsService").getString("knownAs"),
                            sValue: objData.known_as,
                            bText: true
                        }, {
                            sLabel: _this2.get("_objCandidateProfileStringsService").getString("middleName"),
                            sValue: objData.middle_name,
                            bText: true
                        }, {
                            sLabel: _this2.get("_objCandidateProfileStringsService").getString("maidenName"),
                            sValue: objData.maiden_name,
                            bText: true
                        }]
                    }]);
                }

                _this2.getOtherNamesDone(objData);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this2.set("_bServerError", true);
                    return;
                }
                // We swallow all 404 errors as it means that the candidate does not have
                // any other names info
                _this2.getOtherNamesDone();
            }));
        },
        getOtherNamesDone: function getOtherNamesDone(objData) {
            var _this3 = this;

            if (this.get("_bAllowEdit")) {
                this.set("_arrHeaderActions", [{
                    sTitle: this.get("_objStringsService").getString("edit"),
                    sIcon: "edit",
                    executeCardAction: function executeCardAction() {
                        _this3.setEditPopup(objData);
                    }
                }]);
            }

            this.set("_bLoading", false);
        },
        getPayload: function getPayload() {
            return a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());
        },

        setEditPopup: function setEditPopup(objOtherNamesData) {
            var _this4 = this;

            this.clearAllErrorMessages();
            this.clearForm();

            this.set("_sHeaderPopup", this.get("_objStringsService").getTokenString("editItem", this.get("_objCandidateProfileStringsService").getString("otherNames")));

            this.populateInputFields(objOtherNamesData);

            this.set("_bShowEditPopup", true);
            this.set("_bPopupLoading", false);

            if (!a24Core.isEmpty(this.get("setBreadCrumbAction"))) {
                this.get("setBreadCrumbAction")();
            }

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this4.set("_objOriginalData", _this4.getPlainFormValueObject());
                });
            });
        },
        populateInputFields: function populateInputFields(objOtherNamesData) {
            if (!a24Core.isEmpty(objOtherNamesData)) {
                this.get("objChildren.known_as").setValueFromOutside(objOtherNamesData.known_as);
                this.get("objChildren.middle_name").setValueFromOutside(objOtherNamesData.middle_name);
                this.get("objChildren.maiden_name").setValueFromOutside(objOtherNamesData.maiden_name);
            }
        },
        setSuccessResponse: function setSuccessResponse(sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bPopupLoading", false);
            this.set("_bShowEditPopup", false);

            this.getOtherNameDetails();
        },
        setErrorResponse: function setErrorResponse(sCode, objError, sSnackbarMessage) {
            if (sCode === "MAX_LENGTH") {
                this.setResponseError(objError.arrPath, "validationRestMaxLength");
            } else if (sCode === "MIN_LENGTH") {
                this.setResponseError(objError.arrPath, "validationRestMaxLength");
            } else if (sCode === "PATTERN") {
                this.setResponseError(objError.arrPath, "validationRestInvalidChar");
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }

            this.set("_bPopupLoading", false);
        },
        actions: {
            onPopupClose: function onPopupClose() {
                if (this.get("_bPopupLoading")) {
                    return;
                }
            },
            onPopupEditCancel: function onPopupEditCancel() {
                if (this.get("_bPopupLoading")) {
                    return;
                }
                // Check if the form has any unsaved data
                var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

                if (bDataMatches) {
                    this.set("_bShowEditPopup", false);
                } else {
                    this.set("_bShowUnsavedData", true);
                }
            },
            onPopupEditConfirm: function onPopupEditConfirm() {
                var _this5 = this;

                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    var objPayload = this.getPayload();

                    this.set("_bPopupLoading", true);

                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objCandidateProfileStringsService").getString("otherNames")));
                    this.get("objOtherNamesRestService").updateOtherNames(this, "_objCandidateOtherNamesPromise", this.get("sCandidateId"), objPayload, function () {
                        _this5.setSuccessResponse(_this5.get("_objStringsService").getTokenString("successUpdateItem", _this5.get("_objCandidateProfileStringsService").getString("otherNames")));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                        _this5.setErrorResponse(sCode, objError, _this5.get("_objStringsService").getTokenString("failUpdateItem", _this5.get("_objCandidateProfileStringsService").getString("otherNames")));
                    }));
                }
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowEditPopup", false);
                this.set("_bShowUnsavedData", false);
            }
        }
    });
});
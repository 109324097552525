define("a24-ember-candidate-profile/components/page-compliance-rules", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/page-compliance-rules", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _pageComplianceRules, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _pageComplianceRules.default,
        objQueryParams: null,
        objRestCandidateType: null,
        objSavedSearchService: null,
        sUrlBase: null,
        sSavedSearchRef: null,

        _bPageLoading: true,

        _arrSelectedItems: null,

        _objSelectedItem: null,
        _objCandidateTypesResponse: null,

        _objUserSession: Ember.inject.service("user-session"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),

        init: function init() {
            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objProfileStringsService").getString("complianceRules"));

            this.doServiceCalls();
        },
        doServiceCalls: function doServiceCalls() {
            var _this = this;

            this.set("_bPageLoading", true);

            var objPromiseCandidateTypes = this.get("objRestCandidateType").getCandidateTypes(this, "_objCandidateTypesResponse", this.get("_objUserSession").getUserContextReference().mId, function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this._handleServerErrors();
            }));

            var objPromiseList = {
                _objCandidateTypes: objPromiseCandidateTypes
            };

            var arrRequiredResponses = ["_objCandidateTypes"];

            var objThis = this;

            a24RSVP.configure(this, objPromiseList, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    var arrCandidateTypes = [];
                    if (!a24Core.isEmpty(objResponses._objCandidateTypes) && !a24Core.isEmpty(objResponses._objCandidateTypes.objResponse)) {
                        arrCandidateTypes = objResponses._objCandidateTypes.objResponse;
                    }

                    objThis._setupPageConfig(arrCandidateTypes);
                }
            }]);
        },

        _setupPageConfig: function _setupPageConfig(arrCandidateTypes) {
            var arrCandidateTypesDropdown = [];
            for (var i = 0; i < arrCandidateTypes.length; i++) {
                arrCandidateTypesDropdown.push({
                    sTitle: arrCandidateTypes[i].name,
                    sValue: arrCandidateTypes[i]._id
                });
            }
            this.set("_arrCandidateTypesDropdown", arrCandidateTypesDropdown);

            var objThis = this;

            var arrSingleActions = [];

            this.set("_objDatagridConfig", {
                sDatagridHeader: objThis.get("_objProfileStringsService").getString("complianceRules"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                arrSingleItemSelectActions: arrSingleActions,
                arrMultiItemSelectActions: [],
                objSavedSearchService: this.get("objSavedSearchService"),
                sSavedSearchRef: this.get("sSavedSearchRef"),
                arrHeaderConfig: [{
                    iUniqueColNum: 1,
                    sTitle: this.get("_objStringsService").getString("name"),
                    sProperty: "name",
                    sFilterType: "text",
                    bText: true,
                    bHideEmptyCheck: true,
                    iWidth: 25,
                    bIsClickable: false // change to true when we add the view
                }, {
                    iUniqueColNum: 2,
                    sTitle: this.get("_objStringsService").getString("description"),
                    sProperty: "description",
                    sFilterType: "text",
                    bText: true,
                    iWidth: 50,
                    bStopColumnSort: true,
                    bStopFiltering: true
                }, {
                    iUniqueColNum: 3,
                    sTitle: this.get("_objProfileStringsService").getString("candidateType"),
                    sProperty: "position_type",
                    sFilterType: "dropdownMulti",
                    bMapping: true,
                    bHideEmptyCheck: true,
                    arrMappingData: arrCandidateTypesDropdown,
                    bSilent: true,
                    iWidth: 10
                }, {
                    bCustom: true,
                    iUniqueColNum: 4,
                    sTitle: this.get("_objProfileStringsService").getString("candidateType"),
                    sProperty: "condition",
                    sFilterType: "text",
                    bStopColumnSort: true,
                    bStopFiltering: true,
                    iWidth: 25,
                    funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                        var sCandidateTypes = "";
                        var arrCandidateTypesRow = [];
                        var arrCandidateTypeDisplay = [];
                        if (!a24Core.isEmpty(objRowData.condition)) {
                            var arrRowConditions = objRowData.condition;
                            for (var i = 0; i < arrRowConditions.length; i++) {
                                arrCandidateTypesRow = arrCandidateTypesRow.concat(arrRowConditions[i].applicable_position_types);
                            }

                            var arrCandidateTypesFiltered = arrCandidateTypesRow.filter(function (value, index, self) {
                                return self.indexOf(value) === index;
                            });
                            for (var l = 0; l < arrCandidateTypesFiltered.length; l++) {
                                var objCandidateType = arrCandidateTypes.find(function (objItem) {
                                    return objItem._id === arrCandidateTypesFiltered[l];
                                });
                                arrCandidateTypeDisplay.push(objCandidateType.name);
                            }

                            sCandidateTypes = arrCandidateTypeDisplay.join(", ");
                        }
                        return Ember.String.htmlSafe("<span title=\"" + sCandidateTypes + "\">" + sCandidateTypes + "</span>");
                    }
                }]
            });

            this.set("_bPageLoading", false);
        },
        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            } else {
                this.set("_objSelectedItem", null);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },
        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell() /*sColumnName, objRowData*/{
                // for clicking on the cell
            }
        }
    });
});
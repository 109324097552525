define("a24-ember-candidate-profile/components/candidate-equality-monitoring-section-component", ["exports", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-equality-monitoring-section-component"], function (exports, _inputFormElement, _didRenderChangesMixin, _candidateEqualityMonitoringSectionComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _candidateEqualityMonitoringSectionComponent.default,
        sCandidateId: null,
        objEqualityMonitoringRestService: null,
        arrCandidateData: null,
        bLoading: true,
        _bPopupLoading: false,
        arrActionItems: null,
        bShowEditPopup: false,
        bShowUnsavedData: false,
        _objOriginalData: null,
        objSelectedReligion: null,
        objSelectedEthnicOrigin: null,
        objSelectedSexualOrientation: null,
        objSelectedDisabled: null,
        sSelectedAgeGroup: null,
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objEnumsService: Ember.inject.service("a24-enums"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objCandidateEqualityMonitoringGetPromise: null,
        _objCandidateEqualityMonitoringUpdatePromise: null,
        _objSpecialEditPopupAction: null,

        _bServerError: false,
        _arrReloadActions: null,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.getEqualityMonitorDetails();
                }
            }]);

            if (a24Core.isEmpty(this.get("arrCandidateData"))) {
                this.set("arrCandidateData", []);
            }

            this.set("_objSpecialEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupClose");
                }
            });

            this.getEqualityMonitorDetails();

            this.set("arrActionItems", [{
                sTitle: this.get("_objStringsService").getString("edit"),
                sIcon: "edit",
                executeCardAction: function executeCardAction() {
                    _this.setEditPopupModal();
                }
            }]);

            this.setInitialDropdownPopupData();

            this.set("objChildConfig", {
                "objChildren.religion": function objChildrenReligion(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.ethnic_origin": function objChildrenEthnic_origin(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.sexual_orientation": function objChildrenSexual_orientation(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.gender_identity": function objChildrenGender_identity(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                }
            });
        },
        prepareForDisplay: function prepareForDisplay(objData) {

            var sReligion = null;
            var sEthnicOrigin = null;
            var sSexualOrientation = null;
            var sGenderIdentity = null;
            var sDisabled = null;

            if (!a24Core.isEmpty(objData) && !a24Core.isEmpty(objData.religion)) {
                sReligion = this.get("_objCandidateProfileEnumsService").getFrontendValue("religion", objData.religion);
            }

            if (!a24Core.isEmpty(objData) && !a24Core.isEmpty(objData.ethnic_origin)) {
                sEthnicOrigin = this.get("_objCandidateProfileEnumsService").getFrontendValue("ethnicOrigin", objData.ethnic_origin);
            }

            if (!a24Core.isEmpty(objData) && !a24Core.isEmpty(objData.sexual_orientation)) {
                sSexualOrientation = this.get("_objCandidateProfileEnumsService").getFrontendValue("sexualOrientation", objData.sexual_orientation);
            }

            if (!a24Core.isEmpty(objData) && !a24Core.isEmpty(objData.gender_identity)) {
                sGenderIdentity = this.get("_objCandidateProfileEnumsService").getFrontendValue("genderIdentity", objData.gender_identity);
            }

            if (!a24Core.isEmpty(objData) && !a24Core.isEmpty(objData.disabled)) {
                sDisabled = this.get("_objEnumsService").getFrontendValue("disabled", objData.disabled === true ? "yes" : "no");
            }

            return [{
                sLabel: this.get("_objCandidateProfileStringsService").getString("religion"),
                sValue: sReligion,
                bText: true
            }, {
                sLabel: this.get("_objCandidateProfileStringsService").getString("ethnicOrigin"),
                sValue: sEthnicOrigin,
                bText: true
            }, {
                sLabel: this.get("_objCandidateProfileStringsService").getString("sexualOrientation"),
                sValue: sSexualOrientation,
                bText: true
            }, {
                sLabel: this.get("_objCandidateProfileStringsService").getString("genderIdentity"),
                sValue: sGenderIdentity,
                bText: true
            }, {
                sLabel: this.get("_objStringsService").getString("disabled"),
                sValue: sDisabled,
                bText: true
            }];
        },
        _candidateDataSuccess: function _candidateDataSuccess(objData) {
            if (!a24Core.isEmpty(objData)) {
                this.set("arrCandidateData", [{
                    arrItems: this.prepareForDisplay(objData)
                }]);
                this.setSelectedPopupValues(objData);
            }
            this.set("bLoading", false);
        },
        _candidateEqualityDataFailure: function _candidateEqualityDataFailure(sCode) {
            if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                this.set("_bServerError", true);
                return;
            }
            this.set("bLoading", false);
        },
        getEqualityMonitorDetails: function getEqualityMonitorDetails() {
            this.set("bLoading", true);
            this.set("_bServerError", false);

            var sCandidateId = Ember.get(this, "sCandidateId");

            var funcSuccessCallback = Ember.run.bind(this, this._candidateDataSuccess);
            var funcFailureCallback = Ember.run.bind(this, this._candidateEqualityDataFailure);

            this.get("objEqualityMonitoringRestService").getEquality(this, "_objCandidateEqualityMonitoringGetPromise", sCandidateId, funcSuccessCallback, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
            funcFailureCallback));
        },
        setEditPopupModal: function setEditPopupModal() {
            var _this2 = this;

            this.clearAllErrorMessages();
            this.set("bShowEditPopup", true);
            this.setSelectedPopupValues(this.get("_objOriginalData"));
            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this2.set("_objOriginalData", _this2.getPlainFormValueObject());
                });
            });
        },
        setInitialDropdownPopupData: function setInitialDropdownPopupData() {
            this.set("_arrReligionDropdown", this.get("_objCandidateProfileEnumsService").getList("religion"));
            this.set("_arrEthnicOriginDropdown", this.get("_objCandidateProfileEnumsService").getList("ethnicOrigin"));
            this.set("_arrSexualOrientationDropdown", this.get("_objCandidateProfileEnumsService").getList("sexualOrientation"));
            this.set("_arrGenderIdentityDropdown", this.get("_objCandidateProfileEnumsService").getList("genderIdentity"));
            this.set("_arrDisabledDropdown", this.get("_objEnumsService").getList("disabled"));
        },
        setSelectedPopupValues: function setSelectedPopupValues(objData) {
            if (!a24Core.isEmpty(objData)) {
                this.setProperties({
                    objSelectedReligion: {
                        sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("religion", objData.religion),
                        sValue: objData.religion
                    },
                    objSelectedEthnicOrigin: {
                        sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("ethnicOrigin", objData.ethnic_origin),
                        sValue: objData.ethnic_origin
                    },
                    objSelectedSexualOrientation: {
                        sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("sexualOrientation", objData.sexual_orientation),
                        sValue: objData.sexual_orientation
                    },
                    objSelectedGenderIdentity: {
                        sLabel: this.get("_objCandidateProfileEnumsService").getFrontendValue("genderIdentity", objData.gender_identity),
                        sValue: objData.gender_identity
                    }
                });
                if (!a24Core.isEmpty(objData.disabled)) {
                    this.set("objSelectedDisabled", {
                        sLabel: this.get("_objEnumsService").getFrontendValue("disabled", objData.disabled),
                        sValue: objData.disabled === true || objData.disabled === "yes" ? "yes" : "no"
                    });
                } else {
                    this.set("objSelectedDisabled", {
                        sValue: null
                    });
                }
            }
        },
        getPayload: function getPayload(sCandidateId) {
            var objPayload = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());
            objPayload.candidate_id = sCandidateId;
            if (objPayload.disabled === "yes") {
                objPayload.disabled = true;
            } else if (objPayload.disabled === "no") {
                objPayload.disabled = false;
            } else {
                delete objPayload.disabled;
            }
            return objPayload;
        },
        updateEqualityMonitorDetails: function updateEqualityMonitorDetails() {
            var _this3 = this;

            var sCandidateId = Ember.get(this, "sCandidateId");

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("updatingEqualityDetails"));

            this.get("objEqualityMonitoringRestService").updateEquality(this, "_objCandidateEqualityMonitoringUpdatePromise", sCandidateId, this.getPayload(sCandidateId), function (objResult) {
                Ember.run.next(function () {
                    Ember.run.schedule("afterRender", function () {
                        _this3.set("_objOriginalData", _this3.getPlainFormValueObject());
                    });
                });
                _this3._candidateDataSuccess(objResult);
                _this3.get("_objSnackbarService").showSnackbarMessage(_this3.get("_objCandidateProfileStringsService").getString("equalityDetailsUpdateSuccess"));
                _this3.set("_bPopupLoading", false);
                _this3.set("bShowEditPopup", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                if (sCode === "PATTERN") {
                    _this3.setResponseError(objError.arrPath, "validationRestInvalidFormat");
                } else if (sCode === "INVALID_TYPE") {
                    _this3.setResponseError(objError.arrPath, "validationRestUnsupportedValue");
                } else {
                    _this3.get("_objSnackbarService").showSnackbarMessage(_this3.get("_objCandidateProfileStringsService").getString("equalityDetailsUpdateFailure"));
                }
                _this3.set("_bPopupLoading", false);
            }));
        },


        actions: {
            onPopupClose: function onPopupClose() {

                var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));
                if (bDataMatches) {
                    this.set("bShowEditPopup", false);
                } else {
                    this.set("bShowUnsavedData", true);
                }
            },
            onPopupEditConfirm: function onPopupEditConfirm() {
                var bHasErrors = this.doFullValidation();
                if (!bHasErrors) {
                    this.set("_bPopupLoading", true);
                    this.updateEqualityMonitorDetails();
                }
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("bShowEditPopup", false);
                this.set("bShowUnsavedData", false);
            }
        }
    });
});
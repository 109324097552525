define("a24-ember-menu/components/left-menu-with-title-bar-wrapper", ["exports", "a24-ember-menu/templates/components/left-menu-with-title-bar-wrapper", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _leftMenuWithTitleBarWrapper, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        /*
         * Services
         */
        sLeftMenuService: "left-menu-service",
        sPageLoadingService: null,

        objLeftMenuService: Ember.computed("sLeftMenuService", function () {
            return Ember.getOwner(this).lookup("service:" + this.get("sLeftMenuService"));
        }),

        objNavigation: Ember.computed("sPageLoadingService", function () {
            if (a24Core.isEmpty(this.get("sPageLoadingService"))) {
                return null;
            }
            return Ember.getOwner(this).lookup("service:" + this.get("sPageLoadingService"));
        }),

        bPageLoading: Ember.computed("objNavigation.bPageLoading", function () {
            if (a24Core.isEmpty(this.get("objNavigation"))) {
                return false;
            }

            return !!this.get("objNavigation.bPageLoading");
        }),
        /*
         * Default values
         */
        layout: _leftMenuWithTitleBarWrapper.default,
        classNames: ["left-menu-with-title-bar-wrapper"],

        /*
         * @property sNavbarImageUrl
         * The navbar image url
         */
        sNavbarImageUrl: null,

        /*
         * @property sNavbarRightComponent
         * The component to render on the right of the navbar
         */
        sNavbarRightComponent: null,

        /*
         * @property sLeftMenuLoaderComponent
         * The component to render when the left menu is loading
         */
        sLeftMenuLoaderComponent: "loading-component",

        /*
         * @private @property _bIsLeftMenuOpen
         * True if the left menu is opened
         */
        _bIsLeftMenuOpen: false,

        /*
         * @private @property _bIsRightPanelOpen
         * True if the right panel is opened
         */
        _bIsRightPanelOpen: false,

        /*
         * Computed properties
         */
        _bIsDesktop: Ember.computed.or("media.isDesktop", "media.isJumbo"),
        sTopBarComponent: Ember.computed("objLeftMenuService.sTopBarComponent", function () {
            return Ember.get(this, "objLeftMenuService.sTopBarComponent");
        }),
        _iTopStyle: 0,
        _iTopStyleOffset: 0,
        _sTopStyleSafe: Ember.computed("_iTopStyle", function () {
            return Ember.String.htmlSafe("top: " + Ember.get(this, "_iTopStyle") + "px");
        }),
        _bIsPanelOpen: Ember.computed.or("_bIsLeftMenuOpen", "_bIsRightPanelOpen"),

        sRightPanelComponent: "",
        _sIcon: "",
        /*
         * Observers
         */
        _onRightPanelChange: Ember.observer("objLeftMenuService.sRightPanelComponent", "objLeftMenuService.iRightPanelComponentWidth", "objLeftMenuService.iRightPanelCollapsedWidth", function () {
            if (a24Core.isEmpty(this.get("sRightPanelComponent"))) {
                Ember.set(this, "objLeftMenuService.mRightPanelComponentData", null);
                Ember.set(this, "objLeftMenuService.iRightPanelComponentWidth", 300);
                Ember.set(this, "objLeftMenuService.sRightPanelState", "");
                Ember.set(this, "objLeftMenuService.iRightPanelCollapsedWidth", 50);
                Ember.set(this, "objLeftMenuService.iRightPanelWidth", 0);
            }

            this.set("sRightPanelComponent", Ember.get(this, "objLeftMenuService.sRightPanelComponent"));
            this.set("iRightPanelComponentWidth", Ember.get(this, "objLeftMenuService.iRightPanelComponentWidth"));

            Ember.run.scheduleOnce("afterRender", this, this._setDefaultLayout);
        }),

        _onRightConfigChange: Ember.observer("media.isDesktop", "media.isJumbo", function () {
            Ember.run.debounce(this, this._setDefaultLayout, 100);
        }),

        init: function init() {
            this._super.apply(this, arguments);
            Ember.get(this, "objLeftMenuService");
        },

        didInsertElement: function didInsertElement() {
            var _this = this;

            this._super.apply(this, arguments);

            Ember.run.schedule("afterRender", function () {
                _this._setDefaultLayout();
            });
        },
        _hideLeftMenu: function _hideLeftMenu() {
            var _ref = [$("#main"), $("#sidebar")],
                objMain = _ref[0],
                objSidebar = _ref[1];

            this._animate(objMain, "marginLeft", 0, "fast");
            var iLeftMenuMargin = 240 - parseInt(objSidebar.css("marginLeft"));
            this._animate(objSidebar, "left", 0 - iLeftMenuMargin, "fast", Ember.get(this, "objLeftMenuService.afterMenuAnimation"));
            if (Ember.get(this, "_bIsDesktop")) {
                objSidebar.removeClass("light-box-shadow");
            }
        },
        _collapseRightPanel: function _collapseRightPanel() {
            var _this2 = this;

            var _ref2 = [$("#main"), $("#rightPanelContainer"), $("#rightPanelContainer .right-panel-fader")],
                objMain = _ref2[0],
                objRightPanelContainer = _ref2[1],
                objRightPanelFader = _ref2[2];

            var iRightPanelComponentWidth = Ember.get(this, "objLeftMenuService.iRightPanelComponentWidth");
            var iRightPanelCollapsedWidth = Ember.get(this, "objLeftMenuService.iRightPanelCollapsedWidth");

            if (Ember.get(this, "_bIsDesktop")) {
                this._animate(objMain, "marginRight", iRightPanelCollapsedWidth, "fast");
                Ember.set(this, "objLeftMenuService.iRightPanelWidth", iRightPanelComponentWidth + iRightPanelCollapsedWidth);
            }
            Ember.set(this, "objLeftMenuService.sRightPanelState", "hideStart");

            if (Ember.get(this, "_bIsDesktop")) {
                //This is not correct but we are doing it like this currently so that other
                //components can know what size to animate to along with the right side.
                Ember.set(this, "objLeftMenuService.iRightPanelWidth", iRightPanelCollapsedWidth);
            }

            objRightPanelFader.css({ "width": "100%" });
            this._animate(objRightPanelFader, "opacity", 1, "fast");
            this._animate(objRightPanelContainer, "width", iRightPanelCollapsedWidth, "fast", function () {
                Ember.run(function () {
                    Ember.get(_this2, "_objDidRenderService").countRendered();
                });
                $(window).trigger("resize");
                if (Ember.get(_this2, "_bIsDesktop")) {
                    Ember.set(_this2, "objLeftMenuService.iRightPanelWidth", iRightPanelCollapsedWidth);
                }
                Ember.set(_this2, "objLeftMenuService.sRightPanelState", "hideEnd");
            });
        },

        /*
         * Actions
         */
        actions: {
            toggleMenu: function toggleMenu() {
                var _ref3 = [$("#main"), $("#sidebar")],
                    objMain = _ref3[0],
                    objSidebar = _ref3[1];


                if (Ember.get(this, "_bIsLeftMenuOpen")) {

                    this._hideLeftMenu();
                } else {
                    if (Ember.get(this, "_bIsDesktop")) {
                        this._animate(objMain, "marginLeft", 240, "fast");
                        objSidebar.addClass("light-box-shadow");
                    }
                    this._animate(objSidebar, "left", 0, "fast", Ember.get(this, "objLeftMenuService.afterMenuAnimation"));
                }

                this.toggleProperty("_bIsLeftMenuOpen");
            },
            closePanel: function closePanel() {
                if (Ember.get(this, "_bIsLeftMenuOpen")) {
                    this._hideLeftMenu();
                    this.toggleProperty("_bIsLeftMenuOpen");
                } else if (Ember.get(this, "_bIsRightPanelOpen")) {
                    this._collapseRightPanel();
                    Ember.set(this, "_sIcon", "chevron_left");
                    this.toggleProperty("_bIsRightPanelOpen");
                }
            },
            onMenuItemClick: function onMenuItemClick() {
                if (Ember.get(this, "_bIsLeftMenuOpen") && !Ember.get(this, "_bIsDesktop")) {
                    var _ref4 = [$("#main"), $("#sidebar")],
                        objMain = _ref4[0],
                        objSidebar = _ref4[1];


                    this._animate(objMain, "marginLeft", 0, "fast");
                    var iLeftMenuMargin = 240 - parseInt(objSidebar.css("marginLeft"));
                    this._animate(objSidebar, "left", 0 - iLeftMenuMargin, "fast", Ember.get(this, "objLeftMenuService.afterMenuAnimation"));

                    this.toggleProperty("_bIsLeftMenuOpen");
                }
            },
            toggleRightPanel: function toggleRightPanel() {
                var _this3 = this;

                var _ref5 = [$("#main"), $("#rightPanelContainer"), $("#rightPanelContainer .right-panel-fader")],
                    objMain = _ref5[0],
                    objRightPanelContainer = _ref5[1],
                    objRightPanelFader = _ref5[2];

                var iRightPanelComponentWidth = Ember.get(this, "objLeftMenuService.iRightPanelComponentWidth");
                var iRightPanelCollapsedWidth = Ember.get(this, "objLeftMenuService.iRightPanelCollapsedWidth");
                if (Ember.get(this, "_bIsRightPanelOpen")) {
                    // closing the right panel
                    Ember.set(this, "_sIcon", "chevron_left");
                    this._collapseRightPanel();
                } else {
                    // expanding the right panel
                    Ember.set(this, "_sIcon", "chevron_right");

                    if (Ember.get(this, "_bIsDesktop")) {
                        this._animate(objMain, "marginRight", iRightPanelComponentWidth + iRightPanelCollapsedWidth, "fast");
                        Ember.set(this, "objLeftMenuService.iRightPanelWidth", iRightPanelCollapsedWidth);
                    }

                    Ember.set(this, "objLeftMenuService.sRightPanelState", "showStart");

                    if (Ember.get(this, "_bIsDesktop")) {
                        //This is not correct but we are doing it like this currently so that other
                        //components can know what size to animate to along with the right side.
                        Ember.set(this, "objLeftMenuService.iRightPanelWidth", iRightPanelComponentWidth + iRightPanelCollapsedWidth);
                    }

                    this._animate(objRightPanelFader, "opacity", 0, "fast");
                    this._animate(objRightPanelContainer, "width", iRightPanelComponentWidth + iRightPanelCollapsedWidth, "fast", function () {
                        objRightPanelFader.css({ "width": "0%" });
                        Ember.run(function () {
                            Ember.get(_this3, "_objDidRenderService").countRendered();
                        });
                        $(window).trigger("resize");

                        if (Ember.get(_this3, "_bIsDesktop")) {
                            Ember.set(_this3, "objLeftMenuService.iRightPanelWidth", iRightPanelComponentWidth + iRightPanelCollapsedWidth);
                        }
                        Ember.set(_this3, "objLeftMenuService.sRightPanelState", "showEnd");
                    });
                }

                this.toggleProperty("_bIsRightPanelOpen");
            }
        },

        _observeRenderCount: Ember.observer("_objDidRenderService._iCountRender", function () {
            Ember.run.once(this, "_setMainContainerTop");
        }),

        _setMainContainerTop: function _setMainContainerTop() {
            if (a24Core.isEmpty(this.get("objLeftMenuService.sTopBarComponent"))) {
                this.set("_iTopStyle", 0);
                this.set("_iTopStyleOffset", 0);
            } else {
                this.set("_iTopStyle", this.$(".top-bar").height() + 2);
                this.set("_iTopStyleOffset", -2);
            }
        },

        /*
         * Private functions
         */
        _animate: function _animate(objElement, sProp, iValue, sDuration, funcComplete) {
            objElement.animate(_defineProperty({}, sProp, iValue), {
                duration: sDuration,
                complete: funcComplete
            });
        },
        _setDefaultLayout: function _setDefaultLayout() {
            var _ref6 = [$("#main"), $("#sidebar"), $("#rightPanel"), $("#rightPanelContainer"), $("#rightPanelContainer .right-panel-fader")],
                objMain = _ref6[0],
                objSidebar = _ref6[1],
                objRightPanel = _ref6[2],
                objRightPanelComponent = _ref6[3],
                objRightPanelFader = _ref6[4];

            var bIsDesktop = Ember.get(this, "_bIsDesktop");
            var iRightPanelComponentWidth = Ember.get(this, "objLeftMenuService.iRightPanelComponentWidth");
            var iRightPanelCollapsedWidth = Ember.get(this, "objLeftMenuService.iRightPanelCollapsedWidth");

            if (bIsDesktop) {
                objMain.css({ "marginLeft": 240 });
                objSidebar.css({ "left": 0 });
                objSidebar.addClass("light-box-shadow");

                if (!a24Core.isEmpty(this.get("sRightPanelComponent")) && !a24Core.isEmpty(objRightPanelComponent)) {
                    // set right panel component to expanded
                    objMain.css({ "marginRight": iRightPanelComponentWidth + iRightPanelCollapsedWidth });
                    objRightPanelComponent.css({ "width": iRightPanelComponentWidth + iRightPanelCollapsedWidth });
                    objRightPanel.css({ "width": iRightPanelComponentWidth + iRightPanelCollapsedWidth });
                    objRightPanelFader.css({ width: "0%", opacity: 0 });
                    Ember.set(this, "_sIcon", "chevron_right");
                    Ember.set(this, "objLeftMenuService.iRightPanelWidth", iRightPanelComponentWidth + iRightPanelCollapsedWidth);
                    Ember.set(this, "objLeftMenuService.sRightPanelState", "showEnd");
                } else {
                    objMain.css({ "marginRight": 0 });
                    Ember.set(this, "objLeftMenuService.iRightPanelWidth", 0);
                    Ember.set(this, "objLeftMenuService.sRightPanelState", "");
                }
            } else {
                objMain.css({ "marginLeft": 0 });
                objSidebar.css({ "left": -240 });
                if (!a24Core.isEmpty(this.get("sRightPanelComponent")) && !a24Core.isEmpty(objRightPanelComponent)) {
                    // set right panel component to collapsed
                    objMain.css({ "marginRight": iRightPanelCollapsedWidth });
                    objRightPanelComponent.css({ "width": iRightPanelCollapsedWidth });
                    objRightPanel.css({ "width": iRightPanelComponentWidth + iRightPanelCollapsedWidth });
                    objRightPanelFader.css({ width: "100%", opacity: 1 });
                    Ember.set(this, "_sIcon", "chevron_left");
                    Ember.set(this, "objLeftMenuService.iRightPanelWidth", iRightPanelCollapsedWidth);
                    Ember.set(this, "objLeftMenuService.sRightPanelState", "hideEnd");
                } else {
                    objMain.css({ "marginRight": 0 });
                    Ember.set(this, "objLeftMenuService.iRightPanelWidth", 0);
                    Ember.set(this, "objLeftMenuService.sRightPanelState", "");
                }
            }

            Ember.set(this, "_bIsLeftMenuOpen", bIsDesktop);
            Ember.set(this, "_bIsRightPanelOpen", bIsDesktop);
        }
    });
});
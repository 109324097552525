define("a24-ember-window/mixins/did-render-changes-mixin", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        _objDidRenderService: Ember.inject.service("component-did-render"),
        _objDomService: Ember.inject.service("dom-event"),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get("_objDomService");

            window.a24DidRenderCountIncrease = function () {
                Ember.run(function () {
                    _this.get("_objDidRenderService").countRendered();
                });
            };
        },
        didRender: function didRender() {
            this._super.apply(this, arguments);
            this.sizeChanges();
        },
        sizeChanges: Ember.observer("_objDomService.iPageWidth", "_objDomService.iPageHeight", function () {
            this.get("_objDidRenderService").countRendered();
        })
    });
});
define("a24-ember-candidate-profile/components/tab-agency-group-candidate-profile-external-info", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/tab-agency-group-candidate-profile-external-info"], function (exports, _didRenderChangesMixin, _tabAgencyGroupCandidateProfileExternalInfo) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _tabAgencyGroupCandidateProfileExternalInfo.default,
        _objNavigationService: Ember.inject.service("navigation"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objUserSession: Ember.inject.service("user-session"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objBasicInfoService: Ember.inject.service("rest-agency-group-candidate-basic-info"),
        _objCandidateAdvancedSearchRestService: Ember.inject.service("rest-agency-group-candidate-advanced-search"),
        _arrExternalData: null,
        _arrExternalDocumentsData: null,
        _arrExternalVerificationsData: null,
        _arrExternalDaysToExpiryData: null,
        _bDisplayReloadCard: false,
        _arrReloadActions: null,
        _bLoading: true,
        _bDisplayPermissionError: false,
        _sActivityContext: null,
        _objBasicInfoGetPromise: null,
        _objCandidateSearchPostPromise: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var objCandidateViewParams = this.get("_objNavigationService.objRouteParams")["agency-group.candidate-view"];
            var objCandidateSearchViewParams = this.get("_objNavigationService.objRouteParams")["agency-group.candidate-search-view"];

            if (!a24Core.isEmpty(objCandidateViewParams)) {
                this.set("_sCandidateId", objCandidateViewParams.candidate_id);
            }
            if (!a24Core.isEmpty(objCandidateSearchViewParams)) {
                this.set("_sCandidateId", objCandidateSearchViewParams.candidate_id);
            }

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.doServiceCalls();
                }
            }]);

            this.doServiceCalls();
        },
        doServiceCalls: function doServiceCalls() {
            var _this2 = this;

            var sCandidateId = this.get("_sCandidateId");

            var objRestBasicInfoPromise = this.get("_objBasicInfoService").getBasicInfo(this, "_objBasicInfoGetPromise", sCandidateId, function () {}, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
            function () {
                // do nothing
            }));

            var objRestCandidateAdvancedSearchPromise = this.get("_objCandidateAdvancedSearchRestService").searchCandidate(this, "_objCandidateSearchPostPromise", sCandidateId, function () {}, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this2.set("_bDisplayReloadCard", true);
                    return;
                }

                // Clear data
                _this2.set("_arrExternalData", []);
                _this2.set("_arrExternalDocumentsData", []);
                _this2.set("_arrExternalVerificationsData", []);
                _this2.set("_bLoading", false);
            }));

            var objServiceCalls = {
                _objRestBasicInfoProperty: objRestBasicInfoPromise,
                _objRestCandidateAdvancedSearchProperty: objRestCandidateAdvancedSearchPromise
            };

            var arrRequiredResponses = ["_objRestCandidateAdvancedSearchProperty"];

            this.set("_bLoading", true);
            Ember.copy(a24RSVP).configure(this, objServiceCalls, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objRestBasicInfoProperty) && !a24Core.isEmpty(objResponses._objRestBasicInfoProperty.objHeaders) && objResponses._objRestBasicInfoProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRestCandidateAdvancedSearchProperty) && !a24Core.isEmpty(objResponses._objRestCandidateAdvancedSearchProperty.objHeaders) && objResponses._objRestCandidateAdvancedSearchProperty.objHeaders.statusText === "abort") {
                        return;
                    }
                    if (!a24Core.isEmpty(objResponses._objRestBasicInfoProperty) && !a24Core.isEmpty(objResponses._objRestBasicInfoProperty.objResponse)) {
                        var objData = objResponses._objRestBasicInfoProperty.objResponse;
                        var sCandidateProfile = _this2.get("_objCandidateProfileStringsService").getString("candidateProfile");
                        var sCandidateName = objData.first_name + " " + objData.last_name;

                        _this2.set("_objTitleBarService.sTitle", sCandidateProfile + " - " + sCandidateName);
                    }

                    if (!a24Core.isEmpty(objResponses._objRestCandidateAdvancedSearchProperty) && !a24Core.isEmpty(objResponses._objRestCandidateAdvancedSearchProperty.objResponse)) {
                        var objAdvancedSearchData = objResponses._objRestCandidateAdvancedSearchProperty.objResponse[0];
                        var arrExternalGeneral = [];
                        var sDbsStatus = "";
                        if (!a24Core.isEmpty(objAdvancedSearchData.dbs_status)) {
                            sDbsStatus = _this2.get("_objCandidateProfileEnumsService").getFrontendValue("dbsStatuses", objAdvancedSearchData.dbs_status);
                        }

                        var sLastContacted = "";
                        if (!a24Core.isEmpty(objAdvancedSearchData.last_contacted_at)) {
                            var objLastContactedyDateTime = a24DateManager.createDate(_this2.get("_objUserSession").get("objContextDetails.sTimezone"));
                            objLastContactedyDateTime.setDateFromRest(objAdvancedSearchData.last_contacted_at);
                            sLastContacted = objLastContactedyDateTime.getDateFormat();
                        }
                        arrExternalGeneral = [{
                            sLabel: _this2.get("_objCandidateProfileStringsService").getString("dbsStatus"),
                            sValue: sDbsStatus,
                            bText: true
                        }, {
                            sLabel: _this2.get("_objCandidateProfileStringsService").getString("lastContacted"),
                            sValue: sLastContacted,
                            bText: true
                        }];

                        _this2.set("_arrExternalData", arrExternalGeneral);

                        var arrExternalDocuments = [];
                        if (!a24Core.isEmpty(objAdvancedSearchData.external_documents)) {
                            arrExternalDocuments = objAdvancedSearchData.external_documents;
                        }

                        _this2.set("_arrExternalDocumentsData", arrExternalDocuments);

                        var arrExternalVerifications = [];
                        if (!a24Core.isEmpty(objAdvancedSearchData.external_verifications)) {
                            arrExternalVerifications = objAdvancedSearchData.external_verifications;
                        }

                        _this2.set("_arrExternalVerificationsData", arrExternalVerifications);

                        var arrExternalDaysToExpiry = [];
                        if (!a24Core.isEmpty(objAdvancedSearchData.external_days_to_expiry)) {
                            arrExternalDaysToExpiry = objAdvancedSearchData.external_days_to_expiry;
                        }

                        _this2.set("_arrExternalDaysToExpiryData", arrExternalDaysToExpiry);

                        _this2.set("_bLoading", false);
                    }
                }
            }]);
        }

    });
});
define("a24-ember-lib/services/a24-constants-ember-lib", ["exports", "a24-ember-lookup/services/a24-constants-base"], function (exports, _a24ConstantsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24ConstantsBase.default.extend({

        //######### Should all go where all the date things will be
        DATE_FORMAT: "DD MMM YYYY",
        DATE_FORMAT_ISO_FORMAT: "YYYY-MM-DDTHH:mm:ssZ",
        DATE_TIME_FORMAT: "DD MMM YYYY HH:mm",
        DATE_TIME_DASH_FORMAT: "DD MMM YYYY - HH:mm",
        TIME_FORMAT: "HH:mm",
        YEAR_FORMAT: "YYYY",
        MONTH_FORMAT: "MMM",
        MONTH_YEAR_FORMAT: "MMM YYYY",
        DAY_FORMAT: "DD",
        DAY_DATE_FORMAT: "ddd DD MMM YYYY",

        MILLISECONDS_1_SECOND: 1000,
        MILLISECONDS_1_MINUTE: 60000,
        MILLISECONDS_1_DAY: 86400000,

        MOMENT_TIME_YEARS: "years",
        MOMENT_TIME_MONTHS: "months",
        MOMENT_TIME_WEEKS: "weeks",
        MOMENT_TIME_DATE: "date",
        MOMENT_TIME_DAYS: "days",
        MOMENT_TIME_HOURS: "hours",
        MOMENT_TIME_MINUTES: "minutes",
        MOMENT_TIME_SECONDS: "seconds",

        MOMENT_UNIT_ISO_WEEK: "isoWeek",
        MOMENT_UNIT_YEAR: "year",
        MOMENT_UNIT_MONTH: "month",
        MOMENT_TIMEZONE_UTC: "UTC",

        MAX_END_HOUR: 23,
        MAX_END_MIN: 59,
        MAX_END_SEC: 59,

        //######### Should all go where the header and icons will go
        // Round Icon Predefined Sizes
        ROUND_ICON_SIZE_SMALL: "round-icon-component-small",
        ROUND_ICON_SIZE_MEDIUM: "round-icon-component-medium",
        ROUND_ICON_SIZE_LARGE: "round-icon-component-large",
        ROUND_ICON_SIZE_EXTRA_LARGE: "round-icon-component-extra-large",
        ROUND_ICON_SIZE_JUMBO: "round-icon-component-jumbo",
        ROUND_ICON_SIZE_EXTRA_LARGE_PX: 60,
        // Text/Icon Size
        ROUND_ICON_CONTENT_SIZE_SMALL: "round-icon-component-content-small",
        ROUND_ICON_CONTENT_SIZE_MEDIUM: "round-icon-component-content-medium",
        ROUND_ICON_CONTENT_SIZE_LARGE: "round-icon-component-content-large",
        ROUND_ICON_CONTENT_SIZE_JUMBO: "round-icon-component-content-jumbo",

        //Font sizes
        FONT_SIZE_H4_PX: 24,

        //Round Icon Header Predefined Sizes
        ROUND_ICON_HEADER_SIZE_SMALL: "heading-small",
        ROUND_ICON_HEADER_SIZE_MEDIUM: "heading-medium",
        ROUND_ICON_HEADER_SIZE_LARGE: "heading-large",

        CARD_DATAGRID_FILTER_QUERY_TYPE_CONTAINS: "contains",
        CARD_DATAGRID_FILTER_QUERY_TYPE_IS: "is",
        CARD_DATAGRID_FILTER_QUERY_TYPE_IS_NOT: "is_not",
        CARD_DATAGRID_FILTER_QUERY_TYPE_STARTS_WITH: "starts_with",
        CARD_DATAGRID_FILTER_QUERY_TYPE_ENDS_WITH: "ends_with",

        //######### Generic ones, can move to related addons should they ever move to own addons
        GOOGLE_ANALYTICS_PAGE_VIEW_ID_TOKEN: "XXXXXX",
        URL_VALUE_POPUPS: "r-pop",

        //######### Should all go where all the color codes things will be
        COLOR_SUCCESS: "#00C851",
        COLOR_WARNING: "#ffbb33"
    });
});
define("a24-ember-candidate-profile/components/candidate-dismissals", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-dismissals", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _candidateDismissals, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateDismissals.default,
        classNames: ["candidate-dismissals"],
        objSettingsRestService: null,
        sCandidateId: null,
        objEmploymentDismissalsRestService: null,
        _bLoading: false,
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objEnumsService: Ember.inject.service("a24-enums"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _sPopupHeader: null,
        _arrEmploymentDismissalsData: null,
        _arrEmploymentDismissalsDataView: null,
        _objOriginalData: null,
        _objSpecialEditPopupAction: null,
        _objEmploymentDissmisalsGetPromise: null,
        _objEmploymentDissmisalsCreatePromise: null,
        _objEmploymentDissmisalsUpdatePromise: null,
        _objEmploymentDissmisalsDeletePromise: null,
        _arrActionItems: null,
        _bAllowEdit: true,
        _bShowUnsavedData: false,
        _bShowEditPopup: false,
        _bPopupLoading: false,
        _bServerError: false,
        _arrReloadActions: null,
        _arrYesNoRadioButtonItems: null,
        _bShowTextArea: false,
        _objDismissalSelected: null,
        _bReason: false,
        _sReason: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.set("objChildConfig", {
                "objChildren.bDismissal": function objChildrenBDismissal(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.reason": function objChildrenReason(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                }
            });

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.getCandidateEmploymentDismissals();
                }
            }]);

            if (!this.get("objSettingsRestService.objSettings.employment_history_editable")) {
                this.set("_bAllowEdit", false);
            }

            this.set("_objSpecialEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupClose");
                }
            });

            this.set("_arrActionItems", []);

            var sPopupHeader = this.get("_objStringsService").getTokenString("editItem", this.get("_objCandidateProfileStringsService").getString("dismissal"));

            this.set("_sPopupHeader", sPopupHeader);

            this.set("_arrYesNoRadioButtonItems", this.get("_objEnumsService").getList("yesNo"));
            this.getCandidateEmploymentDismissals();
        },
        onInputValueChanged: function onInputValueChanged(arrPathToInputMixin) {

            var sLookup = arrPathToInputMixin.join(".");

            if (sLookup === "objChildren.bDismissal") {
                this.set("_bShowTextArea", this.get(sLookup + ".mValue.sValue") === this.get("_objStringsService").getString("yes"));
            }
        },
        getCandidateEmploymentDismissals: function getCandidateEmploymentDismissals() {
            var _this2 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);

            var sCandidateId = this.get("sCandidateId");

            this.get("objEmploymentDismissalsRestService").getEmploymentDismissals(this, "_objEmploymentDissmisalsGetPromise", sCandidateId, function (arrData) {
                _this2.getEmploymentDismissalsDone();
                _this2.prepareForDisplay(arrData);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
            function (sCode) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this2.set("_bServerError", true);
                    return;
                }
                _this2.getEmploymentDismissalsDone();
                _this2.prepareForDisplay([]);
            }));
        },
        getEmploymentDismissalsDone: function getEmploymentDismissalsDone() {
            var _this3 = this;

            if (this.get("_bAllowEdit") && (this.get("objEmploymentDismissalsRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE") || this.get("objEmploymentDismissalsRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP"))) {
                this.set("_arrActionItems", [{
                    sTitle: this.get("_objStringsService").getString("edit"),
                    sIcon: "edit",
                    executeCardAction: function executeCardAction() {
                        _this3.openEditPopup();
                    }
                }]);
            }

            this.set("_bLoading", false);
        },
        setRadioButtonInputValue: function setRadioButtonInputValue(sProperty, bValue) {
            if (bValue) {
                this.set(sProperty, {
                    sValue: this.get("_objStringsService").getString("yes"),
                    sLabel: this.get("_objStringsService").getString("yes")
                });
            } else {
                this.set(sProperty, {
                    sValue: this.get("_objStringsService").getString("no"),
                    sLabel: this.get("_objStringsService").getString("no")
                });
            }
        },
        prepareForDisplay: function prepareForDisplay(arrData) {
            this.set("_arrEmploymentDismissalsData", arrData);

            var sDismisal = this.get("_objStringsService").getString("no");
            if (!a24Core.isEmpty(arrData)) {
                sDismisal = this.get("_objStringsService").getString("yes");
            }

            var arrItems = [{
                sLabel: this.get("_objCandidateProfileStringsService").getString("haveYouEverBeenDismissed"),
                sValue: sDismisal,
                bText: true
            }];

            this.set("_arrEmploymentDismissalsDataView", [{
                arrItems: arrItems
            }]);

            if (!a24Core.isEmpty(arrData) && !a24Core.isEmpty(arrData[0]) && !a24Core.isEmpty(arrData[0].reason)) {
                this.set("_bReason", true);
                this.set("_sReason", arrData[0].reason);
            } else {
                this.set("_bReason", false);
            }
        },
        openEditPopup: function openEditPopup() {
            var _this4 = this;

            this.clearAllErrorMessages();
            this.clearForm();
            this.set("_bShowEditPopup", true);
            if (!a24Core.isEmpty(this.get("setBreadCrumbAction"))) {
                this.get("setBreadCrumbAction")();
            }

            var arrEmploymentDismissalsData = this.get("_arrEmploymentDismissalsData");
            if (!a24Core.isEmpty(arrEmploymentDismissalsData)) {
                // choose yes if not empty
                this.setRadioButtonInputValue("_objDismissalSelected", true);
                this.get("objChildren")["reason"].setValueFromOutside(arrEmploymentDismissalsData[0].reason);
            } else {
                // choose no if empty response
                this.setRadioButtonInputValue("_objDismissalSelected", false);
            }

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this4.set("_objOriginalData", _this4.getPlainFormValueObject());
                });
            });
        },
        getPayload: function getPayload() {
            var objPayload = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

            // remove bDismissal
            delete objPayload.bDismissal;

            return objPayload;
        },
        createEmploymentDismissal: function createEmploymentDismissal() {
            var _this5 = this;

            this.set("_bPopupLoading", true);

            var sCandidateId = this.get("sCandidateId");

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objCandidateProfileStringsService").getString("dismissal")));

            this.get("objEmploymentDismissalsRestService").createEmploymentDismissal(this, "_objEmploymentDissmisalsCreatePromise", sCandidateId, this.getPayload(), function () {
                _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("successUpdateItem", _this5.get("_objCandidateProfileStringsService").getString("dismissal")));
                _this5.set("_bPopupLoading", false);
                _this5.set("_bShowEditPopup", false);
                _this5.getCandidateEmploymentDismissals();
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("failUpdateItem", _this5.get("_objCandidateProfileStringsService").getString("dismissal")));

                _this5.set("_bPopupLoading", false);
            }));
        },
        updateEmploymentDismissal: function updateEmploymentDismissal() {
            var _this6 = this;

            this.set("_bPopupLoading", true);

            var sCandidateId = this.get("sCandidateId");
            var arrEmploymentDismissalsData = this.get("_arrEmploymentDismissalsData");
            var sEmploymentDismissalId = arrEmploymentDismissalsData[0]._id;

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objCandidateProfileStringsService").getString("dismissal")));

            this.get("objEmploymentDismissalsRestService").updateEmploymentDismissal(this, "_objEmploymentDissmisalsUpdatePromise", sCandidateId, sEmploymentDismissalId, this.getPayload(), function () {
                _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objStringsService").getTokenString("successUpdateItem", _this6.get("_objCandidateProfileStringsService").getString("dismissal")));
                _this6.set("_bPopupLoading", false);
                _this6.set("_bShowEditPopup", false);
                _this6.getCandidateEmploymentDismissals();
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objStringsService").getTokenString("failUpdateItem", _this6.get("_objCandidateProfileStringsService").getString("dismissal")));

                _this6.set("_bPopupLoading", false);
            }));
        },
        deleteEmploymentDismissal: function deleteEmploymentDismissal() {
            var _this7 = this;

            this.set("_bPopupLoading", true);

            var sCandidateId = this.get("sCandidateId");
            var arrEmploymentDismissalsData = this.get("_arrEmploymentDismissalsData");
            var sEmploymentDismissalId = arrEmploymentDismissalsData[0]._id;

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objCandidateProfileStringsService").getString("dismissal")));

            this.get("objEmploymentDismissalsRestService").deleteEmploymentDismissal(this, "_objEmploymentDissmisalsDeletePromise", sCandidateId, sEmploymentDismissalId, function () {
                _this7.get("_objSnackbarService").showSnackbarMessage(_this7.get("_objStringsService").getTokenString("successUpdateItem", _this7.get("_objCandidateProfileStringsService").getString("dismissal")));
                _this7.set("_bPopupLoading", false);
                _this7.set("_bShowEditPopup", false);
                _this7.getCandidateEmploymentDismissals();
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this7.get("_objSnackbarService").showSnackbarMessage(_this7.get("_objStringsService").getTokenString("failUpdateItem", _this7.get("_objCandidateProfileStringsService").getString("dismissal")));

                _this7.set("_bPopupLoading", false);
            }));
        },
        actions: {
            onPopupClose: function onPopupClose() {
                if (!this.get("_bPopupLoading")) {
                    var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));
                    if (bDataMatches) {
                        this.set("_bShowEditPopup", false);
                    } else {
                        this.set("_bShowUnsavedData", true);
                    }
                }
            },

            onPopupEditConfirm: function onPopupEditConfirm() {
                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    var arrEmploymentDismissalsData = this.get("_arrEmploymentDismissalsData");
                    if (!a24Core.isEmpty(arrEmploymentDismissalsData) && this.get("objChildren.bDismissal.mValue.sValue") === this.get("_objStringsService").getString("no")) {
                        // delete
                        this.deleteEmploymentDismissal();
                    } else if (!a24Core.isEmpty(arrEmploymentDismissalsData) && this.get("objChildren.bDismissal.mValue.sValue") === this.get("_objStringsService").getString("yes")) {
                        // put with reason
                        this.updateEmploymentDismissal();
                    } else if (a24Core.isEmpty(arrEmploymentDismissalsData) && this.get("objChildren.bDismissal.mValue.sValue") === this.get("_objStringsService").getString("yes")) {
                        // post with reason
                        this.createEmploymentDismissal();
                    } else {
                        // do nothing
                        this.set("_bShowEditPopup", false);
                    }
                }
            },

            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowEditPopup", false);
                this.set("_bShowUnsavedData", false);
            }
        }
    });
});
define("a24-ember-candidate-profile/components/candidate-professional-registration", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-professional-registration", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _candidateProfessionalRegistration, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateProfessionalRegistration.default,

        sCandidateId: null,
        sCandidateType: null,
        sCandidateTypeName: null,
        objSettingsRestService: null,
        objCandidateRegistrationBodyRestService: null,

        _bAllowAddEdit: false,
        _bServerError: false,
        _bIsAdd: false,
        _bNoData: false,

        _bShowRegistrationBodyAddEditEntryPopup: false,
        _bAddEditRegistrationBodyEntryPopupLoading: false,
        _bShowAddEditRegistrationBodyPopup: false,
        _bAddEditRegistrationBodyPopupLoading: false,
        _bShowRegistrationBodyDeletePopup: false,
        _bRegistrationBodyDeletePopupLoading: false,
        _bShowUnsavedData: false,
        _bShowRegistrationBodyEntryDeletePopup: false,
        _bRegistrationBodyEntryDeletePopupLoading: false,
        _bShowRegBodyEntryViewPopup: false,

        _objSelectedSystemRegistrationBodyStatus: null,
        _objRegBodyEntryView: null,
        _arrSystemRegistrationBodyStatuses: null,
        _sRegistrationBodyEntryId: null,
        _sCardHeader: null,
        _sSystemRegistrationBodyId: null,
        _sAddEditRegistrationBodyEntryHeader: null,
        _objSpecialAddEditRegistrationBodyPopupAction: null,
        _arrSystemRegistrationBodies: null,
        _arrCandidateRegistrationBody: null,
        _arrDisplayCandidateRegistrationBody: null,
        _objCandidateRegistrationBodyServicePromise: null,
        _objSystemServiceRegistrationBodyPromise: null,
        _sRegistrationBodyId: null,
        _sAddEditRegistrationBodyHeader: null,
        _objOriginalData: null,
        _objSelectedSystemRegistrationBody: null,
        _arrReloadActions: null,
        _arrSystemRegistrationBodyEntries: null,
        _arrSystemRegistrationBodyEntryStatuses: null,
        _objSystemServiceRegistrationBodyEntriesPromise: null,
        _objSystemServiceRegistrationBodyStatusesPromise: null,
        _objSpecialAddEditRegistrationBodyEntryPopupAction: null,
        _objSpecialViewRegBodyEntryPopupAction: null,
        _arrSpecialViewRegBodyEntryPopupAction: null,

        _objUserSession: Ember.inject.service("user-session"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objCandidateEnums: Ember.inject.service("a24-enums-candidate-profile"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objSystemService: Ember.inject.service("rest-system"),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_sAddEditRegistrationBodyEntryHeader", this.get("_objStringsService").getTokenString("addItem", this.get("_objStringsCandidateProfile").getString("registrationBodyEntry")));

            this.set("_objSpecialAddEditRegistrationBodyPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._onPopupRegistrationBodyAddEditCancel();
                }
            });

            this.set("_objSpecialAddEditRegistrationBodyEntryPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._onPopupRegistrationBodyEntryAddEditCancel();
                }
            });

            this.set("_objSpecialViewRegBodyEntryPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._onPopupRegBodyEntryViewCancel();
                }
            });

            if (this.get("_bIsAgencyGroup")) {
                this.set("_arrSpecialViewRegBodyEntryPopupAction", [{
                    sTitle: this.get("_objStringsService").getString("remove"),
                    sIcon: "delete",
                    executeCardAction: function executeCardAction() {
                        _this.set("_bShowRegistrationBodyEntryDeletePopup", true);
                    }
                }]);
            }

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objStringsCandidateProfile").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this._getCandidateRegistrationBody();
                }
            }]);

            this.set("_arrSystemRegistrationBodies", []);
            this.set("_arrSystemRegistrationBodyStatuses", []);

            var objValidationConfig = {};

            objValidationConfig["objChildren.registration_body_id"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.reference"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.registration_body_status_id"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            objValidationConfig["objChildren.registration_body_entry_id"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.status_id"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            this.set("objChildConfig", objValidationConfig);

            this._getCandidateRegistrationBody();
        },
        _bIsAgency: Ember.computed("objCandidateRegistrationBodyRestService.sContext", function () {
            return this.get("objCandidateRegistrationBodyRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY");
        }),
        _bIsAgencyGroup: Ember.computed("objCandidateRegistrationBodyRestService.sContext", function () {
            return this.get("objCandidateRegistrationBodyRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP");
        }),
        onCandidateTypeChange: Ember.observer("sCandidateType", function () {
            this.set("_arrSystemRegistrationBodies", null);
            this._getCandidateRegistrationBody();
        }),
        _getCandidateRegistrationBody: function _getCandidateRegistrationBody() {
            var _this2 = this;

            if (!this.get("objSettingsRestService.objSettings.professional_registration_editable") || a24Core.isEmpty(this.get("objSettingsRestService.objSettings.agency_groups")) || a24Core.isEmpty(this.get("sCandidateType"))) {
                this.set("_bAllowAddEdit", false);
                return;
            } else {
                this.set("_bAllowAddEdit", true);
            }

            this.set("_sCardHeader", this.get("_objStringsCandidateProfile").getTokenString("professionalRegistrationHeader", this.get("sCandidateTypeName")));

            this.set("_bLoading", true);
            this.set("_bServerError", false);

            this.get("objCandidateRegistrationBodyRestService").getRegistrationBody(this, "_objCandidateRegistrationBodyServicePromise", this.get("sCandidateId"), function (arrResponse) {
                _this2.set("_bLoading", false);
                _this2._prepareForDisplay(arrResponse);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this2.set("_bLoading", false);
                _this2.set("_bServerError", true);
            }), {
                items_per_page: {
                    mValue: 1000
                },
                position_types: {
                    mValue: this.get("sCandidateType")
                }
            });
        },
        onRegistrationBodyEntryTagClearClicked: function onRegistrationBodyEntryTagClearClicked(objRegistrationBody, objEntry) {
            this.set("_bShowRegistrationBodyEntryDeletePopup", true);
            this.set("_sRegistrationBodyId", objRegistrationBody._id);
            this.set("_sRegistrationBodyEntryId", objEntry._id);
        },
        onRegBodyEntryTagClicked: function onRegBodyEntryTagClicked(objRegistrationBody, objEntry) {
            this.set("_sRegistrationBodyId", objRegistrationBody._id);
            this.set("_sRegistrationBodyEntryId", objEntry._id);

            var sObtainedDate = null;
            var sValidDate = null;
            var sExpiryDate = null;

            if (!a24Core.isEmpty(objEntry.obtained_at)) {
                var objObtainedDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                objObtainedDate.setDateFromRest(objEntry.obtained_at, true);
                sObtainedDate = objObtainedDate.getDateFormat();
            }

            if (!a24Core.isEmpty(objEntry.valid_at)) {
                var objValidDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                objValidDate.setDateFromRest(objEntry.valid_at, true);
                sValidDate = objValidDate.getDateFormat();
            }

            if (!a24Core.isEmpty(objEntry.expires_at)) {
                var objExpiredDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                objExpiredDate.setDateFromRest(objEntry.expires_at, true);
                sExpiryDate = objExpiredDate.getDateFormat();
            }

            var arrItems = [{
                sLabel: this.get("_objStringsService").getString("name"),
                sValue: objEntry.registration_body_entry.name,
                bText: true
            }, {
                sLabel: this.get("_objStringsCandidateProfile").getString("shortName"),
                sValue: objEntry.registration_body_entry.short_name,
                bText: true
            }, {
                sLabel: this.get("_objStringsService").getString("status"),
                sValue: objEntry.status.name,
                bText: true
            }, {
                sLabel: this.get("_objStringsCandidateProfile").getString("obtainedAt"),
                sValue: sObtainedDate,
                bDate: true
            }, {
                sLabel: this.get("_objStringsCandidateProfile").getString("validFrom"),
                sValue: sValidDate,
                bDate: true
            }, {
                sLabel: this.get("_objStringsCandidateProfile").getString("expiresOn"),
                sValue: sExpiryDate,
                bDate: true
            }];

            this.set("_objRegBodyEntryView", [{
                arrItems: arrItems
            }]);
            this.set("_bShowRegBodyEntryViewPopup", true);
        },
        _prepareForDisplay: function _prepareForDisplay(arrResponse) {
            var _this3 = this;

            if (this.get("_bAllowAddEdit") && !this.get("_bIsAgency")) {
                this.get("_objFabButtonService").showFabButton(function () {
                    _this3._showAddEditRegistrationBodyPopup();
                });
            }

            if (a24Core.isEmpty(arrResponse)) {
                this.set("_bNoData", true);
                this.set("_arrCandidateRegistrationBody", []);
                this.set("_arrDisplayCandidateRegistrationBody", []);
            } else {
                this.set("_bNoData", false);
                this.set("_arrCandidateRegistrationBody", arrResponse);

                var objThis = this;

                var funcCardActionEditExecute = function funcCardActionEditExecute(objItem) {
                    return function () {
                        objThis._onRegistrationBodyEditClicked(objItem);
                    };
                };

                var funcCardActionRemoveExecute = function funcCardActionRemoveExecute(objItem) {
                    return function () {
                        objThis._onRegistrationBodyRemoveClicked(objItem);
                    };
                };

                var funcCardActionAddExecute = function funcCardActionAddExecute(objItem) {
                    return function () {
                        objThis._onRegistrationBodyAddClicked(objItem);
                    };
                };

                var funcGroupFilterExecute = function funcGroupFilterExecute(objItem) {
                    return function (objComponent) {
                        return objItem.status.name === objComponent.objData.mValue.status.name;
                    };
                };

                var funcTagClearExecute = function funcTagClearExecute(objRegistrationBody) {
                    return function (sTitle, objItem) {
                        objThis.onRegistrationBodyEntryTagClearClicked(objRegistrationBody, objItem);
                    };
                };

                var funcTagClickedExecute = function funcTagClickedExecute(objRegistrationBody) {
                    return function (sTitle, objItem) {
                        objThis.onRegBodyEntryTagClicked(objRegistrationBody, objItem);
                    };
                };

                var arrItems = arrResponse.map(function (objItem) {
                    var arrHeaderActions = [];
                    var arrComponents = [];
                    var arrGroupings = [];

                    if ((objItem.status.editable || _this3.get("_bIsAgencyGroup")) && !_this3.get("_bIsAgency")) {
                        arrHeaderActions.pushObject({
                            sTitle: _this3.get("_objStringsService").getString("edit"),
                            sIcon: "edit",
                            executeCardAction: funcCardActionEditExecute(objItem)
                        });
                        arrHeaderActions.pushObject({
                            sTitle: _this3.get("_objStringsService").getString("remove"),
                            sIcon: "delete",
                            executeCardAction: funcCardActionRemoveExecute(objItem)
                        });

                        if (_this3.get("_bIsAgencyGroup")) {
                            arrHeaderActions.pushObject({
                                sTitle: _this3.get("_objStringsService").getString("add"),
                                sIcon: "add",
                                executeCardAction: funcCardActionAddExecute(objItem)
                            });
                        }
                    }

                    if (!a24Core.isEmpty(objItem.registration_body_entries)) {
                        arrGroupings = objItem.registration_body_entries.uniqBy("status.name").sortBy("status.name").map(function (objItem) {
                            return {
                                sTitle: objItem.status.name,
                                funcGroupFilter: funcGroupFilterExecute(objItem)
                            };
                        });

                        var bHasClear = false;
                        var sClearColor = null;

                        if (_this3.get("_bIsAgencyGroup")) {
                            bHasClear = true;
                            sClearColor = "white-text";
                        }

                        arrComponents = objItem.registration_body_entries.map(function (objEntry) {
                            return {
                                sComponent: "tag-component-shrink",
                                objData: {
                                    sTitle: objEntry.registration_body_entry.short_name + " - " + objEntry.registration_body_entry.name,
                                    mValue: objEntry,
                                    sColor: "a24-primary-color-hue-3-force white-text",
                                    bHasClear: bHasClear,
                                    sClearColor: sClearColor
                                },
                                objActions: {
                                    onClearTag: funcTagClearExecute(objItem),
                                    onTagClicked: funcTagClickedExecute(objItem)
                                }
                            };
                        });
                    }

                    return {
                        objData: objItem,
                        sHeading: objItem.registration_body.short_name + " - " + objItem.registration_body.name,
                        arrHeaderActions: arrHeaderActions,
                        arrComponents: arrComponents,
                        arrGroupings: arrGroupings,
                        sGroupTitleStyle: "font-md a24-grey-text-darken-1",
                        arrSubHeadingData: [{
                            arrItems: [{
                                sLabel: _this3.get("_objStringsCandidateProfile").getString("registrationNo"),
                                sValue: objItem.reference,
                                bText: true
                            }, {
                                sLabel: _this3.get("_objStringsService").getString("status"),
                                sValue: objItem.status.name,
                                bText: true
                            }]
                        }]
                    };
                });

                this.set("_arrDisplayCandidateRegistrationBody", arrItems);
            }
        },
        _populateInputFields: function _populateInputFields(objRegistrationBody) {
            var objSelected = null;
            if (!a24Core.isEmpty(this.get("_arrSystemRegistrationBodies"))) {
                objSelected = this.get("_arrSystemRegistrationBodies").findBy("_id", objRegistrationBody.registration_body._id);
            }

            if (!a24Core.isEmpty(objSelected)) {
                this.set("_objSelectedSystemRegistrationBody", Ember.copy(objSelected));
            }

            this.get("objChildren.reference").setValueFromOutside(objRegistrationBody.reference);

            var objSelectedStatus = null;
            if (!a24Core.isEmpty(this.get("_arrSystemRegistrationBodyStatuses"))) {
                objSelectedStatus = this.get("_arrSystemRegistrationBodyStatuses").findBy("_id", objRegistrationBody.status._id);
            }

            if (!a24Core.isEmpty(objSelectedStatus)) {
                this.set("_objSelectedSystemRegistrationBodyStatus", Ember.copy(objSelectedStatus));
            }
        },
        _getSystemRegistrationBodiesAndStatuses: function _getSystemRegistrationBodiesAndStatuses(funcSuccess) {
            var _this4 = this;

            this.set("_bAddEditRegistrationBodyPopupLoading", true);

            var objServiceCalls = {};
            var arrRequiredResponses = [];
            if (a24Core.isEmpty(this.get("_arrSystemRegistrationBodies")) && this.get("_bIsAdd")) {
                var objPromiseRegistrationBodies = this.get("_objSystemService").getRegistrationBody(this, "_objSystemServiceRegistrationBodyPromise", function () {}, // handled in rsvp
                a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                    _this4.set("_bAddEditRegistrationBodyPopupLoading", false);
                    _this4.set("_bShowAddEditRegistrationBodyPopup", false);
                    _this4.set("_arrSystemRegistrationBodies", []);
                }), {
                    items_per_page: {
                        mValue: 1000
                    },
                    position_types: {
                        mValue: this.get("sCandidateType")
                    },
                    status: {
                        mValue: this.get("_objStringsCandidateProfile").getString("active").toLowerCase()
                    },
                    sortBy: {
                        mValue: "name"
                    }
                });

                objServiceCalls._objRestRegistrationBodiesProperty = objPromiseRegistrationBodies;
                arrRequiredResponses.push("_objRestRegistrationBodiesProperty");
            }

            if (!this.get("_bIsAdd") && this.get("_bIsAgencyGroup")) {
                var objPromiseRegistrationBodyStatuses = this.get("_objSystemService").getRegistrationBodyStatuses(this, "_objSystemServiceRegistrationBodyStatusesPromise", this.get("_sSystemRegistrationBodyId"), function () {}, // handled in rsvp
                a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                    _this4.set("_bAddEditRegistrationBodyPopupLoading", false);
                    _this4.set("_bShowAddEditRegistrationBodyPopup", false);
                    _this4.set("_arrSystemRegistrationBodyStatuses", []);
                }), {
                    items_per_page: {
                        mValue: 1000
                    },
                    sortBy: {
                        mValue: "name"
                    }
                });

                objServiceCalls._objRestRegistrationBodyStatusesProperty = objPromiseRegistrationBodyStatuses;
                arrRequiredResponses.push("_objRestRegistrationBodyStatusesProperty");
            }

            if (!a24Core.isEmpty(arrRequiredResponses)) {
                Ember.copy(a24RSVP).configure(this, objServiceCalls, [{
                    arrRequiredResponses: arrRequiredResponses,
                    funcOnComplete: function funcOnComplete(objResponses) {
                        if (!a24Core.isEmpty(objResponses._objRestRegistrationBodiesProperty) && !a24Core.isEmpty(objResponses._objRestRegistrationBodiesProperty.objResponse)) {
                            _this4.set("_arrSystemRegistrationBodies", objResponses._objRestRegistrationBodiesProperty.objResponse.map(function (objRegBody) {
                                return {
                                    name: objRegBody.short_name + " - " + objRegBody.name,
                                    _id: objRegBody._id
                                };
                            }));
                        }

                        if (!a24Core.isEmpty(objResponses._objRestRegistrationBodyStatusesProperty) && !a24Core.isEmpty(objResponses._objRestRegistrationBodyStatusesProperty.objResponse)) {
                            _this4.set("_arrSystemRegistrationBodyStatuses", objResponses._objRestRegistrationBodyStatusesProperty.objResponse);
                        }

                        if (!a24Core.isEmpty(funcSuccess)) {
                            funcSuccess(objResponses);
                        }
                    }
                }]);
            } else {
                funcSuccess();
            }
        },
        _getSystemRegistrationBodyEntriesAndStatuses: function _getSystemRegistrationBodyEntriesAndStatuses() {
            var _this5 = this;

            this.set("_bAddEditRegistrationBodyEntryPopupLoading", true);

            var objPromiseEntries = this.get("_objSystemService").getRegistrationBodyEntries(this, "_objSystemServiceRegistrationBodyEntriesPromise", this.get("_sSystemRegistrationBodyId"), function () {}, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this5.set("_bAddEditRegistrationBodyEntryPopupLoading", false);
                _this5.set("_bShowRegistrationBodyAddEditEntryPopup", false);
                _this5.set("_bShowRegistrationBodyAddEditEntryPopup", false);
                _this5.set("_arrSystemRegistrationBodyEntries", []);
            }), {
                items_per_page: {
                    mValue: 1000
                },
                sortBy: {
                    mValue: "name"
                },
                status: {
                    mValue: "enabled"
                }
            });

            var objPromiseStatuses = this.get("_objSystemService").getRegistrationBodyEntryStatuses(this, "_objSystemServiceRegistrationBodyStatusesPromise", this.get("_sSystemRegistrationBodyId"), function () {}, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this5.set("_bAddEditRegistrationBodyEntryPopupLoading", false);
                _this5.set("_bShowRegistrationBodyAddEditEntryPopup", false);
                _this5.set("_bShowRegistrationBodyAddEditEntryPopup", false);
                _this5.set("_arrSystemRegistrationBodyEntryStatuses", []);
            }), {
                items_per_page: {
                    mValue: 1000
                },
                sortBy: {
                    mValue: "name"
                }
            });

            var objServiceCalls = {
                _objRestEntriesProperty: objPromiseEntries,
                _objRestStatusesProperty: objPromiseStatuses
            };

            Ember.copy(a24RSVP).configure(this, objServiceCalls, [{
                arrRequiredResponses: ["_objRestEntriesProperty", "_objRestStatusesProperty"],
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objRestEntriesProperty) && !a24Core.isEmpty(objResponses._objRestEntriesProperty.objResponse)) {
                        _this5.set("_arrSystemRegistrationBodyEntries", objResponses._objRestEntriesProperty.objResponse.map(function (objRegBodyEntry) {
                            return {
                                name: objRegBodyEntry.short_name + " - " + objRegBodyEntry.name,
                                _id: objRegBodyEntry._id
                            };
                        }));
                    } else {
                        _this5.set("_arrSystemRegistrationBodyEntries", []);
                    }

                    if (!a24Core.isEmpty(objResponses._objRestStatusesProperty) && !a24Core.isEmpty(objResponses._objRestStatusesProperty.objResponse)) {
                        _this5.set("_arrSystemRegistrationBodyEntryStatuses", objResponses._objRestStatusesProperty.objResponse);
                    } else {
                        _this5.set("_arrSystemRegistrationBodyEntryStatuses", []);
                    }

                    _this5.set("_bAddEditRegistrationBodyEntryPopupLoading", false);
                }
            }]);
        },
        _showAddEditRegistrationBodyPopup: function _showAddEditRegistrationBodyPopup(objRegistrationBody) {
            var _this6 = this;

            this.clearAllErrorMessages();
            this.clearForm();

            this.set("_sAddEditRegistrationBodyHeader", this.get("_objStringsCandidateProfile").getHTMLTokenString("addProfessionalRegistrationHeader", this.get("sCandidateTypeName")));
            this.set("_bIsAdd", true);

            if (!a24Core.isEmpty(objRegistrationBody)) {
                this.set("_sAddEditRegistrationBodyHeader", this.get("_objStringsCandidateProfile").getHTMLTokenString("editProfessionalRegistrationHeader", this.get("sCandidateTypeName")));
                this.set("_bIsAdd", false);
            }

            this.set("_bShowAddEditRegistrationBodyPopup", true);

            this._getSystemRegistrationBodiesAndStatuses(function () {
                _this6.set("_bAddEditRegistrationBodyPopupLoading", false);
                if (!a24Core.isEmpty(objRegistrationBody)) {
                    _this6._populateInputFields(objRegistrationBody);
                }

                Ember.run.next(function () {
                    Ember.run.schedule("afterRender", function () {
                        _this6.set("_objOriginalData", _this6.getPlainFormValueObject());
                    });
                });
            });
        },
        _onRegistrationBodyEditClicked: function _onRegistrationBodyEditClicked(objItem) {
            this.set("_sRegistrationBodyId", objItem._id);
            this.set("_sSystemRegistrationBodyId", objItem.registration_body._id);
            this._showAddEditRegistrationBodyPopup(objItem);
        },
        _onRegistrationBodyRemoveClicked: function _onRegistrationBodyRemoveClicked(objItem) {
            this.set("_sRegistrationBodyId", objItem._id);
            this.set("_bShowRegistrationBodyDeletePopup", true);
        },
        _onRegistrationBodyAddClicked: function _onRegistrationBodyAddClicked(objItem) {
            var _this7 = this;

            this.set("_sRegistrationBodyId", objItem._id);
            this.set("_sSystemRegistrationBodyId", objItem.registration_body._id);
            this.set("_bShowRegistrationBodyAddEditEntryPopup", true);

            this.clearAllErrorMessages();
            this.clearForm();

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this7.set("_objOriginalData", _this7.getPlainFormValueObject());
                });
            });
            this._getSystemRegistrationBodyEntriesAndStatuses();
        },
        _onPopupRegistrationBodyAddEditCancel: function _onPopupRegistrationBodyAddEditCancel() {
            if (this.get("_bAddEditRegistrationBodyPopupLoading")) {
                return;
            }
            // Check if the form has any unsaved data
            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowAddEditRegistrationBodyPopup", false);
            } else {
                this.set("_bShowUnsavedData", true);
            }
        },
        _onPopupRegistrationBodyEntryAddEditCancel: function _onPopupRegistrationBodyEntryAddEditCancel() {
            if (this.get("_bAddEditRegistrationBodyEntryPopupLoading")) {
                return;
            }
            // Check if the form has any unsaved data
            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowRegistrationBodyAddEditEntryPopup", false);
            } else {
                this.set("_bShowUnsavedData", true);
            }
        },
        _onPopupRegBodyEntryViewCancel: function _onPopupRegBodyEntryViewCancel() {
            this.set("_bShowRegBodyEntryViewPopup", false);
        },
        _doSuccess: function _doSuccess(sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bShowAddEditRegistrationBodyPopup", false);
            this.set("_bAddEditRegistrationBodyPopupLoading", false);
            this.set("_bShowRegistrationBodyDeletePopup", false);
            this.set("_bRegistrationBodyDeletePopupLoading", false);
            this.set("_bShowRegistrationBodyAddEditEntryPopup", false);
            this.set("_bAddEditRegistrationBodyEntryPopupLoading", false);
            this.set("_bShowRegistrationBodyEntryDeletePopup", false);
            this.set("_bRegistrationBodyEntryDeletePopupLoading", false);
            this.set("_bShowRegBodyEntryViewPopup", false);

            this._getCandidateRegistrationBody();
        },
        _doError: function _doError(sCode, objError, sSnackbarMessage) {
            if (sCode === "ENUM_MISMATCH") {
                this.setResponseError(objError.arrPath, "validationRestUnsupportedValue");
            } else if (sCode === "PATTERN" || sCode === "INVALID_FORMAT") {
                this.setResponseError(objError.arrPath, "validationRestInvalidFormat");
            } else if (sCode === "MIN_LENGTH") {
                this.setResponseError(objError.arrPath, "validationRestMinLength");
            } else if (sCode === "REGISTRATION_BODY_ENTRY_ALREADY_EXISTS") {
                this.setResponseError(objError.arrPath, "validationRegistrationBodyEntryAlreadyExist", false, false, this.get("_objStringsCandidateProfile"));
            } else if (sCode === "SYSTEM_REGISTRATION_BODY_ENTRY_NOT_FOUND") {
                this.setResponseError(objError.arrPath, "validationRegistrationBodyEntryNotExist", false, false, this.get("_objStringsCandidateProfile"));
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }
        },
        _getPayload: function _getPayload() {
            var objPayload = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

            if (!a24Core.isEmpty(objPayload.expires_at)) {
                var objExpiresAt = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objExpiresAt.setTimeToEndOfDay();
                objExpiresAt.setDateFromBrowser(objPayload.expires_at, a24Constants.DATE_FORMAT);
                objPayload.expires_at = objExpiresAt.getDateStringForRest(true);
            }

            if (!a24Core.isEmpty(objPayload.obtained_at)) {
                var objObtainedAt = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objObtainedAt.setTimeToStartOfDay();
                objObtainedAt.setDateFromBrowser(objPayload.obtained_at, a24Constants.DATE_FORMAT);
                objPayload.obtained_at = objObtainedAt.getDateStringForRest(true);
            }

            if (!a24Core.isEmpty(objPayload.valid_at)) {
                var objValidAt = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objValidAt.setTimeToStartOfDay();
                objValidAt.setDateFromBrowser(objPayload.valid_at, a24Constants.DATE_FORMAT);
                objPayload.valid_at = objValidAt.getDateStringForRest(true);
            }

            return objPayload;
        },

        _onPopupRegistrationBodyAddEditSave: function _onPopupRegistrationBodyAddEditSave() {
            var _this8 = this;

            this.clearAllErrorMessages();

            if (!this.doFullValidation()) {
                var objPayload = this._getPayload();

                this.set("_bAddEditRegistrationBodyPopupLoading", true);
                if (this.get("_bIsAdd")) {
                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objStringsCandidateProfile").getString("professionalRegistration")));

                    this.get("objCandidateRegistrationBodyRestService").addRegistrationBody(this, "_objCandidateRegistrationBodyServicePromise", this.get("sCandidateId"), objPayload, function () {
                        _this8._doSuccess(_this8.get("_objStringsService").getTokenString("successAddItem", _this8.get("_objStringsCandidateProfile").getString("professionalRegistration")));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                        _this8.set("_bAddEditRegistrationBodyPopupLoading", false);

                        if (sCode === "404" || sCode === "409") {
                            _this8.get("_objSnackbarService").showSnackbarMessage(_this8.get("_objStringsCandidateProfile").getString("addFailedRefreshPage"));
                        } else {
                            _this8._doError(sCode, objError, _this8.get("_objStringsService").getTokenString("failAddItem", _this8.get("_objStringsCandidateProfile").getString("professionalRegistration")));
                        }
                    }));
                } else {
                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objStringsCandidateProfile").getString("professionalRegistration")));

                    this.get("objCandidateRegistrationBodyRestService").updateRegistrationBody(this, "_objCandidateRegistrationBodyServicePromise", this.get("sCandidateId"), this.get("_sRegistrationBodyId"), objPayload, function () {
                        _this8._doSuccess(_this8.get("_objStringsService").getTokenString("successUpdateItem", _this8.get("_objStringsCandidateProfile").getString("professionalRegistration")));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                        _this8.set("_bAddEditRegistrationBodyPopupLoading", false);

                        if (sCode === "404" || sCode === "409") {
                            _this8.get("_objSnackbarService").showSnackbarMessage(_this8.get("_objStringsService").getString("updateFailedRefreshPage"));
                        } else {
                            _this8._doError(sCode, objError, _this8.get("_objStringsService").getTokenString("failUpdateItem", _this8.get("_objStringsCandidateProfile").getString("professionalRegistration")));
                        }
                    }));
                }
            }
        },
        _onPopupRegistrationBodyEntryAddEditSave: function _onPopupRegistrationBodyEntryAddEditSave() {
            var _this9 = this;

            this.clearAllErrorMessages();

            if (!this.doFullValidation()) {
                var objPayload = this._getPayload();

                this.set("_bAddEditRegistrationBodyEntryPopupLoading", true);

                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objStringsCandidateProfile").getString("registrationBodyEntry")));

                this.get("objCandidateRegistrationBodyRestService").addRegistrationBodyEntry(this, "_objCandidateRegistrationBodyServicePromise", this.get("sCandidateId"), this.get("_sRegistrationBodyId"), objPayload, function () {
                    _this9._doSuccess(_this9.get("_objStringsService").getTokenString("successAddItem", _this9.get("_objStringsCandidateProfile").getString("registrationBodyEntry")));
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                    _this9.set("_bAddEditRegistrationBodyEntryPopupLoading", false);

                    _this9._doError(sCode, objError, _this9.get("_objStringsService").getTokenString("failAddItem", _this9.get("_objStringsCandidateProfile").getString("registrationBodyEntry")));
                }));
            }
        },
        _onPopupRegistrationBodyDeleteConfirm: function _onPopupRegistrationBodyDeleteConfirm() {
            var _this10 = this;

            this.set("_bRegistrationBodyDeletePopupLoading", true);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("removingItem", this.get("_objStringsCandidateProfile").getString("professionalRegistration")));

            this.get("objCandidateRegistrationBodyRestService").deleteRegistrationBody(this, "_objCandidateRegistrationBodyServicePromise", this.get("sCandidateId"), this.get("_sRegistrationBodyId"), function () {
                _this10._doSuccess(_this10.get("_objStringsService").getTokenString("successRemoveItem", _this10.get("_objStringsCandidateProfile").getString("professionalRegistration")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                _this10.set("_bRegistrationBodyDeletePopupLoading", false);

                if (sCode === "404" || sCode === "409") {
                    _this10.get("_objSnackbarService").showSnackbarMessage(_this10.get("_objStringsService").getString("removeFailedRefreshPage"));
                } else {
                    _this10._doError(sCode, objError, _this10.get("_objStringsService").getTokenString("failRemoveItem", _this10.get("_objStringsCandidateProfile").getString("professionalRegistration")));
                }
            }));
        },
        _onPopupRegistrationBodyEntryDeleteConfirm: function _onPopupRegistrationBodyEntryDeleteConfirm() {
            var _this11 = this;

            this.set("_bRegistrationBodyEntryDeletePopupLoading", true);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("removingItem", this.get("_objStringsCandidateProfile").getString("registrationBodyEntry")));

            this.get("objCandidateRegistrationBodyRestService").deleteRegistrationBodyEntry(this, "_objCandidateRegistrationBodyServicePromise", this.get("sCandidateId"), this.get("_sRegistrationBodyId"), this.get("_sRegistrationBodyEntryId"), function () {
                _this11._doSuccess(_this11.get("_objStringsService").getTokenString("successRemoveItem", _this11.get("_objStringsCandidateProfile").getString("registrationBodyEntry")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                _this11.set("_bRegistrationBodyEntryDeletePopupLoading", false);

                if (sCode === "404" || sCode === "409") {
                    _this11.get("_objSnackbarService").showSnackbarMessage(_this11.get("_objStringsService").getString("removeFailedRefreshPage"));
                } else {
                    _this11._doError(sCode, objError, _this11.get("_objStringsService").getTokenString("failRemoveItem", _this11.get("_objStringsCandidateProfile").getString("registrationBodyEntry")));
                }
            }));
        },
        actions: {
            onPopupRegistrationBodyAddEditCancel: function onPopupRegistrationBodyAddEditCancel() {
                this._onPopupRegistrationBodyAddEditCancel();
            },
            onPopupRegistrationBodyAddEditSave: function onPopupRegistrationBodyAddEditSave() {
                this._onPopupRegistrationBodyAddEditSave();
            },
            onPopupRegistrationBodyDeleteConfirm: function onPopupRegistrationBodyDeleteConfirm() {
                this._onPopupRegistrationBodyDeleteConfirm();
            },
            onPopupRegistrationBodyDeleteClose: function onPopupRegistrationBodyDeleteClose() {
                if (this.get("_bRegistrationBodyDeletePopupLoading")) {
                    return;
                }

                this.set("_bShowRegistrationBodyDeletePopup", false);
            },
            onPopupRegistrationBodyEntryDeleteConfirm: function onPopupRegistrationBodyEntryDeleteConfirm() {
                this._onPopupRegistrationBodyEntryDeleteConfirm();
            },
            onPopupRegistrationBodyEntryDeleteClose: function onPopupRegistrationBodyEntryDeleteClose() {
                if (this.get("_bRegistrationBodyEntryDeletePopupLoading")) {
                    return;
                }

                this.set("_bShowRegistrationBodyEntryDeletePopup", false);
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowUnsavedData", false);
                this.set("_bShowAddEditRegistrationBodyPopup", false);
                this.set("_bShowRegistrationBodyAddEditEntryPopup", false);
            },
            onPopupRegistrationBodyEntryAddEditCancel: function onPopupRegistrationBodyEntryAddEditCancel() {
                this._onPopupRegistrationBodyEntryAddEditCancel();
            },
            onPopupRegistrationBodyEntryAddEditSave: function onPopupRegistrationBodyEntryAddEditSave() {
                this._onPopupRegistrationBodyEntryAddEditSave();
            },
            onPopupRegBodyEntryViewCancel: function onPopupRegBodyEntryViewCancel() {
                this._onPopupRegBodyEntryViewCancel();
            }
        }
    });
});
define("a24-ember-candidate-profile/components/candidate-activities", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-activities", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _candidateActivities, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateActivities.default,
        sCandidateId: null,
        sCandidateName: null,
        objActivityRestService: null,
        objActivityTypeRestService: null,
        objRestCandidateActivityEmailComm: null,
        objRestCandidateActivityConversation: null,
        objRestCandidateActivityNotification: null,
        sActivityContext: null,
        _bLoading: false,
        _bPopupLoading: false,
        _arrHeaderActions: null,
        _sPopupHeader: null,
        _arrActivities: null,
        _objSpecialAddPopupAction: null,
        _bShowUnsavedData: false,
        _bShowAddPopup: false,
        _objActivityEditData: null,
        _objActivitiesGetPromise: null,
        _objActivityPostPromise: null,
        _arrActivityTypes: null,
        _arrActivityTypeAddEditDropdown: null,
        _arrReloadActions: null,
        _arrNotificationsSentResponse: null,

        _env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        _sTriageBaseUrl: Ember.computed.readOnly("_env.sTriageBaseUrl"),

        _objNavigation: Ember.inject.service("navigation"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUserSession: Ember.inject.service("user-session"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objEmailPreviewGeneratorService: Ember.inject.service("email-preview-generator"),
        _objConversationPreviewGeneratorService: Ember.inject.service("conversation-preview-generator"),
        _objCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objStaffshiftCoreEnums: Ember.inject.service("a24-enums-staffshift-core"),

        _bServerError: false,
        _iResultCount: 0,
        _iPage: 1,
        _iPerPage: 10,
        _arrFilterConfig: null,
        _objQueryParams: null,

        _objActivityEmailCommPromise: null,

        _bShowEmailMessagePopup: false,
        _bEmailMessagePopupLoading: false,
        _objSpecialEmailMessagePopupAction: null,
        _arrEmailMessagePopupActions: null,
        _arrConversationPreviewPopupActions: null,
        _arrNotificationPreviewPopupActions: null,
        _objEmailPreview: null,
        _objActivityTypesGetPromise: null,
        _objConversationPreview: null,
        _bShowConversationPreviewPopup: false,
        _bConversationPreviewPopupLoading: false,
        _objActivityConversationPreviewPromise: null,
        _bShowNotifcationPreviewPopup: false,
        _bShowNotifcationsSentPopup: false,
        _bNotificationPreviewPopupLoading: false,
        _bNotificationSentPopupLoading: false,
        _objActivityNotificationPreviewPromise: null,
        _objActivityNotificationMediumPreviewPromise: null,
        _objCurrentNotificationSentCache: null,
        _bIsEmail: null,
        _bOptOut: false,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.doServiceCalls();
                }
            }]);

            this.set("_sPopupHeader", this.get("_objStringsService").getTokenString("addItem", this.get("_objCandidateProfileStringsService").getString("activity")));

            this.set("_arrHeaderActions", [{
                sTooltipText: this.get("_objStringsService").getString("add"),
                sIcon: "add",
                executeCardAction: function executeCardAction() {
                    _this.setAddPopup();
                }
            }, {
                sTooltipText: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.doServiceCalls(_this.get("_objQueryParams"));
                }
            }]);

            this.set("_objSpecialAddPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.hidePopup();
                }
            });

            this.set("_objSpecialNotificationSentPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.set("_bShowNotifcationsSentPopup", false);
                }
            });

            // Create an action that will close the preview popup
            this.set("_objSpecialNotificationPreviewPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.set("_bShowNotifcationPreviewPopup", false);
                }
            });

            // Set up the form children
            this.set("objChildConfig", {
                // activity type drop down
                "objChildren.activity_type": function objChildrenActivity_type(objMixin) {
                    // This field is required
                    a24Validation.addRequired(objMixin, true);
                },
                // description
                "objChildren.description": function objChildrenDescription(objMixin) {
                    // This fields has a min Length of 1
                    a24Validation.addMinLength(objMixin, 3);
                },
                // comment
                "objChildren.comment": function objChildrenComment(objMixin) {
                    // This fields has a min Length of 1
                    a24Validation.addMinLength(objMixin, 1);
                }
            });
            this.set("_objCurrentNotificationSentCache", {});

            this.doServiceCalls();
        },
        doServiceCalls: function doServiceCalls(objQueryParams) {
            var _this2 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);
            this.set("_iResultCount", 0);

            var sCandidateId = this.get("sCandidateId");

            if (a24Core.isEmpty(objQueryParams)) {
                objQueryParams = {
                    page: { mValue: this.get("_iPage") },
                    items_per_page: { mValue: this.get("_iPerPage") }
                };
            }

            if (a24Core.isEmpty(objQueryParams.activity_date)) {
                objQueryParams.activity_date = {
                    mValue: "desc",
                    bSortBy: true
                };
            }
            // Save the current query params for later use.
            this.set("_objQueryParams", Ember.copy(objQueryParams, true));

            var objPromiseActivities = this.get("objActivityRestService").getActivities(this, "_objActivitiesGetPromise", sCandidateId, function () {}, // handled in RSVP
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this2.set("_bServerError", true);
                    return;
                }
                // Clear data
                _this2.set("_arrActivities", []);
            }), objQueryParams);

            var objServiceCalls = {
                _objRestActivitiesProperty: objPromiseActivities
            };

            var arrRequiredResponses = ["_objRestActivitiesProperty"];

            // load activity types if not loaded
            if (a24Core.isEmpty(this.get("_arrActivityTypesContext"))) {
                var objActivityTypesContextQueryParams = {
                    disabled: { mValue: false },
                    context: { mValue: this.get("sActivityContext") },
                    name: { mValue: "asc", bSortBy: true },
                    page: { mValue: 1 },
                    items_per_page: { mValue: 1000 }
                };

                var objPromiseActivityTypesContext = this.get("objActivityTypeRestService").getActivityTypes(this, "_objActivityTypesContextGetPromise", function () {}, // handled in RSVP
                a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                        _this2.set("_bServerError", true);
                        return;
                    }
                    // Clear data
                    _this2.set("_arrActivityTypesContext", []);
                }), objActivityTypesContextQueryParams);

                objServiceCalls._objRestActivityTypesContextProperty = objPromiseActivityTypesContext;
                arrRequiredResponses.push("_objRestActivityTypesContextProperty");
            }

            if (a24Core.isEmpty(this.get("_arrActivityTypes"))) {
                var objActivityTypesQueryParams = {
                    name: { mValue: "asc", bSortBy: true },
                    page: { mValue: 1 },
                    items_per_page: { mValue: 1000 }
                };

                var objPromiseActivityTypes = this.get("objActivityTypeRestService").getActivityTypes(this, "_objActivityTypesGetPromise", function () {}, // handled in RSVP
                a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                        _this2.set("_bServerError", true);
                        return;
                    }
                    // Clear data
                    _this2.set("_arrActivityTypes", []);
                }), objActivityTypesQueryParams);

                objServiceCalls._objRestActivityTypesProperty = objPromiseActivityTypes;
                arrRequiredResponses.push("_objRestActivityTypesProperty");
            }

            Ember.copy(a24RSVP).configure(this, objServiceCalls, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objRestActivityTypesContextProperty) && !a24Core.isEmpty(objResponses._objRestActivityTypesContextProperty.objHeaders) && objResponses._objRestActivityTypesContextProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRestActivityTypesProperty) && !a24Core.isEmpty(objResponses._objRestActivityTypesProperty.objHeaders) && objResponses._objRestActivityTypesProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRestActivitiesProperty) && !a24Core.isEmpty(objResponses._objRestActivitiesProperty.objHeaders) && objResponses._objRestActivitiesProperty.objHeaders.statusText === "abort") {
                        return;
                    }

                    if (!a24Core.isEmpty(objResponses._objRestActivityTypesProperty) && !a24Core.isEmpty(objResponses._objRestActivityTypesProperty.objResponse)) {
                        var arrActivityTypes = objResponses._objRestActivityTypesProperty.objResponse;
                        _this2.set("_arrActivityTypes", arrActivityTypes);

                        var arrActivityTypeDropdown = [];
                        for (var k = 0; k < arrActivityTypes.length; k++) {
                            arrActivityTypeDropdown.push({
                                sTitle: arrActivityTypes[k].name,
                                sValue: arrActivityTypes[k].code
                            });
                        }

                        _this2.set("_arrFilterConfig", [{
                            sTitle: _this2.get("_objStringsService").getString("createdBy"),
                            sProperty: "created_by_user_name",
                            sFilterType: "text",
                            bHideEmptyCheck: true,
                            bText: true
                        }, {
                            sTitle: _this2.get("_objCandidateProfileStringsService").getString("activityType"),
                            sProperty: "activity_type",
                            sFilterType: "dropdownMulti",
                            arrMappingData: arrActivityTypeDropdown,
                            bHideEmptyCheck: true,
                            bMapping: true
                        }, {
                            sTitle: _this2.get("_objCandidateProfileStringsService").getString("activityDate"),
                            sProperty: "activity_date_at",
                            sFilterType: "date",
                            bHideEmptyCheck: true,
                            bDate: true
                        }]);
                    }

                    if (!a24Core.isEmpty(objResponses._objRestActivityTypesContextProperty) && !a24Core.isEmpty(objResponses._objRestActivityTypesContextProperty.objResponse)) {
                        _this2.set("_arrActivityTypesContext", objResponses._objRestActivityTypesContextProperty.objResponse);

                        var arrActivityTypeContextDropdown = [];
                        var arrActivityTypesContext = objResponses._objRestActivityTypesContextProperty.objResponse;
                        for (var l = 0; l < arrActivityTypesContext.length; l++) {
                            arrActivityTypeContextDropdown.push({
                                sTitle: arrActivityTypesContext[l].name,
                                sValue: arrActivityTypesContext[l].code
                            });
                        }

                        _this2.set("_arrActivityTypeAddEditDropdown", arrActivityTypeContextDropdown);
                    }

                    if (!a24Core.isEmpty(objResponses._objRestActivitiesProperty) && !a24Core.isEmpty(objResponses._objRestActivitiesProperty.objResponse) && !a24Core.isEmpty(_this2.get("_arrActivityTypes"))) {
                        _this2.set("_arrActivities", _this2.prepareForDisplay(objResponses._objRestActivitiesProperty.objResponse));

                        _this2.set("_iResultCount", a24RestCallHelper.getXResultCount(objResponses._objRestActivitiesProperty.objHeaders));

                        _this2.set("_bLoading", false);
                        if (!a24Core.isEmpty(_this2.get("onDoneLoading"))) {
                            _this2.get("onDoneLoading")();
                        }
                    } else {
                        _this2.set("_arrActivities", []);
                        _this2.set("_bLoading", false);
                        if (!a24Core.isEmpty(_this2.get("onDoneLoading"))) {
                            _this2.get("onDoneLoading")();
                        }
                    }
                }
            }]);
        },
        prepareForDisplay: function prepareForDisplay(arrData) {
            var _this3 = this;

            var arrActivities = [];
            var objThis = this;

            arrData.forEach(function (objActivity) {
                // lookup icon and label for activity type
                var objLookupData = null;
                var arrActivityTypes = _this3.get("_arrActivityTypes");
                for (var l = 0; l < arrActivityTypes.length; l++) {
                    if (arrActivityTypes[l].code === objActivity.activity_type) {
                        objLookupData = arrActivityTypes[l];
                        break;
                    }
                }

                var sSeenDateFormatted = momentHelper.getTimeFromNow(objActivity.activity_date, a24Constants.DATE_FORMAT_REST_FORMAT);
                var objActivityDateTime = a24DateManager.createDate(objThis.get("_objUserSession").get("objContextDetails.sTimezone"));
                objActivityDateTime.setDateFromRest(objActivity.activity_date);

                var arrData = [{
                    arrItems: [{
                        sLabel: _this3.get("_objStringsService").getString("description"),
                        sValue: objActivity.description,
                        bText: true
                    }, {
                        sLabel: _this3.get("_objStringsService").getString("comment"),
                        sValue: objActivity.comment,
                        bText: true
                    }, {
                        sLabel: _this3.get("_objStringsService").getString("createdBy"),
                        sValue: objActivity.created_by_user_name,
                        bText: true
                    }, {
                        sLabel: _this3.get("_objCandidateProfileStringsService").getString("activityDate"),
                        sValue: objActivityDateTime.getDateTimeFormat(),
                        bText: true
                    }]
                }];

                var sIcon = null;
                var sName = _this3.get("_objCandidateProfileStringsService").getString("unknown");

                if (!a24Core.isEmpty(objLookupData)) {
                    sIcon = objLookupData.icon;
                    sName = objLookupData.name;
                }

                // Add an actvity entry
                var objExpandingActivity = {
                    sIcon: sIcon,
                    sLabel: _this3.get("_objStringsService").getHTMLTokenString("a24-string-html-dot-value", sName, objActivity.created_by_user_name),
                    sDate: sSeenDateFormatted,
                    arrData: arrData
                };

                if (!a24Core.isEmpty(objThis.get("objActivityRestService.sContext")) && (objThis.get("objActivityRestService.sContext") === objThis.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP") || objThis.get("objActivityRestService.sContext") === objThis.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY"))) {
                    // Check the linked entities for an EmailCommunicationMessage
                    if (!a24Core.isEmpty(objActivity.linked_entities)) {
                        var _loop = function _loop(i) {
                            var objLinkedEntity = objActivity.linked_entities[i];
                            objExpandingActivity.arrActions = [];
                            if (objLinkedEntity.entity_type === "EmailCommunicationMessage") {
                                // EmailCommunicationMessage found, adding action
                                objExpandingActivity.arrActions.push({
                                    sTitle: objThis.get("_objCandidateProfileStringsService").getString("emailPreview"),
                                    sIcon: "attachment",
                                    executeCardAction: function executeCardAction() {
                                        objThis.attachmentFromActivity(objActivity, objLinkedEntity);
                                    }
                                });
                                return "break";
                            } else if (objLinkedEntity.entity_type === "ChatConversation") {
                                objExpandingActivity.arrActions.push({
                                    sTitle: _this3.get("_objCoreStrings").getString("conversationPreview"),
                                    sIcon: "open_in_browser",
                                    executeCardAction: function executeCardAction() {
                                        objThis.conversationFromActivity(objActivity, objLinkedEntity);
                                    }
                                });
                                return "break";
                            } else if (objLinkedEntity.entity_type === "CandidateNotifications") {
                                objExpandingActivity.arrActions.push({
                                    sTitle: _this3.get("_objCoreStrings").getString("notificationPreview"),
                                    sIcon: "open_in_browser",
                                    executeCardAction: function executeCardAction() {
                                        objThis.notifcationFromActivity(objActivity, objLinkedEntity);
                                    }
                                });
                                return "break";
                            } else if (objLinkedEntity.entity_type === "Order" && !a24Core.isEmpty(objLinkedEntity.entity_id) && objThis.get("objActivityRestService.sContext") === objThis.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY")) {
                                var iOrderId = objLinkedEntity.entity_id;
                                objExpandingActivity.arrActions.push({
                                    sTitle: _this3.get("_objCandidateProfileStringsService").getString("openOrderInNewTab"),
                                    sIcon: "open_in_new",
                                    executeCardAction: function executeCardAction() {
                                        objThis._viewOrderInNewWindow(iOrderId);
                                    }
                                });
                                return "break";
                            }
                        };

                        for (var i = 0; i < objActivity.linked_entities.length; i++) {
                            var _ret = _loop(i);

                            if (_ret === "break") break;
                        }
                    }
                }

                arrActivities.push(objExpandingActivity);
            });
            return arrActivities;
        },
        _viewOrderInNewWindow: function _viewOrderInNewWindow(sOrderId) {
            var sUrl = this.get("_sTriageBaseUrl") + "/views#agency-staffing-order-details;orderId=" + sOrderId + ";cId=" + this.get("_objNavigation").get("sContextId");

            this._openLink(sUrl);
        },
        _openLink: function _openLink(sLink) {
            window.open(sLink);
        },

        attachmentFromActivity: function attachmentFromActivity(objActivity, objLinkedEntity) {
            var _this4 = this;

            var funcSuccess = function funcSuccess(objData) {
                Ember.set(objActivity, "objStoredResponse", objData);

                // On success
                _this4.set("_objEmailPreview", {
                    sSubject: objData.subject,
                    objFrom: objData.sender,
                    objTo: objData.to_recipient,
                    arrCC: objData.cc_recipients,
                    arrBCC: objData.bcc_recipients,
                    objReplyTo: objData.reply_to,
                    sHTML: objData.body_html,
                    sDeliveryStatus: objData.delivery_status,
                    sDeliveryFailureReason: objData.delivery_failure_reason
                });

                // Create an action that will open the email in a new tab
                _this4.set("_objSpecialEmailMessagePopupAction", {
                    sTitle: _this4.get("_objStringsService").getString("close"),
                    sIcon: "close",
                    executeCardAction: function executeCardAction() {
                        _this4.set("_bShowEmailMessagePopup", false);
                    }
                });
                _this4.set("_arrEmailMessagePopupActions", [{
                    sTitle: _this4.get("_objCoreStrings").getString("openInNewTab"),
                    sIcon: "open_in_new",
                    executeCardAction: function executeCardAction() {
                        var objEmailPreview = _this4.get("_objEmailPreview");
                        // Generate the email html from details
                        var sHtml = _this4.get("_objEmailPreviewGeneratorService").generateFullHtmlWithHTMLWrappingFromEmailObject(objEmailPreview);
                        _this4.openHtmlInNewWindow(sHtml);
                    }
                }]);

                // Take the popup out of loading
                _this4.set("_bEmailMessagePopupLoading", false);
            };

            this.set("_bShowEmailMessagePopup", true);
            this.set("_bEmailMessagePopupLoading", true);

            // Use the stored response if it is set
            var objStoredResponse = Ember.get(objActivity, "objStoredResponse");
            if (!a24Core.isEmpty(objStoredResponse)) {
                funcSuccess(objStoredResponse);
                return;
            }

            this.get("objRestCandidateActivityEmailComm").getEmailCommMessage(this, "_objActivityEmailCommPromise", this.get("sCandidateId"), objActivity._id, objLinkedEntity.entity_id, funcSuccess, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this4.set("_bShowEmailMessagePopup", false);
                _this4.set("_bEmailMessagePopupLoading", false);

                _this4.get("_objSnackbarService").showSnackbarMessage(_this4.get("_objCandidateProfileStringsService").getString("couldNotLoadEmailPreview"));
            }));
        },

        notifcationFromActivity: function notifcationFromActivity(objActivity, objLinkedEntity) {
            var _this5 = this;

            var objThis = this;

            // open popup showing notifications sent and delivery status
            this.set("_bShowNotifcationsSentPopup", true);

            this.set("_bNotificationSentPopupLoading", true);
            this.set("_bOptOut", false);
            this.get("objRestCandidateActivityNotification").getActivityNotification(this, "_objActivityNotificationPreviewPromise", this.get("sCandidateId"), objActivity._id, objLinkedEntity.entity_id, function (objResponse) {
                if (!a24Core.isEmpty(objResponse) && !a24Core.isEmpty(objResponse.notifications_sent)) {
                    // reset cache
                    _this5.set("_objCurrentNotificationSentCache", {});
                    // save notifications sent and delivery status
                    var arrNotificationsSent = [];

                    var _loop2 = function _loop2(i) {
                        var objData = {};
                        var sMedium = objResponse.notifications_sent[i].medium;
                        var sMediumType = sMedium;
                        var objMediumTypeEnum = _this5.get("_objStaffshiftCoreEnums").getFrontendValue("mediumType", sMedium, null, true);
                        var sStatus = _this5.get("_objStaffshiftCoreEnums").getFrontendValue("notificationStatus", objResponse.notifications_sent[i].status);
                        var sIcon = "texture";
                        var sIconFa = null;
                        var sIconSvg = null;
                        if (!a24Core.isEmpty(objMediumTypeEnum)) {
                            sMediumType = objMediumTypeEnum.sLabel;
                            sIcon = objMediumTypeEnum.icon;
                            sIconFa = objMediumTypeEnum.icon_fa;
                            sIconSvg = objMediumTypeEnum.icon_svg;
                        }
                        if (a24Core.isEmpty(sStatus)) {
                            sStatus = objResponse.notifications_sent[i].status;
                        }
                        var sActivityId = objActivity._id;
                        var sLinkedEntityId = objLinkedEntity.entity_id;
                        var sNotificationSentId = objResponse.notifications_sent[i].id;

                        objData.activity_id = sActivityId;
                        objData.medium = sMediumType;
                        objData.backend_medium = sMedium;
                        objData.status = sStatus;
                        objData.icon = sIcon;
                        objData.icon_fa = sIconFa;
                        objData.icon_svg = sIconSvg;
                        objData.entity_id = sLinkedEntityId;
                        objData.notifications_sent_id = sNotificationSentId;
                        objData.arrActions = [];
                        objData.arrActions.push({
                            sTitle: objThis.get("_objCandidateProfileStringsService").getString("viewNotificationPreview"),
                            sIcon: "visibility",
                            executeCardAction: function executeCardAction() {
                                objThis.activityNotificationFromMedium(sActivityId, sLinkedEntityId, sMedium, sNotificationSentId);
                            }
                        });
                        arrNotificationsSent.push(objData);
                    };

                    for (var i = 0; i < objResponse.notifications_sent.length; i++) {
                        _loop2(i);
                    }

                    _this5.set("_arrNotificationsSentResponse", arrNotificationsSent);

                    _this5.set("_bNotificationSentPopupLoading", false);
                } else {
                    if (objResponse.status === _this5.get("_objStaffshiftCoreEnums.notificationStatus.OPT_OUT.backend")) {
                        _this5.set("_bOptOut", true);
                        _this5.set("_bNotificationSentPopupLoading", false);
                        _this5.set("_arrNotificationsSentResponse", []);
                    } else {
                        _this5.set("_bShowNotifcationsSentPopup", false);
                        _this5.set("_bNotificationSentPopupLoading", false);

                        _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objCandidateProfileStringsService").getString("couldNotLoadNotificationSentInformation"));
                    }
                }
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this5.set("_bShowNotifcationsSentPopup", false);
                _this5.set("_bNotificationSentPopupLoading", false);

                _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objCandidateProfileStringsService").getString("couldNotLoadNotificationSentInformation"));
            }));
        },
        activityNotificationFromMedium: function activityNotificationFromMedium(sActivityId, sLinkedEntityId, sMedium, sNotificationSentId) {
            var _this6 = this;

            var funcSuccess = function funcSuccess(objData) {
                _this6.set("_objCurrentNotificationSentCache." + sNotificationSentId, objData);
                // On success
                if (sMedium === _this6.get("_objStaffshiftCoreEnums").getBackendValue("mediumType", _this6.get("_objStringsService").getString("email"))) {

                    _this6.set("_bIsEmail", true);

                    var sSubject = "";
                    var objSender = null;
                    var objRecipient = null;
                    var objReplyTo = null;
                    var sBody = "";
                    var sStatus = "";
                    var sStatusReason = "";
                    if (!a24Core.isEmpty(objData.data)) {
                        if (!a24Core.isEmpty(objData.data.subject)) {
                            sSubject = objData.data.subject;
                        }
                        if (!a24Core.isEmpty(objData.data.sender)) {
                            objSender = objData.data.sender;
                        }
                        if (!a24Core.isEmpty(objData.data.to_recipient)) {
                            objRecipient = objData.data.to_recipient;
                        }
                        if (!a24Core.isEmpty(objData.data.reply_to)) {
                            objReplyTo = objData.data.reply_to;
                        }
                        if (!a24Core.isEmpty(objData.data.body_html)) {
                            sBody = objData.data.body_html;
                        }
                        if (!a24Core.isEmpty(objData.status_reason)) {
                            sStatusReason = objData.status_reason;
                        }
                    }
                    if (!a24Core.isEmpty(objData.status)) {
                        if (objData.status === _this6.get("_objStaffshiftCoreEnums.notificationStatus.OPT_OUT.backend")) {
                            sBody = _this6.get("_objSSCoreStrings").getString("failedToGenerateNotificationPreviewOptOut");
                        }
                        sStatus = _this6.get("_objStaffshiftCoreEnums").getFrontendValue("notificationStatus", objData.status);
                    }
                    _this6.set("_objNotificationPreview", {
                        sSubject: sSubject,
                        objFrom: objSender,
                        objTo: objRecipient,
                        objReplyTo: objReplyTo,
                        sHTML: sBody,
                        sDeliveryStatus: sStatus
                    });
                    if (!a24Core.isEmpty(sStatusReason)) {
                        _this6.set("_objNotificationPreview.sDeliveryFailureReason", sStatusReason);
                    }
                    _this6.set("_arrNotificationPreviewPopupActions", [{
                        sTitle: _this6.get("_objCoreStrings").getString("openInNewTab"),
                        sIcon: "open_in_new",
                        executeCardAction: function executeCardAction() {
                            var objNotificationPreview = _this6.get("_objNotificationPreview");
                            // Generate the email html from details
                            var sHtml = _this6.get("_objEmailPreviewGeneratorService").generateFullHtmlWithHTMLWrappingFromEmailObject(objNotificationPreview);
                            _this6.openHtmlInNewWindow(sHtml);
                        }
                    }]);
                } else {
                    _this6.set("_bIsEmail", false);
                    _this6.set("_arrNotificationPreviewPopupActions", null);
                    _this6.set("_objNotificationPreview", objData);
                }

                // Take the popup out of loading
                _this6.set("_bNotificationPreviewPopupLoading", false);
            };

            this.set("_bShowNotifcationPreviewPopup", true);
            this.set("_bNotificationPreviewPopupLoading", true);

            // Use the stored response if it is set
            var objStoredNotifciationResponse = this.get("_objCurrentNotificationSentCache." + sNotificationSentId);

            if (!a24Core.isEmpty(objStoredNotifciationResponse)) {
                funcSuccess(objStoredNotifciationResponse);
                return;
            }

            this._getActivityNotificationFromMedium(sActivityId, sLinkedEntityId, sMedium, sNotificationSentId, funcSuccess);
        },

        _getActivityNotificationFromMedium: function _getActivityNotificationFromMedium(sActivityId, sLinkedEntityId, sMedium, sNotificationSentId, funcSuccess) {
            var _this7 = this;

            this.get("objRestCandidateActivityNotification").getActivityNotificationFromMedium(this, "_objActivityNotificationMediumPreviewPromise", this.get("sCandidateId"), sActivityId, sLinkedEntityId, sMedium, sNotificationSentId, funcSuccess, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this7.set("_bShowNotifcationPreviewPopup", false);
                _this7.set("_bNotificationPreviewPopupLoading", false);

                _this7.get("_objSnackbarService").showSnackbarMessage(_this7.get("_objCandidateProfileStringsService").getString("couldNotLoadNotificationPreview"));
            }));
        },

        conversationFromActivity: function conversationFromActivity(objActivity, objLinkedEntity) {
            var _this8 = this;

            var funcSuccess = function funcSuccess(objData) {

                Ember.set(objActivity, "objStoredConversationResponse", objData);

                // On success
                _this8.set("_objConversationPreview", objData);
                // Create an action that will open the conversation preview in a new tab
                _this8.set("_objSpecialConversationPreviewPopupAction", {
                    sTitle: _this8.get("_objStringsService").getString("close"),
                    sIcon: "close",
                    executeCardAction: function executeCardAction() {
                        _this8.set("_bShowConversationPreviewPopup", false);
                    }
                });

                if (objData.status !== "open") {
                    _this8.set("_arrConversationPreviewPopupActions", [{
                        sTitle: _this8.get("_objCoreStrings").getString("openInNewTab"),
                        sIcon: "open_in_new",
                        executeCardAction: function executeCardAction() {
                            var _objConversationPreview = _this8.get("_objConversationPreview");
                            // Generate the conversation html from details
                            var sHtml = _this8.get("_objConversationPreviewGeneratorService").generateFullHtmlWithHTMLWrappingFromConversation(_objConversationPreview);
                            _this8.openHtmlInNewWindow(sHtml);
                        }
                    }]);
                }

                // Take the popup out of loading
                _this8.set("_bConversationPreviewPopupLoading", false);
            };

            this.set("_bShowConversationPreviewPopup", true);
            this.set("_bConversationPreviewPopupLoading", true);

            // Use the stored response if it is set
            var objStoredConversationResponse = Ember.get(objActivity, "objStoredConversationResponse");
            if (!a24Core.isEmpty(objStoredConversationResponse)) {
                funcSuccess(objStoredConversationResponse);
                return;
            }

            this.get("objRestCandidateActivityConversation").getActivityConversation(this, "_objActivityConversationPreviewPromise", this.get("sCandidateId"), objActivity._id, objLinkedEntity.entity_id, funcSuccess, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this8.set("_bShowConversationPreviewPopup", false);
                _this8.set("_bConversationPreviewPopupLoading", false);

                _this8.get("_objSnackbarService").showSnackbarMessage(_this8.get("_objCandidateProfileStringsService").getString("couldNotLoadConversationEmailPreview"));
            }));
        },

        openHtmlInNewWindow: function openHtmlInNewWindow(sHtml) {
            // Open the html in a new window
            var objNewWindow = window.open();

            if (objNewWindow !== null) {
                objNewWindow.document.write(sHtml);
                objNewWindow.document.close();
                objNewWindow.focus();
            }
        },
        setAddPopup: function setAddPopup() {
            var _this9 = this;

            this.clearAllErrorMessages();
            this.clearForm();
            this.set("_bShowAddPopup", true);

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this9.set("_objOriginalData", _this9.getPlainFormValueObject());
                });
            });
        },
        hidePopup: function hidePopup() {
            if (this.get("_bPopupLoading")) {
                return;
            }
            // Check if the form has any unsaved data
            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowAddPopup", false);
            } else {
                this.set("_bShowUnsavedData", true);
            }
        },
        getPayload: function getPayload() {
            // Get the form data
            var objFormData = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

            return objFormData;
        },
        setErrorResponse: function setErrorResponse(sCode, objError, sSnackbarMessage) {
            if (sCode === "MIN_LENGTH") {
                this.setResponseError(objError.arrPath, "validationRestMinLength");
            } else if (sCode === "ENUM_MISMATCH") {
                this.setResponseError(objError.arrPath, "validationRestUnsupportedValue");
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }

            this.set("_bPopupLoading", false);
        },
        setSuccessResponse: function setSuccessResponse(sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bPopupLoading", false);
            this.set("_bShowAddPopup", false);

            this.doServiceCalls();
        },
        actions: {
            // If the filter changes, we wish to reload the card
            cardDatagridFilterChangeEvent: function cardDatagridFilterChangeEvent(sProperty, objValue) {
                objValue = Ember.copy(objValue, true);
                var objParams = objValue;
                this.set("_iPage", 1);
                objParams.page = { mValue: 1 };
                objParams.items_per_page = { mValue: this.get("_iPerPage") };
                if (!a24Core.isEmpty(objParams.activity_date_at)) {
                    if (objParams.activity_date_at.bNotWithinLast === true) {
                        objParams.activity_date_at_to = {
                            mValue: objParams.activity_date_at.mValue.sFrom,
                            bEncode: true
                        };
                    } else if (objParams.activity_date_at.bNotWithinNext === true) {
                        objParams.activity_date_at_from = {
                            mValue: objParams.activity_date_at.mValue.sTo,
                            bEncode: true
                        };
                    } else {
                        objParams.activity_date_at_from = {
                            mValue: objParams.activity_date_at.mValue.sFrom,
                            bEncode: true
                        };
                        objParams.activity_date_at_to = {
                            mValue: objParams.activity_date_at.mValue.sTo,
                            bEncode: true
                        };
                    }
                    delete objParams.activity_date_at;
                }
                this.doServiceCalls(objParams);
            },
            cardDatagridPagerEvent: function cardDatagridPagerEvent(objPageQuery) {
                objPageQuery = Ember.copy(objPageQuery, true);

                var objQueryParams = this.get("_objQueryParams");
                objQueryParams.page = objPageQuery.page;
                objQueryParams.items_per_page = objPageQuery.per_page;

                this.doServiceCalls(objQueryParams);
            },
            onPopupConfirmationCancel: function onPopupConfirmationCancel() {
                this.hidePopup();
            },
            onPopupConfirmationConfirm: function onPopupConfirmationConfirm() {
                var _this10 = this;

                // When the save button is clicked
                var bHasError = this.doFullValidation();
                if (!bHasError) {
                    this.set("_bPopupLoading", true);

                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objCandidateProfileStringsService").getString("activity")));

                    var objPayload = this.getPayload();
                    // call to save the person activity
                    this.get("objActivityRestService").createActivity(this, "_objActivityPostPromise", this.get("sCandidateId"), objPayload, function () {
                        _this10.setSuccessResponse(_this10.get("_objStringsService").getTokenString("successAddItem", _this10.get("_objCandidateProfileStringsService").getString("activity")));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                        _this10.setErrorResponse(sCode, objError, _this10.get("_objStringsService").getTokenString("failAddItem", _this10.get("_objCandidateProfileStringsService").getString("activity")));
                    }));
                }
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowUnsavedData", false);
                this.set("_bShowAddPopup", false);
            },
            // If the custom action is clicked
            customActionClicked: function customActionClicked(objItem) {
                objItem.executeCardAction();
            },

            onEmailMessagePopupClose: function onEmailMessagePopupClose() {
                this.set("_bShowEmailMessagePopup", false);
            },
            onConversationPreviewPopupClose: function onConversationPreviewPopupClose() {
                this.set("_bShowConversationPreviewPopup", false);
            },
            onNotificationSentPopupClose: function onNotificationSentPopupClose() {
                this.set("_bShowNotifcationsSentPopup", false);
            },
            onNotificationPreviewPopupClose: function onNotificationPreviewPopupClose() {
                this.set("_bShowNotifcationPreviewPopup", false);
            },
            onNotificationSentClick: function onNotificationSentClick(objData) {
                var sActivityId = objData.activity_id;
                var sLinkedEntityId = objData.entity_id;
                var sMedium = objData.backend_medium;
                var sNotificationSentId = objData.notifications_sent_id;

                this.activityNotificationFromMedium(sActivityId, sLinkedEntityId, sMedium, sNotificationSentId);
            }
        }
    });
});
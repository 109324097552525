define("a24-ember-lib/components/saved-search-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/saved-search-component", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _savedSearchComponent, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _savedSearchComponent.default,
        classNames: ["display-inline-block position-relative saved-search-component"],

        objSavedSearchService: null,
        sSavedSearchRef: null,
        bShowActions: null,
        bShowSaveAction: true,

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        _sSavedSearchRemoveId: null,
        _sCustomTabIndex: null,

        _bShowSaveAction: true,
        _bShowAddSavedSearch: false,
        _bShowUnsavedData: false,
        _bPopupLoading: false,
        _bShowDeleteConfirm: false,
        _bPopupDeleteLoading: false,
        _bInitDone: false,

        _arrInitSavedSearch: null,

        _objSelected: null,
        _objGetSavedSearch: null,
        _objCreateSavedSearch: null,
        _objDeleteSavedSearch: null,
        _objOriginalData: null,
        _objSpecialAddPopupAction: null,

        _objEmberLibString: Ember.inject.service("a24-strings-ember-lib"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objUrlTrickeryService: Ember.inject.service("url-trickery"),

        _bLoading: Ember.computed("_bInitDone", function () {
            if (this.get("_bInitDone")) {
                return false;
            }
            return true;
        }),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var objValidationConfig = {};

            objValidationConfig["objChildren.name"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            this.set("objChildConfig", objValidationConfig);

            this.set("_objSpecialAddPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupAddCancel");
                }
            });

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    if (a24Core.isEmpty(_this.get("bShowActions"))) {
                        _this.set("bShowActions", true);
                    }
                });
            });

            this._getInitSavedSearch();

            var objThis = this;
            var funcGetSuggestions = function funcGetSuggestions(sSearchName, funcCallback) {

                if (!_this.get("_bInitDone")) {
                    return;
                }

                var arrData = _this.get("_arrInitSavedSearch");

                if (!a24Core.isEmpty(_this.get("objChildren.saved_search").mValue)) {
                    sSearchName = "";
                }

                arrData = _this._filterSavedSearch(sSearchName, arrData);

                if (a24Core.isEmpty(arrData)) {
                    arrData = [];
                }

                // Define the array that will contain formatted data
                var arrFormatted = [];

                // Loop over the results

                var _loop = function _loop(i) {

                    // Get the item at index i
                    var objDataItem = arrData[i];

                    // Push label with id as an object to the search box
                    if (!objDataItem.deleted) {
                        sTooltip = objDataItem.name;

                        if (!a24Core.isEmpty(objDataItem.description)) {
                            sTooltip += "\n" + objDataItem.description;
                        }
                        arrFormatted.push({
                            sValue: objDataItem._id,
                            sLabel: objDataItem.name,
                            sUrl: objDataItem.url,
                            sToolTipText: Ember.String.htmlSafe(sTooltip),
                            arrActions: _this.get("bShowActions") ? [{
                                sTitle: _this.get("_objStringsService").getString("remove"),
                                sIcon: "delete",
                                sToolTipClass: "a24-tooltip",
                                executeCardAction: function executeCardAction() {
                                    objThis.set("_bShowDeleteConfirm", true);
                                    objThis.set("_sSavedSearchRemoveId", objDataItem._id);
                                }
                            }] : null
                        });
                    }
                };

                for (var i = 0; i < arrData.length; i++) {
                    var sTooltip;

                    _loop(i);
                }

                // Call the callback with the formatted data
                funcCallback(arrFormatted);
            };

            this.set("_funcGetSavedSearchSuggestions", funcGetSuggestions);
        },

        _filterSavedSearch: function _filterSavedSearch(sSearchFilter, arrData) {
            var arrFilteredData = [];
            for (var i = 0; i < arrData.length; i++) {
                if (arrData[i].name.toLowerCase().includes(sSearchFilter.toLowerCase())) {
                    arrFilteredData.push(arrData[i]);
                }
            }
            return arrFilteredData;
        },
        _removeSavedSearchData: function _removeSavedSearchData(objRemovedSavedSearchData) {
            var arrSavedSearch = this.get("_arrInitSavedSearch");
            for (var i = 0; i < arrSavedSearch.length; i++) {
                if (arrSavedSearch[i]._id === objRemovedSavedSearchData._id) {
                    this.get("_arrInitSavedSearch").removeAt(i);
                    break;
                }
            }

            this.set("_arrInitSavedSearch", arrSavedSearch);
        },
        _addSavedSearchData: function _addSavedSearchData(objAddedSavedSearchData) {
            this.get("_arrInitSavedSearch").pushObject(objAddedSavedSearchData);
            this.set("_arrInitSavedSearch", this.get("_arrInitSavedSearch").sortBy("name"));
        },

        _getInitSavedSearch: function _getInitSavedSearch() {
            var _this2 = this;

            var objQueryParams = {
                type: {
                    mValue: "shared",
                    bEncode: true
                },
                reference: {
                    mValue: this.get("sSavedSearchRef"),
                    bEncode: true
                },
                deleted: {
                    mValue: false,
                    bEncode: true
                },
                sortBy: {
                    mValue: "name"
                },
                items_per_page: {
                    mValue: 1000
                }
            };

            this.get("objSavedSearchService").getList(this, "_objGetSavedSearch", objQueryParams, function (arrData) {
                if (a24Core.isEmpty(arrData)) {
                    arrData = [];
                }

                _this2.set("_arrInitSavedSearch", arrData);
                _this2.set("_bInitDone", true);
                _this2._autoSelectSavedSearch();

                if (a24Core.isEmpty(_this2.get("bShowActions"))) {
                    _this2.set("bShowActions", true);
                }
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                // Call the callback with empty
                _this2.set("_arrInitSavedSearch", []);
            }));
        },

        _autoSelectSavedSearch: function _autoSelectSavedSearch() {
            var _this3 = this;

            var arrSavedSearch = this.get("_arrInitSavedSearch");
            var sCurrentUrl = window.location.href.replace(window.location.origin, "");
            if (sCurrentUrl.startsWith("/#/")) {
                sCurrentUrl = sCurrentUrl.substring("/#/".length - 1);
            } else if (sCurrentUrl.startsWith("/emberViews#/")) {
                sCurrentUrl = sCurrentUrl.substring("/emberViews#/".length - 1);
            } else if (this.get("_objEnv").environment === "test") {
                sCurrentUrl = sCurrentUrl.slice(sCurrentUrl.indexOf("/tests/") + 1, sCurrentUrl.length);
            }
            for (var i = 0; i < arrSavedSearch.length; i++) {
                if (sCurrentUrl === arrSavedSearch[i].url) {
                    var objSavedSeach = arrSavedSearch[i];
                    Ember.run.next(function () {
                        _this3.get("objChildren.saved_search").setValueFromOutside({
                            sValue: objSavedSeach._id,
                            sLabel: objSavedSeach.name,
                            sUrl: objSavedSeach.url
                        });
                    });
                    break;
                }
            }
        },

        observeShowAction: Ember.observer("bShowSaveAction", function () {
            var bShowSaveAction = this.get("bShowSaveAction");
            if (bShowSaveAction) {
                this.clearForm();
            }
            this.set("_bShowSaveAction", bShowSaveAction);
        }),

        onInputValueChanged: function onInputValueChanged(arrChildRef) {
            this._super.apply(this, arguments);

            // Get the reference of the field that changed
            var sLookup = arrChildRef.join(".");

            if (sLookup === "objChildren.saved_search") {
                var objValue = this.get(sLookup + ".mValue");
                if (!a24Core.isEmpty(objValue) && !a24Core.isEmpty(objValue.sUrl)) {
                    this.set("_objSelected", objValue);
                    this.get("changeShowSaveAction")(false);

                    var sCurrentUrl = window.location.href.replace(window.location.origin, "");

                    if (sCurrentUrl.startsWith("/#/") || this.get("_objEnv").environment === "test") {
                        this.get("_objUrlTrickeryService").replaceBrowserUrl(window.location.origin + "/#" + objValue.sUrl);
                    } else if (sCurrentUrl.startsWith("/emberViews#/")) {
                        this.get("_objUrlTrickeryService").replaceBrowserUrl(window.location.origin + "/emberViews#" + objValue.sUrl);
                    }
                } else {
                    this.set("_objSelected", null);
                }
            }
        },

        _getPayload: function _getPayload() {

            var objPayload = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

            delete objPayload.saved_search;

            return objPayload;
        },

        _setErrorResponse: function _setErrorResponse(sCode, objError, sSnackbarMessage) {
            if (sCode === "NAME_ALREADY_EXISTS") {
                this.setResponseError(objError.arrPath, "nameAlreadyExists", false, true, this.get("_objEmberLibString"));
            } else if (sCode === "PATTERN") {
                this.setResponseError(objError.arrPath, "validationRestInvalidFormat");
            } else if (sCode === "403" || sCode === "401") {
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objEmberLibString").getString("requiredPermission"));
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }

            this.set("_bPopupLoading", false);
        },

        _openSavedSearchPopup: function _openSavedSearchPopup() {
            var _this4 = this;

            this.get("sSavedSearchRef");
            var sCurrentUrl = window.location.href.replace(window.location.origin, "");

            if (sCurrentUrl.startsWith("/#/")) {
                sCurrentUrl = sCurrentUrl.substring("/#/".length - 1);
            } else if (sCurrentUrl.startsWith("/emberViews#/")) {
                sCurrentUrl = sCurrentUrl.substring("/emberViews#/".length - 1);
            } else if (this.get("_objEnv").environment === "test") {
                sCurrentUrl = sCurrentUrl.slice(sCurrentUrl.indexOf("/tests/") + 1, sCurrentUrl.length);
            }
            this.set("_sSavedUrl", sCurrentUrl);

            this.get("objChildren.name").clear();
            this.get("objChildren.description").clear();
            this.clearAllErrorMessages();
            this.set("_bShowAddSavedSearch", true);
            this.set("_sCustomTabIndex", "-1");

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this4.set("_objOriginalData", _this4._getPayload());
                });
            });
        },

        actions: {
            onPopupUnsavedCancel: function onPopupUnsavedCancel() {
                this.set("_bShowUnsavedData", false);
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowUnsavedData", false);
                this.set("_bShowAddSavedSearch", false);
                this.set("_sCustomTabIndex", null);
            },
            changeShowSaveAction: function changeShowSaveAction(bValue) {
                if (!a24Core.isEmpty(this.get("changeShowSaveAction"))) {
                    this.get("changeShowSaveAction")(bValue);
                }
            },
            onPopupDeleteConfirm: function onPopupDeleteConfirm() {
                var _this5 = this;

                this.set("_bPopupDeleteLoading", true);
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("removingItem", this.get("_objEmberLibString").getString("savedSearch")));
                this.get("objSavedSearchService").deleteItem(this, "_objDeleteSavedSearch", this.get("_sSavedSearchRemoveId"), function (objResponse) {
                    _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("successRemoveItem", _this5.get("_objEmberLibString").getString("savedSearch")));
                    _this5.set("_bPopupDeleteLoading", false);
                    _this5.set("_bShowDeleteConfirm", false);
                    if (_this5.get("_sSavedSearchRemoveId") === _this5.get("_objSelected").sValue) {
                        _this5.get("objChildren.saved_search").setValueFromOutside();
                        _this5.get("changeShowSaveAction")(true);
                    }
                    _this5._removeSavedSearchData(objResponse);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    if (sCode === "404") {
                        _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getString("removeFailedRefreshPage"));
                    } else if (sCode === "403" || sCode === "401") {
                        _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objEmberLibString").getString("requiredPermission"));
                    } else {
                        _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("failRemoveItem", _this5.get("_objEmberLibString").getString("savedSearch")));
                    }

                    _this5.set("_bPopupDeleteLoading", false);
                }));
            },
            onPopupClose: function onPopupClose() {
                if (this.get("_bPopupDeleteLoading")) {
                    return;
                }

                this.set("_bShowDeleteConfirm", false);
            },
            saveActionClicked: function saveActionClicked() {
                this._openSavedSearchPopup();
            },
            onPopupAddCancel: function onPopupAddCancel() {
                if (this.get("_bPopupLoading")) {
                    return;
                }
                // Check if the form has any unsaved data
                var bDataMatches = a24Core.compareObjects(Ember, this._getPayload(), this.get("_objOriginalData"));

                if (bDataMatches) {
                    this.set("_bShowAddSavedSearch", false);
                    this.set("_sCustomTabIndex", null);
                } else {
                    this.set("_bShowUnsavedData", true);
                }
            },
            onPopupAddConfirm: function onPopupAddConfirm() {
                var _this6 = this;

                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    var objPayload = this._getPayload();

                    var objData = {
                        "name": objPayload.name,
                        "description": objPayload.description,
                        "reference": this.get("sSavedSearchRef"),
                        "url": this.get("_sSavedUrl"),
                        "type": "shared"
                    };

                    if (a24Core.isEmpty(objPayload.description)) {
                        delete objData.description;
                    }

                    this.set("_bPopupLoading", true);

                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objEmberLibString").getString("savedSearch")));

                    this.get("objSavedSearchService").createItem(this, "_objCreateSavedSearch", objData, function (objResponse) {
                        _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objStringsService").getTokenString("successAddItem", _this6.get("_objEmberLibString").getString("savedSearch")));
                        _this6.set("_bPopupLoading", false);
                        _this6.set("_bShowAddSavedSearch", false);
                        _this6.set("_sCustomTabIndex", null);
                        Ember.run.next(function () {
                            _this6.get("objChildren.saved_search").setValueFromOutside({
                                sValue: objResponse._id,
                                sLabel: objResponse.name,
                                sUrl: objResponse.url
                            });
                        });
                        _this6.get("changeShowSaveAction")(false);
                        _this6._addSavedSearchData(objResponse);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                        _this6._setErrorResponse(sCode, objError, _this6.get("_objStringsService").getTokenString("failAddItem", _this6.get("_objEmberLibString").getString("savedSearch")));
                    }));
                }
            }
        }
    });
});
define("a24-ember-lib/components/card-datagrid-column-toggle", ["exports", "a24-ember-lib/templates/components/card-datagrid-column-toggle", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _cardDatagridColumnToggle, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _cardDatagridColumnToggle.default,
        classNames: ["display-inline-block position-relative"],
        arrAllowedHeaders: null,
        _sColumnSelected: "",
        _bShowColumnsDropdown: false,
        _objOnlyDisplayedHeader: null,
        _arrHeaders: null,
        _arrSelectedHeaders: null,
        _sColumnOptionsDropdownId: "",
        init: function init() {
            this._super.apply(this, arguments);

            this.set("_sColumnSelected", Ember.guidFor({}));
            this.set("_sColumnOptionsDropdownId", Ember.guidFor({}));

            this._configureColumns();

            this.addObserver("arrAllowedHeaders", this, this.get("_configureColumns"));
            this.addObserver("_arrHeaders.@each.bShowHeader", this, this.get("_funcColumnChangeCallbackObserver"));
        },
        _configureColumns: function _configureColumns() {
            var _this = this;

            if (!a24Core.isEmpty(this.get("arrAllowedHeaders"))) {
                var arrHeaders = this.get("arrAllowedHeaders").filter(function (objItem) {
                    return !Ember.get(objItem, "bSilent");
                });

                var arrSelectedHeaders = arrHeaders.filter(function (objItem) {
                    return Ember.get(objItem, "bShowHeader");
                });

                if (arrSelectedHeaders.length === 1) {
                    Ember.run.next(function () {
                        Ember.run.schedule("afterRender", function () {
                            var objActives = _this.$(".multiple-select-dropdown li.active");
                            objActives.addClass("disabled");
                            objActives.find("input").attr("disabled", "disabled");
                        });
                    });
                }

                this.set("_arrHeaders", arrHeaders);
                this.set("_arrSelectedHeaders", arrSelectedHeaders);
            } else {
                this.set("_arrHeaders", []);
                this.set("_arrSelectedHeaders", []);
            }
        },
        _funcColumnChangeCallbackObserver: function _funcColumnChangeCallbackObserver() {
            Ember.run.once(this, "_funcColumnChangeCallback");
        },
        _funcColumnChangeCallback: function _funcColumnChangeCallback() {
            var arrSelectedHeaders = this.get("_arrHeaders").filter(function (objItem) {
                return Ember.get(objItem, "bShowHeader");
            });

            this.set("_arrSelectedHeaders", arrSelectedHeaders);

            this.setToggleStateForColumns(arrSelectedHeaders);
        },
        toggleDatagridColumnChange: function toggleDatagridColumnChange() {
            if (!a24Core.isEmpty(this.get("cardDatagridColumnToggleChangeEvent"))) {
                return this.get("cardDatagridColumnToggleChangeEvent")();
            }
        },
        setToggleStateForColumns: function setToggleStateForColumns(arrColumns) {
            var _this2 = this;

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    if (arrColumns.length === 1) {
                        var objActives = _this2.$(".multiple-select-dropdown li.active");
                        objActives.addClass("disabled");
                        objActives.find("input").attr("disabled", "disabled");
                    } else {
                        var objActivesDisabled = _this2.$(".multiple-select-dropdown li.active.disabled");
                        objActivesDisabled.removeClass("disabled");
                        objActivesDisabled.find("input").removeAttr("disabled");
                    }
                });
            });
        },
        actions: {
            toggleColumns: function toggleColumns(arrSelectedColumns) {
                this.removeObserver("_arrHeaders.@each.bShowHeader", this, this.get("_funcColumnChangeCallbackObserver"));

                var arrHeaders = this.get("_arrHeaders");

                for (var i = 0; i < arrHeaders.length; i++) {
                    var objHeader = arrHeaders[i];

                    if (!a24Core.isEmpty(arrSelectedColumns.findBy("sProperty", objHeader.sProperty))) {
                        Ember.set(objHeader, "bShowHeader", true);
                    } else {
                        Ember.set(objHeader, "bShowHeader", false);
                    }
                }

                this.setToggleStateForColumns(arrSelectedColumns);

                this.addObserver("_arrHeaders.@each.bShowHeader", this, this.get("_funcColumnChangeCallbackObserver"));

                Ember.run.scheduleOnce("afterRender", this, "toggleDatagridColumnChange");
            },

            columnToggleOptionClicked: function columnToggleOptionClicked() {
                this.set("_bShowColumnsDropdown", true);

                var objCurrentController = this;

                //Here we do a next since we first need the DOM to draw the filter options popup before we can access
                //the first dropdown to focus it(seems it can only be focused in a ember next, might have to do with how
                //mdb inits the dropdown) and also override the click on the popup so that it does not close the
                //popup when click happens on itself.
                Ember.run.next(function () {
                    Ember.run.schedule("afterRender", function () {
                        var objOptionsDropdown = objCurrentController.$("#" + objCurrentController.get("_sColumnOptionsDropdownId") + " input");
                        if (!a24Core.isEmpty(objOptionsDropdown)) {
                            objOptionsDropdown.first().focus();
                        }
                    });
                });
            },
            closeFilterDropdown: function closeFilterDropdown() {
                this.set("_bShowColumnsDropdown", false);

                Ember.run.scheduleOnce("afterRender", this, "toggleDatagridColumnChange");
            }
        }
    });
});
define("a24-ember-candidate-profile/components/candidate-consultant", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-consultant", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _candidateConsultant, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateConsultant.default,
        // Public Variables
        sHeading: null,
        objSettingsRestService: null,
        objConsultantService: null,
        objConsultantRoleService: null,
        objUserService: null,
        sCandidateId: null,
        bAllowAddEdit: true,
        objAgencyAgencyGroupService: null,

        // Private Variables
        _sConsultantId: null,
        _bLoading: true,
        _bPopupLoading: true,
        _bShowAddEditPopup: false,
        _bShowUnsavedData: false,
        _bRedrawDropdown: true,
        _bAdding: true,
        _bShowDeletePopup: false,
        _bPopupDeleteLoading: false,

        _objOriginalData: null,
        _objSelectedRoles: null,

        _arrCandidateConsultants: null,
        _arrConsultantRoles: null,
        _arrDisplayConsultants: null,

        _funcGetSuggestions: null,

        // Promise Objects
        _objCandidateConsultantPromise: null,
        _objCandidateConsultantDeletePromise: null,
        _objCandidateConsultantCreatePromise: null,
        _objCandidateConsultantUpdatePromise: null,
        _objConsultantRolePromise: null,
        _objGetUserSuggestionsPromise: null,

        // Services
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUserSession: Ember.inject.service("user-session"),

        _bServerError: false,
        _arrReloadActions: null,
        _sAgencyGroupId: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.doMainServiceCalls();
                }
            }]);

            if (!this.get("objSettingsRestService.objSettings.profile_editable")) {
                this.set("bAllowAddEdit", false);
            }

            var sAgencyGroupId = this.get("_objUserSession").getUserContextReference().mId;

            var funcGetSuggestions = function funcGetSuggestions(sSearchName, funcCallback) {

                _this.get("objUserService").getAvailableUserByQuery(_this, "_objGetUserSuggestions", sAgencyGroupId, sSearchName, // Query string passed to function
                function (arrData) {
                    // When service call succeeds

                    // If the data is empty, ensure it's an empty array
                    if (a24Core.isEmpty(arrData)) {
                        arrData = [];
                    }

                    // Define the array that will contain formatted data
                    var arrFormatted = [];

                    // Loop over the results
                    for (var i = 0; i < arrData.length; i++) {

                        // Get the item at index i
                        var objDataItem = arrData[i];

                        // Build the label to show
                        var sLabel = objDataItem.sFirstName + " " + objDataItem.sLastName + " - " + objDataItem.sUsername;

                        // Push label with id as an object to the search box
                        arrFormatted.push({
                            name: sLabel,
                            _id: objDataItem.id
                        });
                    }

                    // Call the callback with the formatted data
                    funcCallback(arrFormatted);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    // Call the callback with empty
                    funcCallback([]);
                }));
            };
            this.set("_funcGetSuggestions", funcGetSuggestions);

            if (this.get("bAllowAddEdit")) {
                this.set("_arrHeaderActions", [{
                    sTitle: this.get("_objStringsService").getTokenString("addItem", this.get("_objCandidateProfileStringsService").getString("consultant")),
                    sIcon: "add",
                    executeCardAction: function executeCardAction() {
                        _this.addConsultant();
                    }
                }]);
            }

            this.set("_objSpecialAddEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.popupAddEditCancel();
                }
            });

            this.set("objChildConfig", {
                "objChildren.consultant_role": function objChildrenConsultant_role(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.assigned_user": function objChildrenAssigned_user(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                }
            });

            if (!a24Core.isEmpty(this.get("objAgencyAgencyGroupService"))) {
                this.doAgencyGroupServiceCall();
            } else {
                this.set("_sAgencyGroupId", sAgencyGroupId);
            }
            this.observeRequiredIds();
        },
        doAgencyGroupServiceCall: function doAgencyGroupServiceCall() {
            var _this2 = this;

            this.get("objAgencyAgencyGroupService").getAgencyGroup(this, "_objAgencyAgencyGroupGetPromise", function (objData) {
                if (!a24Core.isEmpty(objData)) {
                    _this2.set("_sAgencyGroupId", objData[0].agency_group_id);
                }
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
            function (sCode) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this2.set("_bServerError", true);
                    return;
                }
            }));
        },
        // observes ids required to make the service calls
        observeRequiredIds: Ember.observer("_sAgencyGroupId", "sCandidateId", function () {
            if (!a24Core.isEmpty(this.get("_sAgencyGroupId")) && !a24Core.isEmpty(this.get("sCandidateId"))) {
                Ember.run.once(this, this.doMainServiceCalls);
            }
        }),
        doMainServiceCalls: function doMainServiceCalls() {
            var _this3 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);
            var objPromiseCandidateConsultant = this.get("objConsultantService").getCandidateConsultantList(this, "_objCandidateConsultantPromise", this.get("_sAgencyGroupId"), this.get("sCandidateId"), function () {}, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this3.set("_bServerError", true);
                    return;
                }
                //Handle other errors
            }));

            var objPromiseConsultantRole = null;
            if (!a24Core.isEmpty(this.get("objConsultantRoleService"))) {
                objPromiseConsultantRole = this.get("objConsultantRoleService").getConsultantRoles(this, "_objConsultantRolePromise", function () {}, // handled in rsvp
                a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode) {
                    if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                        _this3.set("_bServerError", true);
                        return;
                    }
                    //Handle other errors
                }));
            }

            var objServiceCalls = {
                _objRestCandidateConsultantProperty: objPromiseCandidateConsultant
            };

            var arrRequiredResponses = ["_objRestCandidateConsultantProperty"];

            if (!a24Core.isEmpty(objPromiseConsultantRole)) {
                objServiceCalls._objRestConsultantRoleProperty = objPromiseConsultantRole;
                arrRequiredResponses.push("_objRestConsultantRoleProperty");
            }

            Ember.copy(a24RSVP).configure(this, objServiceCalls, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objRestCandidateConsultantProperty) && !a24Core.isEmpty(objResponses._objRestCandidateConsultantProperty.objHeaders) && objResponses._objRestCandidateConsultantProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRestConsultantRoleProperty) && !a24Core.isEmpty(objResponses._objRestConsultantRoleProperty.objHeaders) && objResponses._objRestConsultantRoleProperty.objHeaders.statusText === "abort") {
                        return;
                    }

                    if (!a24Core.isEmpty(objResponses._objRestCandidateConsultantProperty) && !a24Core.isEmpty(objResponses._objRestCandidateConsultantProperty.objResponse)) {
                        _this3.set("_arrCandidateConsultants", objResponses._objRestCandidateConsultantProperty.objResponse);
                    } else {
                        _this3.set("_arrCandidateConsultants", []);
                    }

                    if (!a24Core.isEmpty(objResponses._objRestConsultantRoleProperty) && !a24Core.isEmpty(objResponses._objRestConsultantRoleProperty.objResponse)) {
                        _this3.set("_arrConsultantRoles", objResponses._objRestConsultantRoleProperty.objResponse);
                    } else {
                        _this3.set("_arrConsultantRoles", []);
                    }

                    _this3._prepareForDisplay();
                }
            }]);
        },
        _prepareForDisplay: function _prepareForDisplay() {
            var arrRoles = Ember.copy(this.get("_arrConsultantRoles"));
            var arrCandidateConsultants = this.get("_arrCandidateConsultants");

            var arrDisplayConsultants = [];
            var arrRolesDropdown = [];

            for (var i = 0; i < arrCandidateConsultants.length; i++) {
                var iFoundIndex = null;
                for (var l = 0; l < arrRoles.length; l++) {
                    if (arrRoles[l].name === arrCandidateConsultants[i].consultant_role) {
                        iFoundIndex = l;
                    }
                }
                if (!a24Core.isEmpty(iFoundIndex)) {
                    arrRoles.splice(iFoundIndex, 1);
                }
            }

            var objThis = this;

            // Setup array for the roles dropdown
            for (var j = 0; j < arrRoles.length; j++) {
                var objRoleItem = {
                    sValue: arrRoles[j].name,
                    sLabel: arrRoles[j].name
                };

                arrRolesDropdown.push(objRoleItem);
            }

            // This is commented out as it not posible to edit roles on a consultant. The backend does not allow
            // this as they had to do some shortcuts. So the edit does not make sense here.
            // But this is not deleted as in the future we will want to edit consultants.
            // var funcCardActionEditExecute = function(objItem) {
            //     return function() {
            //         objThis.editConsultant(objItem);
            //     };
            // };
            var funcCardActionDeleteExecute = function funcCardActionDeleteExecute(objItem) {
                return function () {
                    objThis.deleteConsultant(objItem);
                };
            };

            for (var k = 0; k < arrCandidateConsultants.length; k++) {

                var arrActions = [];
                if (this.get("bAllowAddEdit")) {
                    // This is commented out as it not posible to edit roles on a consultant. The backend does not allow
                    // this as they had to do some shortcuts. So the edit does not make sense here.
                    // But this is not deleted as in the future we will want to edit consultants.
                    // arrActions.push({
                    //     sTitle: this.get("_objStringsService").getTokenString(
                    //         "editItem",
                    //         this.get("_objCandidateProfileStringsService").getString("consultant")
                    //     ),
                    //     sIcon: "edit",
                    //     executeCardAction: funcCardActionEditExecute(arrCandidateConsultants[k])
                    // });
                    arrActions.push({
                        sTitle: this.get("_objStringsService").getTokenString("removeItem", this.get("_objCandidateProfileStringsService").getString("consultant")),
                        sIcon: "delete",
                        executeCardAction: funcCardActionDeleteExecute(arrCandidateConsultants[k])
                    });
                }

                var objDisplayItem = {
                    sLabel: arrCandidateConsultants[k].assigned_user.name,
                    sExtra: arrCandidateConsultants[k].consultant_role,
                    sIcon: "person",
                    arrActions: arrActions
                };

                arrDisplayConsultants.push(objDisplayItem);
            }

            this.set("_arrRolesForDropdown", arrRolesDropdown);
            this.set("_arrDisplayConsultants", arrDisplayConsultants);
            this._setupRoleDropdown(arrRolesDropdown);

            this.set("_bLoading", false);
        },

        _setupRoleDropdown: function _setupRoleDropdown(arrRolesDropdown) {

            arrRolesDropdown.sort(function (objA, objB) {
                if (objA.sValue < objB.sValue) {
                    return -1;
                } else if (objA.sValue > objB.sValue) {
                    return 1;
                }
                return 0;
            });

            this.set("_arrRolesDropdownDisplay", arrRolesDropdown);
        },

        addConsultant: function addConsultant() {
            this.set("_sHeaderPopup", this.get("_objCandidateProfileStringsService").getString("addConsultant"));
            this.set("_bRedrawDropdown", false);
            this.set("_bAdding", true);
            this._setupRoleDropdown(this.get("_arrRolesForDropdown"));

            this.setAddEditPopup(null);
        },

        deleteConsultant: function deleteConsultant(objCandidateConsultant) {
            this.set("_sConsultantId", objCandidateConsultant._id);
            this.set("_bShowDeletePopup", true);
        },

        editConsultant: function editConsultant(objCandidateConsultant) {
            this.set("_sHeaderPopup", this.get("_objCandidateProfileStringsService").getString("editConsultant"));
            this.set("_bRedrawDropdown", false);
            this.set("_bAdding", false);
            this.set("_sConsultantId", objCandidateConsultant._id);

            var arrRolesDropdown = Ember.copy(this.get("_arrRolesForDropdown"));

            // Adds the current role back on the dropdown since its the one you are editing
            arrRolesDropdown.push({
                sValue: objCandidateConsultant.consultant_role,
                sLabel: objCandidateConsultant.consultant_role
            });
            this._setupRoleDropdown(arrRolesDropdown);
            this.setAddEditPopup(objCandidateConsultant);
        },

        setAddEditPopup: function setAddEditPopup(objCandidateConsultant) {
            var _this4 = this;

            this.clearAllErrorMessages();
            this.clearForm();

            this.set("_bPopupLoading", false);
            this.set("_bRedrawDropdown", true);
            this.set("_bShowAddEditPopup", true);

            if (!a24Core.isEmpty(objCandidateConsultant)) {
                this._populateInputFields(objCandidateConsultant);
            }

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this4.set("_objOriginalData", _this4.getPlainFormValueObject());
                });
            });
        },

        popupAddEditCancel: function popupAddEditCancel() {
            if (this.get("_bPopupLoading")) {
                return;
            }
            // Check if the form has any unsaved data
            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowAddEditPopup", false);
            } else {
                this.set("_bShowUnsavedData", true);
            }
        },


        _populateInputFields: function _populateInputFields(objCandidateConsultant) {
            // Find the role from the dropdown list
            var objSelectedRoles = this.get("_arrRolesDropdownDisplay").findBy("sValue", objCandidateConsultant.consultant_role);

            // Selected the role from the dropdown list
            if (!a24Core.isEmpty(objSelectedRoles)) {
                this.set("_objSelectedRoles", Ember.copy(objSelectedRoles, true));
            }

            this.get("objChildren.assigned_user").setValueFromOutside(objCandidateConsultant.assigned_user);
        },

        actions: {
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowAddEditPopup", false);
                this.set("_bShowUnsavedData", false);
            },

            onPopupAddEditCancel: function onPopupAddEditCancel() {
                this.popupAddEditCancel();
            },
            onPopupAddEditConfirm: function onPopupAddEditConfirm() {
                var _this5 = this;

                var sConsultantId = this.get("_sConsultantId");
                var sCandidateId = this.get("sCandidateId");
                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    this.set("_bPopupLoading", true);

                    var objData = this.getPlainFormValueObject();

                    if (this.get("_bAdding")) {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objCandidateProfileStringsService").getString("consultant")));

                        this.get("objConsultantService").createCandidateConsultant(this, "_objCandidateConsultantDeletePromise", sCandidateId, objData, function () {
                            _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("successAddItem", _this5.get("_objCandidateProfileStringsService").getString("consultant")));

                            _this5.set("_bShowAddEditPopup", false);

                            _this5.doMainServiceCalls();
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                            if (sCode === "ALREADY_EXISTS") {
                                _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objCandidateProfileStringsService").getString("addFailedRefreshPage"));
                            } else {
                                _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("failAddItem", _this5.get("_objCandidateProfileStringsService").getString("consultant")));
                            }

                            _this5.set("_bPopupLoading", false);
                        }));
                    } else {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objCandidateProfileStringsService").getString("consultant")));

                        this.get("objConsultantService").updateCandidateConsultant(this, "_objCandidateConsultantDeletePromise", sCandidateId, sConsultantId, objData, function () {
                            _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("successUpdateItem", _this5.get("_objCandidateProfileStringsService").getString("consultant")));

                            _this5.set("_bShowAddEditPopup", false);

                            _this5.doMainServiceCalls();
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                            if (sCode === "404 ") {
                                _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getString("updateFailedRefreshPage"));
                            } else {
                                _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("failUpdateItem", _this5.get("_objCandidateProfileStringsService").getString("consultant")));
                            }

                            _this5.set("_bPopupLoading", false);
                        }));
                    }
                }
            },

            onPopupDeleteClose: function onPopupDeleteClose() {
                if (this.get("_bPopupDeleteLoading")) {
                    return;
                }
                this.set("_bShowDeletePopup", false);
            },

            onPopupDeleteConfirm: function onPopupDeleteConfirm() {
                var _this6 = this;

                var sConsultantId = this.get("_sConsultantId");
                var sCandidateId = this.get("sCandidateId");
                this.set("_bPopupDeleteLoading", true);

                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("removingItem", this.get("_objCandidateProfileStringsService").getString("consultant")));

                this.get("objConsultantService").deleteCandidateConsultant(this, "_objCandidateConsultantDeletePromise", sCandidateId, sConsultantId, function () {
                    _this6.set("_bShowDeletePopup", false);

                    _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objStringsService").getTokenString("successRemoveItem", _this6.get("_objCandidateProfileStringsService").getString("consultant")));
                    _this6.set("_bPopupDeleteLoading", false);
                    _this6.doMainServiceCalls();
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    _this6.set("_bPopupDeleteLoading", false);

                    if (sCode === "404") {
                        _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objStringsService").getString("removeFailedRefreshPage"));
                    } else {
                        _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objStringsService").getTokenString("failRemoveItem", _this6.get("_objCandidateProfileStringsService").getString("consultant")));
                    }
                }));
            }
        }
    });
});
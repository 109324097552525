define("a24-ember-candidate-profile/components/card-datagrid-custom-filter-candidate-position-held", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/card-datagrid-custom-filter-candidate-position-held", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _cardDatagridCustomFilterCandidatePositionHeld, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _cardDatagridCustomFilterCandidatePositionHeld.default,
        objHeaderItem: null,

        _bLoadingJobFamilies: false,
        _bLoadingJobTitles: false,
        _bShowJobTitleField: false,
        _bShowExperienceDurationField: false,
        _bShowExperienceDurationFieldNumberInput: false,

        _arrFilterJobTitles: null,
        _arrExperienceDuration: null,
        _objGetJobTitleSuggestions: null,
        _arrStatuses: null,

        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objFilterCustomCandidatePositionHeldService: Ember.inject.service("card-datagrid-custom-filter-candidate-position-held"),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("_arrExperienceDuration", this.get("_objCandidateProfileEnumsService").getList("experienceDuration"));

            this.set("_arrStatuses", this.get("_objCandidateProfileEnumsService").getList("positionHeldStatus"));

            this._setGetSuggestionFunction();
        },
        _setGetSuggestionFunction: function _setGetSuggestionFunction() {
            var _this = this;

            this.set("_funcGetSuggestions", function (sQueryString, funcOnComplete) {
                var objQuery = {
                    sTitleName: {
                        mValue: sQueryString,
                        bLike: true,
                        bEncode: true
                    },
                    sortBy: {
                        mValue: "sTitleName"
                    },
                    items_per_page: {
                        mValue: 10
                    }
                };

                _this.get("_objFilterCustomCandidatePositionHeldService._objJobTitleService").getJobTitles(_this, "_objGetJobTitleSuggestions", objQuery, function (arrData) {
                    // If the data is empty, ensure it's an empty array
                    if (a24Core.isEmpty(arrData)) {
                        arrData = [];
                    }

                    for (var i = 0; i < arrData.length; i++) {
                        arrData[i].sDisplayName = arrData[i].sJobFamilyName + ": " + arrData[i].sTitleName;
                    }

                    // Call the callback with the formatted data
                    funcOnComplete(arrData);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    // Call the callback with empty
                    funcOnComplete([]);
                }));
            });
        },
        _observeAllValues: Ember.observer("objHeaderItem._objJobTitle", "objHeaderItem._objStatus", "objHeaderItem._objExperienceDuration", "objHeaderItem._iNumberOfMonths", function () {
            if (!a24Core.isEmpty(this.get("objHeaderItem._iNumberOfMonths"))) {
                if (this.get("objHeaderItem._iNumberOfMonths") < 1) {
                    this.set("objHeaderItem._iNumberOfMonths", 1);
                }
            }

            if (!a24Core.isEmpty(this.get("objChildren._objJobTitle")) && a24Core.isEmpty(this.get("objHeaderItem._objJobTitle"))) {
                this.get("objChildren._objJobTitle").clear();
            }
            if (!a24Core.isEmpty(this.get("objChildren._objStatus")) && a24Core.isEmpty(this.get("objHeaderItem._objStatus"))) {
                this.get("objChildren._objStatus").clear();
            }

            if (!a24Core.isEmpty(this.get("objChildren._objExperienceDuration")) && a24Core.isEmpty(this.get("objHeaderItem._objExperienceDuration"))) {
                this.get("objChildren._objExperienceDuration").clear();
            }

            Ember.run.once(this, "_setupFilterForEdit");
            Ember.run.once(this, "_setFlags");
        }).on("init"),

        _setupFilterForEdit: function _setupFilterForEdit() {
            var _this2 = this;

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    if (!a24Core.isEmpty(_this2.get("objHeaderItem._objJobTitle")) && a24Core.isEmpty(_this2.get("objChildren._objJobTitle.mValue"))) {
                        _this2.get("objChildren._objJobTitle").setValueFromOutside(_this2.get("objHeaderItem._objJobTitle"));
                    }
                });
            });
        },

        onInputValueChanged: function onInputValueChanged(arrPathToInputMixin) {
            var sLookup = arrPathToInputMixin.join(".");

            if (sLookup === "objChildren._objJobTitle") {
                var objJobTitle = this.get(sLookup + ".mValue");

                if (!a24Core.isEmpty(objJobTitle)) {
                    this.set("objHeaderItem._objJobTitle", objJobTitle);
                } else {
                    this.set("objHeaderItem._objJobTitle", null);
                    this.set("objHeaderItem._objStatus", null);
                    this.set("objHeaderItem._objExperienceDuration", null);
                    this.set("objHeaderItem._iNumberOfMonths", null);
                }
            } else if (sLookup === "objChildren._objExperienceDuration") {
                var objExperienceDuration = this.get(sLookup + ".mValue");
                if (!a24Core.isEmpty(objExperienceDuration)) {
                    this.set("objHeaderItem._objExperienceDuration", objExperienceDuration);
                } else {
                    this.set("objHeaderItem._objExperienceDuration", null);
                    this.set("objHeaderItem._iNumberOfMonths", null);
                }
            } else if (sLookup === "objChildren._objStatus") {
                var objStatus = this.get(sLookup + ".mValue");
                if (!a24Core.isEmpty(objStatus)) {
                    this.set("objHeaderItem._objStatus", objStatus);
                } else {
                    this.set("objHeaderItem._objStatus", null);
                }
            }
        },

        _setFlags: function _setFlags() {
            if (!a24Core.isEmpty(this.get("objHeaderItem._objJobTitle"))) {
                this.set("_bShowExperienceDurationField", true);

                if (!a24Core.isEmpty(this.get("objHeaderItem._objExperienceDuration"))) {

                    if (this.get("objHeaderItem._objExperienceDuration.sValue") === this.get("_objCandidateProfileEnumsService.experienceDuration.ANY_AMOUNT_OF.backend")) {
                        this.set("_bShowExperienceDurationFieldNumberInput", false);
                        this.customFilterDisplayAddButtonEvent(true);
                    } else {
                        this.set("_bShowExperienceDurationFieldNumberInput", true);
                        this.customFilterDisplayAddButtonEvent(!a24Core.isEmpty(this.get("objHeaderItem._iNumberOfMonths")));
                    }
                } else {
                    this.set("_bShowExperienceDurationFieldNumberInput", false);
                    this.customFilterDisplayAddButtonEvent(false);
                }
            } else {
                this.set("_bShowExperienceDurationField", false);
                this.set("_bShowExperienceDurationFieldNumberInput", false);
                this.customFilterDisplayAddButtonEvent(false);
            }
        }
    });
});
define("a24-ember-candidate-profile/components/page-tag-management", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/page-tag-management", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _pageTagManagement, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _pageTagManagement.default,
        objQueryParams: null,

        sUrlBase: null,
        objUsersService: null,
        objTagService: null,

        _bPageLoading: true,
        _arrSelectedItems: null,
        _objSelectedItem: null,

        _objUserSession: Ember.inject.service("user-session"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objFabButtonService: Ember.inject.service("fab-button"),

        _objSystemTagTypeService: Ember.inject.service("rest-system-tag-type"),
        _objSystemTagTypePromise: null,
        _objUsersPromise: null,
        _objTagPromise: null,

        _arrTagTypesDropdown: null,
        _objSelectedTagType: null,
        _objCreatedByFilter: null,

        _bPopupLoading: false,
        _objSpecialAddEditPopupAction: null,
        _objSpecialDeletePopupAction: null,
        _bShowAddEditPopup: false,
        _bShowUnsavedData: false,
        _bAddState: true,
        _bShowDeleteConfirm: false,
        _bPopupDeleteLoading: false,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objProfileStringsService").getString("tagManagement"));

            this.set("_objSpecialAddEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupAddEditCancel");
                }
            });
            this.set("_objSpecialDeletePopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onDeleteClose");
                }
            });
            var objValidationConfig = {};
            objValidationConfig["objChildren.name"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.tag_type_id"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.delete"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            this.set("objChildConfig", objValidationConfig);

            this.doServiceCalls();
        },
        doServiceCalls: function doServiceCalls() {
            this.set("_bPageLoading", true);

            var objTagTypes = this.get("_objSystemTagTypeService").getTagTypes(this, "_objSystemTagTypePromise", null, function () {
                // Handled by RSVP
            });

            var objPromiseList = {
                _objTagTypes: objTagTypes
            };

            var arrRequiredResponses = ["_objTagTypes"];

            var objThis = this;

            a24RSVP.configure(this, objPromiseList, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    var arrTagTypes = [];
                    if (!a24Core.isEmpty(objResponses._objTagTypes) && !a24Core.isEmpty(objResponses._objTagTypes.objResponse)) {
                        arrTagTypes = objResponses._objTagTypes.objResponse;
                    }

                    objThis._setupPageConfig(arrTagTypes);
                }
            }]);
        },

        _setupPageConfig: function _setupPageConfig(arrTagTypes) {
            var _this2 = this;

            var arrTagTypesDrop = [];
            for (var i = 0; i < arrTagTypes.length; i++) {
                arrTagTypesDrop.push({
                    sTitle: arrTagTypes[i].name,
                    sValue: arrTagTypes[i]._id
                });
            }
            this.set("_arrTagTypesDropdown", arrTagTypesDrop);

            var objCreatedByFilter = {
                iUniqueColNum: 4,
                sTitle: this.get("_objStringsService").getString("createdBy"),
                sProperty: "created_by_user_id",
                sFilterType: "suggest",
                bHideEmptyCheck: true,
                bSilent: true,
                bText: true,
                iWidth: 1,

                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "id",
                sFilterOptionsLabelKey: "sNameUserName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: false,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    _this2.get("objUsersService").suggestUsers(_this2, "_objUsersPromise", _this2.get("_objNavigation.sContextId"), sSuggestVal, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        } else {
                            for (var i = 0; i < arrData.length; i++) {
                                arrData[i].sNameUserName = arrData[i].sFirstName + " " + arrData[i].sLastName + ": " + arrData[i].sUsername;
                            }
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            };
            this.set("_objCreatedByFilter", objCreatedByFilter);
            if (a24Core.isEmpty(this.get("objQueryParams.created_by_user_id.sValue"))) {
                var sCreatedByFilter = this.get("_objUserSession.objAuthData.identity.id") + "||" + this.get("_objUserSession.objAuthData.identity.sFirstName") + " " + this.get("_objUserSession.objAuthData.identity.sLastName") + ": " + this.get("_objUserSession.objAuthData.identity.sUsername");

                //This stops the init load from cancelling the first attempt without the created_by_user_id which
                //gets checked in onWillDoServiceCall
                this.set("objQueryParams.created_by_user_id", {
                    sDefault: "",
                    sValue: sCreatedByFilter
                });
            }

            // This array contain the datagrid columns
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objProfileStringsService").getString("tag"),
                sProperty: "name",
                sFilterType: "text",
                bHideEmptyCheck: true,
                bText: true,
                bIsClickable: true,
                iWidth: 2
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objProfileStringsService").getString("tagType"),
                sProperty: "tag_type_id",
                sFilterType: "dropdown",
                bMapping: true,
                arrMappingData: arrTagTypesDrop,
                bHideEmptyCheck: true,
                iWidth: 2
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objStringsService").getString("dateCreated"),
                sProperty: "created_at",
                bHideEmptyCheck: true,
                sFilterType: "date",
                bDate: true,
                iWidth: 1
            }, objCreatedByFilter];

            var objThis = this;

            var arrSingleActions = [{
                sTitle: objThis.get("_objStringsService").getTokenString("editItem", objThis.get("_objProfileStringsService").getString("tag")),
                sIcon: "edit",
                executeCardAction: function executeCardAction() {
                    objThis._editTag(objThis.get("_objSelectedItem"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return arrSelectedItems[0].created_by_user_id === objThis.get("_objUserSession.objAuthData.identity.id");
                }
            }, {
                sTitle: objThis.get("_objStringsService").getTokenString("removeItem", objThis.get("_objProfileStringsService").getString("tag")),
                sIcon: "delete",
                executeCardAction: function executeCardAction() {
                    objThis.set("_bShowDeleteConfirm", true);
                    objThis.set("_bPopupDeleteLoading", false);
                    _this2.clearAllErrorMessages();
                    _this2.clearForm();
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return arrSelectedItems[0].created_by_user_id === objThis.get("_objUserSession.objAuthData.identity.id");
                }
            }];

            this.set("_objDatagridConfig", {
                sDatagridHeader: objThis.get("_objProfileStringsService").getString("tags"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                arrSingleItemSelectActions: arrSingleActions,
                arrMultiItemSelectActions: [],
                arrHeaderConfig: arrDataGridHeaderConfig,
                bEmitWillDoServiceCall: true
            });

            this.get("_objFabButtonService").showFabButton(function () {
                objThis._setAddEditPopup();
            });

            this.set("_bPageLoading", false);
        },
        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            } else {
                this.set("_objSelectedItem", null);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },
        _setAddEditPopup: function _setAddEditPopup(objTag) {
            var _this3 = this;

            this.clearAllErrorMessages();
            this.clearForm();

            this.set("_bShowAddEditPopup", true);
            this.set("_bPopupLoading", false);
            this.set("_bAddState", true);

            if (!a24Core.isEmpty(objTag)) {
                this.set("_bAddState", false);
                this.get("objChildren.name").setValueFromOutside(objTag.name);
            }

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this3.set("_objOriginalData", _this3.getPlainFormValueObject());
                });
            });
        },
        _setErrorResponse: function _setErrorResponse(sCode, objError, sSnackbarMessage) {
            if (sCode === "PATTERN") {
                this.setResponseError(objError.arrPath, "validationRestInvalidFormat");
            } else if (sCode === "INVALID_TAG_TYPE_ID") {
                this.setResponseError(objError.arrPath, "validationRestUnsupportedValue");
            } else if (sCode === "MIN_LENGTH") {
                this.setResponseError(objError.arrPath, "validationRestMin");
            } else if (sCode === "MAX_LENGTH") {
                this.setResponseError(objError.arrPath, "validationRestMax");
            } else if (sCode === "401") {
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objEmberLibString").getString("requiredPermission"));
            } else if (sCode === "404") {
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("updateFailedRefreshPage"));
            } else if (sCode === "409") {
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objProfileStringsService").getString("tagAlreadyExists"));
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }

            this.set("_bPopupLoading", false);
        },
        _setSuccessResponse: function _setSuccessResponse(sSnackbarMessage) {
            var _this4 = this;

            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bShowAddEditPopup", false);
            this.set("_bShowDeleteConfirm", false);

            this.set("_bPageLoading", true);
            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this4.set("_bPageLoading", false);
                });
            });
        },
        _editTag: function _editTag(objTag) {
            this._setAddEditPopup(objTag);
        },
        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell(sColumnName, objRowData) {
                if (sColumnName === "name") {
                    this._editTag(objRowData);
                }
            },
            onWillDoServiceCall: function onWillDoServiceCall() {
                var _this5 = this;

                if (a24Core.isEmpty(this.get("_objCreatedByFilter.sFilterValue"))) {
                    Ember.run.next(function () {
                        //Copy objQueryParams, this is how it is expected and done in the card datagrid container
                        var objQueryNew = Ember.copy(_this5.get("objQueryParams"), true);
                        objQueryNew.created_by_user_id.sValue = _this5.get("_objUserSession.objAuthData.identity.id") + "||" + _this5.get("_objUserSession.objAuthData.identity.sFirstName") + " " + _this5.get("_objUserSession.objAuthData.identity.sLastName") + ": " + _this5.get("_objUserSession.objAuthData.identity.sUsername");
                        _this5.set("objQueryParams", objQueryNew);
                    });

                    //This will stop api call without the created by user id
                    //Note it will only start working once https://github.com/A24Group/payroll-admin-ui/issues/24 is live
                    return true;
                }
            },
            onPopupAddEditConfirm: function onPopupAddEditConfirm() {
                var _this6 = this;

                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    var objPayload = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());
                    this.set("_bPopupLoading", true);

                    if (this.get("_bAddState")) {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objProfileStringsService").getString("tag")));

                        this.get("objTagService").createTag(this, "_objTagPromise", objPayload, function () {
                            _this6._setSuccessResponse(_this6.get("_objStringsService").getTokenString("successAddItem", _this6.get("_objProfileStringsService").getString("tag")));
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                            _this6._setErrorResponse(sCode, objError, _this6.get("_objStringsService").getTokenString("failAddItem", _this6.get("_objProfileStringsService").getString("tag")));
                        }));
                    } else {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objProfileStringsService").getString("tag")));

                        this.get("objTagService").updateTag(this, "_objTagPromise", this.get("_objSelectedItem._id"), objPayload, function () {
                            _this6._setSuccessResponse(_this6.get("_objStringsService").getTokenString("successUpdateItem", _this6.get("_objProfileStringsService").getString("tag")));
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                            _this6._setErrorResponse(sCode, objError, _this6.get("_objStringsService").getTokenString("failUpdateItem", _this6.get("_objProfileStringsService").getString("tag")));
                        }));
                    }
                }
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowAddEditPopup", false);
                this.set("_bShowUnsavedData", false);
            },
            onPopupAddEditCancel: function onPopupAddEditCancel() {
                if (this.get("_bPopupLoading")) {
                    return;
                }
                // Check if the form has any unsaved data
                var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

                if (bDataMatches) {
                    this.set("_bShowAddEditPopup", false);
                } else {
                    this.set("_bShowUnsavedData", true);
                }
            },
            onDeleteConfirm: function onDeleteConfirm() {
                var _this7 = this;

                this.clearAllErrorMessages();

                if (this.doFullValidation()) {
                    return;
                } else {
                    var objPayload = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());
                    if (objPayload.delete !== "DELETE") {
                        this.setResponseError(["delete"], this.get("_objProfileStringsService").getString("typeDeleteError"), true, true);
                        return;
                    }
                }

                this.set("_bPopupDeleteLoading", true);
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("removingItem", this.get("_objProfileStringsService").getString("tag")));
                this.get("objTagService").deleteTag(this, "_objTagPromise", this.get("_objSelectedItem._id"), function () {
                    _this7._setSuccessResponse(_this7.get("_objStringsService").getTokenString("successRemoveItem", _this7.get("_objProfileStringsService").getString("tag")));
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    if (sCode === "404") {
                        _this7.get("_objSnackbarService").showSnackbarMessage(_this7.get("_objStringsService").getString("removeFailedRefreshPage"));
                    } else {
                        _this7.get("_objSnackbarService").showSnackbarMessage(_this7.get("_objStringsService").getTokenString("failRemoveItem", _this7.get("_objProfileStringsService").getString("tag")));
                    }

                    _this7.set("_bPopupDeleteLoading", false);
                }));
            },
            onDeleteClose: function onDeleteClose() {
                if (this.get("_bPopupDeleteLoading")) {
                    return;
                }

                this.set("_bShowDeleteConfirm", false);
            }
        }
    });
});
define("a24-ember-candidate-profile/components/tab-agency-group-candidate-profile-notifications-preferences", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/tab-agency-group-candidate-profile-notifications-preferences"], function (exports, _didRenderChangesMixin, _tabAgencyGroupCandidateProfileNotificationsPreferences) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        _objDomService: Ember.inject.service("dom-event"),
        _objNavigationService: Ember.inject.service("navigation"),
        layout: _tabAgencyGroupCandidateProfileNotificationsPreferences.default,
        _bLoading: true,
        _sCandidateId: null,
        _sIFrameUrl: null,
        _objReactIframeData: null,

        init: function init() {
            this._super.apply(this, arguments);

            var objCandidateViewParams = this.get("_objNavigationService.objRouteParams")["agency-group.candidate-view"];

            var objCandidateSearchViewParams = this.get("_objNavigationService.objRouteParams")["agency-group.candidate-search-view"];

            if (!a24Core.isEmpty(objCandidateViewParams)) {
                this.set("_sCandidateId", objCandidateViewParams.candidate_id);
            }
            if (!a24Core.isEmpty(objCandidateSearchViewParams)) {
                this.set("_sCandidateId", objCandidateSearchViewParams.candidate_id);
            }

            var objEnv = Ember.getOwner(this).resolveRegistration("config:environment");

            if (a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_sIFrameUrl", objEnv.sNotificationsPreferencesUrl);
            }

            this.set("_objReactIframeData", {
                type: "candidate_info",
                data: {
                    candidateId: this.get("_sCandidateId")
                }
            });
        },

        actions: {
            onRecieveData: function onRecieveData(objData) {
                if (objData.type === "app_ready" || objData.type === "router_ready") {
                    this.set("_bLoading", false);
                }
            }
        }
    });
});
define("a24-ember-candidate-profile/components/candidate-documents", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element", "a24-ember-candidate-profile/templates/components/candidate-documents"], function (exports, _didRenderChangesMixin, _inputFormElement, _candidateDocuments) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateDocuments.default,
        sCandidateId: null,
        objDocumentsRestService: null,
        objDocumentTypesRestService: null,
        _bLoading: true,
        _bServerError: true,
        _arrReloadActions: null,
        _bAddPopupLoading: false,
        _bShowUnsavedData: false,
        _bAddPopupUploading: false,
        _objRestGetDocumentProperty: null,
        _objRestGetDocumentsProperty: null,
        _objRestCreateDocumentProperty: null,
        _objRestUploadDocumentProperty: null,
        _objRestGetDocumentTypesProperty: null,
        _objSpecialAddPopupAction: null,
        _arrDisplayDocuments: null,
        _arrDisplayDocumentTypes: null,
        _arrHeaderActions: null,
        _bShowAddPopup: false,
        _iUploadProgress: 0,
        _objOriginalData: null,
        // This is defined to allow mocking the file reader in test
        _objFileReader: null,
        _iResultCount: 0,
        _iPage: 1,
        _iPerPage: 10,
        _arrFilterConfig: null,
        _objQueryParams: null,
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUserSession: Ember.inject.service("user-session"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        _bIsCandidateContext: Ember.computed("objDocumentsRestService.sContext", function () {
            return this.get("objDocumentsRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE");
        }),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            if (a24Core.isEmpty(this.get("_objFileReader"))) {
                this.set("_objFileReader", new FileReader());
            }

            if (this.get("_bIsCandidateContext")) {
                this.set("_arrHeaderActions", [{
                    sTitle: this.get("_objStringsService").getString("add"),
                    sIcon: "add",
                    executeCardAction: function executeCardAction() {
                        _this._setupAddPopup();
                    }
                }]);
            }

            this.set("_objSpecialAddPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupAddCancel");
                }
            });

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this._getDocuments();
                }
            }]);

            this.set("_arrFilterConfig", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("document"),
                sProperty: "name",
                sFilterType: "text",
                bHideEmptyCheck: true,
                bText: true
            }]);

            this._setupValidations();
            this._getDocuments();
        },

        _setupValidations: function _setupValidations() {
            this.set("objChildConfig", {
                "objChildren.type": function objChildrenType(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMaxLength(objMixin, 250);
                }
            });
        },

        _setupFileValidation: function _setupFileValidation() {
            this.get("objChildren.file").get("arrValidation").clear();
            this.get("objChildren.file").set("funcValidationRequired", null);

            a24Validation.addRequired(this.get("objChildren.file"), true);
            a24Validation.addMinFileSize(this.get("objChildren.file"), 0.0009765625); //1KB
            if (!a24Core.isEmpty(this.get("objChildren.type.mValue"))) {
                a24Validation.addMaxFileSize(this.get("objChildren.file"), this.get("objChildren.type.mValue.max_size_bytes") / 1024 / 1024 //to get to MB
                );

                a24Validation.addAllowedMimeTypes(this.get("objChildren.file"), this.get("objChildren.type.mValue.allowed_mime_types"), this.get("objChildren.type.mValue.allowed_extensions"));
            }
        },

        _setupAddPopup: function _setupAddPopup() {
            var _this2 = this;

            this.clearAllErrorMessages();
            this.clearForm();

            this.set("_bShowAddPopup", true);
            if (!a24Core.isEmpty(this.get("setBreadCrumbAction"))) {
                this.get("setBreadCrumbAction")();
            }

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this2.set("_objOriginalData", _this2.getPlainFormValueObject());

                    if (a24Core.isEmpty(_this2.get("_arrDisplayDocumentTypes"))) {
                        _this2._getDocumentTypes();
                    }
                });
            });
        },

        _getDocumentTypes: function _getDocumentTypes() {
            var _this3 = this;

            // Put the card in loading
            this.set("_bAddPopupLoading", true);

            // Get documents
            this.get("objDocumentTypesRestService").getDocumentTypes(this, "_objRestGetDocumentTypesProperty", this.get("sCandidateId"), function (arrData) {
                _this3.set("_arrDisplayDocumentTypes", arrData);
                // Put the card out of loading
                _this3.set("_bAddPopupLoading", false);
            });
        },

        _getDocuments: function _getDocuments(objQueryParams) {
            var _this4 = this;

            // Put the card in loading
            this.set("_bLoading", true);
            this.set("_bServerError", false);

            if (a24Core.isEmpty(objQueryParams)) {
                objQueryParams = {
                    page: { mValue: this.get("_iPage") },
                    items_per_page: { mValue: this.get("_iPerPage") }
                };
            }

            if (a24Core.isEmpty(objQueryParams.created_at)) {
                objQueryParams.created_at = {
                    mValue: "desc",
                    bSortBy: true
                };
            }
            // Save the current query params for later use.
            this.set("_objQueryParams", Ember.copy(objQueryParams, true));

            // Get documents
            this.get("objDocumentsRestService").getDocuments(this, "_objRestGetDocumentsProperty", this.get("sCandidateId"), function (arrData, sStatus, objXHR) {
                if (!a24Core.isEmpty(arrData)) {
                    _this4.set("_arrDisplayDocuments", _this4._prepareForDisplay(arrData));
                } else {
                    _this4.set("_arrDisplayDocuments", []);
                }
                _this4.set("_iResultCount", a24RestCallHelper.getXResultCount(objXHR));

                // Put the card out of loading
                _this4.set("_bLoading", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this4.set("_bServerError", true);
                    return;
                }
                // Clear data
                _this4.set("_arrDisplayDocuments", []);
                // Put the card out of loading
                _this4.set("_bLoading", false);
            }), objQueryParams);
        },

        _prepareForDisplay: function _prepareForDisplay(arrDocuments) {
            var _this5 = this;

            return arrDocuments.map(function (objDocument) {
                var objDate = a24DateManager.createDate(_this5.get("_objUserSession").get("objContextDetails.sTimezone"));

                objDate.setDateFromRest(objDocument.created_at);

                var sStatus = _this5.get("_objCandidateProfileEnumsService").getFrontendValue("uploadedDocumentStatuses", objDocument.status);

                var arrItems = [{
                    sLabel: _this5.get("_objCandidateProfileStringsService").getString("document"),
                    sValue: objDocument.name,
                    bText: true
                }, {
                    sLabel: _this5.get("_objCandidateProfileStringsService").getString("uploaded"),
                    sValue: objDate.getDateTimeFormat(),
                    bText: true
                }];

                if (!_this5.get("_bIsCandidateContext")) {
                    arrItems.pushObject({
                        sLabel: _this5.get("_objStringsService").getString("uploadedBy"),
                        sValue: objDocument.uploaded_by.name,
                        bText: true
                    });
                }

                arrItems.pushObject({
                    sLabel: _this5.get("_objStringsService").getString("status"),
                    sValue: sStatus,
                    bText: true
                });

                if (!a24Core.isEmpty(objDocument.rejected_by)) {
                    if (!_this5.get("_bIsCandidateContext")) {
                        arrItems.pushObject({
                            sLabel: _this5.get("_objCandidateProfileStringsService").getString("rejectedBy"),
                            sValue: objDocument.rejected_by.name,
                            bText: true
                        });
                    }

                    arrItems.pushObject({
                        sLabel: _this5.get("_objCandidateProfileStringsService").getString("reason"),
                        sValue: objDocument.rejected_by.comment,
                        bText: true
                    });
                }

                if (!a24Core.isEmpty(objDocument.verified_by) && !_this5.get("_bIsCandidateContext")) {
                    arrItems.pushObject({
                        sLabel: _this5.get("_objCandidateProfileStringsService").getString("verifiedBy"),
                        sValue: objDocument.verified_by.name,
                        bText: true
                    });

                    if (!a24Core.isEmpty(objDocument.verified_by.comment)) {
                        arrItems.pushObject({
                            sLabel: _this5.get("_objStringsService").getString("comment"),
                            sValue: objDocument.verified_by.comment,
                            bText: true
                        });
                    }
                }

                var arrActions = [];

                arrActions.pushObject({
                    sTitle: _this5.get("_objStringsService").getString("fileDownload"),
                    sIcon: "file_download",
                    executeCardAction: function executeCardAction() {
                        _this5._getDocument(objDocument);
                    }
                });

                return {
                    sIcon: "insert_drive_file",
                    sLabel: _this5.get("_objStringsService").getHTMLTokenString("a24-string-html-dot-value", objDocument.name, sStatus),
                    sExtra: _this5.get("_objStringsService").getHTMLTokenString("a24-string-html-dot-value", _this5.get("_objCandidateProfileStringsService").getString("uploaded"), momentHelper.getTimeFromNow(objDocument.created_at, a24Constants.DATE_FORMAT_REST_FORMAT)),
                    arrActions: arrActions,
                    arrItems: [{
                        arrItems: arrItems
                    }]
                };
            });
        },

        _getDocument: function _getDocument(objDocument) {
            var _this6 = this;

            var objSnackbarService = this.get("_objSnackbarService");
            objSnackbarService.showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("startingDownload"));

            // Get document
            this.get("objDocumentsRestService").getDocument(this, "_objRestGetDocumentProperty", this.get("sCandidateId"), objDocument._id, function (objResponse) {
                objSnackbarService.showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("downloadingDocument"));
                _this6._openInNewTab(objResponse.signed_download_url);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /* sCode, sStatus, objErrorThrown, objError */{
                objSnackbarService.showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("downloadDocumentFailed"));
            }));
        },

        _openInNewTab: function _openInNewTab(sUrl) {
            window.open(sUrl);
        },

        _hexToBase64: function _hexToBase64(sString) {
            var sFormattedString = sString.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ");

            return btoa(String.fromCharCode.apply(null, sFormattedString));
        },

        _getPayload: function _getPayload(funcSuccess) {
            var _this7 = this;

            var objReader = this.get("_objFileReader");
            var objFormData = this.getPlainFormValueObject();
            var objFile = objFormData.file;
            var arrBlob = objFile.slice(0, objFile.size);

            objReader.onloadend = function (objEvent) {
                if (objEvent.target.readyState === FileReader.DONE) {
                    var objSpark = new SparkMD5.ArrayBuffer(arrBlob);
                    objSpark.append(objEvent.target.result);
                    var sEtag = objSpark.end();
                    var sMd5Digest = _this7._hexToBase64(sEtag);

                    var objPayload = {
                        type_id: objFormData.type,
                        metadata: {
                            file_size: objFile.size,
                            file_type: objFile.type,
                            etag: sEtag,
                            md5_digest: sMd5Digest,
                            original_file_name: objFile.name
                        }
                    };

                    funcSuccess(objPayload);
                }
            };

            objReader.readAsArrayBuffer(arrBlob);
        },

        _createDocument: function _createDocument(funcSuccess) {
            var _this8 = this;

            this.set("_bAddPopupLoading", true);

            this._getPayload(function (objPayload) {
                _this8.get("_objSnackbarService").showSnackbarMessage(_this8.get("_objStringsService").getTokenString("addingItem", _this8.get("_objCandidateProfileStringsService").getString("document")));

                // Create document
                _this8.get("objDocumentsRestService").createDocument(_this8, "_objRestCreateDocumentProperty", _this8.get("sCandidateId"), objPayload, function (objResponse) {
                    _this8.set("_bAddPopupLoading", false);

                    funcSuccess(objResponse);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                    if (sCode === "MIN_LENGTH") {
                        if (objError.arrPath.join(".") === "metadata.file_type") {
                            _this8.setResponseError(["file"], "validationFileAllowedAllMimeTypes");
                        } else if (objError.arrPath.join(".") === "type_id") {
                            _this8.setResponseError(["type"], "validationRestMinLength");
                        }
                    } else if (sCode === "MINIMUM" && objError.arrPath.join(".") === "metadata.file_size") {
                        _this8.setResponseError(["file"], "validationRestMin");
                    } else if (sCode === "MAXIMUM" && objError.arrPath.join(".") === "metadata.file_size") {
                        _this8.setResponseError(["file"], "validationRestMax");
                    } else if (sCode === "FILE_TYPE_DOES_NOT_SUPPORT" && objError.arrPath.join(".") === "metadata.file_type") {
                        var sErrorMessage = _this8.get("_objStringsService").getTokenString("validationFileAllowedMimeTypes", _this8.get("_objStringsService").getString("file"), _this8.get("objChildren.type.mValue.allowed_extensions").join(", "));
                        _this8.setResponseError(["file"], sErrorMessage, true, true);
                    } else if (sCode === "NO_TYPE_FOUND") {
                        if (objError.arrPath.join(".") === "metadata.file_type") {
                            _this8.setResponseError(["file"], "validationRestUnsupportedValue");
                        } else if (objError.arrPath.join(".") === "type_id") {
                            _this8.setResponseError(["type"], "validationRestUnsupportedValue");
                        }
                    } else {
                        _this8.get("_objSnackbarService").showSnackbarMessage(_this8.get("_objStringsService").getTokenString("failAddItem", _this8.get("_objCandidateProfileStringsService").getString("document")));
                    }

                    _this8.set("_bAddPopupLoading", false);
                }));
            });
        },

        _uploadDocument: function _uploadDocument(sSignedUploadUrl, objHeaders) {
            var _this9 = this;

            this.set("_bAddPopupUploading", true);

            var objFormData = this.getPlainFormValueObject();
            var arrBlob = objFormData.file.slice(0, objFormData.file.size);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("uploadingDocument"));

            var funcXhr = function funcXhr() {
                // get the native XmlHttpRequest object
                var objXhr = _this9._getXhrObject();
                // set the onprogress event handler
                objXhr.upload.onprogress = function (objEvent) {
                    var iProgress = parseInt(objEvent.loaded / objEvent.total * 100);
                    _this9.set("_iUploadProgress", iProgress);
                };
                // return the customized object
                return objXhr;
            };

            this._performUpload(sSignedUploadUrl, arrBlob, objFormData.file.type, objHeaders, funcXhr, Ember.run.bind(this, this._uploadDocumentSuccess), Ember.run.bind(this, this._uploadDocumentFailure));
        },

        _performUpload: function _performUpload(sUrl, arrBlob, sContentType, objHeaders, funcXhr, funcSuccess, funcFailure) {
            /**
             * This is done manually because of the precise nature of this call and also
             * that because of how the upload is done, it is safe since the first get call
             * will handle the spamming scenario, since if user spams service call it
             * will stop the success from running thus stopping the upload calls aswell
             */
            return $.ajax({
                url: sUrl,
                type: "PUT",
                data: arrBlob,
                contentType: sContentType,
                processData: false,
                headers: objHeaders,
                xhr: funcXhr,
                success: funcSuccess,
                error: funcFailure
            });
        },

        _getXhrObject: function _getXhrObject() {
            return $.ajaxSettings.xhr();
        },

        _uploadDocumentSuccess: function _uploadDocumentSuccess(objErrorData, sStatus /* , objErrorThrown */) {
            // We do not want to deal with an abort service call
            if (sStatus === "abort" || a24Core.isEmpty(this)) {
                return;
            }

            this.set("_bAddPopupUploading", false);
            this.set("_iUploadProgress", 0);
            this.set("_bShowAddPopup", false);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("uploadDocumentSuccess"));

            this._getDocuments();
        },

        _uploadDocumentFailure: function _uploadDocumentFailure(objErrorData, sStatus /* , objErrorThrown */) {
            // We do not want to deal with an abort service call
            if (sStatus === "abort" || a24Core.isEmpty(this)) {
                return;
            }

            this.set("_bAddPopupUploading", false);
            this.set("_iUploadProgress", 0);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("uploadDocumentFailed"));
        },

        actions: {
            // If the custom action is clicked
            customActionClicked: function customActionClicked(objItem) {
                objItem.executeCardAction();
            },

            // If the filter changes, we wish to reload the card
            cardDatagridFilterChangeEvent: function cardDatagridFilterChangeEvent(sProperty, objValue) {
                objValue = Ember.copy(objValue, true);
                var objParams = objValue;
                this.set("_iPage", 1);
                objParams.page = { mValue: 1 };
                objParams.items_per_page = { mValue: this.get("_iPerPage") };

                this._getDocuments(objParams);
            },

            cardDatagridPagerEvent: function cardDatagridPagerEvent(objPageQuery) {
                objPageQuery = Ember.copy(objPageQuery, true);

                var objQueryParams = this.get("_objQueryParams");
                objQueryParams.page = objPageQuery.page;
                objQueryParams.items_per_page = objPageQuery.per_page;

                this._getDocuments(objQueryParams);
            },

            onPopupAddConfirm: function onPopupAddConfirm() {
                var _this10 = this;

                this._setupFileValidation();

                if (!this.doFullValidation()) {
                    this._createDocument(function (objResponse) {
                        var objHeaders = objResponse.headers.reduce(function (objHeaders, objHeader) {
                            objHeaders[objHeader.name] = objHeader.value;
                            return objHeaders;
                        }, {});

                        _this10._uploadDocument(objResponse.signed_upload_url, objHeaders);
                    });
                }
            },

            onPopupAddCancel: function onPopupAddCancel() {
                if (!this.get("_bAddPopupLoading") && !this.get("_bAddPopupUploading")) {
                    // Check if the form has any unsaved data
                    var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

                    if (bDataMatches) {
                        this.set("_bShowAddPopup", false);
                    } else {
                        this.set("_bShowUnsavedData", true);
                    }
                }
            },

            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowUnsavedData", false);
                this.set("_bShowAddPopup", false);
            }
        }
    });
});
define("a24-ember-lib/components/card-actions-component", ["exports", "a24-ember-lib/templates/components/card-actions-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _cardActionsComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _cardActionsComponent.default,
        classNames: ["card-actions-component"],
        classNameBindings: ["bMobileView:card-actions-component-mobile"],
        bMobileView: false,
        objSpecialAction: null,
        arrActionItems: null,
        arrVisibleItems: null,
        arrMoreItems: null,
        bShowDropdown: false,
        iVisibleActions: 4,
        _sItemId: null,
        _sCardActionDropdownContentId: null,
        // the tooltip position for the drop down menu
        _sDropDownToolTipPosition: null,
        sIconClass: "a24-grey-text-darken-1",
        init: function init() {
            this._super.apply(this, arguments);

            this.set("_sCardActionDropdownContentId", Ember.guidFor({}));

            if (this.get("arrActionItems") === null) {
                this.set("arrActionItems", []);
                if (!a24Core.isEmpty(this.get("objData"))) {
                    this.set("arrActionItems", this.get("objData.arrActionItems"));
                }
            }

            this.setItems();
        },
        setItems: Ember.observer("arrActionItems", function () {
            var _this = this;

            var iVisibleActions = this.get("iVisibleActions");
            var iCount = 0;
            var arrVisibleItems = [];
            var arrMoreItems = [];
            if (!a24Core.isEmpty(this.get("objSpecialAction"))) {
                iVisibleActions = iVisibleActions - 1;
                // this will be the last item, so set the tooltip position to left
                this.set("objSpecialAction.sToolTipPosition", "left");
            }

            if (!a24Core.isEmpty(this.get("arrActionItems"))) {
                this.get("arrActionItems").forEach(function (objDataItem) {
                    if (a24Core.isEmpty(objDataItem.sIconClass)) {
                        Ember.set(objDataItem, "sIconClass", _this.get("sIconClass"));
                    }
                    if (!a24Core.isEmpty(objDataItem.sTitle)) {
                        Ember.set(objDataItem, "sToolTipPosition", "bottom");
                    }
                    if (a24Core.isEmpty(objDataItem.bLightWaves)) {
                        Ember.set(objDataItem, "bLightWaves", false);
                    }

                    if (iCount < iVisibleActions) {
                        arrVisibleItems.push(objDataItem);
                    } else {
                        //If we have reached the max visible items we need to remove the last
                        //item to make space for the 3 dots overflow item
                        if (iCount === iVisibleActions) {
                            var objLast = arrVisibleItems[arrVisibleItems.length - 1];
                            arrVisibleItems.removeObject(objLast);
                            arrMoreItems.push(objLast);
                        }

                        arrMoreItems.push(objDataItem);
                    }
                    iCount++;
                });
                // set the tooltip position for the drop down
                if (arrMoreItems.length > 0) {
                    if (a24Core.isEmpty(this.get("objSpecialAction"))) {
                        // the drop down is the last item
                        this.set("_sDropDownToolTipPosition", "left");
                    } else {
                        // objSpecialAction is the last item
                        this.set("_sDropDownToolTipPosition", "bottom");
                    }
                }

                // set the tooltip position of the last item
                if (a24Core.isEmpty(this.get("objSpecialAction")) && arrVisibleItems.length > 0) {
                    var iLastIndex = arrVisibleItems.length - 1;
                    var objItem = arrVisibleItems[iLastIndex];
                    if (a24Core.isEmpty(Ember.get(arrVisibleItems[iLastIndex], "sToolTipPosition")) && !a24Core.isEmpty(objItem.sTitle)) {
                        if (arrMoreItems.length > 0) {
                            // will have menu so not the last item
                            Ember.set(arrVisibleItems[iLastIndex], "sToolTipPosition", "bottom");
                        } else {
                            // this is the last item
                            Ember.set(arrVisibleItems[iLastIndex], "sToolTipPosition", "left");
                        }
                    }
                }
            }

            this.set("arrVisibleItems", arrVisibleItems);
            this.set("arrMoreItems", arrMoreItems);
        }),
        actions: {
            executeCardAction: function executeCardAction(objSelect, objEvent) {
                objSelect.executeCardAction();
                // Prevent action from being trigger on other elements
                objEvent.preventDefault();
                objEvent.stopPropagation();
            },
            executeCardDropdownAction: function executeCardDropdownAction(objSelect, objEvent) {

                // Prevent action from being trigger on other elements
                objEvent.preventDefault();
                objEvent.stopPropagation();

                objSelect.executeCardAction();
                this.set("bShowDropdown", false);
            }
        }
    });
});
define("a24-ember-candidate-profile/components/card-datagrid-custom-filter-candidate-distance", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/card-datagrid-custom-filter-candidate-distance", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _cardDatagridCustomFilterCandidateDistance, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _cardDatagridCustomFilterCandidateDistance.default,

        objHeaderItem: null,

        _bShowDistanceUnitField: false,
        _bShowLinkedClientField: false,
        _bShowCustomLocationField: false,
        _bLoading: true,
        _bInputLoaded: false,
        _bInputError: false,
        _arrDistanceUnitOptions: null,
        _arrLocationsOptions: null,
        _sLocationPrompt: null,
        _sUnitPrompt: null,
        _sLatitude: null,
        _sLongitude: null,
        _objUserSession: Ember.inject.service("user-session"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objEnumsService: Ember.inject.service("a24-enums"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objFormInputsStringsService: Ember.inject.service("a24-strings-form-inputs"),
        _objFilterCustomCandidateDistanceService: Ember.inject.service("card-datagrid-custom-filter-candidate-distance"),
        _arrLimitByCountryCodes: null,
        _bClientSearch: false,
        _bHasLocationDropDown: Ember.computed("_bClientSearch", "_bAgencyContext", function () {
            if (this.get("_bAgencyContext") && !this.get("_bClientSearch")) {
                return true;
            }
            return false;
        }),
        init: function init() {
            this._super.apply(this, arguments);

            var arrDistanceUnitOptions = this.get("_objCandidateProfileEnumsService").getList("distanceUnitOptions");
            // Strip the kilometers from the enum since the option of the dropdown is kilometers
            arrDistanceUnitOptions.shift();

            this.set("_arrDistanceUnitOptions", arrDistanceUnitOptions);
            this.set("_sLocationPrompt", this.get("_objCandidateProfileStringsService").getString("client"));
            this.set("_sUnitPrompt", this.get("_objCandidateProfileStringsService").getString("kilometers"));

            var arrLocationOptions = [{
                sLabel: this.get("_objCandidateProfileStringsService").getString("customLocation"),
                sValue: "custom_location"
            }];

            this.set("_arrLocationsOptions", arrLocationOptions);

            // Get the country based on context sCountryId
            var objContextCountry = this.get("_objEnumsService").getCountryObjectByCode(this.get("_objUserSession.objContextDetails.sCountryId"));
            if (!a24Core.isEmpty(objContextCountry)) {
                // Limit address if country is set
                this.set("_arrLimitByCountryCodes", [objContextCountry.backend]);
            }

            this.setDefaultDistanceUnit();

            if (!a24Core.isEmpty(this.get("objHeaderItem.bClientSearch")) && this.get("objHeaderItem.bClientSearch")) {
                this.set("_bClientSearch", true);
            }

            if (this.get("_bHasLocationDropDown")) {
                this.setGetSuggestionFunction();
            } else {
                this.set("objHeaderItem._objLocationOption", {
                    sValue: "custom_location"
                });
            }

            this._observeAllValues();
        },
        _bAgencyContext: Ember.computed("objHeaderItem.sContext", function () {
            if (this.get("objHeaderItem.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY")) {
                return true;
            }
            return false;
        }),

        _observeAllValues: Ember.observer("objHeaderItem._iWithinDistance", "objHeaderItem._objDistanceUnit", "objHeaderItem._objLinkedClient", "objHeaderItem._objAddressSuggest", "objHeaderItem._objLocationOption", "objHeaderItem._sLatitude", "objHeaderItem._sLongitude", "objHeaderItem._bEmptyEntriesOnly", function () {
            if (!a24Core.isEmpty(this.get("objHeaderItem._iWithinDistance"))) {
                if (this.get("objHeaderItem._iWithinDistance") < 1) {
                    this.set("objHeaderItem._iWithinDistance", 1);
                }
            }

            Ember.run.once(this, "_setupFilterForEdit");
            Ember.run.once(this, "_onInputChanges");
        }),

        _setupFilterForEdit: function _setupFilterForEdit() {
            var _this = this;

            //NB only input-form-??? fields that work the corrent way with setValueFromOutside should be in a next
            //all other inputs will bind correctly on their inits
            if (!this.get("objHeaderItem._bEmptyEntriesOnly")) {
                Ember.run.next(function () {
                    Ember.run.schedule("afterRender", function () {
                        if (!a24Core.isEmpty(_this.get("objHeaderItem._objLinkedClient")) && a24Core.isEmpty(_this.get("objHeaderItem._objLocationOption"))) {
                            _this.get("objChildren.objLinkedClient").setValueFromOutside(_this.get("objHeaderItem._objLinkedClient"));
                        }

                        if (!a24Core.isEmpty(_this.get("objHeaderItem._objAddressSuggest")) && !a24Core.isEmpty(_this.get("objHeaderItem._objLocationOption")) && _this.get("objHeaderItem._objLocationOption.sValue") === "custom_location") {
                            if (_this.get("_bInputLoaded")) {
                                _this.get("objChildren.objAddressSuggest").setValueFromOutside(_this.get("objHeaderItem._objAddressSuggest"));
                            }
                        }
                        if (!a24Core.isEmpty(_this.get("objHeaderItem._sLatitude"))) {
                            _this.get("objChildren.sLatitude").setValueFromOutside(_this.get("objHeaderItem._sLatitude"));
                        }
                        if (!a24Core.isEmpty(_this.get("objHeaderItem._sLongitude"))) {
                            _this.get("objChildren.sLongitude").setValueFromOutside(_this.get("objHeaderItem._sLongitude"));
                        }
                    });
                });
            }
        },

        _onInputChanges: function _onInputChanges() {
            var _this2 = this;

            if (this.get("objHeaderItem._bEmptyEntriesOnly")) {
                this.customFilterDisplayAddButtonEvent(this.get("objHeaderItem._bEmptyEntriesOnly"));
            } else if (!a24Core.isEmpty(this.get("objHeaderItem._iWithinDistance"))) {
                this.set("_bShowDistanceUnitField", true);

                if (a24Core.isEmpty(this.get("objHeaderItem._objDistanceUnit"))) {
                    var objDistanceUnit = this.get("_objCandidateProfileEnumsService").getList("distanceUnitOptions").findBy("sValue", this.get("_objCandidateProfileEnumsService.distanceUnitOptions.KM.backend"));
                    this.set("objHeaderItem._objDistanceUnit", objDistanceUnit);
                }
                this.set("_bShowGeoInputs", true);

                if (a24Core.isEmpty(this.get("objHeaderItem._objLocationOption.sValue"))) {
                    this.set("_bShowLinkedClientField", true);
                    this.set("_bShowCustomLocationField", false);

                    if (a24Core.isEmpty(this.get("objHeaderItem._objLinkedClient"))) {
                        //This is just to clear text enterend before
                        if (!a24Core.isEmpty(this.get("objChildren.objLinkedClient"))) {
                            this.get("objChildren.objLinkedClient").clear();
                        }
                    } else {
                        Ember.run.schedule("afterRender", function () {
                            _this2._setCoordsFromClient(_this2.get("objHeaderItem._objLinkedClient"));
                        });
                    }
                } else if (this.get("objHeaderItem._objLocationOption.sValue") === "custom_location") {
                    this.set("_bShowLinkedClientField", false);
                    this.set("_bShowCustomLocationField", true);

                    if (a24Core.isEmpty(this.get("objHeaderItem._objAddressSuggest"))) {
                        if (this.get("_bInputLoaded") && !a24Core.isEmpty(this.get("objChildren.objAddressSuggest"))) {
                            //This is just to clear text enterend before
                            this.get("objChildren.objAddressSuggest").clear();
                        }
                    } else {
                        Ember.run.schedule("afterRender", function () {
                            _this2._setCoordsFromGoogle(_this2.get("objHeaderItem._objAddressSuggest"));
                        });
                    }
                }

                if (!a24Core.isEmpty(this.get("objHeaderItem._sLatitude")) && !a24Core.isEmpty(this.get("objHeaderItem._sLongitude"))) {
                    this.customFilterDisplayAddButtonEvent(true);
                } else {
                    this.customFilterDisplayAddButtonEvent(false);
                }
            } else {
                this.set("_bShowDistanceUnitField", false);
                this.set("_bShowLinkedClientField", false);
                this.set("_bShowCustomLocationField", false);
                this.set("_bShowGeoInputs", false);
                this.customFilterDisplayAddButtonEvent(false);

                if (a24Core.isEmpty(this.get("objHeaderItem._objLocationOption"))) {
                    this.set("_objLocationOption", null);
                }

                if (a24Core.isEmpty(this.get("objHeaderItem._objLinkedClient")) && !a24Core.isEmpty(this.get("objChildren.objLinkedClient"))) {
                    this.get("objChildren.objLinkedClient").clear();
                }
                if (a24Core.isEmpty(this.get("objHeaderItem._objAddressSuggest")) && this.get("_bInputLoaded") && !a24Core.isEmpty(this.get("objChildren.objAddressSuggest"))) {
                    this.get("objChildren.objAddressSuggest").clear();
                }
                if (a24Core.isEmpty(this.get("objHeaderItem._sLatitude")) && !a24Core.isEmpty(this.get("objChildren.sLatitude"))) {
                    this.get("objChildren.sLatitude").clear();
                }
                if (a24Core.isEmpty(this.get("objHeaderItem._sLongitude")) && !a24Core.isEmpty(this.get("objChildren.sLongitude"))) {
                    this.get("objChildren.sLongitude").clear();
                }
            }
        },

        _observerForErrorMessage: Ember.observer("_bShowCustomLocationField", function () {
            var _this3 = this;

            if (this.get("_bInputError") && this.get("_bShowCustomLocationField")) {
                Ember.run.next(function () {
                    _this3.get("objChildren.objAddressSuggest").clearAllErrorMessages();
                    _this3.setResponseError(["objAddressSuggest"], "addressSuggestInputLoadError", false, false, _this3.get("_objCandidateProfileStringsService"));
                });
            }
        }),

        _setCoordsFromClient: function _setCoordsFromClient(objClient) {
            if (!a24Core.isEmpty(objClient.objSiteGeoLocation)) {
                this.get("objChildren.sLatitude").setValueFromOutside(objClient.objSiteGeoLocation.fLatitude);
                this.get("objChildren.sLongitude").setValueFromOutside(objClient.objSiteGeoLocation.fLongitude);
                this.get("objChildren.objLinkedClient").clearAllErrorMessages();
            } else {
                this.get("objChildren.objLinkedClient").clearAllErrorMessages();
                this.setResponseError(["objLinkedClient"], "cannotDoQueryForClient", false, false, this.get("_objCandidateProfileStringsService"));
            }
        },


        _setCoordsFromGoogle: function _setCoordsFromGoogle(objAddressSuggest) {
            if (!a24Core.isEmpty(objAddressSuggest.sLatitude) && !a24Core.isEmpty(objAddressSuggest.sLongitude)) {
                this.get("objChildren.sLatitude").setValueFromOutside(objAddressSuggest.sLatitude);
                this.get("objChildren.sLongitude").setValueFromOutside(objAddressSuggest.sLongitude);
                this.get("objChildren.objAddressSuggest").clearAllErrorMessages();
            } else {
                this.get("objChildren.objAddressSuggest").clearAllErrorMessages();
                this.setResponseError(["objAddressSuggest"], "cannotDoQueryForAddress", false, false, this.get("_objCandidateProfileStringsService"));
            }
        },

        onInputValueChanged: function onInputValueChanged(arrPathToInputMixin) {
            var sLookup = arrPathToInputMixin.join(".");

            if (sLookup === "objChildren.objLinkedClient") {
                var objClient = this.get(sLookup + ".mValue");
                if (!a24Core.isEmpty(objClient)) {
                    this.set("objHeaderItem._objLinkedClient", objClient);
                    this._setCoordsFromClient(objClient);
                } else {
                    this.get(sLookup).clearAllErrorMessages();
                    this.set("objHeaderItem._objLinkedClient", null);
                }
            } else if (sLookup === "objChildren.objAddressSuggest") {
                var objAddressSuggest = this.get(sLookup + ".mValue");
                if (!a24Core.isEmpty(objAddressSuggest)) {
                    this.set("objHeaderItem._objAddressSuggest", objAddressSuggest);
                    this._setCoordsFromGoogle(objAddressSuggest);
                } else {
                    this.get(sLookup).clearAllErrorMessages();
                    this.set("objHeaderItem._objAddressSuggest", null);
                }
            } else if (sLookup === "objChildren.sLatitude") {
                var sLatitude = this.get(sLookup + ".mValue");
                this.get("objChildren.sLatitude").clearAllErrorMessages();
                if (!a24Core.isEmpty(sLatitude)) {
                    var isFloat = this._isFloat(sLatitude);
                    var isValid = false;
                    var fLatitude = null;
                    if (isFloat) {
                        fLatitude = parseFloat(sLatitude);
                        isValid = fLatitude >= -90 && fLatitude <= 90;
                    }
                    if (isFloat && isValid) {
                        this.set("objHeaderItem._sLatitude", fLatitude);
                    } else {
                        this.set("objHeaderItem._sLatitude", null);
                        if (sLatitude.trim() !== "-") {
                            this.get("objChildren.sLatitude").clearAllErrorMessages();
                            this.setResponseError(["sLatitude"], this.get("_objCandidateProfileStringsService").getString("latitudeInputError"), true, true);
                        }
                    }
                } else {
                    this.set("objHeaderItem._sLatitude", null);
                }
            } else if (sLookup === "objChildren.sLongitude") {
                var sLongitude = this.get(sLookup + ".mValue");
                this.get("objChildren.sLongitude").clearAllErrorMessages();
                if (!a24Core.isEmpty(sLongitude)) {
                    var _isFloat = this._isFloat(sLongitude);
                    var _isValid = false;
                    var fLongitude = null;
                    if (_isFloat) {
                        fLongitude = parseFloat(sLongitude);
                        _isValid = fLongitude >= -180 && fLongitude <= 180;
                    }
                    if (_isFloat && _isValid) {
                        this.set("objHeaderItem._sLongitude", fLongitude);
                    } else {
                        this.set("objHeaderItem._sLongitude", null);
                        if (sLongitude.trim() !== "-") {
                            this.get("objChildren.sLongitude").clearAllErrorMessages();
                            this.setResponseError(["sLongitude"], this.get("_objCandidateProfileStringsService").getString("longitudeInputError"), true, true);
                        }
                    }
                } else {
                    this.set("objHeaderItem._sLongitude", null);
                }
            }
        },

        _isFloat: function _isFloat(mValue) {
            var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
            if (!floatRegex.test(mValue)) {
                return false;
            }

            mValue = parseFloat(mValue);
            return !isNaN(mValue);
        },

        setDefaultDistanceUnit: function setDefaultDistanceUnit() {
            if (a24Core.isEmpty(this.get("objHeaderItem._objDistanceUnit"))) {
                if (this.get("_objUserSession.objContextDetails.sCountryId") === "826") {
                    var objCountyDistanceUnit = this.get("_arrDistanceUnitOptions").findBy("sValue", this.get("_objCandidateProfileEnumsService.distanceUnitOptions.MILES.backend"));
                    this.set("objHeaderItem._objDistanceUnit", objCountyDistanceUnit);
                }
            }
        },

        setGetSuggestionFunction: function setGetSuggestionFunction() {
            var _this4 = this;

            this.set("_funcGetSuggestions", function (sQueryString, funcOnComplete) {
                var objQuery = {
                    sSiteName: {
                        mValue: sQueryString,
                        bLike: true,
                        bEncode: true
                    },
                    sortBy: {
                        mValue: "sSiteName"
                    },
                    items_per_page: {
                        mValue: 10
                    }
                };

                _this4.get("objHeaderItem.objLinkedClientsService").getLinkedClients(_this4, "_objGetUserSuggestions", objQuery, function (arrData) {
                    // If the data is empty, ensure it's an empty array
                    if (a24Core.isEmpty(arrData)) {
                        arrData = [];
                    }

                    for (var i = 0; i < arrData.length; i++) {
                        arrData[i].sDisplayName = arrData[i].sOrganisationName + ": " + arrData[i].sSiteName;
                    }

                    // Call the callback with the formatted data
                    funcOnComplete(arrData);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    // Call the callback with empty
                    funcOnComplete([]);
                }));
            });
        },
        inputFormAddressSuggestInitDone: function inputFormAddressSuggestInitDone() {
            this.set("_bInputLoaded", true);
            this.set("_bInputError", false);
            this.set("_bLoading", false);
            this._setupFilterForEdit();
        },
        actions: {
            onInputFormAddressSuggestInitDone: function onInputFormAddressSuggestInitDone() {
                this.inputFormAddressSuggestInitDone();
            },
            onInputFormAddressSuggestError: function onInputFormAddressSuggestError() {
                this.set("_bInputError", true);
                this.set("_bInputLoaded", false);
                this.set("_bLoading", false);
                this._observerForErrorMessage();
            }
        }
    });
});
define("a24-ember-candidate-profile/services/rest-agency-candidate-type", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        sContext: null,
        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("sContext", this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY"));
        },

        /**
         * Retrieves the agency group candidate type list
         *
         * @param objContext - The caller of this function
         * @param sProperty - The Property name to get the promise object
         * @param sAgencyId - The agency id
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  23 Oct 2018
         */
        getCandidateTypes: function getCandidateTypes(objCaller, sProperty, sAgencyId, funcSuccess, objFailure) {

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency/" + sAgencyId + "/candidate-type");

            a24RestUrlConstruct.addQueryParamObject({
                name: {
                    bSortBy: true,
                    mValue: "asc"
                }
            });

            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: {
                    mValue: 1000
                }
            });

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});
define("a24-ember-candidate-profile/components/candidate-qualifications", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-qualifications", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _candidateQualifications, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateQualifications.default,
        objSettingsRestService: null,
        sCandidateId: null,
        sCandidateType: null,
        sCandidateTypeName: null,
        objCandidateQualificationsRestService: null,

        _bAllowEdit: true,
        _bServerError: false,
        _bLoading: true,
        _bNoData: true,
        _bPopupLoading: true,
        _bPopupQualificationLoading: false,
        _bPopupDeleteLoading: false,
        _bShowManagePopup: false,
        _bChangesMadeToQualifications: false,
        _bShowQualificationEdit: false,
        _bIsAdd: false,
        _bShowDeleteConfirm: false,
        _bShowUnsavedData: false,
        _bShowVerifyConfirm: false,
        _bPopupVerifyLoading: false,

        _sFilterCurrentQualificationsValue: "",
        _sFilterQualificationsValue: "",
        _sQualificationId: null,
        _sCandidateQualificationId: null,
        _sCardHeader: null,
        _sManagePopupHeader: null,
        _sAddEditQualificationHeader: null,

        _objCandidateQualificationsServicePromise: null,
        _objSystemServicePromise: null,
        _objSpecialPopupAction: null,
        _objSpecialAddEditQualificationPopupAction: null,
        _objOriginalData: null,

        _arrQualifications: null,
        _arrSystemQualifications: null,
        _arrHeaderActions: null,
        _arrQualificationsSortComponents: null,
        _arrSortComponents: null,
        _arrCandidateQualificationsSortComponent: null,
        _arrCandidateQualifications: null,

        _bShowQualificationView: false,
        _sViewQualificationHeader: null,
        _arrViewQualificationData: null,
        _arrViewQualificationHeaderActions: null,
        _objSpecialViewQualificationPopupAction: null,

        _objUserSession: Ember.inject.service("user-session"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objCandidateEnums: Ember.inject.service("a24-enums-candidate-profile"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        _objSystemService: Ember.inject.service("rest-system"),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_objSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._onPopupManageCancel();
                }
            });

            this.set("_objSpecialAddEditQualificationPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._onPopupQualificationAddEditCancel();
                }
            });

            this.set("_objSpecialViewQualificationPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._onPopupQualificationViewCancel();
                }
            });

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this._getCandidateQualifications(_this.get("sCandidateType"));
                }
            }]);

            if (a24Core.isEmpty(this.get("_arrViewQualificationHeaderActions"))) {
                this.set("_arrViewQualificationHeaderActions", []);
            }

            if (a24Core.isEmpty(this.get("_arrHeaderActions"))) {
                this.set("_arrHeaderActions", []);
            }

            if (a24Core.isEmpty(this.get("_arrQualificationsSortComponents"))) {
                this.set("_arrQualificationsSortComponents", []);
            }

            this._getCandidateQualifications(this.get("sCandidateType"));
        },
        onCandidateTypeChange: Ember.observer("sCandidateType", function () {
            // Resets the system qualifications as tabs changed.
            this.set("_arrSystemQualifications", null);
            this._getCandidateQualifications(this.get("sCandidateType"));
        }),
        _bIsAgency: Ember.computed("objCandidateQualificationsRestService.sContext", function () {
            return this.get("objCandidateQualificationsRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY");
        }),
        _bIsAgencyGroup: Ember.computed("objCandidateQualificationsRestService.sContext", function () {
            return this.get("objCandidateQualificationsRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP");
        }),
        _getCandidateQualifications: function _getCandidateQualifications(sCandidateType) {
            var _this2 = this;

            if (!this.get("objSettingsRestService.objSettings.employment_skills_editable") || a24Core.isEmpty(this.get("objSettingsRestService.objSettings.agency_groups")) || a24Core.isEmpty(this.get("sCandidateType"))) {
                this.set("_bAllowEdit", false);
                return;
            } else {
                this.set("_bAllowEdit", true);
            }
            this.set("_bLoading", true);
            this.set("_bPopupLoading", true);
            this.set("_bServerError", false);
            if (!a24Core.isEmpty(this.get("objChildren.sSearchCurrentQualifications"))) {
                this.get("objChildren.sSearchCurrentQualifications").clear();
            }
            this.set("_sCardHeader", this.get("_objCandidateProfileStringsService").getTokenString("qualificationsHeader", this.get("sCandidateTypeName")));
            this.get("objCandidateQualificationsRestService").getQualifications(this, "_objCandidateQualificationsServicePromise", this.get("sCandidateId"), function (arrResponse) {
                _this2.set("_arrCandidateQualifications", null);
                _this2._prepareForDisplay(arrResponse);
                _this2.set("_bShowQualificationEdit", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this2.set("_bServerError", true);
                _this2.set("_bLoading", false);
            }), {
                items_per_page: {
                    mValue: 1000
                },
                position_types: {
                    mValue: sCandidateType
                }
            });
        },
        _prepareForDisplay: function _prepareForDisplay(arrResponse) {
            var _this3 = this;

            if (this.get("_bAllowEdit") && !this.get("_bIsAgency")) {
                this.get("_objFabButtonService").showFabButton(function () {
                    _this3._showManageQualifications();
                });
            }

            if (a24Core.isEmpty(arrResponse)) {
                this.set("_bNoData", true);
                this.set("_arrHeaderActions", []);
            } else {
                this.set("_bNoData", false);
                this.set("_arrCandidateQualifications", arrResponse);
                var arrHeaderActions = [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("refresh"),
                    sIcon: "refresh",
                    executeCardAction: function executeCardAction() {
                        _this3._getCandidateQualifications(_this3.get("sCandidateType"));
                    }
                }];

                if (!this.get("_bIsAgency")) {
                    arrHeaderActions.push({
                        sTitle: this.get("_objStringsService").getString("edit"),
                        sIcon: "edit",
                        executeCardAction: function executeCardAction() {
                            _this3._showManageQualifications();
                        }
                    });
                }

                this.set("_arrHeaderActions", arrHeaderActions);

                this.set("_arrSortComponents", this._setupTagComponents(arrResponse, false));
            }
            this.set("_bLoading", false);
        },
        onInputValueChanged: function onInputValueChanged(arrChildRef) {
            this._super.apply(this, arguments);

            // Get the reference of the field that changed
            var sLookup = arrChildRef.join(".");
            if (sLookup === "objChildren.sSearchCurrentQualifications") {
                this.set("_sFilterCurrentQualificationsValue", this.get(sLookup + ".mValue"));
            } else if (sLookup === "objChildren.sSearchQualifications") {
                this.set("_sFilterQualificationsValue", this.get(sLookup + ".mValue"));
            }
        },

        _setupTagComponents: function _setupTagComponents(arrCandidateQualifications, bManage) {
            var objThis = this;
            var bCanAction = false;
            var arrSortComponents = [];
            var sEditIcon = null;

            // Setup edit qualifications part
            if (!a24Core.isEmpty(arrCandidateQualifications)) {
                for (var k = 0; k < arrCandidateQualifications.length; k++) {
                    sEditIcon = "";
                    bCanAction = arrCandidateQualifications[k].status === this.get("_objCandidateEnums.qualificationsStatus.UNVERIFIED.backend");
                    if (bManage) {
                        sEditIcon = "edit";
                    }
                    arrSortComponents.push({
                        sComponent: "tag-component-shrink",
                        objData: {
                            sTitle: arrCandidateQualifications[k].qualification_name,
                            mValue: arrCandidateQualifications[k],
                            sColor: "a24-primary-color-hue-3-force white-text",
                            sActionIcon: bCanAction ? sEditIcon : "verified_user",
                            sActionIconColor: "white-text"
                        },
                        objActions: {
                            onTagClicked: function onTagClicked(sTitle, mValue) {
                                objThis.onQualificationsTagClicked(mValue);
                            }
                        }
                    });
                }
            }

            if (bManage) {
                arrSortComponents.sort(function (objA, objB) {
                    if (objA.objData.sTitle.toLowerCase() < objB.objData.sTitle.toLowerCase()) {
                        return -1;
                    }
                    if (objA.objData.sTitle.toLowerCase() > objB.objData.sTitle.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
            }

            return arrSortComponents;
        },

        _showManageQualifications: function _showManageQualifications() {
            this.set("_sManagePopupHeader", this.get("_objCandidateProfileStringsService").getTokenString("headerManageQualifications", this.get("sCandidateTypeName")));
            this.set("_bShowManagePopup", true);
            this._getSystemQualifications();
        },

        _getSystemQualifications: function _getSystemQualifications() {
            var _this4 = this;

            this.set("_bPopupLoading", true);
            if (a24Core.isEmpty(this.get("_arrSystemQualifications"))) {
                this.get("_objSystemService").getQualifications(this, "_objSystemServicePromise", function (arrResponse) {
                    _this4.set("_arrSystemQualifications", arrResponse);
                    _this4._setupQualificationsForManage();
                    _this4.set("_bPopupLoading", false);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                    _this4.set("_bPopupLoading", false);
                    _this4.set("_bShowManagePopup", false);
                    _this4.get("_objSnackbarService").showSnackbarMessage(_this4.get("_objCandidateProfileStringsService").getString("getSystemQualificationsError"));
                }), {
                    position_types: {
                        mValue: this.get("sCandidateType")
                    },
                    sortBy: {
                        mValue: "name"
                    },
                    status: {
                        mValue: this.get("_objCandidateEnums.systemQualificationStatus.ACTIVE.backend")
                    },
                    items_per_page: {
                        mValue: 1000
                    }
                });
            } else {
                this._setupQualificationsForManage();
                this.set("_bPopupLoading", false);
            }
        },
        _setupQualificationsForManage: function _setupQualificationsForManage() {
            var arrSystemQualifications = this.get("_arrSystemQualifications");
            var arrCandidateQualifications = this.get("_arrCandidateQualifications");
            var arrSystemQualificationSortComponents = [];
            var bFound = false;
            var objThis = this;

            // Setup edit qualifications part

            this.set("_arrCandidateQualificationsSortComponent", this._setupTagComponents(arrCandidateQualifications, true));

            //Setup System qualifications for the list
            if (!a24Core.isEmpty(arrSystemQualifications)) {
                for (var i = 0; i < arrSystemQualifications.length; i++) {
                    bFound = false;
                    if (!a24Core.isEmpty(arrCandidateQualifications)) {
                        for (var l = 0; l < arrCandidateQualifications.length; l++) {
                            if (arrCandidateQualifications[l].qualification_name === arrSystemQualifications[i].name) {
                                bFound = true;
                                break;
                            }
                        }
                    }
                    if (!bFound) {
                        arrSystemQualificationSortComponents.push({
                            sComponent: "tag-component-shrink",
                            objData: {
                                sTitle: arrSystemQualifications[i].name,
                                mValue: arrSystemQualifications[i]
                            },
                            objActions: {
                                onTagClicked: function onTagClicked(sTitle, mValue) {
                                    objThis.onQualificationsTagClicked(mValue);
                                }
                            }
                        });
                    }
                }
            }
            this.set("_arrQualificationsSortComponents", arrSystemQualificationSortComponents);
        },
        _onPopupManageCancel: function _onPopupManageCancel() {
            if (this.get("_bPopupLoading")) {
                return;
            }
            this.set("_bShowManagePopup", false);
            if (!a24Core.isEmpty(this.get("objChildren.sSearchQualifications"))) {
                this.get("objChildren.sSearchQualifications").clear();
            }
            if (this.get("_bChangesMadeToQualifications")) {
                this.set("_bChangesMadeToQualifications", false);
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getHTMLTokenString("itemUpdateDelay"));
                this._getCandidateQualifications(this.get("sCandidateType"));
                if (!a24Core.isEmpty(this.get("objChildren.sSearchCurrentQualifications"))) {
                    this.get("objChildren.sSearchCurrentQualifications").clear();
                }
            }
        },
        _onPopupQualificationViewCancel: function _onPopupQualificationViewCancel() {
            this.set("_bShowQualificationView", false);
        },
        _onPopupQualificationAddEditCancel: function _onPopupQualificationAddEditCancel() {
            if (this.get("_bPopupQualificationLoading")) {
                return;
            }
            // Check if the form has any unsaved data
            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowQualificationEdit", false);
            } else {
                this.set("_bShowUnsavedData", true);
            }
        },
        _clearFormData: function _clearFormData() {
            var sFilterCurrentQualificationsValue = this.get("_sFilterCurrentQualificationsValue");
            var sFilterQualificationsValue = this.get("_sFilterQualificationsValue");
            this.clearAllErrorMessages();
            this.clearForm();

            //Reset the search values since the clearForm would have cleared them.
            if (!a24Core.isEmpty(this.get("objChildren.sSearchCurrentQualifications"))) {
                this.get("objChildren.sSearchCurrentQualifications").setValueFromOutside(sFilterCurrentQualificationsValue);
            }
            if (!a24Core.isEmpty(this.get("objChildren.sSearchQualifications"))) {
                this.get("objChildren.sSearchQualifications").setValueFromOutside(sFilterQualificationsValue);
            }
        },
        onQualificationsVerifyClicked: function onQualificationsVerifyClicked(mValue) {
            this.set("_bShowVerifyConfirm", true);
            this.set("_bPopupVerifyLoading", false);

            this.set("_sQualificationId", mValue.qualification_id);
            this.set("_sCandidateQualificationId", mValue._id);
        },
        onQualificationsRemoveClicked: function onQualificationsRemoveClicked(mValue) {
            this.set("_bShowDeleteConfirm", true);
            this.set("_bPopupDeleteLoading", false);

            this.set("_sQualificationId", mValue.qualification_id);
            this.set("_sCandidateQualificationId", mValue._id);
        },
        onQualificationsEditClicked: function onQualificationsEditClicked(mValue) {
            var _this5 = this;

            this._clearFormData();

            this.set("_sAddEditQualificationHeader", this.get("_objCandidateProfileStringsService").getHTMLTokenString("editQualificationsHeader", this.get("sCandidateTypeName"), mValue.qualification_name));
            if (!a24Core.isEmpty(mValue.obtained_at)) {
                var objObtainedAtDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objObtainedAtDate.setDateFromRest(mValue.obtained_at, true);

                this.get("objChildren.obtained_at").setValueFromOutside(objObtainedAtDate.getDateFormat());
            }
            if (!a24Core.isEmpty(mValue.expires_at)) {
                var objExpiresAtDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objExpiresAtDate.setDateFromRest(mValue.expires_at, true);

                this.get("objChildren.expires_at").setValueFromOutside(objExpiresAtDate.getDateFormat());
            }
            this.get("objChildren.institution").setValueFromOutside(mValue.institution);

            this.set("_sQualificationId", mValue.qualification_id);
            this.set("_sCandidateQualificationId", mValue._id);
            this.set("_bIsAdd", false);
            this.set("_bPopupQualificationLoading", false);
            this.set("_bShowQualificationEdit", true);

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this5.set("_objOriginalData", _this5.getPlainFormValueObject());
                });
            });
        },
        onQualificationsTagClicked: function onQualificationsTagClicked(mValue) {
            var _this6 = this;

            if (a24Core.isEmpty(mValue.qualification_id)) {
                this._clearFormData();

                // this is when its from the system list
                this.set("_sAddEditQualificationHeader", this.get("_objCandidateProfileStringsService").getHTMLTokenString("addQualificationsHeader", this.get("sCandidateTypeName"), mValue.name));
                this.set("_sQualificationId", mValue._id);
                this.set("_sCandidateQualificationId", null);
                this.set("_bIsAdd", true);
                this.set("_bPopupQualificationLoading", false);
                this.set("_bShowQualificationEdit", true);

                Ember.run.next(function () {
                    Ember.run.schedule("afterRender", function () {
                        _this6.set("_objOriginalData", _this6.getPlainFormValueObject());
                    });
                });
            } else {
                if (!this.get("_bIsAgency")) {
                    this._showManageQualifications();
                }

                this.set("_sViewQualificationHeader", this.get("_objCandidateProfileStringsService").getHTMLTokenString("viewQualificationsHeader", this.get("sCandidateTypeName"), mValue.qualification_name));

                var objThis = this;

                var funcCardActionVerifyExecute = function funcCardActionVerifyExecute(objItem) {
                    return function () {
                        objThis.onQualificationsVerifyClicked(objItem);
                    };
                };

                var funcCardActionEditExecute = function funcCardActionEditExecute(objItem) {
                    return function () {
                        objThis.onQualificationsEditClicked(objItem);
                    };
                };

                var funcCardActionRemoveExecute = function funcCardActionRemoveExecute(objItem) {
                    return function () {
                        objThis.onQualificationsRemoveClicked(objItem);
                    };
                };

                //We check status to see if you can edit or delete
                if (!this.get("_bIsAgency")) {
                    if (this.get("_bIsAgencyGroup")) {
                        var arrActions = [{
                            sTitle: this.get("_objStringsService").getString("remove"),
                            sIcon: "delete",
                            executeCardAction: funcCardActionRemoveExecute(mValue)
                        }, {
                            sTitle: this.get("_objStringsService").getString("edit"),
                            sIcon: "edit",
                            executeCardAction: funcCardActionEditExecute(mValue)
                        }];

                        if (mValue.status === this.get("_objCandidateEnums.qualificationsStatus.UNVERIFIED.backend")) {
                            arrActions.unshift({
                                sTitle: this.get("_objCandidateProfileStringsService").getString("verify"),
                                sIcon: "verified_user",
                                executeCardAction: funcCardActionVerifyExecute(mValue)
                            });
                        }

                        this.set("_arrViewQualificationHeaderActions", arrActions);
                    } else if (mValue.status === this.get("_objCandidateEnums.qualificationsStatus.UNVERIFIED.backend")) {
                        this.set("_arrViewQualificationHeaderActions", [{
                            sTitle: this.get("_objStringsService").getString("remove"),
                            sIcon: "delete",
                            executeCardAction: funcCardActionRemoveExecute(mValue)
                        }, {
                            sTitle: this.get("_objStringsService").getString("edit"),
                            sIcon: "edit",
                            executeCardAction: funcCardActionEditExecute(mValue)
                        }]);
                    } else {
                        this.set("_arrViewQualificationHeaderActions", []);
                    }
                } else {
                    this.set("_arrViewQualificationHeaderActions", []);
                }

                var sDateObtained = null;
                var sDateExpired = null;

                if (!a24Core.isEmpty(mValue.obtained_at)) {
                    var objDateObtained = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objDateObtained.setDateFromRest(mValue.obtained_at, true);
                    sDateObtained = objDateObtained.getDateFormat();
                }
                if (!a24Core.isEmpty(mValue.expires_at)) {
                    var objDateExpired = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objDateExpired.setDateFromRest(mValue.expires_at, true);
                    sDateExpired = objDateExpired.getDateFormat();
                }

                var arrItems = [{
                    sLabel: this.get("_objCandidateProfileStringsService").getString("dateObtained"),
                    sValue: sDateObtained,
                    bText: true
                }, {
                    sLabel: this.get("_objCandidateProfileStringsService").getString("expiryDate"),
                    sValue: sDateExpired,
                    bText: true
                }, {
                    sLabel: this.get("_objCandidateProfileStringsService").getString("institution"),
                    sValue: mValue.institution,
                    bText: true
                }, {
                    sLabel: this.get("_objStringsService").getString("status"),
                    sValue: this.get("_objCandidateEnums").getFrontendValue("qualificationsStatus", mValue.status),
                    bText: true
                }];

                this.set("_arrViewQualificationData", [{
                    arrItems: arrItems
                }]);

                this.set("_objQualificationView", true);
                this.set("_bShowQualificationView", true);
            }
        },
        getPayload: function getPayload() {
            var objPayload = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

            if (!a24Core.isEmpty(objPayload.obtained_at)) {
                var objStartDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objStartDate.setDateFromBrowser(objPayload.obtained_at, a24Constants.DATE_FORMAT);

                objStartDate.setTimeToStartOfDay();

                objPayload.obtained_at = objStartDate.getDateStringForRest();
            }

            if (!a24Core.isEmpty(objPayload.expires_at)) {
                var objEndDate = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objEndDate.setDateFromBrowser(objPayload.expires_at, a24Constants.DATE_FORMAT);

                objEndDate.setTimeToEndOfDay();

                objPayload.expires_at = objEndDate.getDateStringForRest();
            }

            objPayload.qualification_id = this.get("_sQualificationId");

            return objPayload;
        },

        _onPopupQualificationAddEditSave: function _onPopupQualificationAddEditSave() {
            var _this7 = this;

            this.clearAllErrorMessages();

            if (!this.doFullValidation()) {
                var objPayload = this.getPayload();

                this.set("_bPopupQualificationLoading", true);
                if (this.get("_bIsAdd")) {
                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objCandidateProfileStringsService").getString("qualification")));

                    this.get("objCandidateQualificationsRestService").addQualification(this, "_objCandidateQualificationsServicePromise", this.get("sCandidateId"), objPayload, function (objNewQualification) {
                        var arrSystemQualifications = _this7.get("_arrSystemQualifications");
                        var objQualification = objNewQualification;
                        for (var i = 0; i < arrSystemQualifications.length; i++) {

                            if (objNewQualification.qualification_id === arrSystemQualifications[i]._id) {
                                objQualification.qualification_name = arrSystemQualifications[i].name;
                                objQualification.position_types = arrSystemQualifications[i].position_types;
                                break;
                            }
                        }

                        if (a24Core.isEmpty(_this7.get("_arrCandidateQualifications"))) {
                            _this7.set("_arrCandidateQualifications", [objQualification]);
                        } else {
                            _this7.get("_arrCandidateQualifications").pushObject(objQualification);
                        }

                        _this7._doSuccess(_this7.get("_objStringsService").getTokenString("successAddItem", _this7.get("_objCandidateProfileStringsService").getString("qualification")));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                        _this7.set("_bPopupQualificationLoading", false);
                        if (sCode === "404" || sCode === "409") {
                            _this7.get("_objSnackbarService").showSnackbarMessage(_this7.get("_objCandidateProfileStringsService").getString("addFailedRefreshPage"));
                        } else {
                            _this7._doError(sCode, objError, _this7.get("_objStringsService").getTokenString("failAddItem", _this7.get("_objCandidateProfileStringsService").getString("qualification")));
                        }
                    }));
                } else {
                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objCandidateProfileStringsService").getString("qualification")));

                    this.get("objCandidateQualificationsRestService").updateQualification(this, "_objCandidateQualificationsServicePromise", this.get("sCandidateId"), this.get("_sCandidateQualificationId"), objPayload, function (objResponse) {
                        //Update the item from the existing items so that the manage popup will display the correct
                        // information when users click on it again.
                        var arrCandidateQualifications = _this7.get("_arrCandidateQualifications");
                        for (var i = 0; i < arrCandidateQualifications.length; i++) {
                            if (_this7.get("_sQualificationId") === arrCandidateQualifications[i].qualification_id) {
                                var objQualification = arrCandidateQualifications[i];
                                objQualification.obtained_at = objResponse.obtained_at;
                                objQualification.expires_at = objResponse.expires_at;
                                objQualification.institution = objResponse.institution;
                                objQualification.status = objResponse.status;
                                break;
                            }
                        }

                        _this7._doSuccess(_this7.get("_objStringsService").getTokenString("successUpdateItem", _this7.get("_objCandidateProfileStringsService").getString("qualification")));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                        _this7.set("_bPopupQualificationLoading", false);
                        if (sCode === "404" || sCode === "409") {
                            _this7.get("_objSnackbarService").showSnackbarMessage(_this7.get("_objStringsService").getString("updateFailedRefreshPage"));
                        } else {
                            _this7._doError(sCode, objError, _this7.get("_objStringsService").getTokenString("failUpdateItem", _this7.get("_objCandidateProfileStringsService").getString("qualification")));
                        }
                    }));
                }
            }
        },
        _onPopupQualificationVerifyConfirm: function _onPopupQualificationVerifyConfirm() {
            var _this8 = this;

            this.set("_bPopupVerifyLoading", true);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getTokenString("verifyingItem", this.get("_objCandidateProfileStringsService").getString("qualification")));

            this.get("objCandidateQualificationsRestService").verifyQualification(this, "_objCandidateQualificationsServicePromise", this.get("sCandidateId"), this.get("_sCandidateQualificationId"), function () {
                // Changing the status of the qualification so that it will reflect on the manage popup
                var arrCandidateQualifications = _this8.get("_arrCandidateQualifications");
                for (var i = 0; i < arrCandidateQualifications.length; i++) {
                    if (_this8.get("_sQualificationId") === arrCandidateQualifications[i].qualification_id) {
                        _this8.set("_arrCandidateQualifications." + i + ".status", _this8.get("_objCandidateEnums.qualificationsStatus.VERIFIED.backend"));
                        break;
                    }
                }

                _this8._doSuccess(_this8.get("_objCandidateProfileStringsService").getTokenString("successVerifyItem", _this8.get("_objCandidateProfileStringsService").getString("qualification")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                _this8.set("_bPopupVerifyLoading", false);
                if (sCode === "404" || sCode === "409") {
                    _this8.get("_objSnackbarService").showSnackbarMessage(_this8.get("_objCandidateProfileStringsService").getString("verifyFailedRefreshPage"));
                } else {
                    _this8._doError(sCode, objError, _this8.get("_objCandidateProfileStringsService").getTokenString("failVerifyItem", _this8.get("_objCandidateProfileStringsService").getString("qualification")));
                }
            }));
        },
        _onPopupQualificationDeleteConfirm: function _onPopupQualificationDeleteConfirm() {
            var _this9 = this;

            this.set("_bPopupDeleteLoading", true);

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("removingItem", this.get("_objCandidateProfileStringsService").getString("qualification")));

            this.get("objCandidateQualificationsRestService").deleteQualification(this, "_objCandidateQualificationsServicePromise", this.get("sCandidateId"), this.get("_sCandidateQualificationId"), function () {
                //Remove the item from the existing items so that the manage popup wont display that item and make it
                //availible to be added again
                var arrCandidateQualifications = _this9.get("_arrCandidateQualifications");
                var iRemoveIndex = -1;
                for (var i = 0; i < arrCandidateQualifications.length; i++) {
                    if (_this9.get("_sQualificationId") === arrCandidateQualifications[i].qualification_id) {
                        iRemoveIndex = i;
                        break;
                    }
                }
                if (iRemoveIndex !== -1) {
                    arrCandidateQualifications.removeAt(iRemoveIndex);
                }

                _this9._doSuccess(_this9.get("_objStringsService").getTokenString("successRemoveItem", _this9.get("_objCandidateProfileStringsService").getString("qualification")));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                _this9.set("_bPopupDeleteLoading", false);
                if (sCode === "404" || sCode === "409") {
                    _this9.get("_objSnackbarService").showSnackbarMessage(_this9.get("_objStringsService").getString("removeFailedRefreshPage"));
                } else {
                    _this9._doError(sCode, objError, _this9.get("_objStringsService").getTokenString("failRemoveItem", _this9.get("_objCandidateProfileStringsService").getString("qualification")));
                }
            }));
        },
        _doSuccess: function _doSuccess(sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bShowQualificationView", false);
            this.set("_bShowQualificationEdit", false);
            this.set("_bShowDeleteConfirm", false);
            this.set("_bShowVerifyConfirm", false);

            if (!a24Core.isEmpty(this.get("objChildren.sSearchQualifications"))) {
                this.get("objChildren.sSearchQualifications").clear();
            }

            this.set("_bChangesMadeToQualifications", true);

            this._getSystemQualifications();
        },
        _doError: function _doError(sCode, objError, sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
        },
        actions: {
            onPopupManageCancel: function onPopupManageCancel() {
                this._onPopupManageCancel();
            },
            onPopupQualificationAddEditCancel: function onPopupQualificationAddEditCancel() {
                this._onPopupQualificationAddEditCancel();
            },
            onPopupQualificationAddEditSave: function onPopupQualificationAddEditSave() {
                this._onPopupQualificationAddEditSave();
            },
            onPopupDeleteClose: function onPopupDeleteClose() {
                if (this.get("_bPopupDeleteLoading")) {
                    return;
                }

                this.set("_bShowDeleteConfirm", false);
            },
            onPopupVerifyClose: function onPopupVerifyClose() {
                if (this.get("_bPopupVerifyLoading")) {
                    return;
                }

                this.set("_bShowVerifyConfirm", false);
            },
            onPopupVerifyConfirm: function onPopupVerifyConfirm() {
                this._onPopupQualificationVerifyConfirm();
            },
            onPopupDeleteConfirm: function onPopupDeleteConfirm() {
                this._onPopupQualificationDeleteConfirm();
            },
            onPopupQualificationViewDone: function onPopupQualificationViewDone() {
                this._onPopupQualificationViewCancel();
            },
            onPopupQualificationViewCancel: function onPopupQualificationViewCancel() {
                this._onPopupQualificationViewCancel();
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowQualificationEdit", false);
                this.set("_bShowUnsavedData", false);
            }
        }
    });
});
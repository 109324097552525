define("a24-ember-candidate-profile/components/card-datagrid-custom-filter-candidate-external-days-to-expiry", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/card-datagrid-custom-filter-candidate-external-days-to-expiry", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _cardDatagridCustomFilterCandidateExternalDaysToExpiry, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _cardDatagridCustomFilterCandidateExternalDaysToExpiry.default,
        objHeaderItem: null,

        _arrExternalDaysType: null,
        _arrExpiringIn: null,
        _objGetExternalDaysToExpiry: null,

        _bShowExpiryDaysNumberInput: false,

        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objFilterCustomCandidateExternalDaysToExpiryService: Ember.inject.service("card-datagrid-custom-filter-candidate-external-days-to-expiry"),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("_arrExpiringIn", this.get("_objCandidateProfileEnumsService").getList("expiringIn"));
        },

        onInputValueChanged: function onInputValueChanged(arrPathToInputMixin) {
            var sLookup = arrPathToInputMixin.join(".");

            if (sLookup === "objChildren._bEmptyEntriesOnly") {
                this.set("objHeaderItem._bEmptyEntriesOnly", this.get(sLookup + ".mValue.bValue") === true);
            } else if (sLookup === "objChildren._objExternalDaysType") {
                var objExternalDaysType = this.get(sLookup + ".mValue");

                if (!a24Core.isEmpty(objExternalDaysType)) {
                    this.set("objHeaderItem._objExternalDaysType", objExternalDaysType);
                } else {
                    this.set("objHeaderItem._objExternalDaysType", null);
                }
            } else if (sLookup === "objChildren._objExpiringIn") {
                var objExpiringIn = this.get(sLookup + ".mValue");

                if (!a24Core.isEmpty(objExpiringIn)) {
                    this.set("objHeaderItem._objExpiringIn", objExpiringIn);
                } else {
                    this.set("objHeaderItem._objExpiringIn", null);
                }
            }
        },

        _observeAllValues: Ember.observer("objHeaderItem._bEmptyEntriesOnly", "objHeaderItem._objExternalDaysType", "objHeaderItem._objExpiringIn", "objHeaderItem._iNumberOfDays", function () {
            if (!a24Core.isEmpty(this.get("objChildren._objExternalDaysType")) && a24Core.isEmpty(this.get("objHeaderItem._objExternalDaysType"))) {
                this.get("objChildren._objExternalDaysType").clear();
            }
            if (!a24Core.isEmpty(this.get("objChildren._objExpiringIn")) && a24Core.isEmpty(this.get("objHeaderItem._objExpiringIn"))) {
                this.get("objChildren._objExpiringIn").clear();
            }

            Ember.run.once(this, "_setupFilterForEdit");
            Ember.run.once(this, "_setFlags");
        }).on("init"),

        _setupFilterForEdit: function _setupFilterForEdit() {},

        _setFlags: function _setFlags() {
            if (this.get("objHeaderItem._bEmptyEntriesOnly")) {
                this.customFilterDisplayAddButtonEvent(true);
            } else {
                if (!a24Core.isEmpty(this.get("objHeaderItem._objExternalDaysType")) && !a24Core.isEmpty(this.get("objHeaderItem._objExpiringIn"))) {
                    if (this.get("objHeaderItem._objExpiringIn.sValue") !== this.get("_objCandidateProfileEnumsService.expiringIn.NEVER.backend")) {
                        this.set("_bShowExpiryDaysNumberInput", true);
                        if (!a24Core.isEmpty(this.get("objHeaderItem._iNumberOfDays"))) {
                            if (this.get("objHeaderItem._iNumberOfDays") == "-") {
                                this.customFilterDisplayAddButtonEvent(false);
                            } else {
                                this.customFilterDisplayAddButtonEvent(true);
                            }
                        } else {
                            this.customFilterDisplayAddButtonEvent(false);
                        }
                    } else {
                        this.set("_bShowExpiryDaysNumberInput", false);
                        this.customFilterDisplayAddButtonEvent(true);
                    }
                } else {
                    this.set("_bShowExpiryDaysNumberInput", false);
                    this.customFilterDisplayAddButtonEvent(false);
                }
            }
        }
    });
});
define("a24-ember-lib/components/react-page-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/react-page-component"], function (exports, _didRenderChangesMixin, _reactPageComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _reactPageComponent.default,
        classNames: ["tab-react-iframe", "react-page"],
        classNameBindings: ["_bShowPopup:modal-opened"],

        sIFrameId: null,
        sIFrameUrl: null,
        sIFrameLoader: null,
        objPostIframeData: null,

        _objDomService: Ember.inject.service("dom-event"),
        _objChatterIframeComService: Ember.inject.service("chatter-iframe-com-base"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUserSession: Ember.inject.service("user-session"),

        _sIFrameFullUrl: null,
        _bShowPopup: false,
        _bReadyFired: false,
        _funcUnregister: null,
        _funcPostLayoutData: null,
        _objLayoutInfo: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            // We need to specifically get the service since it is lazy loaded and incompatible with observe only
            this.get("_objDomService.iPageHeight");

            if (!a24Core.isEmpty(this.get("sIFrameUrl"))) {
                var env = Ember.getOwner(this).resolveRegistration("config:environment");
                if (env.environment === "test") {
                    this.set("sIFrameId", "testFrameIdhere1234");
                } else if (a24Core.isEmpty(this.get("sIFrameId"))) {
                    this.set("sIFrameId", Ember.guidFor({}));
                }
                this.set("_funcUnregister", this.get("_objChatterIframeComService").receiveMessage(function (objMessage) {
                    var objData = {};
                    if (!a24Core.isEmpty(objMessage.data)) {
                        objData = JSON.parse(objMessage.data);
                    }
                    if (a24Core.isEmpty(objData.frameId) || objData.frameId === _this.get("sIFrameId")) {
                        _this._handleMessages(objData);
                    }
                }, this.get("sIFrameUrl")));
            }

            var funcPostLayoutData = function funcPostLayoutData() {
                if (a24Core.isEmpty(_this) || !_this.get("_bReadyFired")) {
                    return;
                }
                Ember.run.next(function () {
                    Ember.run.schedule("afterRender", function () {
                        if (a24Core.isEmpty(_this)) {
                            return;
                        }
                        var objRect = _this.element.getBoundingClientRect();
                        if (_this.get("_objLayoutInfo.layoutLeft") !== objRect.left || _this.get("_objLayoutInfo.layoutTop") !== objRect.top) {
                            var objLayoutInfo = {
                                layoutLeft: objRect.left,
                                layoutRight: 0,
                                layoutTop: objRect.top,
                                layoutBottom: 0
                            };
                            _this._postData({
                                type: "layout_info",
                                data: objLayoutInfo
                            });
                            _this.set("_objLayoutInfo", objLayoutInfo);
                        }
                    });
                });
            };

            this.set("_funcPostLayoutData", funcPostLayoutData);
            this.get("_objDomService").on("resize", this.get("_funcPostLayoutData"));

            this._setFullUrl();
        },

        _setFullUrl: function _setFullUrl() {
            if (a24Core.isEmpty(this.get("sIFrameUrl"))) {
                this.set("_sIFrameFullUrl", null);
                return;
            }
            var sPre = "?";
            if (this.get("sIFrameUrl").indexOf("?") !== -1) {
                sPre = "&";
            }
            if (a24Core.isEmpty(this.get("sIFrameLoader"))) {
                // This will be the default
                this.set("_sIFrameFullUrl", this.get("sIFrameUrl") + sPre + "reactLoaderType=modeBackground" + "&reactFrameId=" + this.get("sIFrameId"));
            } else {
                this.set("_sIFrameFullUrl", this.get("sIFrameUrl") + sPre + "reactLoaderType=" + this.get("sIFrameLoader") + "&reactFrameId=" + this.get("sIFrameId"));
            }
        },

        willDestroyElement: function willDestroyElement() {
            if (!a24Core.isEmpty(this.get("_funcUnregister"))) {
                this.get("_funcUnregister")();
            }
            this.get("_objDomService").off("resize", this.get("_funcPostLayoutData"));
            this._super.apply(this, arguments);
        },


        _onRender: Ember.observer("_objDidRenderService._iCountRender", function () {
            Ember.run.once("afterRender", this._funcPostLayoutData);
        }),

        _onDataChange: Ember.observer("objPostIframeData", function () {
            Ember.run.once(this, this._postContextData);
        }),

        _postContextData: function _postContextData() {
            if (!a24Core.isEmpty(this.get("objPostIframeData"))) {
                this._postData(this.get("objPostIframeData"));
            }
        },

        _postData: function _postData(objData) {
            var objDataCopy = Ember.copy(objData, true);
            if (objDataCopy.type === "trigger_route") {
                if (a24Core.isEmpty(objDataCopy.data)) {
                    objDataCopy.data = {};
                }
                objDataCopy.data.authResponse = Ember.copy(this.get("_objUserSession.objAuthData"), true);
                objDataCopy.data.contextResponse = Ember.copy(this.get("_objUserSession.objContextDetails"), true);
            }
            this.get("_objChatterIframeComService").postMessage(JSON.stringify(objDataCopy), this.get("sIFrameUrl"), this.$("iframe")[0].contentWindow);
        },

        _handleMessages: function _handleMessages(objData) {
            if (!this.get("_bReadyFired") && (objData.type === "app_ready" || objData.type === "router_ready")) {
                this.set("_bReadyFired", true);
                Ember.run.once(this, this._funcPostLayoutData);
                Ember.run.once(this, this._postContextData);
            } else if (objData.type === "toggle_modal") {
                var bIsOpen = objData.data.open === true;
                this.set("_bShowPopup", bIsOpen);
                this.$(".react-iframe-wrapper").css("z-index", bIsOpen ? "1051" : "0");
            } else if (objData.type === "snackbar-message") {
                this.get("_objSnackbarService").showSnackbarMessage(objData.data.message);
            } else if (objData.type === "show_modal_masks") {
                var iMaskCount = objData.data;
                var bOpen = iMaskCount > 0;
                this.set("_bShowPopup", bOpen);
                this.$(".react-iframe-wrapper").css("z-index", bOpen ? "1051" : "0");

                var objBackgroundModal = this.$(".a24-modal-backdrop");
                var iOpacity = 1 - Math.pow(1 - 0.3, iMaskCount);
                objBackgroundModal.fadeTo(150, iOpacity);
            }
            // This is for if we want to handle custom messages
            if (!a24Core.isEmpty(this.get("onRecieveData"))) {
                this.get("onRecieveData")(objData);
            }
        },

        actions: {
            onPopupClose: function onPopupClose() {
                if (this.get("_bShowPopup")) {
                    this._postData({
                        type: "modal_overlay_clicked"
                    });
                }
            }
        }
    });
});
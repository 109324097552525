define("a24-ember-lib/components/card-datagrid-pager", ["exports", "a24-ember-lib/templates/components/card-datagrid-pager", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _cardDatagridPager, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _cardDatagridPager.default,
        iResultCount: 0, // The total result count
        iPage: 1, // The page
        iPerPage: 25, // The amount of items for each page
        sOfSeparator: "", // The "of" separator in "x - y of z"
        sToSeparator: "", // The "-" separator in "x - y of z"
        _objStringsService: Ember.inject.service("a24-strings"),
        _iPageMin: 0,
        _iPageMax: 0,
        _bIsFirstPage: false,
        _bIsLastPage: false,
        _iFromPageDisplay: 0,
        _iToPageDisplay: 0,
        init: function init() {
            this._super.apply(this, arguments);

            if (a24Core.isEmpty(this.get("sOfSeparator"))) {
                this.set("sOfSeparator", this.get("_objStringsService").getString("of").toLowerCase());
            }
            if (a24Core.isEmpty(this.get("sToSeparator"))) {
                this.set("sToSeparator", this.get("_objStringsService").getString("to").toLowerCase());
            }

            this.calculatePageLimits();

            // Notify parent of the new page
            var objPageQuery = {
                page: {
                    mValue: this.get("iPage")
                },
                per_page: {
                    mValue: this.get("iPerPage")
                }
            };
            if (!a24Core.isEmpty(this.get("cardDatagridPagerInitEvent"))) {
                this.get("cardDatagridPagerInitEvent")(objPageQuery);
            }
        },
        calculatePageLimits: Ember.observer("iResultCount", "iPage", function () {
            var iPageMin = 1;
            var iPageMax = 1;

            var iResultCount = this.get("iResultCount");

            if (iResultCount > 0) {
                // If there are more than 0 results
                var iPerPage = this.get("iPerPage");
                var iResultsOnLastPage = iResultCount % iPerPage;
                var iPages = (iResultCount - iResultsOnLastPage) / iPerPage;

                // This means we only have one page
                if (iResultsOnLastPage !== 0) {
                    iPages++; // Add single page to the iPages count
                }

                iPageMin = 1;
                iPageMax = iPages;
            }

            this.set("_iPageMin", iPageMin);
            this.set("_iPageMax", iPageMax);

            var iPage = this.get("iPage");
            // Update the page display
            this.updatePage(iPage, false);
        }),
        updatePage: function updatePage(iPage, bFireEvent) {
            var iPageMin = this.get("_iPageMin");
            var iPageMax = this.get("_iPageMax");

            // Restrict pages to the limits
            if (iPage > iPageMax) {
                iPage = iPageMax;
            }
            if (iPage < iPageMin) {
                iPage = iPageMin;
            }

            // Calculate whether the page is first and store
            this.set("_bIsFirstPage", iPage === iPageMin);
            // Calculate whether the page is last and store
            this.set("_bIsLastPage", iPage === iPageMax);

            // Set the page
            this.set("iPage", iPage);

            // Call function to update the display
            this.updateDisplay(bFireEvent);
        },
        updateDisplay: function updateDisplay(bFireEvent) {

            // Get variables
            var iResultCount = this.get("iResultCount");
            var iPage = this.get("iPage");
            var iPerPage = this.get("iPerPage");

            // Get starting count
            var iFromResult = (iPage - 1) * iPerPage + 1;
            // Get ending count
            var iToResult = iFromResult + iPerPage - 1;

            // If the result count is 0
            if (iResultCount === 0) {
                iFromResult = iResultCount;
            }
            // If the ending count is larger than the last item
            if (iToResult > iResultCount) {
                iToResult = iResultCount; // Limit the ending count
            }

            this.set("_iFromPageDisplay", iFromResult);
            this.set("_iToPageDisplay", iToResult);

            // If we want to fire the event
            if (bFireEvent) {
                // Notify parent of the new page
                var objPageQuery = {
                    page: {
                        mValue: iPage
                    },
                    per_page: {
                        mValue: iPerPage
                    }
                };
                if (!a24Core.isEmpty(this.get("cardDatagridPagerEvent"))) {
                    this.get("cardDatagridPagerEvent")(objPageQuery);
                }
            }
        },
        actions: {
            firstPageClick: function firstPageClick() {
                // This is for the future when we want "back to first page" logic
                //this.updatePage(this.get("_iPageMin"), true);
            },
            previousPageClick: function previousPageClick() {
                var iPage = this.get("iPage");
                iPage--;
                this.updatePage(iPage, true);
            },
            nextPageClick: function nextPageClick() {
                var iPage = this.get("iPage");
                iPage++;
                this.updatePage(iPage, true);
            },
            lastPageClick: function lastPageClick() {
                // This is for the future when we want "go to last page" logic
                //this.updatePage(this.get("_iPageMax"), true);
            }
        }
    });
});
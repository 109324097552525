define("a24-ember-candidate-profile/components/candidate-next-of-kin", ["exports", "a24-ember-candidate-profile/templates/components/candidate-next-of-kin", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element"], function (exports, _candidateNextOfKin, _didRenderChangesMixin, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateNextOfKin.default,
        sCandidateId: null,
        objNextOfKinRestService: null,
        _objNextofKinEditData: null,
        _objSelectedCountry: null,
        _objSelectedAddressType: null,
        _arrCandidateData: null,
        _arrHeaderActions: null,
        _arrAddressTypeDropdown: null,
        _arrCountryDropdown: null,
        _bLoading: true,
        _bPopupLoading: false,
        _bShowUnsavedData: false,
        _bIsAdd: false,
        _bShowNextOfKinErrorMessagePopup: false,
        _bShowDeleteConfirm: false,
        _sHeaderPopup: null,
        _objOriginalData: null,
        _bShowAddEditPopup: false,
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objEnumsService: Ember.inject.service("a24-enums"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objCandidateProfileNextOfKinPromise: null,
        _objSpecialAddEditPopupAction: null,
        _bAddressFieldsRequired: false,

        _bServerError: false,
        _arrReloadActions: null,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.getNextOfKinDetails();
                }
            }]);

            this.set("_objSpecialAddEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.send("onPopupAddEditCancel");
                }
            });

            var objValidationConfig = {};

            objValidationConfig["objChildren.relationship"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 2);
                a24Validation.addMaxLength(objMixin, 100);
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.name"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 2);
                a24Validation.addMaxLength(objMixin, 100);
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.contact_number"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.comment"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 2);
                a24Validation.addMaxLength(objMixin, 100);
            };
            objValidationConfig["objChildren.address.type"] = function (objMixin) {
                objMixin.get("arrValidation").pushObject(function () {
                    if (_this._addressHasValues()) {
                        a24Validation.addRequired(objMixin, true);
                    } else {
                        a24Validation.addRequired(objMixin, false);
                    }
                });
            };
            objValidationConfig["objChildren.address.address_line1"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addMaxLength(objMixin, 100);
                objMixin.get("arrValidation").pushObject(function () {
                    if (_this._addressHasValues()) {
                        a24Validation.addRequired(objMixin, true);
                    } else {
                        a24Validation.addRequired(objMixin, false);
                    }
                });
            };
            objValidationConfig["objChildren.address.address_line2"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addMaxLength(objMixin, 100);
            };
            objValidationConfig["objChildren.address.city"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addMaxLength(objMixin, 100);
            };
            objValidationConfig["objChildren.address.province"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 2);
                a24Validation.addMaxLength(objMixin, 100);
            };
            objValidationConfig["objChildren.address.country_code"] = function (objMixin) {
                objMixin.get("arrValidation").pushObject(function () {
                    if (_this._addressHasValues()) {
                        a24Validation.addRequired(objMixin, true);
                    } else {
                        a24Validation.addRequired(objMixin, false);
                    }
                });
            };
            objValidationConfig["objChildren.address.post_code"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 1);
                a24Validation.addMaxLength(objMixin, 100);
                objMixin.get("arrValidation").pushObject(function () {
                    if (_this._addressHasValues()) {
                        a24Validation.addRequired(objMixin, true);
                    } else {
                        a24Validation.addRequired(objMixin, false);
                    }
                });
            };

            this.set("objChildConfig", objValidationConfig);

            if (a24Core.isEmpty(this.get("_arrCandidateData"))) {
                this.set("_arrCandidateData", []);
            }

            this.set("_arrAddressTypeDropdown", this.get("_objCandidateProfileEnumsService").getList("addressType"));
            this.set("_arrCountryDropdown", this.get("_objEnumsService").getList("country"));

            this.getNextOfKinDetails();
        },
        _addressHasValues: function _addressHasValues() {
            return !a24Core.isEmpty(this.get("objChildren")["address.type"].mValue) || !a24Core.isEmpty(this.get("objChildren")["address.address_line1"].mValue) || !a24Core.isEmpty(this.get("objChildren")["address.address_line2"].mValue) || !a24Core.isEmpty(this.get("objChildren")["address.city"].mValue) || !a24Core.isEmpty(this.get("objChildren")["address.province"].mValue) || !a24Core.isEmpty(this.get("objChildren")["address.country_code"].mValue) || !a24Core.isEmpty(this.get("objChildren")["address.post_code"].mValue);
        },
        onInputValueChanged: function onInputValueChanged(arrPath) {
            var _this2 = this;

            this.set("_bAddressFieldsRequired", this._addressHasValues());

            // Get the reference of the field that changed
            var sLookup = arrPath.join(".");

            if (sLookup === "objChildren.address.country_code") {
                var objCountryCode = this.get("objChildren")["address.country_code"];

                if (!a24Core.isEmpty(objCountryCode) && !a24Core.isEmpty(objCountryCode.mValue)) {
                    var sCountryCode = objCountryCode.mValue.sValue;
                    var objPostCode = this.get("objChildren")["address.post_code"];

                    objPostCode.get("arrValidation").clear();
                    objPostCode.set("funcValidationRequired", null);

                    if (sCountryCode === "UK" || sCountryCode === "GB") {
                        a24Validation.addPostCode(objPostCode);
                        objPostCode.get("arrValidation").pushObject(function () {
                            if (_this2._addressHasValues()) {
                                a24Validation.addRequired(objPostCode, true);
                            } else {
                                a24Validation.addRequired(objPostCode, false);
                            }
                        });
                    } else {
                        a24Validation.addMinLength(objPostCode, 1);
                        a24Validation.addMaxLength(objPostCode, 100);
                        objPostCode.get("arrValidation").pushObject(function () {
                            if (_this2._addressHasValues()) {
                                a24Validation.addRequired(objPostCode, true);
                            } else {
                                a24Validation.addRequired(objPostCode, false);
                            }
                        });
                    }

                    this._formatPostCode();
                }
            } else if (sLookup === "objChildren.address.post_code") {
                this._formatPostCode();
            }
        },
        _formatPostCode: function _formatPostCode() {
            var objCountryCode = this.get("objChildren")["address.country_code"];
            var objPostCode = this.get("objChildren")["address.post_code"];

            if (!a24Core.isEmpty(objCountryCode) && !a24Core.isEmpty(objCountryCode.mValue) && (objCountryCode.mValue.sValue === "UK" || objCountryCode.mValue.sValue === "GB")) {
                if (!a24Core.isEmpty(objPostCode.mValue)) {
                    var sInputValue = objPostCode.mValue.trim();
                    if (!/\s/.test(sInputValue)) {
                        var sValue1 = sInputValue.slice(0, sInputValue.length - 3);
                        var sValue2 = sInputValue.slice(sInputValue.length - 3, sInputValue.length);
                        objPostCode.setValueFromOutside(sValue1.toUpperCase() + " " + sValue2.toUpperCase());
                    } else if (sInputValue.split(" ").length > 1) {
                        objPostCode.setValueFromOutside(sInputValue.replace(/  +/g, " ").toUpperCase());
                    }
                }
            }
        },
        _onLoadingChange: Ember.observer("_bLoading", function () {
            var _this3 = this;

            if (this.get("_bLoading")) {
                this.set("_arrHeaderActions", []);
            } else {
                this.set("_arrHeaderActions", [{
                    sTitle: this.get("_objStringsService").getString("add"),
                    sIcon: "add",
                    executeCardAction: function executeCardAction() {
                        _this3.setAddEditPopup();
                    }
                }]);
            }
        }).on("init"),
        prepareForDisplay: function prepareForDisplay(arrData) {
            var arrDisplay = [];

            // Callback function for the array filter
            var funcFilter = function funcFilter(sValue) {
                return !a24Core.isEmpty(sValue);
            };

            var objThis = this;

            var funcCardActionEditExecute = function funcCardActionEditExecute(sId) {
                return function () {
                    objThis.setAddEditPopup(sId);
                };
            };
            var funcCardActionRemoveExecute = function funcCardActionRemoveExecute(sId) {
                return function () {
                    objThis.removeNextOfKin(sId);
                };
            };

            for (var i = 0; i < arrData.length; i++) {

                var sAddress = "";
                var arrAddress = [];
                var bAddress = false;

                if (!a24Core.isEmpty(arrData[i].address)) {
                    arrAddress = [arrData[i].address.address_line1, arrData[i].address.address_line2, arrData[i].address.city, arrData[i].address.post_code, arrData[i].address.province, this.get("_objEnumsService").getFrontendValue("country", arrData[i].address.country_code)];

                    arrAddress = arrAddress.filter(funcFilter);

                    sAddress = Ember.String.htmlSafe(arrAddress.join("<br/>"));
                    bAddress = true;
                }

                var arrActions = [{
                    sTitle: this.get("_objStringsService").getString("edit"),
                    sIcon: "edit",
                    executeCardAction: funcCardActionEditExecute(arrData[i])
                }, {
                    sTitle: this.get("_objStringsService").getString("remove"),
                    sIcon: "delete",
                    executeCardAction: funcCardActionRemoveExecute(arrData[i])
                }];

                arrDisplay.push({
                    sIcon: "person",
                    sName: arrData[i].name,
                    sExtra: arrData[i].relationship,
                    arrActions: arrActions,
                    arrItems: [{
                        arrItems: [{
                            sLabel: this.get("_objCandidateProfileStringsService").getString("relationship"),
                            sValue: arrData[i].relationship,
                            bText: true
                        }, {
                            sLabel: this.get("_objStringsService").getString("contactNumber"),
                            sValue: arrData[i].contact_number,
                            bText: true
                        }, {
                            sLabel: this.get("_objStringsService").getString("comment"),
                            sValue: arrData[i].comment,
                            bText: true
                        }, {
                            sLabel: bAddress ? this.get("_objCandidateProfileEnumsService").getFrontendValue("addressType", arrData[i].address.type) : this.get("_objStringsService").getString("addresses"),
                            sValue: sAddress,
                            bText: true
                        }]
                    }]
                });
            }
            return arrDisplay;
        },
        getNextOfKinDetails: function getNextOfKinDetails() {
            var _this4 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);

            this.get("objNextOfKinRestService").getNextOfKins(this, "_objCandidateProfileNextOfKinPromise", this.get("sCandidateId"), function (arrData) {
                _this4.set("_arrCandidateData", []);
                if (!a24Core.isEmpty(arrData)) {
                    _this4.set("_arrCandidateData", _this4.prepareForDisplay(arrData));
                }
                _this4.set("_bLoading", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this4.set("_bServerError", true);
                    return;
                }
                // We swallow all 404 errors as it means that the candidate does not have
                // any next of kin info
                _this4.set("_arrCandidateData", []);
                _this4.set("_bLoading", false);
            }));
        },
        removeNextOfKin: function removeNextOfKin(objNextofKinData) {
            this.set("_bShowDeleteConfirm", true);
            this.set("_objNextofKinEditData", objNextofKinData);
        },
        setAddEditPopup: function setAddEditPopup(objNextofKinData) {
            var _this5 = this;

            this.clearAllErrorMessages();
            this.clearForm();

            this.set("_sHeaderPopup", this.get("_objCandidateProfileStringsService").getString("addNextofKin"));
            this.set("_bIsAdd", true);

            if (!a24Core.isEmpty(objNextofKinData)) {
                // set inputs based on id
                this.set("_sHeaderPopup", this.get("_objCandidateProfileStringsService").getString("editNextofKin"));
                this.set("_bIsAdd", false);
                this.set("_objNextofKinEditData", objNextofKinData);

                this.populateInputFields(objNextofKinData);
            }

            this.set("_bShowAddEditPopup", true);
            if (!a24Core.isEmpty(this.get("setBreadCrumbAction"))) {
                this.get("setBreadCrumbAction")();
            }

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this5.set("_objOriginalData", _this5.getPlainFormValueObject());
                });
            });
        },
        populateInputFields: function populateInputFields(objNextofKinData) {
            this.get("objChildren.name").setValueFromOutside(objNextofKinData.name);
            this.get("objChildren.relationship").setValueFromOutside(objNextofKinData.relationship);
            this.get("objChildren.contact_number").setValueFromOutside(objNextofKinData.contact_number);
            this.get("objChildren.comment").setValueFromOutside(objNextofKinData.comment);

            if (!a24Core.isEmpty(objNextofKinData.address)) {
                this.get("objChildren")["address.address_line1"].setValueFromOutside(objNextofKinData.address.address_line1);
                this.get("objChildren")["address.address_line2"].setValueFromOutside(objNextofKinData.address.address_line2);
                this.get("objChildren")["address.city"].setValueFromOutside(objNextofKinData.address.city);
                this.get("objChildren")["address.post_code"].setValueFromOutside(objNextofKinData.address.post_code);
                this.get("objChildren")["address.province"].setValueFromOutside(objNextofKinData.address.province);

                var objSelectedCountry = this.get("_arrCountryDropdown").findBy("sValue", objNextofKinData.address.country_code);
                var objSelectedAddressType = this.get("_arrAddressTypeDropdown").findBy("sValue", objNextofKinData.address.type);

                if (!a24Core.isEmpty(objSelectedCountry)) {
                    this.set("_objSelectedCountry", Ember.copy(objSelectedCountry));
                }

                if (!a24Core.isEmpty(objSelectedAddressType)) {
                    this.set("_objSelectedAddressType", Ember.copy(objSelectedAddressType));
                }
            }
        },
        setErrorResponse: function setErrorResponse(sCode, objError, sSnackbarMessage) {
            if (!a24Core.isEmpty(objError) && !a24Core.isEmpty(objError.arrPath)) {
                var arrPath = objError.arrPath.length > 1 ? [objError.arrPath.join(".")] : objError.arrPath;

                if (sCode === "MAX_LENGTH") {
                    this.setResponseError(arrPath, "validationRestMaxLength");
                } else if (sCode === "MIN_LENGTH") {
                    this.setResponseError(arrPath, "validationRestMaxLength");
                } else if (sCode === "PATTERN") {
                    this.setResponseError(arrPath, "validationRestInvalidFormat");
                } else if (sCode === "ENUM_MISMATCH") {
                    this.setResponseError(arrPath, "validationRestUnsupportedValue");
                } else {
                    this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
                }
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }

            this.set("_bPopupLoading", false);
        },
        setSuccessResponse: function setSuccessResponse(sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bPopupLoading", false);
            this.set("_bShowAddEditPopup", false);
            this.set("_bShowDeleteConfirm", false);

            this.getNextOfKinDetails();
        },
        getPayload: function getPayload() {
            var objPayload = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());
            if (!this._addressHasValues()) {
                delete objPayload.address;
            }
            return objPayload;
        },

        actions: {
            onPopupDeleteConfirm: function onPopupDeleteConfirm() {
                var _this6 = this;

                this.set("_bPopupLoading", true);
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("removingNextOfKin"));
                this.get("objNextOfKinRestService").deleteNextOfKin(this, "_objCandidateProfileNextOfKinPromise", this.get("sCandidateId"), this.get("_objNextofKinEditData._id"), function () {
                    _this6.setSuccessResponse(_this6.get("_objCandidateProfileStringsService").getString("successRemoveNextOfKin"));
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    if (sCode === "404") {
                        _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objStringsService").getString("removeFailedRefreshPage"));
                    } else {
                        _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("failRemoveNextOfKin"));
                    }

                    _this6.set("_bPopupLoading", false);
                }));
            },
            onPopupClose: function onPopupClose() {
                if (this.get("_bPopupLoading")) {
                    return;
                }

                this.set("_bShowDeleteConfirm", false);
            },
            onPopupAddEditCancel: function onPopupAddEditCancel() {
                if (this.get("_bPopupLoading")) {
                    return;
                }
                // Check if the form has any unsaved data
                var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

                if (bDataMatches) {
                    this.set("_bShowAddEditPopup", false);
                } else {
                    this.set("_bShowUnsavedData", true);
                }
            },
            onPopupAddEditConfirm: function onPopupAddEditConfirm() {
                var _this7 = this;

                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    var objPayload = this.getPayload();

                    this.set("_bPopupLoading", true);
                    if (this.get("_bIsAdd")) {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("creatingNextOfKin"));
                        this.get("objNextOfKinRestService").createNextOfKin(this, "_objCandidateProfileNextOfKinPromise", this.get("sCandidateId"), objPayload, function () {
                            _this7.setSuccessResponse(_this7.get("_objCandidateProfileStringsService").getString("successAddNextOfKin"));
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                            _this7.setErrorResponse(sCode, objError, _this7.get("_objCandidateProfileStringsService").getString("failAddNextOfKin"));
                        }));
                    } else {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("updatingNextOfKin"));
                        this.get("objNextOfKinRestService").updateNextOfKin(this, "_objCandidateProfileNextOfKinPromise", this.get("sCandidateId"), this.get("_objNextofKinEditData._id"), objPayload, function () {
                            _this7.setSuccessResponse(_this7.get("_objCandidateProfileStringsService").getString("successUpdateNextOfKin"));
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                            if (sCode === "404") {
                                _this7.get("_objSnackbarService").showSnackbarMessage(_this7.get("_objStringsService").getString("updateFailedRefreshPage"));
                                _this7.set("_bPopupLoading", false);
                            } else {
                                _this7.setErrorResponse(sCode, objError, _this7.get("_objCandidateProfileStringsService").getString("failUpdateNextOfKin"));
                            }
                        }));
                    }
                }
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowAddEditPopup", false);
                this.set("_bShowUnsavedData", false);
            }
        }
    });
});
define("a24-ember-candidate-profile/components/candidate-emails", ["exports", "a24-ember-candidate-profile/templates/components/candidate-emails", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element"], function (exports, _candidateEmails, _didRenderChangesMixin, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateEmails.default,
        // Public Vars
        objSettingsRestService: null,
        objEmailRestService: null,
        sCandidateId: null,

        // Private vars
        _bLoading: true,
        _bPopupDeleteLoading: false,
        _bPopupActionLoading: false,
        _bAllowAddEdit: true,
        _bShowAddPopup: false,
        _bShowDeletePopup: false,
        _bShowActionPopup: false,
        _bShowUnsavedData: false,

        _sEmailId: null,
        _sAction: null,
        _sActionText: null,
        _sHeaderPopup: null,

        _objEmailGetPromise: null,
        _objEmailCreatePromise: null,
        _objEmailDeletePromise: null,
        _objEmailActionPromise: null,

        _objSpecialAddPopupAction: null,

        _objOriginalData: null,

        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objEnumsService: Ember.inject.service("a24-enums"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objSnackbarService: Ember.inject.service("snackbar"),

        _arrDisplayEmails: null,
        _arrEmailStatusOrder: null,
        _arrHeaderActions: null,

        _bServerError: false,
        _arrReloadActions: null,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.getEmailAddresses();
                }
            }]);

            if (!this.get("objSettingsRestService.objSettings.profile_editable")) {
                this.set("_bAllowAddEdit", false);
            }

            this.set("_sHeaderPopup", this.get("_objStringsService").getString("addEmail"));

            if (this.get("_bAllowAddEdit")) {
                if (!this.get("_bIsCandidateContext")) {
                    this.set("_arrHeaderActions", [{
                        sTitle: this.get("_objStringsService").getString("add"),
                        sIcon: "add",
                        executeCardAction: function executeCardAction() {
                            _this.setAddPopup();
                        }
                    }]);
                }
            }

            this.set("_objSpecialAddPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.popupAddCancel();
                }
            });

            var objValidationConfig = {};

            objValidationConfig["objChildren.label"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.email"] = function (objMixin) {
                a24Validation.addMinLength(objMixin, 3);
                a24Validation.addMaxLength(objMixin, 100);
                a24Validation.addRequired(objMixin, true);
            };

            this.set("objChildConfig", objValidationConfig);

            this.set("_arrEmailStatusOrder", ["primary", "verified", "unverified"]);

            this.set("_arrEmailTypeDropdown", this.get("_objEnumsService").getList("emailTypes"));

            this.getEmailAddresses();
        },

        _bIsCandidateContext: Ember.computed("objEmailRestService.sContext", function () {
            return this.get("objEmailRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE");
        }),

        getEmailAddresses: function getEmailAddresses() {
            var _this2 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);

            var sCandidateId = this.get("sCandidateId");

            this.get("objEmailRestService").getEmails(this, "_objEmailGetPromise", sCandidateId, function (arrData) {
                if (!a24Core.isEmpty(arrData)) {
                    _this2.set("_arrDisplayEmails", _this2.prepareForDisplay(arrData));
                } else {
                    _this2.set("_arrDisplayEmails", []);
                }
                _this2.set("_bLoading", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this2.set("_bServerError", true);
                    return;
                }
                if (sCode === "404" || sCode === "403") {
                    if (!a24Core.isEmpty(_this2.get("onGetEmailsFailure"))) {
                        _this2.get("onGetEmailsFailure")(sCode);
                        return;
                    }
                }
                // Clear data
                _this2.set("_arrDisplayEmails", []);
                _this2.set("_bLoading", false);
            }));
        },
        prepareForDisplay: function prepareForDisplay(arrEmails) {
            var _this3 = this;

            arrEmails.sort(function (objA, objB) {
                // Get the index of the email status order
                var iProfIndexA = _this3.get("_arrEmailStatusOrder").indexOf(objA.status);
                var iProfIndexB = _this3.get("_arrEmailStatusOrder").indexOf(objB.status);

                // Sort items based on email status order
                if (iProfIndexA < iProfIndexB) {
                    return -1;
                } else if (iProfIndexA > iProfIndexB) {
                    return 1;
                }

                if (objA.email < objB.email) {
                    return -1;
                } else if (objA.email > objB.email) {
                    return 1;
                }
                return 0;
            });

            var objThis = this;
            var funcCardActionPrimaryExecute = function funcCardActionPrimaryExecute(sId) {
                return function () {
                    objThis.setPrimaryEmail(sId);
                };
            };
            var funcCardActionVerifyExecute = function funcCardActionVerifyExecute(sId) {
                return function () {
                    objThis.resendVerificationEmail(sId);
                };
            };
            var funcCardActionRemoveExecute = function funcCardActionRemoveExecute(sId) {
                return function () {
                    objThis.removeEmail(sId);
                };
            };

            var arrItems = [];
            // Create items for display on card.
            for (var i = 0; i < arrEmails.length; i++) {

                var objEmail = arrEmails[i];

                var arrActions = [];
                if (this.get("_bAllowAddEdit")) {
                    if (objEmail.status === "verified") {
                        if (!this.get("_bIsCandidateContext")) {
                            arrActions.push({
                                sTitle: this.get("_objStringsService").getString("makePrimary"),
                                sIcon: "star_border",
                                executeCardAction: funcCardActionPrimaryExecute(objEmail._id)
                            });
                        }
                        arrActions.push({
                            sTitle: this.get("_objStringsService").getTokenString("removeItem", this.get("_objStringsService").getString("email")),
                            sIcon: "delete",
                            executeCardAction: funcCardActionRemoveExecute(objEmail._id)
                        });
                    } else if (objEmail.status === "unverified") {
                        arrActions.push({
                            sTitle: this.get("_objCandidateProfileStringsService").getString("resendEmailVerify"),
                            sIcon: "email",
                            executeCardAction: funcCardActionVerifyExecute(objEmail._id)
                        });
                        arrActions.push({
                            sTitle: this.get("_objStringsService").getTokenString("removeItem", this.get("_objStringsService").getString("email")),
                            sIcon: "delete",
                            executeCardAction: funcCardActionRemoveExecute(objEmail._id)
                        });
                    }
                }

                var sHtmlLabel = a24Core.getSafeStringLink(Ember, objEmail.email, objEmail.email, "email");

                var sIcon = a24.getIconForEmail(objEmail.label);

                arrItems.push({
                    sLabel: sHtmlLabel,
                    sIcon: sIcon,
                    sExtra: this.get("_objCandidateProfileEnumsService").getFrontendValue("emailStatus", objEmail.status),
                    arrActions: arrActions,
                    bExpanded: false
                });
            }

            return arrItems;
        },
        setAddPopup: function setAddPopup() {
            var _this4 = this;

            this.clearAllErrorMessages();
            this.clearForm();
            this.set("_bPopupLoading", false);

            this.set("_bShowAddPopup", true);
            if (!a24Core.isEmpty(this.get("setBreadCrumbAction"))) {
                this.get("setBreadCrumbAction")();
            }

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this4.set("_objOriginalData", _this4.getPlainFormValueObject());
                });
            });
        },
        setPrimaryEmail: function setPrimaryEmail(sEmailId) {
            this.set("_sEmailId", sEmailId);
            this.set("_sActionText", this.get("_objCandidateProfileStringsService").getString("makePrimaryPopupText"));
            this.set("_sAction", "make_primary");
            this.set("_bPopupActionLoading", false);
            this.set("_bShowActionPopup", true);
        },
        resendVerificationEmail: function resendVerificationEmail(sEmailId) {
            this.set("_sEmailId", sEmailId);
            this.set("_sActionText", this.get("_objCandidateProfileStringsService").getString("resendEmailVerifyPopupText"));
            this.set("_sAction", "resend_verification");
            this.set("_bPopupActionLoading", false);
            this.set("_bShowActionPopup", true);
        },
        removeEmail: function removeEmail(sEmailId) {
            this.set("_sEmailId", sEmailId);
            this.set("_bPopupDeleteLoading", false);
            this.set("_bShowDeletePopup", true);
        },
        popupAddCancel: function popupAddCancel() {
            if (this.get("_bPopupLoading")) {
                return;
            }
            // Check if the form has any unsaved data
            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowAddPopup", false);
            } else {
                this.set("_bShowUnsavedData", true);
            }
        },

        actions: {
            onPopupDeleteClose: function onPopupDeleteClose() {
                if (this.get("_bPopupDeleteLoading")) {
                    return;
                }
                this.set("_bShowDeletePopup", false);
            },
            onPopupDeleteConfirm: function onPopupDeleteConfirm() {
                var _this5 = this;

                var sEmailId = this.get("_sEmailId");

                this.set("_bPopupDeleteLoading", true);

                var sCandidateId = this.get("sCandidateId");

                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("removingItem", this.get("_objStringsService").getString("email")));

                this.get("objEmailRestService").deleteEmail(this, "_objEmailDeletePromise", sCandidateId, sEmailId, function () {
                    _this5.set("_bShowDeletePopup", false);

                    _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("successRemoveItem", _this5.get("_objStringsService").getString("email")));

                    _this5.getEmailAddresses();
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    _this5.set("_bPopupDeleteLoading", false);

                    if (sCode === "404" || sCode === "EMAIL_DELETE_FORBIDDEN") {
                        _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getString("removeFailedRefreshPage"));
                    } else {
                        _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getTokenString("failRemoveItem", _this5.get("_objStringsService").getString("email")));
                    }
                }));
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowAddPopup", false);
                this.set("_bShowUnsavedData", false);
            },
            onPopupActionClose: function onPopupActionClose() {
                if (this.get("_bPopupActionLoading")) {
                    return;
                }
                this.set("_bShowActionPopup", false);
            },
            onPopupActionConfirm: function onPopupActionConfirm() {
                var _this6 = this;

                var sEmailId = this.get("_sEmailId");
                var sAction = this.get("_sAction");
                var sCandidateId = this.get("sCandidateId");

                this.set("_bPopupActionLoading", true);

                if (sAction === "make_primary") {
                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("makingPrimaryEmail"));
                } else if (sAction === "resend_verification") {
                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objCandidateProfileStringsService").getString("resendVerifyEmail"));
                }

                this.get("objEmailRestService").actionEmail(this, "_objEmailActionPromise", sCandidateId, sEmailId, sAction, function () {
                    _this6.set("_bShowActionPopup", false);

                    if (sAction === "make_primary") {
                        _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("successMakePrimaryEmail"));
                    } else if (sAction === "resend_verification") {
                        _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("successResendVerifyEmail"));
                    }

                    _this6.getEmailAddresses();
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /* , sStatus, objErrorThrown, objError*/) {
                    _this6.set("_bPopupActionLoading", false);
                    if (sCode === "404") {
                        _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objStringsService").getString("updateFailedRefreshPage"));
                    } else if (sCode === "ALREADY_PRIMARY") {
                        _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("failEmailAlreadyPrimary"));
                    } else if (sCode === "NEEDS_VERIFICATION") {
                        _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("failEmailNotVerified"));
                    } else if (sCode === "ALREADY_VERIFIED") {
                        _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("failEmailAlreadyVerified"));
                    } else {
                        if (sAction === "make_primary") {
                            _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("failMakePrimaryEmail"));
                        } else if (sAction === "resend_verification") {
                            _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objCandidateProfileStringsService").getString("failResendVerifyEmail"));
                        }
                    }
                }));
            },
            onPopupAddConfirm: function onPopupAddConfirm() {
                var _this7 = this;

                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    this.set("_bPopupLoading", true);

                    var sCandidateId = this.get("sCandidateId");
                    var objData = this.getPlainFormValueObject();

                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objStringsService").getString("email")));

                    this.get("objEmailRestService").createEmail(this, "_objEmailCreatePromise", sCandidateId, objData, function () {
                        _this7.get("_objSnackbarService").showSnackbarMessage(_this7.get("_objStringsService").getTokenString("successAddItem", _this7.get("_objStringsService").getString("email")));

                        _this7.set("_bShowAddPopup", false);

                        _this7.getEmailAddresses();
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {

                        if (sCode === "PATTERN") {
                            _this7.setResponseError(objError.arrPath, "validationRestInvalidFormat");
                        } else if (sCode === "INVALID_TYPE") {
                            _this7.setResponseError(objError.arrPath, "validationRestUnsupportedValue");
                        } else if (sCode === "ALREADY_EXISTS") {
                            _this7.setResponseError(objError.arrPath, "validationEmailAlreadyExist", false, false, _this7.get("_objCandidateProfileStringsService"));
                        } else {
                            _this7.get("_objSnackbarService").showSnackbarMessage(_this7.get("_objStringsService").getTokenString("failAddItem", _this7.get("_objStringsService").getString("email")));
                        }

                        _this7.set("_bPopupLoading", false);
                    }));
                }
            },
            onPopupAddCancel: function onPopupAddCancel() {
                this.popupAddCancel();
            }
        }
    });
});
define("a24-ember-lib/helpers/format-date", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.formatDate = formatDate;


    /**
     * This function will return a formatted date
     *
     * @param arrParams - The array of params sent to the helper(See the first variables in the function for details)
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 09 Aug 2016
     */
    function formatDate(arrParams) {
        var sDate = arrParams[0]; //The date to format, either in DD MMMM YYYY HH:mm:ss ZZ or DD MMMM YYYY format
        var sTimezone = arrParams[1]; //The timezone to use
        var sFormat = arrParams[2]; //The format you want it back in(If null it will use the DD MMM YYYY format)
        var sFormattedDate = "";
        // If the date is empty, we skip and return empty string
        if (!a24Core.isEmpty(sDate)) {
            if (a24Core.isEmpty(sTimezone)) {
                sFormattedDate = "No Timezone";
            } else {
                if (a24Core.isEmpty(sFormat)) {
                    sFormat = a24Constants.DATE_FORMAT;
                }
                /**
                 * This checks if there is a ":" in the string. If not, it
                 * works on the assumption that it is a date in the "DD MMMM YYYY"
                 * format and will format it accordingly.
                 */
                var objDate = a24DateManager.createDate(sTimezone);
                if (sDate.indexOf(":") === -1) {
                    objDate.setDateFromBrowser(sDate, a24Constants.DATE_FORMAT_REST_FORMAT_DATE_ONLY);
                } else {
                    objDate.setDateFromRest(sDate);
                }

                if (sFormat === a24Constants.DATE_FORMAT) {
                    sFormattedDate = objDate.getDateFormat();
                } else if (sFormat === a24Constants.DATE_TIME_FORMAT) {
                    sFormattedDate = objDate.getDateTimeFormat();
                } else if (sFormat === a24Constants.DATE_TIME_DASH_FORMAT) {
                    sFormattedDate = objDate.getDateTimeDashFormat();
                } else if (sFormat === a24Constants.TIME_FORMAT) {
                    sFormattedDate = objDate.getTimeFormat();
                }
            }
        }
        return sFormattedDate;
    }

    exports.default = Ember.Helper.helper(formatDate);
});
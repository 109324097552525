define("a24-ember-staffshift-candidate-availability/services/a24-strings-staffshift-availability", ["exports", "a24-ember-lookup/services/a24-strings-base"], function (exports, _a24StringsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24StringsBase.default.extend({
        _objStrings: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.set("_objStrings", {
                //===== STRINGS START =====

                booked: "Booked",
                unavailable: "Unavailable",
                available: "Available",
                unknown: "Unknown",

                reportsTo: "Reports to:",
                descriptionOfPosition: "Description of position:",
                staffingInstructions: "Staffing Instructions:",
                agency: "Agency:",
                availability: "Availability",

                calendar: "Calendar",

                updatingAvailability: "Updating availability...",
                availabilityUpdated: "Successfully updated Availability",
                availabilityPartiallyUpdated: "Availability only partially updated. Please refresh the page to see the latest details",

                downloadingTimesheet: "Downloading Timesheet...",
                successfullyDownloadedTimesheet: "Successfully downloaded the timesheet",
                failedDownloadedTimesheet: "Could not downloaded the timesheet",

                emailSentSuccess: "Email sent successfully",
                emailSentFailure: "Email could not be sent at this moment",

                shiftConfirmation: "Shift Confirmation",
                sendShiftConfirmation: "Send Shift Confirmation",
                messageContent: "Message content",
                messagePreview: "Message Preview:",
                shiftConfirmationDetails: "Shift Confirmation Details:",
                notificationSendFailed: "Failed to send notification.",
                send: "Send",
                shiftDetailsFailure: "Failed to load shift details, shift notification will be unavailable",
                bulkAvailabilityUpdate: "Bulk Availability Update",
                preview: "Preview",
                repeatson: "Repeats on",
                availabilityUpdateWarning: "Please note that because bulk update was used that it might affect calendar days currently not in view.",
                bulkUpdate: "Bulk Update",
                perhour: "Per Hour",
                directions: "Directions",
                clientAddress: "Client Address",
                shiftReferenceNo: "Shift Reference No",
                payRates: "Pay Rates",
                totalTotalPayAmount: "Total Pay Amount",
                breaksNotTakenIntoAccount: "Breaks not taken into account for above total pay",
                total: "Total",
                pay: "Pay",
                totalPay: "Total Pay",
                sendTimesheet: "Send Timesheet",
                //===== STRINGS END   =====

                //===== SAFE STRINGS START =====

                notificationSendMessage: "We will attempt to send this notification to the candidate. To see if the message was sent you can check the activity created on the candidate profile and view the status of the message from there."
                //===== SAFE STRINGS END   =====
            });
        }
    });
});
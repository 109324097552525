define("a24-ember-candidate-profile/components/candidate-social-links", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-social-links", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _candidateSocialLinks, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateSocialLinks.default,
        sCandidateId: null,
        objCandidateSocialLinksService: null,

        _bLoading: true,
        _bPopupLoading: false,
        _bPopupDeleteLoading: false,
        _bServerError: false,
        _bShowAddEditPopup: false,
        _bShowDeleteConfirm: false,
        _bShowUnsavedData: false,

        _sHeaderPopup: "",

        _arrReloadActions: null,
        _arrHeaderActions: null,
        _arrSocialLinks: null,
        _arrSocialLinkTypes: null,
        _arrSocialLinkTypesAddEditDropdown: null,

        _objSpecialAddEditPopupAction: null,
        _objSocialLinksGetPromise: null,
        _objSocialLinkTypesGetPromise: null,
        _objOriginalData: null,
        _objSocialLinkEditData: null,
        _objSocialLinksDeletePromise: null,
        _objSocialLinksCreatePromise: null,
        _objSocialLinksUpdatePromise: null,

        _objSocialLinkTypeRestService: Ember.inject.service("rest-social-link-types"),

        _objUserSession: Ember.inject.service("user-session"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objEnumsStaffshiftCore: Ember.inject.service("a24-enums-staffshift-core"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.doServiceCalls(true);
                }
            }]);

            this.set("_objSpecialAddEditPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._onPopupAddEditCancel();
                }
            });

            this.set("_arrHeaderActions", [{
                sTitle: this.get("_objStringsService").getString("add"),
                sIcon: "add",
                executeCardAction: function executeCardAction() {
                    _this.setAddEditPopup();
                }
            }]);

            // Set up the form children
            this.set("objChildConfig", {
                // social type drop down
                "objChildren.name": function objChildrenName(objMixin) {
                    // This field is required
                    a24Validation.addRequired(objMixin, true);
                },
                // description
                "objChildren.type": function objChildrenType(objMixin) {
                    // This fields has a min Length of 1
                    a24Validation.addRequired(objMixin, true);
                }
            });

            this.doServiceCalls(true);
        },

        doServiceCalls: function doServiceCalls(bInitial) {
            var _this2 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);

            var sCandidateId = this.get("sCandidateId");

            var objPromiseSocialLinks = this.get("objCandidateSocialLinksService").getSocialLinks(this, "_objSocialLinksGetPromise", sCandidateId, function () {}, // handled in RSVP
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this2.set("_bServerError", true);
                    return;
                }
                // Clear data
                _this2.set("_arrSocialLinks", []);
            }));

            var objServiceCalls = {
                _objRestSocialLinksProperty: objPromiseSocialLinks
            };

            var arrRequiredResponses = ["_objRestSocialLinksProperty"];

            if (a24Core.isEmpty(this.get("_arrSocialLinkTypes")) && bInitial) {
                var objPromiseSocialLinkTypes = this.get("_objSocialLinkTypeRestService").getSocialLinkTypes(this, "_objSocialLinkTypesGetPromise", function () {}, // handled in RSVP
                a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                        _this2.set("_bServerError", true);
                        return;
                    }
                    // Clear data
                    _this2.set("_arrSocialLinkTypes", []);
                }));

                objServiceCalls._objRestSocialLinkTypesProperty = objPromiseSocialLinkTypes;
                arrRequiredResponses.push("_objRestSocialLinkTypesProperty");
            }

            Ember.copy(a24RSVP).configure(this, objServiceCalls, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objRestSocialLinksProperty) && !a24Core.isEmpty(objResponses._objRestSocialLinksProperty.objHeaders) && objResponses._objRestSocialLinksProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRestSocialLinkTypesProperty) && !a24Core.isEmpty(objResponses._objRestSocialLinkTypesProperty.objHeaders) && objResponses._objRestSocialLinkTypesProperty.objHeaders.statusText === "abort") {
                        return;
                    }

                    if (!a24Core.isEmpty(objResponses._objRestSocialLinkTypesProperty) && !a24Core.isEmpty(objResponses._objRestSocialLinkTypesProperty.objResponse)) {
                        var arrSocialLinkTypesDropdown = [];
                        var arrSocialLinkTypes = objResponses._objRestSocialLinkTypesProperty.objResponse;
                        _this2.set("_arrSocialLinkTypes", arrSocialLinkTypes);
                        for (var i = 0; i < arrSocialLinkTypes.length; i++) {
                            if (!arrSocialLinkTypes[i].disabled) {
                                arrSocialLinkTypesDropdown.push({
                                    sTitle: arrSocialLinkTypes[i].name,
                                    sValue: arrSocialLinkTypes[i]._id
                                });
                            }
                        }

                        _this2.set("_arrSocialLinkTypesAddEditDropdown", arrSocialLinkTypesDropdown);
                    }

                    if (!a24Core.isEmpty(objResponses._objRestSocialLinksProperty) && !a24Core.isEmpty(objResponses._objRestSocialLinksProperty.objResponse)) {
                        _this2.set("_arrSocialLinks", _this2.prepareForDisplay(objResponses._objRestSocialLinksProperty.objResponse));
                    } else {
                        _this2.set("_arrSocialLinks", []);
                    }

                    _this2.set("_bLoading", false);
                }
            }]);
        },
        prepareForDisplay: function prepareForDisplay(arrSocialLinks) {
            var _this3 = this;

            var arrDisplayedSocialLinks = [];
            var objThis = this;
            var arrSocialLinkTypes = this.get("_arrSocialLinkTypes");

            arrSocialLinks.forEach(function (objSocialLink) {
                // lookup icon and label for social type
                var objLookupData = null;

                for (var i = 0; i < arrSocialLinkTypes.length; i++) {
                    if (arrSocialLinkTypes[i]._id === objSocialLink.type) {
                        objLookupData = arrSocialLinkTypes[i];
                        break;
                    }
                }

                if (!a24Core.isEmpty(objLookupData)) {

                    var sLabel = "";
                    if (objSocialLink.name.startsWith("http:") || objSocialLink.name.startsWith("https:")) {
                        sLabel = a24Core.getSafeStringLink(Ember, objSocialLink.name, objSocialLink.name, "externalLink");
                    } else if (objSocialLink.name.startsWith("www.")) {
                        sLabel = a24Core.getSafeStringLink(Ember, objSocialLink.name, "//" + objSocialLink.name, "externalLink");
                    } else {
                        sLabel = objSocialLink.name;
                    }

                    var sIcon = _this3.get("_objEnumsStaffshiftCore").getMaterialSvgIcon(objLookupData.icon);

                    // Add an actvity entry
                    var objSocialLinkItem = {
                        sIcon: sIcon,
                        sLabel: sLabel,
                        sExtra: objLookupData.name
                    };

                    var funcCardActionEditExecute = function funcCardActionEditExecute(objSocialLink) {
                        return function () {
                            objThis.setAddEditPopup(objSocialLink);
                        };
                    };
                    var funcCardActionRemoveExecute = function funcCardActionRemoveExecute(objSocialLink) {
                        return function () {
                            objThis.removeSocialLink(objSocialLink);
                        };
                    };

                    objSocialLinkItem.arrActions = [{
                        sTitle: _this3.get("_objStringsService").getString("edit"),
                        sIcon: "edit",
                        executeCardAction: funcCardActionEditExecute(objSocialLink)
                    }, {
                        sTitle: _this3.get("_objStringsService").getString("remove"),
                        sIcon: "delete",
                        executeCardAction: funcCardActionRemoveExecute(objSocialLink)
                    }];

                    arrDisplayedSocialLinks.push(objSocialLinkItem);
                }
            });
            return arrDisplayedSocialLinks;
        },
        setAddEditPopup: function setAddEditPopup(objSocialLink) {
            var _this4 = this;

            this.clearAllErrorMessages();
            this.clearForm();

            this.set("_sHeaderPopup", this.get("_objStringsService").getTokenString("addItem", this.get("_objCandidateProfileStringsService").getString("socialLink")));

            this.set("_bIsAdd", true);

            if (!a24Core.isEmpty(objSocialLink)) {
                // set inputs based on id
                this.set("_sHeaderPopup", this.get("_objStringsService").getTokenString("editItem", this.get("_objCandidateProfileStringsService").getString("socialLink")));
                this.set("_bIsAdd", false);
                this.set("_objSocialLinkEditData", objSocialLink);

                this.populateInputFields(objSocialLink);
            }

            this.set("_bShowAddEditPopup", true);
            if (!a24Core.isEmpty(this.get("setBreadCrumbAction"))) {
                this.get("setBreadCrumbAction")();
            }

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this4.set("_objOriginalData", _this4.getPlainFormValueObject());
                });
            });
        },
        populateInputFields: function populateInputFields(objSocialLink) {
            var objSelectedSocialLinkType = this.get("_arrSocialLinkTypesAddEditDropdown").findBy("sValue", objSocialLink.type);
            if (!a24Core.isEmpty(objSelectedSocialLinkType)) {
                this.set("_objSelectedSocialLinkType", Ember.copy(objSelectedSocialLinkType, true));
            }

            this.get("objChildren.name").setValueFromOutside(objSocialLink.name);
        },
        removeSocialLink: function removeSocialLink(objSocialLink) {
            this.set("_bShowDeleteConfirm", true);
            this.set("_objSocialLinkEditData", objSocialLink);
        },
        setErrorResponse: function setErrorResponse(sCode, objError, sSnackbarMessage) {
            if (sCode === "PATTERN") {
                this.setResponseError(objError.arrPath, "validationRestInvalidFormat");
            } else {
                this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            }

            this.set("_bPopupLoading", false);
            this.set("_bPopupDeleteLoading", false);
        },
        setSuccessResponse: function setSuccessResponse(sSnackbarMessage) {
            this.get("_objSnackbarService").showSnackbarMessage(sSnackbarMessage);
            this.set("_bPopupLoading", false);
            this.set("_bPopupDeleteLoading", false);
            this.set("_bShowAddEditPopup", false);
            this.set("_bShowDeleteConfirm", false);

            this.doServiceCalls(false);
        },
        getPayload: function getPayload() {
            return a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());
        },

        _onPopupAddEditCancel: function _onPopupAddEditCancel() {
            if (this.get("_bPopupLoading")) {
                return;
            }
            // Check if the form has any unsaved data
            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowAddEditPopup", false);
            } else {
                this.set("_bShowUnsavedData", true);
            }
        },
        actions: {
            onPopupAddEditCancel: function onPopupAddEditCancel() {
                this._onPopupAddEditCancel();
            },
            onPopupAddEditConfirm: function onPopupAddEditConfirm() {
                var _this5 = this;

                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    var objPayload = this.getPayload();

                    this.set("_bPopupLoading", true);
                    if (this.get("_bIsAdd")) {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objCandidateProfileStringsService").getString("socialLink")));
                        this.get("objCandidateSocialLinksService").createSocialLink(this, "_objSocialLinksCreatePromise", this.get("sCandidateId"), objPayload, function () {
                            _this5.setSuccessResponse(_this5.get("_objStringsService").getTokenString("successAddItem", _this5.get("_objCandidateProfileStringsService").getString("socialLink")));
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                            _this5.setErrorResponse(sCode, objError, _this5.get("_objStringsService").getTokenString("failAddItem", _this5.get("_objCandidateProfileStringsService").getString("socialLink")));
                        }));
                    } else {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("updatingItem", this.get("_objCandidateProfileStringsService").getString("socialLink")));

                        this.get("objCandidateSocialLinksService").updateSocialLink(this, "_objSocialLinksUpdatePromise", this.get("sCandidateId"), this.get("_objSocialLinkEditData._id"), objPayload, function () {
                            _this5.setSuccessResponse(_this5.get("_objStringsService").getTokenString("successUpdateItem", _this5.get("_objCandidateProfileStringsService").getString("socialLink")));
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                            if (sCode === "404") {
                                _this5.get("_objSnackbarService").showSnackbarMessage(_this5.get("_objStringsService").getString("updateFailedRefreshPage"));
                                _this5.set("_bPopupLoading", false);
                            } else {
                                _this5.setErrorResponse(sCode, objError, _this5.get("_objStringsService").getTokenString("failUpdateItem", _this5.get("_objCandidateProfileStringsService").getString("socialLink")));
                            }
                        }));
                    }
                }
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowAddEditPopup", false);
                this.set("_bShowUnsavedData", false);
            },
            onPopupClose: function onPopupClose() {
                if (this.get("_bPopupDeleteLoading")) {
                    return;
                }

                this.set("_bShowDeleteConfirm", false);
            },
            onPopupDeleteConfirm: function onPopupDeleteConfirm() {
                var _this6 = this;

                this.set("_bPopupDeleteLoading", true);
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("removingItem", this.get("_objCandidateProfileStringsService").getString("socialLink")));
                this.get("objCandidateSocialLinksService").deleteSocialLink(this, "_objSocialLinksDeletePromise", this.get("sCandidateId"), this.get("_objSocialLinkEditData._id"), function () {
                    _this6.setSuccessResponse(_this6.get("_objStringsService").getTokenString("successRemoveItem", _this6.get("_objCandidateProfileStringsService").getString("socialLink")));
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    if (sCode === "404") {
                        _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objStringsService").getString("removeFailedRefreshPage"));
                    } else {
                        _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objStringsService").getTokenString("failRemoveItem", _this6.get("_objCandidateProfileStringsService").getString("socialLink")));
                    }

                    _this6.set("_bPopupDeleteLoading", false);
                }));
            }

        }
    });
});
define("a24-ember-staffshift-candidate-availability/services/rest-agency-advanced-search-agency-shift", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        /*
          Services
        */
        _objNavigation: Ember.inject.service("navigation"),
        _objUserSession: Ember.inject.service("user-session"),

        _sRestApiBaseUrl: Ember.computed.readOnly("_ENV.sRestApiBaseUrl"),

        _ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * Used to fetch shifts data from the server for an agency
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param objFilterQuery - Query to filter results on
         * @param objSortQuery - Query to sort results on
         * @param iPage - Page to start query on
         * @param iPerPage - Results per page to us
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Ruan Naude <ruan.naude@a24group.com>
         * @since  22 May 2020
         */
        getShifts: function getShifts(objCaller, sProperty, objFilterQuery, objSortQuery, iPage, iPerPage, funcSuccess, objFailure) {

            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + this.get("_objUserSession").getUserContextReference().mId + "/advanced-search/agency-shift");

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, Ember.get(this, "_objUserSession"), Ember.get(this, "_objNavigation"), "POST", Ember.get(this, "_sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, a24RestCallHelper.convertHooksToPostBodyV2(objFilterQuery, null, objSortQuery, iPage, iPerPage)));
        }
    });
});
define("a24-ember-candidate-profile/services/a24-strings-candidate-profile", ["exports", "a24-ember-lookup/services/a24-strings-base"], function (exports, _a24StringsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24StringsBase.default.extend({
        _objStrings: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.set("_objStrings", {
                //===== STRINGS START =====

                distance: "Distance",
                distanceIsEmpty: "Distance is empty",
                isWithin: "Is within",
                km: "km",
                mi: "mi",
                kilometers: "Kilometers",
                miles: "Miles",
                client: "Client",
                enterClientName: "Enter Client name...",
                enterAddress: "Enter address...",
                customLocation: "Custom Location",
                replyWith: "Reply With",

                complianceRules: "Compliance Rules",

                city: "City",
                province: "Province",
                postCode: "Post Code",
                status: "Status",
                responsibleConsultant: "Responsible Consultant",
                userStatus: "User Status",
                lastContactedAt: "Last Contacted At",
                buildQuery: "Build query",
                buildComplianceRuleCondition: "Build Compliance Rule Condition",
                acknowledgedPolicies: "Acknowledged Policies",
                firstTimeToStatus: "First Time To Status",
                lastTimeToStatus: "Last Time To Status",
                externalDocs: "External Docs",
                version: "Version",
                withinDateRange: "Within date range",
                uploadedWithin: "Uploaded within",
                expiryWithin: "Expiry within",
                setupComplete: "Setup Complete",
                emailNotVerified: "Email not Verified",
                inviteSent: "Invite Sent",
                userRegNotInitiated: "User Reg not Initiated",
                enabled: "Enabled",

                tagManagement: "Tag Management",
                tag: "Tag",
                tags: "Tags",
                tagType: "Tag Type",
                type: "Type",
                typeDelete: "Type 'DELETE' to continue...",
                typeDeleteError: "Please type 'DELETE' to continue",
                areYouSureDelete: "Are you sure you want to delete this tag? Deleting the tag cannot be undone. " + "Remember if you had entities linked to the tags, then you will no longer have them curated",
                tagAlreadyExists: "The tag you are trying to create already exists",

                cannotDoQueryForAddress: "Could not query distance for this address since the address does not have coordinates. Please enter the coordinates manually below.",
                addressSuggestInputLoadError: "The custom location suggest functionality is currently unavailable. Please try again later or manually supply the coordinates below.",
                longitudeInputError: "Longitude should be a number between 180 and -180",
                latitudeInputError: "Latitude should be a number between 90 and -90",

                notificationsPreferences: "Notifications Preferences",

                expiresIn: "Expires in",
                profilePicture: "Profile Picture",
                uploadingProfilePic: "Uploading Profile Picture...",
                uploadProfilePicFailure: "Failed to upload profile picture. Please try again in a few minutes",
                uploadProfilePicSuccess: "Successfully uploaded profile picture",
                imagePreparing: "Preparing Image...",
                changeProfilePic: "Change profile picture",
                avatarFailedToRetrieve: "Failed to retrieve profile picture. Retry again in a few minutes",
                removingProfilePic: "Removing profile picture...",
                removeProfilePicSuccess: "Successfully removed profile picture",
                removeProfilePicFailure: "Failed to remove profile picture",
                removeAvatar: "Remove profile picture",
                removeCandidateAvatarConfirmation: "Are you sure you want to remove this profile picture?",
                removePermissionError: "You do not have permission to remove the profile picture of the candidate",
                reloadImage: "Retrieve profile picture",

                totalYearsOfExperience: "Total years of experience",
                positionHeld: "Position Held",
                lessThanAMonth: "Less than a month",

                openOrderInNewTab: "Open Order in new tab",

                played: "Played",
                paused: "Paused",
                keyword: "Keyword",

                for: "For",
                inThe: "In the",
                thatIs: "That is",

                fax: "Fax",
                character: "Character",
                professional: "Professional",
                knownFrom: "Known From",
                knownTo: "Known To",
                profession: "Profession",
                phone: "Phone",
                referenceType: "Reference Type",
                referee: "Referee",
                nameOfReferee: "Name of Referee",
                refereeName: "Referee Name",
                employerDetails: "Employer Details",
                refreeDetails: "Referee Details",
                employerAddress: "Employer Address",
                employerNameCompany: "Employer Name (Company)",
                characterReference: "Character Reference",
                profesionalReference: "Professional Reference",

                profRef: "Prof Ref",
                charRef: "Char Ref",
                references: "References",
                nominatedReferences: "Nominated References",
                numberOfRefereesToProvide: "Always provide at least two professional references, one from each of your two most recent employers. By professional we mean actual employers and not colleagues. It is important you provide us with work email addresses for each referees as far as possible.",

                entry: "Entry",
                shortName: "Short Name",
                entryStatus: "Entry Status",
                obtainedAt: "Obtained At",
                expiresOn: "Expires On",
                validFrom: "Valid From",
                registrationBodyEntry: "Registration Body Entry",
                professionalRegistration: "Professional Registration",
                registrationEntries: "Registration Entries",
                profRegEntries: "Prof Reg Entry",
                registrationNo: "Registration No.",
                registrationNumber: "Registration Number",
                registrationBody: "Registration Body",
                reference: "Reference",
                editProfessionalRegistrationHeader: "Edit Professional Registration - {0}",
                addProfessionalRegistrationHeader: "Add Professional Registration - {0}",
                professionalRegistrationHeader: "Professional Registration - {0}",
                validationRegistrationBodyEntryAlreadyExist: "Registration body entry is already assigned to the professional registration",
                validationRegistrationBodyEntryNotExist: "Registration body entry selected does not exist or is disabled",
                registrationBodyEntryView: "Registration Body Entry View",

                unknown: "Unknown",
                externalPortfolio: "External Portfolio",
                addEmployer: "Add Employer",
                provideYourPosition: "Provide Your Position",
                addDuration: "Add Duration",
                employer: "Employer",
                jobFamily: "Job Family",
                jobTitle: "Job Title",
                selectYourPosition: "Select your Position",
                selectYourJobFamily: "Select your Job Family",
                selectYourJobTitle: "Select your Job Title",

                document: "Document",
                uploaded: "Uploaded",
                rejected: "Rejected",
                rejectedBy: "Rejected By",
                verifiedBy: "Verified By",
                downloadingDocument: "Downloading document...",
                startingDownload: "Starting download...",
                downloadDocumentFailed: "Failed to download document",

                uploadingDocument: "Uploading document...",
                uploadDocumentSuccess: "Successfully uploaded document",
                uploadDocumentFailed: "Failed to upload document",

                as: "As",
                wasFor: "Was for",
                last: "Last",
                month: "Month",
                months: "Months",
                year: "Year",
                years: "Years",
                atLeast: "At least",
                anyAmountOf: "Any amount of",
                withExperienceWithin: "With experience within",

                oops: "Oops...",
                registrationCallMade: "Registration Call Made",
                registrationCallReceived: "Registration Call Received",
                registrationEmailSent: "Registration Email Sent",
                registrationEmailReceived: "Registration Email Received",
                leftVoicemail: "Left Voicemail",
                callMadeNoAnswer: "Call Made No Answer",
                interviewBooked: "Interview Booked",
                initialScreeningCompleted: "Initial Screening Completed",
                initialScreeningPaused: "Initial Screening Paused",
                applicationPackSent: "Application Pack Sent",
                registrationTextSent: "Registration Text Sent",

                activityType: "Activity Type",
                selectActivity: "Select an activity",
                activity: "Activity",
                activities: "Activities",
                activityDate: "Activity Date",

                mobile: "Mobile",
                workFax: "Work Fax",
                homeFax: "Home Fax",
                additional: "Additional",

                divorced: "Divorced",
                married: "Married",
                separated: "Separated",
                single: "Single",
                widow: "Widow",
                widower: "Widower",

                bahai: "Bahai",
                buddhist: "Buddhist",
                christian: "Christian",
                hindu: "Hindu",
                jain: "Jain",
                jewish: "Jewish",
                muslim: "Muslim",
                noReligion: "No Religion",
                sikh: "Sikh",
                protestant: "Protestant",
                romanCatholic: "Roman Catholic",
                preferNotToSay: "Prefer not to say",

                postalAddress: "Postal Address",
                homeAddress: "Home Address",
                alternativeAddress: "Alternative Address",

                verified: "Verified",
                unverified: "Unverified",
                partiallyVerified: "Partially Verified",
                active: "Active",

                whiteBritish: "White British",
                whiteIrish: "White Irish",
                whiteOther: "White Other",
                mixedWhiteAndBlackCaribbean: "Mixed White and Black Caribbean",
                mixedWhiteAndBlackAfrican: "Mixed White and Black African",
                mixedWhiteAndAsian: "Mixed White and Asian",
                mixedOther: "Mixed Other",
                asianBritish: "Asian British",
                asianIndian: "Asian Indian",
                asianPakistani: "Asian Pakistani",
                asianBangladeshi: "Asian Bangladeshi",
                tamil: "Tamil",
                asianOther: "Asian Other",
                blackBritish: "Black British",
                blackCaribbean: "Black Caribbean",
                blackAfrican: "Black African",
                blackOther: "Black Other",
                chinese: "Chinese",
                blackEuropean: "Black European",

                mr: "Mr",
                mrs: "Mrs",
                ms: "Ms",
                miss: "Miss",
                dr: "Dr",
                professor: "Professor",
                baron: "Baron",
                baroness: "Baroness",
                brigadier: "Brigadier",
                canon: "Canon",
                captain: "Captain",
                duchess: "Duchess",
                duke: "Duke",
                esq: "Esq",
                father: "Father",
                hon: "Hon",
                inspector: "Inspector",
                lady: "Lady",
                lord: "Lord",
                ltCol: "Lt col",
                major: "Major",
                mostRever: "Most rever",
                pastor: "Pastor",
                rabbi: "Rabbi",
                revDr: "Rev dr",
                reverend: "Reverend",
                rtReveren: "Rt reveren",
                sir: "Sir",
                sister: "Sister",
                squadronL: "Squadron l",
                wgCdr: "Wg cdr",

                bisexual: "Bisexual",
                gayMan: "Gay Man",
                gayWomen: "Gay Woman/Lesbian",
                straight: "Heterosexual/Straight",

                intersex: "Intersex",
                transgender: "Transgender",
                transsexual: "Transsexual",

                limited: "Limited",
                native: "Native",
                readWriteSpeak: "Read Write & Speak",

                own: "Own",
                public: "Public",

                permanent: "Permanent",
                contract: "Contract",
                agencyWork: "Agency Work",
                selfEmployed: "Self Employed",
                partTime: "Part Time",
                studentFullTime: "Student - Full Time",
                studentPlacement: "Student - Placement",
                workType: "Work Type",
                institute: "Institute",

                currentStatus: "Current Status",
                currentStatusSince: "Current Status Since",
                firstStatus: "First Status",
                firstStatusSince: "First Status Since",
                linkedToGroupSince: "Linked To Group Since",
                linkedToAgencySince: "Linked To Agency Since",

                idNumber: "Id Number",
                contactNumbers: "Contact Numbers",
                contactNumber: "Contact Number",
                noNumber: "No number",
                viewCandidate: "View Candidate",
                personalInfo: "Personal Info",
                profileNote: "Profile Note",
                note: "Note",
                miscDetails: "Miscellaneous Details",
                equalityMonitoring: "Equality Monitoring",
                maritalStatus: "Marital Status",
                religion: "Religion",
                ethnicOrigin: "Ethnic Origin",
                sexualOrientation: "Sexual Orientation",
                genderIdentity: "Gender Identity",
                ageGroup: "Age Group",

                payrollNumber: "Payroll Number",
                linkedInfo: "Linked Info",
                emailOptOut: "Email Opt Out",
                telOptOut: "Tel Opt Out",
                employmentReferenceNumber: "Employment Reference Number",

                relationship: "Relationship",
                addressType: "Address Type",
                contactType: "Contact Type",
                number: "Number",
                emailType: "Email Type",

                updateStatus: "Update Status",
                movedToStatus: "Moved to status",
                reason: "Reason",
                sinceDate: "Since Date",
                changedBy: "Changed By",
                viewHistory: "View History",

                consultant: "Consultant",
                consultants: "Consultants",
                addConsultant: "Add Consultant",
                editConsultant: "Edit Consultant",
                assignedUser: "Assigned User",
                consultantRole: "Consultant Role",
                consultantType: "Consultant Type",

                transportType: "Transport Type",

                languages: "Languages",
                knownAs: "Known As",
                middleName: "Middle Name",
                maidenName: "Maiden Name",

                candidateProfile: "Candidate Profile",

                updatingEqualityDetails: "Updating Equality details...",
                equalityDetailsUpdateSuccess: "Equality details updated successfully",
                equalityDetailsUpdateFailure: "Unexpected error! Failed to update",

                nextOfKin: "Next of Kin",
                addNextofKin: "Add Next of Kin",
                editNextofKin: "Edit Next of Kin",
                failAddNextOfKin: "Failed to create next of kin",
                failUpdateNextOfKin: "Failed to update next of kin",
                failRemoveNextOfKin: "Failed to delete next of kin",
                creatingNextOfKin: "Creating next of kin...",
                updatingNextOfKin: "Updating next of kin...",
                removingNextOfKin: "Deleting next of kin...",
                successUpdateNextOfKin: "Successfully updated next of kin",
                successAddNextOfKin: "Successfully created next of kin",
                successRemoveNextOfKin: "Successfully deleted next of kin",

                typeHereToSearhBelow: "Type here to search the items below...",

                position: "Position",
                positionType: "Position Type",
                jobFamilyName: "Job Family Name",
                jobTitleName: "Job Title Name",
                startDate: "Start Date",
                endDate: "End Date",
                responsibilities: "Responsibilities",
                leavingReason: "Leaving Reason",
                current: "Current",
                missingData: "Missing Data",

                otherNames: "Other Names",
                addressDetails: "Address Details",
                contactDetails: "Contact Details",
                failedAddAddressMaxItems: "Another address of this type cannot be added",
                addressTypeUpdateForbidden: "Address Type can not be changed",

                failAddLang: "Failed to create language",
                failUpdateLang: "Failed to update language",
                failRemoveLang: "Failed to delete language",
                creatingLang: "Creating language...",
                updatingLang: "Updating language...",
                removingLang: "Deleting language...",
                successUpdateLang: "Successfully updated language",
                successAddLang: "Successfully created language",
                successRemoveLang: "Successfully deleted language",
                addLanguage: "Add Language",
                editLanguage: "Edit Language",
                languageName: "Language Name",
                languageProficiency: "Language Proficiency",

                coordinates: "Coordinates",
                county: "County",

                validationEmailAlreadyExist: "Email already exists on the System",
                validationStatusDisabled: "Status is disabled. Please refresh the page and try again",
                validationCandidateStatusChangeExist: "This status is already assigned to the Candidate. Please refresh the page and try again",

                addFailedRefreshPage: "Failed to add item.\nThe data might have changed. Please refresh the page and try again",

                makingPrimaryEmail: "Setting email to primary...",
                resendVerifyEmail: "Resending email verification...",

                successMakePrimaryEmail: "Successfully set email to primary",
                successResendVerifyEmail: "Successfully resent email verification",

                failMakePrimaryEmail: "Failed to set email to primary",
                failResendVerifyEmail: "Failed to resend email verification",

                failEmailAlreadyPrimary: "Email is already a primary email.\nThe data might have changed. Please refresh the page and try again",
                failEmailAlreadyVerified: "Email is already verified.\nThe data might have changed. Please refresh the page and try again",
                failEmailNotVerified: "Email requires verification to perform this action",

                resendEmailVerify: "Resend email verification",

                employerName: "Employer Name",
                employerContactNumber: "Employer Contact Number",
                employerLocation: "Employer Location",
                titleHeld: "Title Held",
                duration: "Duration",
                mainResponsibilities: "Main Responsibilities",
                reasonForLeaving: "Reason For Leaving",
                workHistory: "Work History",
                generateCandidateInterviewDocument: "Generate Candidate Interview Document",
                preparingGeneration: "Preparing generation...",
                generateReport: "Generating report...",
                failGenerateReport: "Failed to generate report",
                dateGenerated: "Date Generated",
                workHistoryWithGaps: "Total Work History With Gaps",

                resendEmailVerifyPopupText: "Are you sure you want resend email verification?",
                makePrimaryPopupText: "Are you sure you want to make this a primary email?\n\nNote by doing this the current primary email will no longer be used for communication and will require a login on the new primary email",

                completingForm: "Completing Form",
                requiresPayment: "Requires Payment",
                requiresCountersignerAction: "Requires Countersigner Action",
                unordered: "Bulky Unordered",
                ordered: "Bulky Ordered",
                receipted: "Bulky Receipted",
                invalid: "Bulky Invalid",
                passed: "Bulky Passed",
                failed: "Bulky Failed",
                missingDocuments: "Missing Documents",
                requiresCandidateAction: "Requires Candidate Action",
                candidateActioned: "Candidate Actioned",
                noCrb: "No CRB",
                expiring: "Expiring",
                expired: "Expired",
                counterSigned: "Counter Signed",
                notRequired: "Not Required ",
                newApplication: "New Application",
                noDisclaimer: "No Disclaimer",
                noLanguage: "No Language   ",
                manualApplication: "Manual Application",
                a24Rejected: "A24 Rejected",
                a24RejectedNotReversed: "A24 Rejected Not Reversed",
                validatedForm: "Validated Form",
                reload: "Reload",
                candidate: "Candidate",
                candidateLink: "Candidate Link",
                candidateType: "Candidate Type",
                linkCandidate: "Link Existing Candidate",
                finish: "Finish",
                unverifiedCandidateEmail: "The email you are trying to add already exists but is unverified and can therefore not be linked to the Agency Group at this time, please try again later",
                interviewHeld: "Interview Held",
                dbsOnlineCheckDone: "DBS Online Check Done",
                interviewFailed: "Interview Failed",
                interviewPassed: "Interview Passed",

                resendInviteEmail: "Resend invite/verification",
                resendInviteEmailConfirmation: "Are you sure you want to resend the invitation/verification email?",
                resendInviteEmailSuccess: "Successfully resent invitation email to candidate",
                resendInviteEmailFailure: "Error! Could not resend the invitation/verification email",

                sendInviteEmailSuccess: "Successfully sent invitation email to candidate",
                sendVerificationEmailSuccess: "Successfully sent verification email to candidate",
                noInviteVerificationEmailSent: "No email was sent to the candidate. The current status of the candidate does not allow an invite/verification email to be sent",
                noNotificationForType: "No notification found for notification type",

                loadDataRetry: "Could not load all the required data for this page, please click the refresh button to try again",
                refresh: "Refresh",
                general: "General",
                policies: "Policies",
                plugins: "Plugins",
                permissionsError: "You do not have the required permissions to view the requested information",
                emailPreview: "Email Preview",
                couldNotLoadEmailPreview: "Could not load the email preview, please try again",
                couldNotLoadConversationEmailPreview: "Could not load the conversation preview, please try again",
                couldNotLoadNotificationPreview: "Could not load the notification preview, please try again",
                couldNotLoadNotificationSentInformation: "Could not load the notification sent information, please try again",
                sendNotification: "Send Notification",
                selectNotificationType: "Select Notification Type",
                send: "Send",
                deliveryStatus: "Delivery Status",
                deliveryFailureReason: "Delivery Failure Reason",
                from: "From",
                to: "To",
                cc: "CC",
                bcc: "BCC",
                replyTo: "Reply to",
                numberOfOccurrences: "Number of occurrences",
                lastOccurredAt: "Last occurred at",

                done: "Done",
                skills: "Skills",
                skill: "Skill",
                verify: "Verify",
                existingSkills: "Existing Skills",
                availableSkills: "Available Skills",
                experience: "Experience",
                employmentExp: "Employment Experience",
                statedExp: "Stated Experience",
                failedAddingSkillMin: "Duration should be more than one",
                validationYearsOrMonthsRequired: "Either years or months is required",
                validationPhoneOrMobileRequired: "Either phone or mobile number is required",
                validationMonthSmallerThan12: "Months is not allowed to be more than 11",
                getSystemSkillsError: "Failed to retrieve available skills. Please try again later",
                verifyConfirmation: "Are you sure you want to verify this item?",
                getSystemQualificationsError: "Failed to retrieve available qualifications. Please try again later",

                qualification: "Qualification",
                qualifications: "Qualifications",
                existingQualifications: "Existing Qualifications",
                availableQualifications: "Available Qualifications",
                dateObtained: "Date Obtained",
                expiryDate: "Expiry Date",
                validDate: "Valid Date",
                institution: "Institution",
                skillName: "Skill name",
                stated: "Stated",
                employment: "Employment",
                total: "Total",
                obtained: "Obtained",
                expire: "Expire",

                experience4PlusYears: "4+ Years Experience",
                experience2To4Years: "2 - 4 Years Experience",
                experience6MonthsTo2Years: "6 Months - 2 Years Experience",
                experience0To6Months: "0-6 Months Experience",

                yearsOfExperience: "Years Of Experience",
                externalVerification: "External Verification",
                unemploymentReason: "Unemployment Reason",
                unemployed: "Unemployed",
                lastUploadedDate: "Last Uploaded Date",
                externalDocuments: "External Documents",
                externalGeneral: "External General",
                dbsStatus: "DBS Status",
                lastContacted: "Last Contacted",
                dismissal: "Dismissal",
                haveYouEverBeenDismissed: "Have you ever been dismissed from any of your previous employers?",
                externalInfo: "External Info",
                thesePagesListData: "This page lists data from external sources. These external sources will push the data to Staffshift. The data you are viewing may be out of date as updates could be delayed.",
                lastUploaded: "Last Uploaded",
                info: "Info",

                never: "Never",
                lessThanAndExpired: "Less than or Expired",
                lessThanNotExpired: "Less than and Not Expired",
                lessThan: "Less than",
                andNotExpired: "and Not Expired",
                orExpired: "or Expired",
                moreThan: "More than",
                exactly: "Exactly",
                days: "Days",
                expiringIn: "Expiring In",
                externalDaysTagEmpty: "External Days to Expiry is empty",
                externalDaysToExpiry: "External Days to Expiry",

                socialLink: "Social Link",
                socialLinks: "Social Links",
                linkType: "Link Type",
                link: "Link",
                agencyGroupRoles: "Agency Group Roles",
                notificationDetails: "Notification Details",
                notificationsSent: "Notifications sent:",
                viewNotificationPreview: "View Notification Preview",

                updateGeoLocation: "Update Geo Location",
                geoLocation: "Geo Location",
                googleMaps: "Google Maps",
                doNotContact: "Do Not Contact",
                notificationPreference: "Notification Preference",
                phoneCall: "Phone Call",
                pushNotification: "Push Notification",

                primaryDelivery: "Primary Delivery",
                firstFallback: "1st Fallback",
                secondFallback: "2nd Fallback",
                thirdFallback: "3rd Fallback",
                noNotificationTemplatesConfigured: "No notification templates configured",
                notificationPriority: "Notification Priority",
                th: "th",
                fallback: "Fallback",
                notificationOptOut: "The candidate has opted out of receiving this notification.",
                transport: "Transport",
                //===== STRINGS END   =====

                //===== TOKEN STRINGS START =====
                externalDaysTag: "External Days to Expiry is {0}",

                externalPortfolioTagEmpty: "Portfolio is empty",

                externalPortfolioTag: "Portfolio is {0}",
                externalPortfolioTagNoTitle: "Is {0}",

                professionalRegistrationTag: "Professional Registration is {0}",
                professionalRegistrationTagNoTitle: "Is {0}",
                professionalRegistrationEntryTag: "Prof Reg Entry is {0}",
                professionalRegistrationEntryTagNoTitle: "Is {0}",

                positionHeldTag: "Position held was for {0} as {1} {2}",
                positionHeldTagNoTitle: "Was for {0} as {1} {2}",

                validationStartDateLessEndDate: "{0} is not allowed to be after the End Date",
                validationStartDateLessNotSameAsEndDate: "{0} is not allowed to be after or same as {1}",
                validationOnlyNumbes: "{0} is only allowed to be numbers.",

                headerManageSkills: "Manage Skills - {0}",
                editSkillsSnackbar: "The skill is currently in {0} status and cannot be edited",

                headerManageQualifications: "Manage Qualifications - {0}",
                editQualificationsSnackbar: "The qualification is currently in {0} status and cannot be edited",
                verifyingItem: "Verifying {0}...",

                successVerifyItem: "Successfully verified {0}",

                failVerifyItem: "Failed to verify {0}",
                lastSeenInApp: "Last Seen In App",

                distanceTag: "Distance is within {0} of {1}",
                distanceTagNoTitle: "Is within {0} of {1}",
                distanceTagCoords: "Distance is within {0} of coordinates {1}",
                distanceTagCoordsNoTitle: "Is within {0} of coordinates {1}",
                //===== TOKEN STRINGS END =====

                //===== SAFE STRINGS START =====

                cannotDoQueryForClient: "Could not query distance for this client since the address is not properly configured in Staffshift. Please email info@staffshift.com with the client name for this to be rectified or enter the coordinates manually below.",

                geoLocationUpdateText: "When updating this please go to {0} to get the coordinates and enter it manually below",

                skillsHeader: "Skills - {0}",
                editSkillsHeader: "Edit Skill - {0}</br>{1}",
                headerManageEditSkill: "Manage Skill - {0}</br>{1}",
                addSkillsHeader: "Add Skill - {0}</br>{1}",

                qualificationsHeader: "Qualifications - {0}",
                editQualificationsHeader: "Edit Qualification - {0}</br>{1}",
                addQualificationsHeader: "Add Qualification - {0}</br>{1}",
                viewQualificationsHeader: "Qualification - {0}</br>{1}",
                verifyFailedRefreshPage: "Failed to verify item.\nThe data might have changed. Please refresh the page and try again",

                failedToRetrieveSuggest: "Failed to retrieve available {0}.<br/>Please try again later",

                candidateUnavailablePleaseReload: "<i class='a24-material-text-icons material-icons warning-color-text'>warning</i> Seems our system is still busy preparing the data for this candidate.<br/>Please give it a few minutes and then click the reload icon at the top right of this card to try again.",
                candidateUnavailablePleaseReloadCandidateContext: "<i class='a24-material-text-icons material-icons warning-color-text'>warning</i> Seems our system is still busy preparing your data.<br/>Please give it a few minutes and then click the reload icon at the top right of this card to try again.",

                apiUnavailablePleaseReload: "<i class='a24-material-text-icons material-icons warning-color-text'>warning</i> Failed to retrieve data from server.<br/>Please click the reload icon at the top right of this card to try again.",

                candidateEditableComingSoon: "This functionality will be available soon.<br/>At the moment if you are a newly registered candidate you are still required to capture this information via the separate application form.<br/>If you have already provided this information, then this will be displayed here soon.",

                unlinkedCandidate: "You are trying to add a candidate with an email address that is already in use on the system.<br/><br/>Do you want to link candidate \"{0}\" with email address {1} to this Agency Group",
                linkedCandidate: "You are trying to add a candidate with an email address that is already in use on the system.<br/><br/>Candidate \"{0}\" with email address {1} is already linked to this Agency Group",

                unlinkedCandidateNoName: "You are trying to add a candidate with an email address that is already in use on the system.<br/><br/>Do you want to link candidate with email address {0} to this Agency Group",
                linkedCandidateNoName: "You are trying to add a candidate with an email address that is already in use on the system.<br/><br/>Candidate with email address {0} is already linked to this Agency Group",

                itemUpdateDelay: "Please note that updates might take a while to reflect here.</br>Click the refresh button on the card to try again",

                linkedCandidateAgency: "You are trying to add a candidate with an email address that is already in use on the system.<br/><br/>Candidate \"{0}\" with email address {1} is already linked to this Agency",
                linkedCandidateNoNameAgency: "You are trying to add a candidate with an email address that is already in use on the system.<br/><br/>Candidate with email address {0} is already linked to this Agency",
                noEmploymentHistoryData: "No Employment history data added.<br/>Please add your full employment history.<br/>Click on the plus sign at the bottom right of your screen to get started.",
                noExistingSkills: "No existing skills. </br>Click on one of the Availible Skills items below to get started.",
                noExistingQualifications: "No existing qualifications. </br>Click on one of the Availible Qualifications items below to get started.",
                noSkillsData: "Please provide us with your skills linked to you work experience.<br/>Remember that you will be held professionally accountable for the data provided.<br/>Click on the plus sign at the bottom right of your screen to get started.",
                noQualificationsData: "Please provide us with your qualifications linked to you work experience.<br/>Remember that you will be held professionally accountable for the data provided.<br/>Click on the plus sign at the bottom right of your screen to get started.",
                noProfessionalRegistrationData: "Please provide us with your Professional Registrations linked to you work experience.<br/>Remember that you will be held professionally accountable for the data provided.<br/>Click on the plus sign at the bottom right of your screen to get started."

                //===== SAFE STRINGS END =====
            });
        }
    });
});
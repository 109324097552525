define("a24-ember-candidate-profile/components/tab-agency-candidate-profile-calendar", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/tab-agency-candidate-profile-calendar"], function (exports, _didRenderChangesMixin, _tabAgencyCandidateProfileCalendar) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _tabAgencyCandidateProfileCalendar.default,

        objAgencyAvailabilityService: Ember.inject.service("agency-availability"),
        objAgencyBookedShiftService: Ember.inject.service("rest-agency-advanced-search-agency-shift"),
        objAgencyEmailRestService: Ember.inject.service("rest-agency-candidate-emails"),

        _funcOnBack: null,

        _sCandidateId: null,
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objBasicInfoService: Ember.inject.service("rest-agency-candidate-basic-info"),
        _objCandidateContactNumbersService: Ember.inject.service("rest-agency-candidate-contact-numbers"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objStatusErrorPageService: Ember.inject.service("status-error-page"),
        _objMenuService: Ember.inject.service("triage-menu"),
        _bDisplayPermissionError: false,

        _objFabButtonService: Ember.inject.service("fab-button"),
        _objBasicInfoResponse: null,
        _objContactNumbersResponse: null,
        _arrNumbers: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var objCandidateViewParams = this.get("_objNavigation.objRouteParams")["index.agency-candidate-profile"];

            var objCandidateSearchViewParams = this.get("_objNavigation.objRouteParams")["index.agency-candidate-search-view"];

            if (!a24Core.isEmpty(objCandidateViewParams)) {
                this.set("_sCandidateId", objCandidateViewParams.candidate_id);
            }
            if (!a24Core.isEmpty(objCandidateSearchViewParams)) {
                this.set("_sCandidateId", objCandidateSearchViewParams.candidate_id);
            }

            this.get("_objTitleBarService").setTitleBar(this.get("_objStringsCandidateProfile").getString("candidateProfile"), function () {
                _this.get("_objNavigation").navigateBack();
            }, false, this.get("_objTitleBarService.sRightComponent"), this.get("_objTitleBarService.objRightComponentData"), this.get("_objTitleBarService.onRightComponentAction"));

            this.set("_funcOnBack", function () {
                _this.get("_objNavigation").navigateBack();
            });

            this.set("_objMenuService.sRightPanelComponent", "right-panel-agency-candidate-calendar");
            this.set("_objMenuService.mRightPanelComponentData", {
                "sCandidateId": this.get("_sCandidateId"),
                "bCalendarLoaded": false,
                "objBasicInfo": null
            });

            this.set("_arrNumbers", []);

            this.getBasicInfoAndContactNumber();
        },

        didDestroyElement: function didDestroyElement() {
            this._super.apply(this, arguments);

            this.set("_objMenuService.sRightPanelComponent", null);
        },

        getBasicInfoAndContactNumber: function getBasicInfoAndContactNumber() {
            var _this2 = this;

            var objPromiseCandidateBasicInfo = this.get("_objBasicInfoService").getBasicInfo(this, "_objBasicInfoResponse", this.get("_sCandidateId"), function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                if (sCode === "403") {
                    _this2.set("_objStatusErrorPageService.sIcon", "warning");
                    _this2.set("_objStatusErrorPageService.sMessage", _this2.get("_objStringsCandidateProfile").getString("permissionsError"));
                    _this2.set("_bDisplayPermissionError", true);
                }
            }));

            var objPromiseContactNumbers = this.get("_objCandidateContactNumbersService").getContactNumbers(this, "_objContactNumbersResponse", this.get("_sCandidateId"), function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2.set("_objMenuService.mRightPanelComponentData.arrNumbers", []);
                _this2.set("_objMenuService.mRightPanelComponentData.bNumbersError", true);
                _this2.set("_objMenuService.mRightPanelComponentData.bNumbersLoaded", true);
            }));

            var objPromiseList = {
                _objRSVPCandidateBasicInfo: objPromiseCandidateBasicInfo,
                _objRSVPContactNumbers: objPromiseContactNumbers
            };

            var objThis = this;
            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: ["_objRSVPCandidateBasicInfo", "_objRSVPContactNumbers"],
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objRSVPCandidateBasicInfo) && !a24Core.isEmpty(objResponses._objRSVPCandidateBasicInfo.objHeaders) && objResponses._objRSVPCandidateBasicInfo.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRSVPContactNumbers) && !a24Core.isEmpty(objResponses._objRSVPContactNumbers.objHeaders) && objResponses._objRSVPContactNumbers.objHeaders.statusText === "abort") {
                        return;
                    }
                    if (!a24Core.isEmpty(objResponses._objRSVPCandidateBasicInfo) && !a24Core.isEmpty(objResponses._objRSVPCandidateBasicInfo.objResponse)) {
                        var objData = objResponses._objRSVPCandidateBasicInfo.objResponse;
                        var sCandidateProfile = objThis.get("_objStringsCandidateProfile").getString("candidateProfile");
                        var sCandidateName = objData.first_name + " " + objData.last_name;
                        objThis.set("_objMenuService.mRightPanelComponentData.sCandidateId", objThis.get("_sCandidateId"));
                        objThis.set("_objMenuService.mRightPanelComponentData.objBasicInfo", objData);
                        objThis.set("_objTitleBarService.sTitle", sCandidateProfile + " - " + sCandidateName);
                    }
                    if (!a24Core.isEmpty(objResponses._objRSVPContactNumbers)) {
                        var arrData = objResponses._objRSVPContactNumbers.objResponse;
                        objThis.set("_objMenuService.mRightPanelComponentData.bNumbersLoaded", true);
                        if (a24Core.isEmpty(arrData)) {
                            objThis.set("_objMenuService.mRightPanelComponentData.arrNumbers", []);
                            objThis.set("_arrNumbers", []);
                        } else {
                            objThis.set("_objMenuService.mRightPanelComponentData.arrNumbers", arrData);
                            objThis.set("_arrNumbers", arrData);
                        }
                    }
                }
            }]);
        },

        actions: {
            onCalendarLoaded: function onCalendarLoaded() {
                this.set("_objMenuService.mRightPanelComponentData.bCalendarLoaded", true);
            }
        }
    });
});
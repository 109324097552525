define("a24-ember-candidate-profile/services/card-datagrid-custom-filter-candidate-external-days-to-expiry", ["exports", "a24-ember-lib/services/card-datagrid-filter-custom-base"], function (exports, _cardDatagridFilterCustomBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _cardDatagridFilterCustomBase.default.extend({

        _objUserSession: Ember.inject.service("user-session"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),

        funcFilterCustomPrimaryQuery: function funcFilterCustomPrimaryQuery(objHeaderItem /* , objHeaderSecondaries */) {
            var objQuery = null;

            if (objHeaderItem._bEmptyEntriesOnly) {
                objQuery = {
                    bEncode: true,
                    sFilterValue: "empty",
                    mValue: "null"
                };
            } else {
                objQuery = {
                    bEncode: true,
                    bAny: a24Core.isEmpty(objHeaderItem._objExternalDaysType),
                    arSec: []
                };

                if (!a24Core.isEmpty(objHeaderItem._objExternalDaysType)) {
                    objQuery.sFilterValue = objHeaderItem._objExternalDaysType.name;
                    objQuery.mValue = objHeaderItem._objExternalDaysType._id;
                }

                if (!a24Core.isEmpty(objHeaderItem._objExpiringIn)) {
                    if (objHeaderItem._objExpiringIn.sValue === this.get("_objCandidateProfileEnumsService.expiringIn.NEVER.backend")) {
                        objQuery.arSec.push({
                            "sProp": "value",
                            "sFilterValue": null,
                            "bEncode": true,
                            "mValue": null
                        });
                    } else {
                        if (!a24Core.isEmpty(objHeaderItem._iNumberOfDays)) {

                            var objDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            var objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                            objDate.addTimeToDate(a24Constants.MOMENT_TIME_DAYS, objHeaderItem._iNumberOfDays);

                            var sDate = objDate.getDateStringForRest(true);
                            var sCurrentDate = objCurrentDate.getDateStringForRest(true);
                            if (objHeaderItem._objExpiringIn.sValue === this.get("_objCandidateProfileEnumsService.expiringIn.LESS_THAN_AND_EXPIRED.backend")) {
                                objQuery.arSec.push({
                                    "sProp": "value",
                                    "sFilterValue": objHeaderItem._iNumberOfDays,
                                    "bEncode": true,
                                    "mValue": sDate,
                                    "bTo": true
                                });
                            } else if (objHeaderItem._objExpiringIn.sValue === this.get("_objCandidateProfileEnumsService.expiringIn.LESS_THAN_NOT_EXPIRED.backend")) {
                                objQuery.arSec.push({
                                    "sProp": "value",
                                    "sFilterValue": objHeaderItem._iNumberOfDays,
                                    "bEncode": true,
                                    "bFromAndTo": true,
                                    "mValue": {
                                        "sFrom": sCurrentDate,
                                        "sTo": sDate
                                    }
                                });
                            } else if (objHeaderItem._objExpiringIn.sValue === this.get("_objCandidateProfileEnumsService.expiringIn.MORE_THAN.backend")) {
                                objQuery.arSec.push({
                                    "sProp": "value",
                                    "sFilterValue": objHeaderItem._iNumberOfDays,
                                    "bEncode": true,
                                    "mValue": sDate,
                                    "bFrom": true
                                });
                            } else if (objHeaderItem._objExpiringIn.sValue === this.get("_objCandidateProfileEnumsService.expiringIn.EXACTLY.backend")) {
                                objQuery.arSec.push({
                                    "sProp": "value",
                                    "sFilterValue": objHeaderItem._iNumberOfDays,
                                    "bEncode": true,
                                    "mValue": sDate
                                });
                            }
                        }
                    }
                }
            }
            return objQuery;
        },

        funcFilterCustomTag: function funcFilterCustomTag(objHeaderItem, objQuery, objHeadersSecondaries, bSkipTitle) {
            if (objQuery.sFilterValue === "empty") {
                if (bSkipTitle) {
                    return {
                        sTag: this.get("_objStringsService").getString("isEmpty")
                    };
                } else {
                    return {
                        sTag: this.get("_objCandidateProfileStringsService").getString("externalDaysTagEmpty")
                    };
                }
            } else {
                var sExternalDaysTagKey = bSkipTitle ? "externalPortfolioTagNoTitle" : "externalDaysTag";
                var sExternalTypeFilter = objQuery.sFilterValue;

                var objDaysQuery = objQuery.arSec.findBy("sProp", "value");

                if (!a24Core.isEmpty(objDaysQuery)) {
                    var sDays = " expiring in " + this.get("_objCandidateProfileStringsService").getString("exactly") + " " + objDaysQuery.sFilterValue + " days";
                    if (objDaysQuery.bFrom) {
                        sDays = " expiring in " + this.get("_objCandidateProfileStringsService").getString("moreThan") + " " + objDaysQuery.sFilterValue + " days";
                    } else if (objDaysQuery.bTo) {
                        sDays = " expiring in " + this.get("_objCandidateProfileStringsService").getString("lessThan") + " " + objDaysQuery.sFilterValue + " days " + this.get("_objCandidateProfileStringsService").getString("orExpired");
                    } else if (objDaysQuery.bFromAndTo) {
                        sDays = " expiring in " + this.get("_objCandidateProfileStringsService").getString("lessThan") + " " + objDaysQuery.sFilterValue + " days " + this.get("_objCandidateProfileStringsService").getString("andNotExpired");
                    } else if (a24Core.isEmpty(objDaysQuery.sFilterValue)) {
                        sDays = " expiring " + this.get("_objCandidateProfileStringsService").getString("never");
                    }

                    sExternalTypeFilter += sDays;
                }

                return {
                    sTag: this.get("_objCandidateProfileStringsService").getTokenString(sExternalDaysTagKey, sExternalTypeFilter)
                };
            }
        },

        funcFilterCustomPrimaryReset: function funcFilterCustomPrimaryReset(objHeaderItem) {
            //Reset the state of the custom filter input
            Ember.set(objHeaderItem, "_bEmptyEntriesOnly", false);
            Ember.set(objHeaderItem, "_objExternalDaysType", null);
            Ember.set(objHeaderItem, "_objExpiringIn", null);
            Ember.set(objHeaderItem, "_iNumberOfDays", null);
        },

        funcFilterCustomFixupQuery: function funcFilterCustomFixupQuery(objHeader /*, objHeaderSecondaries */) {
            if (a24Core.isEmpty(objHeader.mFilterValue)) {
                return;
            }
            for (var i = 0; i < objHeader.mFilterValue.length; i++) {
                var arrSeconderies = objHeader.mFilterValue[i].arSec;
                for (var j = 0; j < arrSeconderies.length; j++) {
                    if (arrSeconderies[j].sProp === "value") {
                        if (!a24Core.isEmpty(arrSeconderies[j].sFilterValue)) {
                            var objDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            var objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                            objDate.addTimeToDate(a24Constants.MOMENT_TIME_DAYS, arrSeconderies[j].sFilterValue);

                            var sDate = objDate.getDateStringForRest(true);
                            var sCurrentDate = objCurrentDate.getDateStringForRest(true);

                            if (arrSeconderies[j].bFromAndTo) {
                                Ember.set(objHeader, "mFilterValue." + i + ".arSec." + j + ".mValue", {
                                    "sFrom": sCurrentDate,
                                    "sTo": sDate
                                });
                            } else {
                                Ember.set(objHeader, "mFilterValue." + i + ".arSec." + j + ".mValue", sDate);
                            }
                        }
                    }
                }
            }
        },

        funcFilterCustomSetEditState: function funcFilterCustomSetEditState(objHeaderItem, iIndex) {
            var objTag = objHeaderItem.mFilterValue[iIndex];
            Ember.set(objHeaderItem, "_bEmptyEntriesOnly", objTag.sFilterValue === "empty");

            if (objTag.sFilterValue !== "empty") {
                if (!a24Core.isEmpty(objTag.mValue) && !a24Core.isEmpty(objTag.sFilterValue)) {
                    var objExternalType = {
                        _id: objTag.mValue,
                        name: objTag.sFilterValue
                    };
                    Ember.set(objHeaderItem, "_objExternalDaysType", objExternalType);
                }

                if (!a24Core.isEmpty(objTag.arSec)) {
                    for (var i = 0; i < objTag.arSec.length; i++) {
                        var objSecondary = objTag.arSec[i];

                        if (objSecondary.sProp === "value") {
                            if (objSecondary.bTo) {
                                Ember.set(objHeaderItem, "_iNumberOfDays", objSecondary.sFilterValue);
                                Ember.set(objHeaderItem, "_objExpiringIn", {
                                    sLabel: this.get("_objCandidateProfileStringsService").getString("lessThanAndExpired"),
                                    sValue: this.get("_objCandidateProfileEnumsService.expiringIn.LESS_THAN_AND_EXPIRED.backend")
                                });
                            } else if (objSecondary.bFromAndTo) {
                                Ember.set(objHeaderItem, "_iNumberOfDays", objSecondary.sFilterValue);
                                Ember.set(objHeaderItem, "_objExpiringIn", {
                                    sLabel: this.get("_objCandidateProfileStringsService").getString("lessThanNotExpired"),
                                    sValue: this.get("_objCandidateProfileEnumsService.expiringIn.LESS_THAN_NOT_EXPIRED.backend")
                                });
                            } else if (objSecondary.bFrom) {
                                Ember.set(objHeaderItem, "_iNumberOfDays", objSecondary.sFilterValue);
                                Ember.set(objHeaderItem, "_objExpiringIn", {
                                    sLabel: this.get("_objCandidateProfileStringsService").getString("moreThan"),
                                    sValue: this.get("_objCandidateProfileEnumsService.expiringIn.MORE_THAN.backend")
                                });
                            } else if (a24Core.isEmpty(objSecondary.sFilterValue)) {
                                Ember.set(objHeaderItem, "_objExpiringIn", {
                                    sLabel: this.get("_objCandidateProfileStringsService").getString("never"),
                                    sValue: this.get("_objCandidateProfileEnumsService.expiringIn.NEVER.backend")
                                });
                            } else {
                                Ember.set(objHeaderItem, "_iNumberOfDays", objSecondary.sFilterValue);
                                Ember.set(objHeaderItem, "_objExpiringIn", {
                                    sLabel: this.get("_objCandidateProfileStringsService").getString("exactly"),
                                    sValue: this.get("_objCandidateProfileEnumsService.expiringIn.EXACTLY.backend")
                                });
                            }
                        }
                    }
                }
            }
        }
    });
});
define("a24-ember-lib/components/card-datagrid-container", ["exports", "a24-ember-lib/templates/components/card-datagrid-container", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _cardDatagridContainer, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _cardDatagridContainer.default,
        sUrlBase: "",
        bDisableFilterKeyboardShortcuts: false,
        bDisableTableKeyboardShortcuts: false,
        objUrlConstantPart: null,
        objDatagridConfig: null,
        objQueryParams: null,
        bShowShareButton: false,
        bShareableLink: false,
        bSwaggerServiceCalls: true,
        bShowSaveAction: true,
        _arrSelectedItems: null,
        _arrActionItems: null,
        _bDatagridLoading: false,
        _arrTableData: null,
        _objFilterParamObject: null,
        _objTableParamObject: null,
        _objPageParamObject: null,
        _objUrlCall: null,
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objFilterService: Ember.inject.service("card-datagrid-filter"),
        _objEnumEmberLibService: Ember.inject.service("a24-enums-ember-lib"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objEmberLibStringService: Ember.inject.service("a24-strings-ember-lib"),
        _bDidInsertElement: false,
        _sPageParam: "",
        _sSortParam: "",
        bPostTypeDatagrid: false,
        bPostTypeDatagridV2: false,

        bAllowHiddenColumns: false,
        arrAllowedHeaders: null,

        _funcQueryParamsObserverCallback: function _funcQueryParamsObserverCallback() {
            var objThis = this;
            // We do this purely because we need certain variables to bind back to the parent for testing
            Ember.run.schedule("actions", function () {
                objThis.getQueryParams();
            });
        },
        _funcPageParamChangeCallback: function _funcPageParamChangeCallback() {
            this.doServiceCall(false);
        },
        _funcSortParamChangeCallback: function _funcSortParamChangeCallback() {
            this.doServiceCall(true);
        },
        init: function init() {
            this._super.apply(this, arguments);
            var objThis = this;
            var arrHeaders = this.get("objDatagridConfig.arrHeaderConfig");
            //Since paging is not something we want to control from outside it is highly likely that these values will
            //not be set, so we set them here to default on the objDatagridConfig so that they also go into the gwt history
            if (a24Core.isEmpty(this.get("objDatagridConfig.iPerPage"))) {
                this.set("objDatagridConfig.iPerPage", 25);
            }
            if (a24Core.isEmpty(this.get("objDatagridConfig.iResultCount"))) {
                this.set("objDatagridConfig.iResultCount", 0);
            }
            if (a24Core.isEmpty(this.get("objDatagridConfig.iPage"))) {
                this.set("objDatagridConfig.iPage", 1);
            }
            if (a24Core.isEmpty(this.get("objDatagridConfig.bHasReloadButton"))) {
                this.set("objDatagridConfig.bHasReloadButton", false);
            }
            if (a24Core.isEmpty(this.get("_arrTableData"))) {
                this.set("_arrTableData", []);
            }

            //Add query params observer if we have any
            if (!a24Core.isEmpty(this.get("objQueryParams"))) {
                this.set("_sPageParam", this.get("objDatagridConfig.iPage").toString());
                this.addObserver("objQueryParams", this, this.get("_funcQueryParamsObserverCallback"));
                this.addObserver("_sPageParam", this, this.get("_funcPageParamChangeCallback"));

                for (var i = 0; i < arrHeaders.length; i++) {
                    //Set the sort if the column has it applied
                    if (!a24Core.isEmpty(arrHeaders[i].sAsc)) {
                        this.set("_sSortParam", arrHeaders[i].sProperty + "::" + arrHeaders[i].sAsc);
                        break;
                    }
                }
                this.addObserver("_sSortParam", this, this.get("_funcSortParamChangeCallback"));

                this.getQueryParams();
            } else {
                $.each(arrHeaders, function (sKey, objItem) {
                    Ember.set(objItem, "bShowHeader", !objItem.bInitialyHidden);
                });
            }

            this.set("arrAllowedHeaders", arrHeaders);

            Ember.run.scheduleOnce("afterRender", this, function () {
                objThis.set("_bDidInsertElement", true);
                //We call the service call here to give the child components the chance to initialize and set the initial
                //query objects.
                objThis.doServiceCall(false);
            });
        },
        getQueryParams: function getQueryParams() {
            var _this = this;

            //This function will get the query params that are in the url and apply them to the datagrid
            var objQueryParams = this.get("objQueryParams");
            var arrHeaders = this.get("objDatagridConfig.arrHeaderConfig");

            var arrVisibleColumns = [];
            if (!a24Core.isEmpty(objQueryParams.vis_cols.sValue)) {
                arrVisibleColumns = objQueryParams.vis_cols.sValue.split(",");
            }

            var arrSortDetails = null;
            if (!a24Core.isEmpty(objQueryParams.sortBy)) {
                if (!a24Core.isEmpty(objQueryParams.sortBy.sValue)) {
                    arrSortDetails = objQueryParams.sortBy.sValue.split("::");
                }
            }
            var bFoundSortColumn = false;
            var sSortColumn = null;
            var sSortOrder = null;
            if (!a24Core.isEmpty(arrSortDetails)) {
                sSortColumn = arrSortDetails[0];
                sSortOrder = arrSortDetails[1];
            }

            var objFirstVisCol = null;
            var bFoundVisCol = false;

            for (var i = 0; i < arrHeaders.length; i++) {

                var objHeader = arrHeaders[i];
                var sProperty = objHeader.sProperty;
                var sUniqueColNum = objHeader.iUniqueColNum + "";

                if (a24Core.isEmpty(sUniqueColNum)) {
                    console.error( //eslint-disable-line no-console
                    "Column with property " + sProperty + " does not have a iUniqueColNum defined.\n" + "Each column on the datagrid should have a iUniqueColNum defined when using query params.\n" + "The iUniqueColNum should be different for each column and never unpdated in the future since it " + "will break users saved bookmarks or links created with the iUniqueColNum in it.");
                    return;
                }

                Ember.set(objHeader, "bShowHeader", false);

                if (arrVisibleColumns.includes(sUniqueColNum) && !objHeader.bSilent) {
                    bFoundVisCol = true;
                    Ember.set(objHeader, "bShowHeader", true);
                }

                if (a24Core.isEmpty(objFirstVisCol) && !objHeader.bSilent) {
                    objFirstVisCol = objHeader;
                }

                if (objHeader.bFilterSecondary || objHeader.bFilterAnd || objHeader.bFilterOr || objHeader.sFilterType === "custom") {
                    // if (objHeader.bFilterSecondary) { <-- use this when below commented out code is used
                    //NOTE: Note the below we use "m" fitler value and not the "s" filter value
                    if (a24Core.isEmpty(objQueryParams[sProperty].sValue)) {
                        Ember.set(objHeader, "sTagValue", null);
                        Ember.set(objHeader, "sTagValueSimple", null);
                        Ember.set(objHeader, "arrTagValue", null);
                        Ember.set(objHeader, "mFilterValue", null);
                    } else {
                        //base64 decode, then JSON decode
                        try {
                            Ember.set(objHeader, "mFilterValue", JSON.parse(atob(objQueryParams[sProperty].sValue)));
                        } catch (objError) {
                            //JSON stringify or btoa fail.

                            Ember.set(objHeader, "sTagValue", null);
                            Ember.set(objHeader, "sTagValueSimple", null);
                            Ember.set(objHeader, "arrTagValue", null);
                            Ember.set(objHeader, "mFilterValue", null);

                            //eslint-disable-next-line no-console
                            console.warn("Could not parse the datagrid advance filter mFilterValue \n", objError);
                        }
                    }

                    // We can add this in the future to try and save space in the url, for now im leaving this out because of
                    // the following scenario(assume we use || for or condition):
                    // Search for "tom" and "bob" will be sName=tom||bob = seach on 2 items
                    // Search for "tom||nom" and "bob" will be sName=tom||nom||bob = search on 3 items instead of only 2
                    // } else if (objHeader.bFilterAnd || objHeader.bFilterOr) {
                    //     // Parsing bFilterAnd and bFilterOr different to save space in the url e.g
                    //     // policies___type_id=Bloep&&noep&&snoep||nomnom&&sneegle, since we can split on that and
                    //     // still be able to build the correct query. It is only when secondary properties come into play
                    //     // where we cant assign all values on one property without base64
                    //
                    //     //NOTE: Note the below we use "m" fitler value and not the "s" filter value
                    //
                    //     if (a24Core.isEmpty(objQueryParams[sProperty].sValue)) {
                    //         set(objHeader, "sTagValue", null);
                    //         set(objHeader, "mFilterValue", null);
                    //     } else {
                    //         set(objHeader, "mFilterValue", objQueryParams[sProperty].sValue);
                    //     }
                } else if ("date" === objHeader.sFilterType) {
                    var iDays = null;
                    var bFixDates = false;
                    if ((objQueryParams[sProperty].sValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_LAST.backend") || objQueryParams[sProperty].sValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_NEXT.backend") || objQueryParams[sProperty].sValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_LAST.backend") || objQueryParams[sProperty].sValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_NEXT.backend")) && !a24Core.isEmpty(objQueryParams[sProperty + "From"].sValue) && !a24Core.isEmpty(objQueryParams[sProperty + "To"].sValue)) {
                        var objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                        var objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                        objFromDate.setDateFromBrowser(objQueryParams[sProperty + "From"].sValue, this.get("_objLibConstants.DATE_FORMAT"));
                        objToDate.setDateFromBrowser(objQueryParams[sProperty + "To"].sValue, this.get("_objLibConstants.DATE_FORMAT"));

                        iDays = objToDate.difference(objFromDate, a24Constants.MOMENT_TIME_DAYS);
                        bFixDates = true;
                    }

                    var objFilterToAndFrom = this.get("_objFilterService").getDateFromAndToValues(objQueryParams[sProperty].sValue, iDays);
                    var bInvalidValue = a24Core.isEmpty(objQueryParams[sProperty].sValue) || objFilterToAndFrom.bNotInset;

                    //TODO might need to add extra check here for new types and then set the to and from here so that the
                    //url updates with the new dates. Not that it is a real problem since the dates only gets used to calc
                    //the amount of days inbetween them. Just if user does look at the url it might appear wrong even tho
                    //it will actually query with the correct expected dates.

                    if (a24Core.isEmpty(objQueryParams[sProperty + "From"].sValue) && a24Core.isEmpty(objQueryParams[sProperty + "To"].sValue) && bInvalidValue) {
                        Ember.set(objHeader, "sFilterFromValue", null);
                        Ember.set(objHeader, "sFilterToValue", null);
                        Ember.set(objHeader, "sTagValue", null);
                        Ember.set(objHeader, "sFilterValue", null);
                    } else {
                        if (bFixDates) {
                            Ember.set(objHeader, "sFilterFromValue", objFilterToAndFrom.sFromDate);
                            Ember.set(objHeader, "sFilterToValue", objFilterToAndFrom.sToDate);
                        } else {
                            Ember.set(objHeader, "sFilterFromValue", objQueryParams[sProperty + "From"].sValue);
                            Ember.set(objHeader, "sFilterToValue", objQueryParams[sProperty + "To"].sValue);
                        }

                        if (bInvalidValue) {
                            Ember.set(objHeader, "sFilterValue", this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_CUSTOM_RANGE.backend"));
                        } else {
                            Ember.set(objHeader, "sFilterValue", objQueryParams[sProperty].sValue);
                        }
                    }
                } else {
                    if (a24Core.isEmpty(objQueryParams[sProperty].sValue)) {
                        Ember.set(objHeader, "sTagValue", null);
                        Ember.set(objHeader, "sFilterValue", null);
                    } else {
                        Ember.set(objHeader, "sFilterValue", objQueryParams[sProperty].sValue);
                    }
                }

                if (sProperty === sSortColumn) {
                    bFoundSortColumn = true;
                    Ember.set(objHeader, "sAsc", sSortOrder);
                } else {
                    Ember.set(objHeader, "sAsc", null);
                }
            }

            //We cant hide all the columns, so when the vis_col param is empty we display the first availible column
            if (a24Core.isEmpty(arrVisibleColumns) || !bFoundVisCol) {
                Ember.set(objFirstVisCol, "bShowHeader", true);
            }

            //Add the special cases that are not in the arrHeaderConfig
            if (!a24Core.isEmpty(objQueryParams.page)) {
                this.set("objDatagridConfig.iPage", parseInt(objQueryParams.page.sValue));
                this.set("_sPageParam", objQueryParams.page.sValue);
            }

            if (!bFoundSortColumn || a24Core.isEmpty(arrSortDetails)) {
                this.set("_objTableParamObject", null);
            } else {
                var objSortQuery = {};
                var objQueryItem = {};
                objQueryItem.mValue = sSortOrder;
                objQueryItem.bSortBy = true;
                objSortQuery[sSortColumn] = objQueryItem;
                this.set("_objTableParamObject", objSortQuery);
            }

            if (!a24Core.isEmpty(objQueryParams.sortBy)) {
                this.set("_sSortParam", objQueryParams.sortBy.sValue);
            }

            // This component and the card datagrid filter component both seem to change the header configurations
            // We add this next here so that we can ensure that all the changes have completed before updating the url
            // to avoid a race condition between the two components
            Ember.run.next(function () {
                // Call this so that we can ensure the url matches what was updated in the headers
                _this.setQueryParams();
            });
        },
        setQueryParams: function setQueryParams() {
            //This function will get the new query param values from the datagrid and then set them back onto the url
            //If we do not have any query params setup then do not run this function
            if (a24Core.isEmpty(this.get("objQueryParams"))) {
                return;
            }

            //Remove objQueryParams since we are doing an internal change and do not want the getQueryParams firing
            this.removeObserver("objQueryParams", this, this.get("_funcQueryParamsObserverCallback"));

            //Update the objQueryParams so that the values for them in the url can update
            var objNewQueryParams = JSON.parse(JSON.stringify(this.get("objQueryParams")));
            var arrHeaders = this.get("objDatagridConfig.arrHeaderConfig");
            var sSortBy = null;

            var arrVisibleHeaders = [];

            for (var i = 0; i < arrHeaders.length; i++) {

                var objHeader = arrHeaders[i];
                var sProperty = objHeader.sProperty;

                // adds the property to the visible columns array if show header is true and silent is
                if (objHeader.bShowHeader && !objHeader.bSilent) {
                    arrVisibleHeaders.push(objHeader.iUniqueColNum);
                }

                if (objHeader.bFilterSecondary || objHeader.bFilterAnd || objHeader.bFilterOr || objHeader.sFilterType === "custom") {
                    // if (objHeader.bFilterSecondary) { <-- use this when below commented out code is used

                    //NOTE: Note the below we use "m" fitler value and not the "s" filter value
                    if (a24Core.isEmpty(objHeader.mFilterValue)) {
                        objNewQueryParams[sProperty].sValue = "";
                    } else {
                        //JSON encode then base64 encode
                        var sFilterValue = "";
                        try {
                            sFilterValue = btoa(JSON.stringify(objHeader.mFilterValue));
                        } catch (objError) {
                            //JSON stringify or btoa fail.
                            //eslint-disable-next-line no-console
                            console.warn("Could not encode the datagrid advance filter mFilterValue \n", objError);
                        }

                        objNewQueryParams[sProperty].sValue = sFilterValue;
                    }

                    // We can add this in the future to try and save space in the url, for now im leaving this out because of
                    // the following scenario(assume we use || for or condition):
                    // Search for "tom" and "bob" will be sName=tom||bob = seach on 2 items
                    // Search for "tom||nom" and "bob" will be sName=tom||nom||bob = search on 3 items instead of only 2
                    // } else if (objHeader.bFilterAnd || objHeader.bFilterOr) {
                    //     // Parsing bFilterAnd and bFilterOr different to save space in the url e.g
                    //     // policies___type_id=Bloep&&noep&&snoep||nomnom&&sneegle, since we can split on that and
                    //     // still be able to build the correct query. It is only when secondary properties come into play
                    //     // where we cant assign all values on one property without base64
                    //
                    //     //NOTE: Note the below we use "m" fitler value and not the "s" filter value
                    //
                    //     if (a24Core.isEmpty(objHeader.mFilterValue)) {
                    //         objNewQueryParams[sProperty].sValue = "";
                    //     } else {
                    //         objNewQueryParams[sProperty].sValue = objHeader.mFilterValue;
                    //     }
                } else if ("date" === objHeader.sFilterType) {
                    if (a24Core.isEmpty(objHeader.sFilterValue)) {
                        objNewQueryParams[sProperty].sValue = "";
                    } else {
                        objNewQueryParams[sProperty].sValue = objHeader.sFilterValue;
                    }

                    objNewQueryParams[sProperty + "From"].sValue = "";
                    objNewQueryParams[sProperty + "To"].sValue = "";

                    if (objHeader.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_CUSTOM_RANGE.backend") || objHeader.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_LAST.backend") || objHeader.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_NEXT.backend") || objHeader.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_LAST.backend") || objHeader.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_NEXT.backend")) {
                        if (!a24Core.isEmpty(objHeader.sFilterFromValue)) {
                            objNewQueryParams[sProperty + "From"].sValue = objHeader.sFilterFromValue;
                        }
                        if (!a24Core.isEmpty(objHeader.sFilterToValue)) {
                            objNewQueryParams[sProperty + "To"].sValue = objHeader.sFilterToValue;
                        }
                    }
                } else {
                    if (a24Core.isEmpty(objHeader.sFilterValue)) {
                        objNewQueryParams[sProperty].sValue = "";
                    } else {
                        objNewQueryParams[sProperty].sValue = objHeader.sFilterValue;
                    }
                }

                //Set the sort if the column has it applied
                if (!a24Core.isEmpty(objHeader.sAsc) && !a24Core.isEmpty(objNewQueryParams.sortBy)) {
                    sSortBy = sProperty + "::" + objHeader.sAsc;
                    objNewQueryParams.sortBy.sValue = sSortBy;
                }
            }

            //This is internal change so remove observer from the _sSortParam while changing it
            this.removeObserver("_sSortParam", this, this.get("_funcSortParamChangeCallback"));
            if (a24Core.isEmpty(sSortBy)) {
                this.set("_sSortParam", "");
            } else {
                this.set("_sSortParam", sSortBy);
            }
            this.addObserver("_sSortParam", this, this.get("_funcSortParamChangeCallback"));

            // Sets the visiable columns query param
            objNewQueryParams.vis_cols.sValue = arrVisibleHeaders.join(",");

            if (!a24Core.isEmpty(objNewQueryParams.page)) {
                objNewQueryParams.page.sValue = this.get("objDatagridConfig.iPage").toString();
            }

            this.set("objQueryParams", objNewQueryParams);

            this.addObserver("objQueryParams", this, this.get("_funcQueryParamsObserverCallback"));
        },
        doServiceCall: function doServiceCall(bFirstPage) {
            var _this2 = this;

            if (!this.get("_bDidInsertElement")) {
                return;
            }
            this.set("_bDatagridLoading", true);

            //Always go back to page 1 unless service call event came from the pager.
            if (bFirstPage) {
                this.set("objDatagridConfig.iPage", 1);
            } else {
                this.updatePageParamFilter();
            }

            var objController = this;
            var objPOSTBody = null;

            var objSuccessCallback = function objSuccessCallback(objResponse, sStatus, jqXHR) {

                objController.set("objDatagridConfig.iResultCount", a24RestCallHelper.getXResultCount(jqXHR));
                objController.set("_arrTableData", a24Core.isEmpty(objResponse) ? [] : objResponse);
                var bDidDoServiceCallHandled = false;
                //This now allows you to either just alter the _arrTableData OR if you want to sort or replace the array
                //with a new one(even from new service call) you can return true and then call the callback to set data
                //later, thus datagrid will stay in loading till then. This technically adds support to datagrid to do more
                //than one call and moesh it all into one response, you would fire the other service calls from the
                //bEmitWillDoServiceCall tho and just wait for them here.
                if (objController.get("objDatagridConfig.bEmitDidDoServiceCall")) {
                    bDidDoServiceCallHandled = objController.sendAction("onDidDoServiceCall", objController.get("_arrTableData"), jqXHR, Ember.copy(objPOSTBody, true), objController.get("objDatagridConfig.objPermanentQuery") ? objController.get("objDatagridConfig.objPermanentQuery") : objController.get("objUrlConstantPart"), objController.get("_objFilterParamObject"), objController.get("_objTableParamObject"), objController.get("objDatagridConfig.iPage"), objController.get("objDatagridConfig.iPerPage"), function (arrData) {
                        objController.set("_arrTableData", arrData);
                        objController.set("_bDatagridLoading", false);
                    });
                }
                if (!bDidDoServiceCallHandled) {
                    objController.set("_bDatagridLoading", false);
                }
            };

            var objFailure = a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {
                if (objController.get("objDatagridConfig.bEmitDidDoServiceCallFail")) {
                    objController.sendAction("onDidDoServiceCallFail", sCode, sStatus, objErrorThrown, objError);
                } else if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    if (_this2.get("objDatagridConfig.bHasReloadButton")) {
                        _this2.set("_bDatagridLoading", false);

                        // display snackbar message
                        _this2.get("_objSnackbarService").showSnackbarMessage(_this2.get("_objEmberLibStringService").getString("datagridReload"), _this2.get("_objEmberLibStringService").getString("reload"), function () {
                            _this2.doServiceCall(false);
                        });
                    }
                }
            });

            /**
             * This if makes the datagrid either of GET type or of POST type
             */
            if (this.get("bPostTypeDatagrid") || this.get("bPostTypeDatagridV2")) {
                if (this.get("bPostTypeDatagrid")) {
                    objPOSTBody = a24RestCallHelper.convertHooksToPostBody(this.get("_objFilterParamObject"), this.get("objDatagridConfig.objPermanentQuery"), this.get("_objTableParamObject"), this.get("objDatagridConfig.iPage"), this.get("objDatagridConfig.iPerPage"));
                } else if (this.get("bPostTypeDatagridV2")) {
                    objPOSTBody = a24RestCallHelper.convertHooksToPostBodyV2(this.get("_objFilterParamObject"), this.get("objDatagridConfig.objPermanentQuery"), this.get("_objTableParamObject"), this.get("objDatagridConfig.iPage"), this.get("objDatagridConfig.iPerPage"));
                }

                var bWillDoServiceCallHandled = false;
                if (this.get("objDatagridConfig.bEmitWillDoServiceCall")) {
                    if (!a24Core.isEmpty(this.get("onWillDoServiceCall"))) {
                        //This now allows you to either just alter the query params before the service call happens OR
                        //to return true and then handle the service call/data on your own and then call the
                        //objSuccessCallback or objFailure passed along to the action.
                        bWillDoServiceCallHandled = this.get("onWillDoServiceCall")(this.get("_arrTableData"), this.get("objDatagridConfig.objPermanentQuery"), this.get("_objFilterParamObject"), this.get("_objTableParamObject"), this.get("objDatagridConfig.iPage"), this.get("objDatagridConfig.iPerPage"), objSuccessCallback, objFailure);
                    }
                }

                if (!bWillDoServiceCallHandled) {
                    a24RestCallHelper.doRestServiceCall(objController, "_objUrlCall", a24RestCallHelper.makeJsonAjaxCall(objController, objController.get("_objUserSession"), objController.get("_objNavigation"), "POST", this.get("sUrlBase"), objSuccessCallback, objFailure, objPOSTBody));
                }
            } else {
                //Build up the complete url using the url param query objects from the filter, pager and table sort
                if (objController.get("bSwaggerServiceCalls")) {
                    a24RestUrlConstruct.setBaseUrlSwagger(this.get("sUrlBase"));
                } else {
                    a24RestUrlConstruct.setBaseUrl(this.get("sUrlBase"));
                }

                var _bWillDoServiceCallHandled = false;
                if (this.get("objDatagridConfig.bEmitWillDoServiceCall")) {
                    if (!a24Core.isEmpty(this.get("onWillDoServiceCall"))) {
                        //This now allows you to either just alter the query params before the service call happens OR
                        //to return true and then handle the service call/data on your own and then call the
                        //objSuccessCallback or objFailure passed along to the action.
                        _bWillDoServiceCallHandled = this.get("onWillDoServiceCall")(this.get("_arrTableData"), this.get("objUrlConstantPart"), this.get("_objFilterParamObject"), this.get("_objTableParamObject"), this.get("objDatagridConfig.iPage"), this.get("objDatagridConfig.iPerPage"), objSuccessCallback, objFailure);
                    }
                }

                var objPageQuery = {
                    page: {
                        mValue: this.get("objDatagridConfig.iPage")
                    },
                    per_page: {
                        mValue: this.get("objDatagridConfig.iPerPage")
                    }
                };

                if (!_bWillDoServiceCallHandled) {
                    a24RestUrlConstruct.addQueryParamObject(this.get("objUrlConstantPart"));
                    a24RestUrlConstruct.addQueryParamObject(this.get("_objFilterParamObject"));
                    a24RestUrlConstruct.addQueryParamObject(this.get("_objTableParamObject"));
                    a24RestUrlConstruct.addQueryParamObject(objPageQuery);

                    var sUrl = a24RestUrlConstruct.getConstructedUrl();

                    a24RestCallHelper.doRestServiceCall(objController, "_objUrlCall", a24RestCallHelper.makeJsonAjaxCall(objController, objController.get("_objUserSession"), objController.get("_objNavigation"), "GET", sUrl, objSuccessCallback, objFailure, null, null, null, !objController.get("bSwaggerServiceCalls")));
                }
            }
        },
        updatePageParamFilter: function updatePageParamFilter() {
            //If we do not have any query params setup then do not run this function
            if (a24Core.isEmpty(this.get("objQueryParams")) || a24Core.isEmpty(this.get("objQueryParams.page"))) {
                return;
            }

            //Remove the _sPageParam observer while we change it internally
            this.removeObserver("_sPageParam", this, this.get("_funcPageParamChangeCallback"));
            this.set("_sPageParam", this.get("objDatagridConfig.iPage").toString());
            //Update the page query param in the url (the query-param-object-builder observes this and set it separately,
            //this is a special case since it it not part of/in the filter params/arrHeaderConfig)
            this.set("objQueryParams.page.sValue", this.get("objDatagridConfig.iPage").toString());
            // Add _sPageParam query params observer
            Ember.run.next(this, function () {
                this.addObserver("_sPageParam", this, this.get("_funcPageParamChangeCallback"));
            });
        },
        cardDatagridFilterInitEventAction: function cardDatagridFilterInitEventAction(objFilterParamObject) {
            this.set("_objFilterParamObject", objFilterParamObject);
            if (this.get("objDatagridConfig.bEmitInitFilterChangeEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridInitFilterChange"))) {
                    this.get("cardDatagridInitFilterChange")(this.get("_objFilterParamObject"));
                }
            }
        },
        cardDatagridTableInitSortAction: function cardDatagridTableInitSortAction(objTableParamObject) {
            this.set("_objTableParamObject", objTableParamObject);
            if (this.get("objDatagridConfig.bEmitInitSortColumnEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridTableInitSort"))) {
                    this.get("cardDatagridTableInitSort")(objTableParamObject);
                }
            }
        },
        cardDatagridFilterChangeEventAction: function cardDatagridFilterChangeEventAction(sProperty, objFilterParamObject) {
            this.set("_objFilterParamObject", objFilterParamObject);

            //If you want to stop the service call and handle it yourself then save the params(or set flag in parent
            //when this event fires) from this event and use the bEmitWillDoServiceCall and calling the objSuccessCallback
            //from that event yourself
            if (this.get("objDatagridConfig.bEmitFilterChangeEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridFilterChange"))) {
                    this.get("cardDatagridFilterChange")(sProperty, this.get("_objFilterParamObject"));
                }
            }
            this.doServiceCall(true);
            this.setQueryParams();
        },
        cardDatagridFilterParamChangeEventAction: function cardDatagridFilterParamChangeEventAction(objFilterParamObject) {
            this.set("_objFilterParamObject", objFilterParamObject);

            //If you want to stop the service call and handle it yourself then save the params(or set flag in parent
            //when this event fires) from this event and use the bEmitWillDoServiceCall and calling the objSuccessCallback
            //from that event yourself
            if (this.get("objDatagridConfig.bEmitFilterChangeEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridFilterChange"))) {
                    this.get("cardDatagridFilterChange")(null, this.get("_objFilterParamObject"));
                }
            }
            this.doServiceCall(true);
            this.updatePageParamFilter();
        },
        cardDatagridTableSortAction: function cardDatagridTableSortAction(sColumn, objTableParamObject) {
            this.set("bShowSaveAction", true);
            this.set("_objTableParamObject", objTableParamObject);

            //If you want to stop the service call and handle it yourself then save the params(or set flag in parent
            //when this event fires) from this event and use the bEmitWillDoServiceCall and calling the objSuccessCallback
            //from that event yourself
            if (this.get("objDatagridConfig.bEmitSortColumnEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridTableSort"))) {
                    this.get("cardDatagridTableSort")(sColumn, this.get("_objTableParamObject"));
                }
            }
            this.doServiceCall(true);
            this.setQueryParams();
        },
        cardDatagridPagerInitEventAction: function cardDatagridPagerInitEventAction(objPageQuery) {
            if (this.get("objDatagridConfig.bEmitPagerInitEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridPagerInitEvent"))) {
                    this.get("cardDatagridPagerInitEvent")(objPageQuery);
                }
            }
        },
        cardDatagridPagerEventAction: function cardDatagridPagerEventAction() {
            //If you want to stop the service call and handle it yourself then save the params(or set flag in parent
            //when this event fires) from this event and use the bEmitWillDoServiceCall and calling the objSuccessCallback
            //from that event yourself
            if (this.get("objDatagridConfig.bEmitPagerEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridPagerEvent"))) {
                    //Page and per page is on datagrid config and can be altered there from parent.
                    this.get("cardDatagridPagerEvent")();
                }
            }
            this.doServiceCall(false);
        },
        cardDatagridTableNoneSelectedAction: function cardDatagridTableNoneSelectedAction() {
            this.set("_arrSelectedItems", null);
            this.set("bDisableFilterKeyboardShortcuts", false);
            if (this.get("objDatagridConfig.bEmitSelectRowEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridTableNoneSelected"))) {
                    this.get("cardDatagridTableNoneSelected")([]);
                }
            }
        },
        funcSelectedItemActionFilter: function funcSelectedItemActionFilter(arrItems) {
            return function (objAction) {
                return a24Core.isEmpty(objAction.funcFilterFunc) || !a24Core.isEmpty(objAction.funcFilterFunc) && objAction.funcFilterFunc(arrItems);
            };
        },
        cardDatagridTableOneSelectedAction: function cardDatagridTableOneSelectedAction(arrItems) {
            this.set("_arrSelectedItems", arrItems);
            this.set("bDisableFilterKeyboardShortcuts", true);
            var arrActions = this.get("objDatagridConfig.arrSingleItemSelectActions");

            if (!a24Core.isEmpty(arrActions)) {
                arrActions = arrActions.filter(this.funcSelectedItemActionFilter(arrItems));
            }

            this.set("_arrActionItems", arrActions);
            if (this.get("objDatagridConfig.bEmitSelectRowEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridTableOneSelected"))) {
                    this.get("cardDatagridTableOneSelected")(arrItems);
                }
            }
        },
        cardDatagridTableMultiSelectedAction: function cardDatagridTableMultiSelectedAction(arrItems) {
            this.set("_arrSelectedItems", arrItems);
            this.set("bDisableFilterKeyboardShortcuts", true);
            var arrActions = this.get("objDatagridConfig.arrMultiItemSelectActions");

            if (!a24Core.isEmpty(arrActions)) {
                arrActions = arrActions.filter(this.funcSelectedItemActionFilter(arrItems));
            }

            this.set("_arrActionItems", arrActions);
            if (this.get("objDatagridConfig.bEmitSelectRowEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridTableMultiSelected"))) {
                    this.get("cardDatagridTableMultiSelected")(arrItems);
                }
            }
        },
        cardDatagridTableCellAction: function cardDatagridTableCellAction(sProperty, objRowData) {
            if (!a24Core.isEmpty(this.get("cardDatagridTableCell"))) {
                this.get("cardDatagridTableCell")(sProperty, objRowData);
            }
        },
        actions: {
            cardDatagridFilterDropdownOpenEvent: function cardDatagridFilterDropdownOpenEvent() {
                this.set("bDisableTableKeyboardShortcuts", true);
            },
            cardDatagridFilterDropdownCloseEvent: function cardDatagridFilterDropdownCloseEvent() {
                this.set("bDisableTableKeyboardShortcuts", false);
            },
            cardDatagridFilterInitEvent: function cardDatagridFilterInitEvent(objFilterParamObject) {
                this.cardDatagridFilterInitEventAction(objFilterParamObject);
            },
            cardDatagridTableInitSort: function cardDatagridTableInitSort(objTableParamObject) {
                this.cardDatagridTableInitSortAction(objTableParamObject);
            },
            cardDatagridFilterChangeEvent: function cardDatagridFilterChangeEvent(sProperty, objFilterParamObject) {
                this.cardDatagridFilterChangeEventAction(sProperty, objFilterParamObject);
            },
            cardDatagridFilterParamChangeEvent: function cardDatagridFilterParamChangeEvent(objFilterParamObject) {
                this.cardDatagridFilterParamChangeEventAction(objFilterParamObject);
            },
            cardDatagridTableSort: function cardDatagridTableSort(sColumn, objTableParamObject) {
                this.cardDatagridTableSortAction(sColumn, objTableParamObject);
            },
            cardDatagridPagerInitEvent: function cardDatagridPagerInitEvent(objPageQuery) {
                this.cardDatagridPagerInitEventAction(objPageQuery);
            },
            cardDatagridPagerEvent: function cardDatagridPagerEvent() {
                this.cardDatagridPagerEventAction();
            },
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this.cardDatagridTableNoneSelectedAction();
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrItems) {
                this.cardDatagridTableOneSelectedAction(arrItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrItems) {
                this.cardDatagridTableMultiSelectedAction(arrItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell(sProperty, objRowData) {
                this.cardDatagridTableCellAction(sProperty, objRowData);
            },
            cardDatagridColumnToggleChangeEvent: function cardDatagridColumnToggleChangeEvent() {
                this.setQueryParams();
            },
            cardDatagridReloadButtonClickEvent: function cardDatagridReloadButtonClickEvent() {
                this.doServiceCall(false);
            }
        }
    });
});
define("a24-ember-lib/components/view-more-component", ["exports", "a24-ember-lib/templates/components/view-more-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _viewMoreComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _viewMoreComponent.default,
        // header for the view more trigger
        sViewMoreLabel: null,
        // whether the component is initially expanded or collapsed
        bExpanded: false,
        // initial height of the component
        iHeight: null,
        // whether to display the horizontal line
        bDisplayHorizRule: false,

        _sItemId: null,
        // whether to display the fadeout div
        _bDisplayFadeOutDiv: true,
        // Will keep track of whether the view-more should actually be shown
        _bShouldViewMore: false,
        _bDoHeightCalc: true,
        _objStringsService: Ember.inject.service("a24-strings"),
        _objWindowConstants: Ember.inject.service("a24-constants-window"),
        init: function init() {
            this._super.apply(this, arguments);
            // this is done so that the _sItemId can passed in during testing and
            // we can test that it is set correct
            if (a24Core.isEmpty(this.get("_sItemId"))) {
                this.set("_sItemId", Ember.guidFor({}));
            }
            // set view more label
            this.set("sViewMoreLabel", this.get("_objStringsService").getString("viewMore"));
        },
        resizeBasedOnRenderCount: Ember.observer("_objDidRenderService._iCountRender", function () {
            var _this = this;

            if (this.get("_bDoHeightCalc")) {
                Ember.run.next(function () {
                    // Get the specified height in rem
                    var iSectionHeight = _this.get("iHeight");

                    // Compare the rem height with the px height
                    var bShouldHaveViewMore = iSectionHeight * _this.get("_objWindowConstants.SCREEN_REM_BASE") < _this.$().outerHeight();
                    _this.set("_bShouldViewMore", bShouldHaveViewMore);
                    if (bShouldHaveViewMore) {

                        Ember.run.scheduleOnce("afterRender", function () {
                            Ember.run.next(function () {
                                // set the styles
                                $("#" + _this.get("_sItemId")).css("height", iSectionHeight + "rem");
                                $("#" + _this.get("_sItemId")).css("min-height", iSectionHeight + "rem");
                            });
                        });
                    }
                });
            }
        }),
        actions: {
            onCollapseExpandStart: function onCollapseExpandStart(sCollapseId) {
                this.set("_bDoHeightCalc", true);
                if (!a24Core.isEmpty(this.get("onCollapseExpandStart"))) {
                    return this.get("onCollapseExpandStart")(sCollapseId);
                }
            },
            onCollapseExpandEnd: function onCollapseExpandEnd(sCollapseId) {
                this.set("_bDoHeightCalc", false);
                // set view more label to View Less
                this.set("sViewMoreLabel", this.get("_objStringsService").getString("viewLess"));
                // hide the fadeout div
                this.set("_bDisplayFadeOutDiv", false);
                if (!a24Core.isEmpty(this.get("onCollapseExpandEnd"))) {
                    return this.get("onCollapseExpandEnd")(sCollapseId);
                }
            },
            onCollapseCollapseStart: function onCollapseCollapseStart(sCollapseId) {
                this.set("_bDoHeightCalc", false);
                // set view more label to View More
                this.set("sViewMoreLabel", this.get("_objStringsService").getString("viewMore"));
                // show the fadeout div
                this.set("_bDisplayFadeOutDiv", true);
                if (!a24Core.isEmpty(this.get("onCollapseCollapseStart"))) {
                    return this.get("onCollapseCollapseStart")(sCollapseId);
                }
            },
            onCollapseCollapseEnd: function onCollapseCollapseEnd(sCollapseId) {
                this.set("_bDoHeightCalc", true);
                if (!a24Core.isEmpty(this.get("onCollapseCollapseEnd"))) {
                    return this.get("onCollapseCollapseEnd")(sCollapseId);
                }
            }
        }
    });
});
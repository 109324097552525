define("a24-ember-staffshift-core/services/chatter-iframe-com", ["exports", "a24-ember-lib/services/chatter-iframe-com-base"], function (exports, _chatterIframeComBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _chatterIframeComBase.default.extend({
        _momentHelper: null,
        _sPrevVisitTime: null,
        _objUserSession: Ember.inject.service("user-session"),
        _objConstantsSSCore: Ember.inject.service("a24-constants-staffshift-core"),

        init: function init() {
            this._super.apply(this, arguments);

            if (a24Core.isEmpty(this.get("_momentHelper"))) {
                this.set("_momentHelper", momentHelper);
            }
        },

        postSessionData: function postSessionData(bNewVisit, sFinalPageName, objRouteParams, sTargetUrl, objTarget) {
            if (a24Core.isEmpty(this.get("_sPrevVisitTime")) || bNewVisit) {
                this.set("_sPrevVisitTime", this.get("_momentHelper").getCurrentDateString(a24Constants.DATE_FORMAT_REST_FORMAT));
            }

            var objBrowser = window.browserReportSync();
            objBrowser.timezone = this.get("_momentHelper").timezoneGuess();
            objBrowser.timezone_offset = this._getOffset();
            objBrowser.timestamp = this.get("_sPrevVisitTime"); //override timestamp so that it is in correct format

            //This will convert from the old triage date format to the "new" current app rest format e.g
            //14 June 2018 07:24:09 +0000
            //2018-06-14T09:24:09+02:00 -->Note timezone change since it will go to current browser timezone, which is fine
            //since we will use setDateFromRestAllowTimeAlter on the other side which will correct this.
            var sVerifyDate = null;
            if (!a24Core.isEmpty(this.get("_objUserSession.objAuthData.identity.dtEmailVerified"))) {
                sVerifyDate = this.get("_momentHelper").stringFormat(this.get("_objUserSession.objAuthData.identity.dtEmailVerified"), this.get("_objConstantsSSCore").DATE_FORMAT_TRIAGE_REST_FORMAT, a24Constants.DATE_FORMAT_REST_FORMAT);
            }
            var sVerifyEmailLastSentDate = null;
            if (!a24Core.isEmpty(this.get("_objUserSession.objAuthData.identity.dtLastEmailVerificationSent"))) {
                sVerifyEmailLastSentDate = this.get("_momentHelper").stringFormat(this.get("_objUserSession.objAuthData.identity.dtLastEmailVerificationSent"), this.get("_objConstantsSSCore").DATE_FORMAT_TRIAGE_REST_FORMAT, a24Constants.DATE_FORMAT_REST_FORMAT);
            }

            //NOTE The chat app relies on te properies marked as CHAT_USE and should not be removed or moved carelessly
            var objData = {
                type: "user_attributes",
                data: {
                    ss: {
                        current_page: {
                            visited_date: this.get("_sPrevVisitTime"), //CHAT_USE
                            full_url: a24Core.getCurrentUrl(), //CHAT_USE
                            page_name: sFinalPageName,
                            page_route_params: objRouteParams
                        },
                        context: {
                            id: this.get("_objUserSession.objAuthData.context.objReferenceId.mId"), //CHAT_USE
                            name: this.get("_objUserSession.objAuthData.context.sName"),
                            resource: this.get("_objUserSession.objAuthData.context.objReferenceId.sResource"), //CHAT_USE
                            avatar: this.get("_objUserSession.objAuthData.context.sAvatarUrl"),
                            timezone: this.get("_objUserSession.objContextDetails.sTimezone"),
                            locale: this.get("_objUserSession.objContextDetails.sLocaleId")
                        },
                        user: {
                            id: this.get("_objUserSession.objAuthData.identity.id"), //CHAT_USE
                            first_name: this.get("_objUserSession.objAuthData.identity.sFirstName"), //CHAT_USE
                            last_name: this.get("_objUserSession.objAuthData.identity.sLastName"), //CHAT_USE
                            username: this.get("_objUserSession.objAuthData.identity.sUsername"), //CHAT_USE
                            timezone: this.get("_objUserSession.objAuthData.identity.sTimezone"),
                            locale: this.get("_objUserSession.objAuthData.identity.sLocaleId"),
                            email_verified_date: sVerifyDate,
                            email_last_verify_email_sent_date: sVerifyEmailLastSentDate,
                            avatar: this.get("_objUserSession.objAuthData.identity.sAvatarUrl"), //CHAT_USE
                            avatar_thumbnail: this.get("_objUserSession.objAuthData.identity.sAvatarThumbnailUrl") //CHAT_USE
                        },
                        browser: objBrowser
                    }
                }
            };

            if (!a24Core.isEmpty(sTargetUrl) && !a24Core.isEmpty(objTarget)) {
                this.postMessage(JSON.stringify(objData), sTargetUrl, objTarget);
            }

            return objData;
        },

        _getOffset: function _getOffset() {
            return -new Date().getTimezoneOffset();
        }
    });
});
define("a24-ember-candidate-profile/components/candidate-position-held", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/candidate-position-held"], function (exports, _didRenderChangesMixin, _candidatePositionHeld) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _candidatePositionHeld.default,
        objCandidatePositionHeldRestService: null,
        sCandidateId: null,

        _bLoading: false,
        _arrPositionHeld: null,
        _objChartData: null,
        _objChartOptions: null,
        _arrReloadActions: null,
        _objCandidatePositionHeldServicePromise: null,

        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.getPositionsHeld();
                }
            }]);

            this.getPositionsHeld();
        },

        getPositionsHeld: function getPositionsHeld() {
            var _this2 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);

            this.get("objCandidatePositionHeldRestService").getPositionsHeld(this, "_objCandidatePositionHeldServicePromise", this.get("sCandidateId"), function (arrData) {
                if (a24Core.isEmpty(arrData)) {
                    _this2.set("_arrPositionHeld", []);
                    _this2.setupChartData([]);
                } else {
                    _this2.set("_arrPositionHeld", arrData);
                    _this2.setupChartData(arrData);
                }
                _this2.set("_bLoading", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this2.set("_bLoading", false);
                _this2.set("_bServerError", true);
            }), {
                items_per_page: {
                    mValue: 1000
                },
                overall_duration: {
                    mValue: "desc",
                    bSortBy: true
                }
            });
        },

        setupChartData: function setupChartData(arrData) {
            var _this3 = this;

            var arrChartLabels = arrData.map(function (objData) {
                return [objData.job_family_name, objData.job_title_name];
            });

            var iSecondsInAYear = 60 * 60 * 24 * 365;
            var objCandidateProfileStringsService = this.get("_objCandidateProfileStringsService");

            this.set("_objChartData", {
                labels: arrChartLabels,
                datasets: [{
                    label: objCandidateProfileStringsService.getString("verified"),
                    backgroundColor: this.get("_objLibConstants.COLOR_SUCCESS"),
                    data: arrData.map(function (objData) {
                        var fValue = 0.0;
                        if (!a24Core.isEmpty(objData.states)) {
                            var objVerified = objData.states.findBy("status", _this3.get("_objCandidateProfileEnumsService.positionHeldStatus.VERIFIED.backend"));
                            if (!a24Core.isEmpty(objVerified)) {
                                fValue = objVerified.total_time / iSecondsInAYear;
                            }
                        }
                        return fValue;
                    })
                }, {
                    label: objCandidateProfileStringsService.getString("unverified"),
                    backgroundColor: this.get("_objLibConstants.COLOR_WARNING"),
                    data: arrData.map(function (objData) {
                        var fValue = 0.0;
                        if (!a24Core.isEmpty(objData.states)) {
                            var objVerified = objData.states.findBy("status", _this3.get("_objCandidateProfileEnumsService.positionHeldStatus.VERIFIED.backend"));
                            var objUnverified = objData.states.findBy("status", _this3.get("_objCandidateProfileEnumsService.positionHeldStatus.UNVERIFIED.backend"));
                            if (!a24Core.isEmpty(objVerified) && !a24Core.isEmpty(objUnverified)) {
                                fValue = (objVerified.total_time + objUnverified.total_time) / iSecondsInAYear;
                            } else if (!a24Core.isEmpty(objUnverified)) {
                                fValue = objUnverified.total_time / iSecondsInAYear;
                            }
                        }
                        return fValue;
                    })
                }],
                borderWidth: 0
            });

            this.set("_objChartOptions", {
                tooltips: {
                    callbacks: {
                        label: function label(objTooltipItem, objData) {
                            var iValue = objTooltipItem.xLabel;

                            if (objTooltipItem.datasetIndex === 1) {
                                iValue = objTooltipItem.xLabel - objData.datasets[0].data[objTooltipItem.index];
                            }

                            if (iValue <= 0) {
                                return;
                            }

                            var iYear = parseInt(iValue);
                            var iMonth = Math.round(12 * (iValue - iYear));
                            var sLabel = objData.datasets[objTooltipItem.datasetIndex].label + ": ";

                            if (iYear <= 0 && iMonth <= 0) {
                                return sLabel + objCandidateProfileStringsService.getString("lessThanAMonth");
                            }

                            var sYear = null;
                            if (iYear > 1) {
                                sYear = objCandidateProfileStringsService.getString("years").toLowerCase();
                            } else {
                                sYear = objCandidateProfileStringsService.getString("year").toLowerCase();
                            }

                            var sMonth = null;
                            if (iMonth > 1) {
                                sMonth = objCandidateProfileStringsService.getString("months").toLowerCase();
                            } else {
                                sMonth = objCandidateProfileStringsService.getString("month").toLowerCase();
                            }

                            if (!a24Core.isEmpty(iYear) && iYear > 0) {
                                sLabel += iYear + " " + sYear;
                            }

                            if (!a24Core.isEmpty(iMonth) && iMonth > 0) {
                                if (!a24Core.isEmpty(iYear) && iYear > 0) {
                                    sLabel += ", ";
                                }
                                sLabel += iMonth + " " + sMonth;
                            }

                            return sLabel;
                        }
                    }
                },
                title: {
                    display: true,
                    position: "bottom",
                    text: objCandidateProfileStringsService.getString("totalYearsOfExperience")
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: true
                        },
                        ticks: {
                            beginAtZero: true,
                            callback: function callback(iValue /*, index, values*/) {
                                if (iValue !== 0) {
                                    return iValue;
                                }
                            }
                        }
                    }],
                    yAxes: [{
                        stacked: true,
                        barThickness: 30,
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                },
                responsive: true,
                maintainAspectRatio: false
            });
        }
    });
});
define("a24-ember-candidate-profile/components/tab-agency-candidate-profile-documents", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-candidate-profile/templates/components/tab-agency-candidate-profile-documents"], function (exports, _didRenderChangesMixin, _tabAgencyCandidateProfileDocuments) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _tabAgencyCandidateProfileDocuments.default,
        _sCandidateId: null,
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objDocumentsService: Ember.inject.service("rest-agency-candidate-documents"),
        _objDocumentTypesService: Ember.inject.service("rest-agency-candidate-document-types"),
        _objBasicInfoService: Ember.inject.service("rest-agency-candidate-basic-info"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objStatusErrorPageService: Ember.inject.service("status-error-page"),
        _bDisplayPermissionError: false,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var objCandidateViewParams = this.get("_objNavigation.objRouteParams")["index.agency-candidate-profile"];

            var objCandidateSearchViewParams = this.get("_objNavigation.objRouteParams")["index.agency-candidate-search-view"];

            if (!a24Core.isEmpty(objCandidateViewParams)) {
                this.set("_sCandidateId", objCandidateViewParams.candidate_id);
            }
            if (!a24Core.isEmpty(objCandidateSearchViewParams)) {
                this.set("_sCandidateId", objCandidateSearchViewParams.candidate_id);
            }

            this.get("_objTitleBarService").setTitleBar(this.get("_objStringsCandidateProfile").getString("candidateProfile"), function () {
                _this.get("_objNavigation").navigateBack();
            }, false, this.get("_objTitleBarService.sRightComponent"), this.get("_objTitleBarService.objRightComponentData"), this.get("_objTitleBarService.onRightComponentAction"));

            this.getBasicInfo();
        },

        getBasicInfo: function getBasicInfo() {
            var _this2 = this;

            this.get("_objBasicInfoService").getBasicInfo(this, "_objBasicInfoGetPromise", this.get("_sCandidateId"), function (objData) {
                var sCandidateProfile = _this2.get("_objStringsCandidateProfile").getString("candidateProfile");
                var sCandidateName = objData.first_name + " " + objData.last_name;
                _this2.get("_objTitleBarService").setTitleBar(sCandidateProfile + " - " + sCandidateName, function () {
                    _this2.get("_objNavigation").navigateBack();
                }, false, _this2.get("_objTitleBarService.sRightComponent"), _this2.get("_objTitleBarService.objRightComponentData"), _this2.get("_objTitleBarService.onRightComponentAction"));
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
            function (sCode) {
                if (sCode === "403") {
                    _this2.set("_objStatusErrorPageService.sIcon", "warning");
                    _this2.set("_objStatusErrorPageService.sMessage", _this2.get("_objStringsCandidateProfile").getString("permissionsError"));
                    _this2.set("_bDisplayPermissionError", true);
                }
            }));
        }
    });
});
define("a24-ember-title-bar/services/title-bar", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        sTitle: null,
        onBack: null,
        bBackDisabled: false,
        sRightComponent: null,
        objRightComponentData: null,
        onRightComponentAction: null,
        sRightBottomComponent: null,
        objRightBottomComponentData: null,
        onRightBottomComponentAction: null,
        arrTabs: null,
        arrOverflowTabs: null,
        funcTitleBarCallback: null,

        _objPreviousActiveTab: null,

        setTitleBar: function setTitleBar(sTitle, onBack, bBackDisabled, sRightComponent, objRightComponentData, onRightComponentAction, sRightBottomComponent, objRightBottomComponentData, onRightBottomComponentAction) {
            var _this = this;

            // This is needed because the title-bar now acts like a singleton component
            Ember.run.schedule("afterRender", function () {
                if (!a24Core.isEmpty(sTitle)) {
                    _this.set("sTitle", sTitle);
                } else {
                    _this.set("sTitle", null);
                }
                if (!a24Core.isEmpty(onBack)) {
                    _this.set("onBack", onBack);
                } else {
                    _this.set("onBack", null);
                }
                if (!a24Core.isEmpty(bBackDisabled)) {
                    _this.set("bBackDisabled", bBackDisabled);
                } else {
                    _this.set("bBackDisabled", false);
                }
                if (!a24Core.isEmpty(sRightComponent)) {
                    _this.set("sRightComponent", sRightComponent);
                } else {
                    _this.set("sRightComponent", null);
                }
                if (!a24Core.isEmpty(objRightComponentData)) {
                    _this.set("objRightComponentData", objRightComponentData);
                } else {
                    _this.set("objRightComponentData", null);
                }
                if (!a24Core.isEmpty(onRightComponentAction)) {
                    _this.set("onRightComponentAction", onRightComponentAction);
                } else {
                    _this.set("onRightComponentAction", null);
                }
                if (!a24Core.isEmpty(sRightBottomComponent)) {
                    _this.set("sRightBottomComponent", sRightBottomComponent);
                } else {
                    _this.set("sRightBottomComponent", null);
                }
                if (!a24Core.isEmpty(objRightBottomComponentData)) {
                    _this.set("objRightBottomComponentData", objRightBottomComponentData);
                } else {
                    _this.set("objRightBottomComponentData", null);
                }
                if (!a24Core.isEmpty(onRightBottomComponentAction)) {
                    _this.set("onRightBottomComponentAction", onRightBottomComponentAction);
                } else {
                    _this.set("onRightBottomComponentAction", null);
                }
            });
        },


        setTabItems: function setTabItems(arrTabs, funcTitleBarCallback) {
            if (!a24Core.isEmpty(arrTabs)) {
                this.set("arrTabs", arrTabs);
            } else {
                this.set("arrTabs", null);
            }

            if (!a24Core.isEmpty(funcTitleBarCallback)) {
                this.set("funcTitleBarCallback", funcTitleBarCallback);
            } else {
                this.set("funcTitleBarCallback", function () {});
            }
        },

        setCurrentSelectedTab: function setCurrentSelectedTab(objActiveTab, arrTabs) {

            if (a24Core.isEmpty(objActiveTab)) {
                objActiveTab = this.get("arrTabs").objectAt(0);
            }

            if (this.get("_objPreviousActiveTab") === objActiveTab) {
                return;
            }

            this.get("arrTabs").forEach(function (objTab) {
                Ember.set(objTab, "bActive", objTab === objActiveTab);
            });

            if (!a24Core.isEmpty(this.get("arrOverflowTabs"))) {
                var arrOverflowTabs = this.get("arrOverflowTabs");
                for (var i = 0; i < arrOverflowTabs.length; i++) {
                    Ember.set(arrTabs[arrTabs.length - 1], "bActive", false);
                    if (arrOverflowTabs[i] === objActiveTab) {
                        Ember.set(arrTabs[arrTabs.length - 1], "bActive", true);
                        break;
                    }
                }
            }

            this.set("_objPreviousActiveTab", objActiveTab);
            if (a24Core.isEmpty(objActiveTab.bMore) || objActiveTab.bMore === false) {
                if (typeof this.get("funcTitleBarCallback") === "function") {
                    this.get("funcTitleBarCallback")(objActiveTab);
                }
            }

            return arrTabs;
        }
    });
});
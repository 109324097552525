define("a24-ember-staffshift-core/services/a24-constants-staffshift-core", ["exports", "a24-ember-lookup/services/a24-constants-base"], function (exports, _a24ConstantsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24ConstantsBase.default.extend({
        AGENCY_DEFAULT_AVATAR: "images/defaultAvatar/agencyAvatar.png",
        AGENCY_GROUP_DEFAULT_AVATAR: "images/defaultAvatar/agencygroupAvatar.png",
        CANDIDATE_DEFAULT_AVATAR: "images/defaultAvatar/defaultAvatar.png",
        ORGANISATION_DEFAULT_AVATAR: "images/defaultAvatar/organisationAvatar.png",
        SYSTEM_ADMIN_DEFAULT_AVATAR: "images/defaultAvatar/systemadminAvatar.png",

        DATE_FORMAT_TRIAGE_REST_FORMAT: "DD MMMM YYYY HH:mm:ss ZZ",

        CONTEXT_AGENCY: "Agency",
        CONTEXT_AGENCY_GROUP: "AgencyGroup",
        CONTEXT_CANDIDATE: "Candidate",
        CONTEXT_ORGANISATION: "Organisation",
        CONTEXT_SYSTEM_ADMIN: "SystemAdmin",
        CONTEXT_AGENCY_GROUP_HYPHEN: "Agency-Group",

        CONTEXT_AGENCY_LOWER: "agency",
        CONTEXT_AGENCY_GROUP_LOWER: "agencygroup",
        CONTEXT_SYSTEM_ADMIN_LOWER: "systemadmin",

        CONTEXT_AGENCY_LOWER_HYPHEN: "agency",
        CONTEXT_AGENCY_GROUP_LOWER_HYPHEN: "agency-group",
        CONTEXT_CANDIDATE_LOWER_HYPHEN: "candidate",
        CONTEXT_ORGANISATION_LOWER_HYPHEN: "organisation",
        CONTEXT_SYSTEM_ADMIN_LOWER_HYPHEN: "system-admin",

        // Locale Strings
        LANGUAGE_LOCALE_UK: "en_UK",
        LANGUAGE_LOCALE_SA: "en_ZA",

        //Firebase
        FIREBASE_ERROR_SIGNED_OUT: "signed_out",
        FIREBASE_ERROR_QUERY_NOT_EXIST: "query_not_exist",
        FIREBASE_ERROR_SIGNED_IN_FAILED: "sign_in_failed"
    });
});
define("a24-ember-staffshift-candidate-availability/components/main-availability-page", ["exports", "a24-ember-staffshift-candidate-availability/templates/components/main-availability-page", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _mainAvailabilityPage, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _mainAvailabilityPage.default,

        // PROPERTIES
        _sMode: "Month",
        bEditMode: false,
        bLoading: false,

        dtDate: null,
        sDateStringFormat: null,
        objAvailabilityService: null,
        objBookedShiftsService: null,
        objEmailRestService: null,

        funcOnBack: null,
        arrNumbers: null,
        // SERVICES
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objUserSession: Ember.inject.service("user-session"),
        _objAvailabilityStrings: Ember.inject.service("a24-strings-staffshift-availability"),
        _objConstantsEmberLibService: Ember.inject.service("a24-constants-ember-lib"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        _bShowBulkUpdatePopup: false,
        // COMPUTED PROPERTIES
        bIsDayView: Ember.computed("_sMode", function () {
            return Ember.get(this, "_sMode") === "Day";
        }),
        bIsToday: Ember.computed("sDateStringFormat", "_sMode", function () {
            var objTodayDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
            var objDate = Ember.get(this, "dtDate");

            var bYearMatch = objDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS) === objTodayDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_YEARS);
            var bMonthMatch = objDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS) === objTodayDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS);
            var bDayMatch = objDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_DATE) === objTodayDate.getTimeOnDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_DATE);

            if (Ember.get(this, "_sMode") === "Month") {
                return bYearMatch && bMonthMatch;
            } else {
                return bYearMatch && bMonthMatch && bDayMatch;
            }
        }),

        _bIsCandidateContext: Ember.computed("objAvailabilityService.sContext", function () {
            return this.get("objAvailabilityService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE");
        }),

        objNavigationData: Ember.observer("bEditMode", "bLoading", "bIsToday", "bIsDayView", function () {
            this.setTitleBar();
        }),

        init: function init() {
            this._super.apply(this, arguments);
            this.onTimeChange("today");
            this.setTitleBar();
        },

        // Functions
        onTimeChange: function onTimeChange(sActionName, sSpecific) {
            var objDate = Ember.get(this, "dtDate");
            if (Ember.get(this, "bIsDayView")) {
                // DAY VIEW
                if (sActionName === "today") {
                    objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                } else if (sActionName === "specific") {
                    objDate.setDateFromRest(sSpecific, true);
                } else if (sActionName === "next") {
                    objDate.addTimeToDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_DAYS, 1);
                } else if (sActionName === "back") {
                    objDate.subtractTimeFromDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_DAYS, 1);
                }
            } else {
                // MONTH VIEW
                if (sActionName === "today") {
                    objDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                } else if (sActionName === "next") {
                    objDate.addTimeToDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, 1);
                } else if (sActionName === "back") {
                    objDate.subtractTimeFromDate(this.get("_objConstantsEmberLibService").MOMENT_TIME_MONTHS, 1);
                }
            }

            Ember.set(this, "dtDate", objDate);
            var sDate = objDate.getDateStringForRest(true);
            Ember.set(this, "sDateStringFormat", "");
            Ember.set(this, "sDateStringFormat", sDate);
        },

        setTitleBar: function setTitleBar() {
            var objData = {
                // This is the text button for today
                bTextDisabled: Ember.get(this, "bEditMode") || Ember.get(this, "bLoading") || Ember.get(this, "bIsToday"),
                // Whether to show the day-month dropdown and the current item for it
                bShowDropdown: true,
                bIsViewDropDownDisabled: Ember.get(this, "bEditMode") || Ember.get(this, "bLoading"),
                sView: Ember.get(this, "_sMode"),
                // Back and Forward button
                bBackDisabled: Ember.get(this, "bEditMode") || Ember.get(this, "bLoading"),
                bForwardDisabled: Ember.get(this, "bEditMode") || Ember.get(this, "bLoading"),
                bShowBulkUpdateButton: Ember.get(this, "bEditMode") && !Ember.get(this, "bIsDayView") && !Ember.get(this, "bLoading")
            };

            var funcNavigation = this.navigationAction.bind(this);
            var funcOnBack = this.get("bIsDayView") ? this.backButtonClick.bind(this) : this.get("funcOnBack");

            var sTitle = "";
            if (Ember.get(this, "_bIsCandidateContext")) {
                sTitle = this.get("_objAvailabilityStrings").getString("calendar");
            } else {
                sTitle = this.get("_objTitleBarService.sTitle");
            }

            this.get("_objTitleBarService").setTitleBar(sTitle, funcOnBack, Ember.get(this, "bEditMode") || Ember.get(this, "bLoading"), this.get("_objTitleBarService.sRightComponent"), this.get("_objTitleBarService.objRightComponentData"), this.get("_objTitleBarService.onRightComponentAction"), "time-range-selector", objData, funcNavigation);
        },

        backButtonClick: function backButtonClick() {
            Ember.set(this, "_sMode", "Month");
            this.onTimeChange("today");
        },
        navigationAction: function navigationAction(sAction, sSelectedItem) {
            if (sAction === "view") {
                Ember.set(this, "bLoading", true);
                Ember.set(this, "_sMode", sSelectedItem);
                this.onTimeChange("today");
            } else if (sAction === "bulkUpdate") {
                Ember.set(this, "_bShowBulkUpdatePopup", true);
            } else {
                this.onTimeChange(sAction);
            }
        },

        // ACTIONS
        actions: {
            onEditStateChange: function onEditStateChange(bEdit) {
                Ember.set(this, "bEditMode", bEdit);
            },
            onLoadingStateChange: function onLoadingStateChange(bLoading) {
                Ember.set(this, "bLoading", bLoading);
            },
            onDayClick: function onDayClick(objDay) {
                Ember.set(this, "_sMode", "Day");
                this.onTimeChange("specific", objDay.sId);
            },
            onCalendarLoaded: function onCalendarLoaded() {
                if (!a24Core.isEmpty(Ember.get(this, "onCalendarLoaded"))) {
                    Ember.get(this, "onCalendarLoaded")();
                }
            }
        }
    });
});
define("a24-ember-candidate-profile/components/card-datagrid-custom-filter-candidate-professional-registration", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element", "a24-ember-candidate-profile/templates/components/card-datagrid-custom-filter-candidate-professional-registration"], function (exports, _didRenderChangesMixin, _inputFormElement, _cardDatagridCustomFilterCandidateProfessionalRegistration) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _cardDatagridCustomFilterCandidateProfessionalRegistration.default,
        objHeaderItem: null,

        _arrRegistrationBodyStatuses: null,
        _arrRegistrationBodyEntries: null,
        _arrRegistrationBodyEntryStatuses: null,
        _arrRegistrationBodies: null,

        _objGetRegistrationBodyPromise: null,
        _objGetRegistrationBodyStatusesPromise: null,
        _objGetRegistrationBodyEntriesPromise: null,
        _objGetRegistrationBodyEntryStatusesPromise: null,

        _funcGetRegistrationBodySuggestions: null,

        _bLoadingRegistrationBodyStatuses: false,
        _bLoadingRegistrationBodyEntries: false,
        _bLoadingRegistrationBodyEntryStatuses: false,

        _bShowRegistrationBodyStatusField: false,
        _bShowRegistrationBodyEntryField: false,
        _bShowRegistrationBodyEntryStatusField: false,

        _objSystemService: Ember.inject.service("rest-system"),
        _objFilterCustomCandidateProfRegService: Ember.inject.service("card-datagrid-custom-filter-candidate-professional-registration"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),

        init: function init() {
            this._super.apply(this, arguments);
            this.set("_arrRegistrationBodyStatuses", []);
            this.set("_arrRegistrationBodyEntries", []);
            this.set("_arrRegistrationBodyEntryStatuses", []);
            this.set("_arrRegistrationBodies", this.get("_objFilterCustomCandidateProfRegService._arrRegistrationBodies").map(function (objRegBody) {
                return {
                    name: objRegBody.short_name + " - " + objRegBody.name,
                    _id: objRegBody._id
                };
            }));
        },

        _observeAllValues: Ember.observer("objHeaderItem._objRegistrationBody", "objHeaderItem._objRegistrationBodyStatus", "objHeaderItem._objRegistrationBodyEntry", "objHeaderItem._objRegistrationBodyEntryStatus", function () {
            if (!a24Core.isEmpty(this.get("objChildren._objRegistrationBody")) && a24Core.isEmpty(this.get("objHeaderItem._objRegistrationBody"))) {
                this.get("objChildren._objRegistrationBody").clear();
            }
            if (!a24Core.isEmpty(this.get("objChildren._objRegistrationBodyStatus")) && a24Core.isEmpty(this.get("objHeaderItem._objRegistrationBodyStatus"))) {
                this.get("objChildren._objRegistrationBodyStatus").clear();
            }
            if (!a24Core.isEmpty(this.get("objChildren._objRegistrationBodyEntry")) && a24Core.isEmpty(this.get("objHeaderItem._objRegistrationBodyEntry"))) {
                this.get("objChildren._objRegistrationBodyEntry").clear();
            }
            if (!a24Core.isEmpty(this.get("objChildren._objRegistrationBodyEntryStatus")) && a24Core.isEmpty(this.get("objHeaderItem._objRegistrationBodyEntryStatus"))) {
                this.get("objChildren._objRegistrationBodyEntryStatus").clear();
            }

            Ember.run.once(this, "_setupFilterForEdit");
            Ember.run.once(this, "_setFlags");
        }).on("init"),

        onInputValueChanged: function onInputValueChanged(arrPathToInputMixin) {
            var sLookup = arrPathToInputMixin.join(".");

            if (sLookup === "objChildren._objRegistrationBody") {
                var objRegistrationBody = this.get(sLookup + ".mValue");

                if (!a24Core.isEmpty(objRegistrationBody)) {
                    this.set("objHeaderItem._objRegistrationBody", objRegistrationBody);
                    this.set("_objRegistrationBody", objRegistrationBody);
                    this._getRegistrationBodyStatuses(objRegistrationBody._id);
                    this._getRegistrationBodyEntries(objRegistrationBody._id);
                    this._getRegistrationBodyEntryStatuses(objRegistrationBody._id);
                } else {
                    this.set("objHeaderItem._objRegistrationBody", null);
                    this.set("objHeaderItem._objRegistrationBodyStatus", null);
                    this.set("objHeaderItem._objRegistrationBodyEntry", null);
                    this.set("objHeaderItem._objRegistrationBodyEntryStatus", null);

                    this.set("_arrRegistrationBodyStatuses", []);
                    this.set("_arrRegistrationBodyEntries", []);
                    this.set("_arrRegistrationBodyEntryStatuses", []);
                }
            } else if (sLookup === "objChildren._objRegistrationBodyStatus") {
                var objRegistrationBodyStatus = this.get(sLookup + ".mValue");

                if (!a24Core.isEmpty(objRegistrationBodyStatus)) {
                    this.set("objHeaderItem._objRegistrationBodyStatus", objRegistrationBodyStatus);
                } else {
                    this.set("objHeaderItem._objRegistrationBodyStatus", null);
                }
            } else if (sLookup === "objChildren._objRegistrationBodyEntry") {
                var objRegistrationBodyEntry = this.get(sLookup + ".mValue");

                if (!a24Core.isEmpty(objRegistrationBodyEntry)) {
                    this.set("objHeaderItem._objRegistrationBodyEntry", objRegistrationBodyEntry);
                } else {
                    this.set("objHeaderItem._objRegistrationBodyEntry", null);
                }
            } else if (sLookup === "objChildren._objRegistrationBodyEntryStatus") {
                var objRegistrationBodyEntryStatus = this.get(sLookup + ".mValue");

                if (!a24Core.isEmpty(objRegistrationBodyEntryStatus)) {
                    this.set("objHeaderItem._objRegistrationBodyEntryStatus", objRegistrationBodyEntryStatus);
                } else {
                    this.set("objHeaderItem._objRegistrationBodyEntryStatus", null);
                }
            }
        },

        _getRegistrationBodyStatuses: function _getRegistrationBodyStatuses(sRegistrationBodyId) {
            var _this = this;

            this.set("_bLoadingRegistrationBodyStatuses", true);

            this.get("_objSystemService").getRegistrationBodyStatuses(this, "_objSystemServiceRegistrationBodyStatusesPromise", sRegistrationBodyId, function (arrData) {
                if (a24Core.isEmpty(arrData)) {
                    arrData = [];
                }
                _this.set("_bLoadingRegistrationBodyStatuses", false);
                _this.set("_arrRegistrationBodyStatuses", arrData);
                if (!a24Core.isEmpty(_this.get("objHeaderItem._objRegistrationBodyStatus"))) {
                    _this.set("objHeaderItem._objRegistrationBodyStatus", Ember.copy(_this.get("objHeaderItem._objRegistrationBodyStatus"), true));
                }
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this.set("_bLoadingRegistrationBodyStatuses", false);
                _this.set("_arrRegistrationBodyStatuses", []);
            }), {
                items_per_page: {
                    mValue: 1000
                },
                sortBy: {
                    mValue: "name"
                }
            });
        },

        _getRegistrationBodyEntries: function _getRegistrationBodyEntries(sRegistrationBodyId) {
            var _this2 = this;

            this.set("_bLoadingRegistrationBodyEntries", true);

            this.get("_objSystemService").getRegistrationBodyEntries(this, "_objGetRegistrationBodyEntriesPromise", sRegistrationBodyId, function (arrData) {
                if (a24Core.isEmpty(arrData)) {
                    arrData = [];
                }
                _this2.set("_bLoadingRegistrationBodyEntries", false);
                _this2.set("_arrRegistrationBodyEntries", arrData.map(function (objRegBodyEntry) {
                    return {
                        name: objRegBodyEntry.short_name + " - " + objRegBodyEntry.name,
                        _id: objRegBodyEntry._id
                    };
                }));

                if (!a24Core.isEmpty(_this2.get("objHeaderItem._objRegistrationBodyEntry"))) {
                    _this2.set("objHeaderItem._objRegistrationBodyEntry", Ember.copy(_this2.get("objHeaderItem._objRegistrationBodyEntry"), true));
                }
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this2.set("_bLoadingRegistrationBodyEntries", false);
                _this2.set("_arrRegistrationBodyEntries", []);
            }), {
                items_per_page: {
                    mValue: 1000
                },
                sortBy: {
                    mValue: "name"
                }
            });
        },

        _getRegistrationBodyEntryStatuses: function _getRegistrationBodyEntryStatuses(sRegistrationBodyId) {
            var _this3 = this;

            this.set("_bLoadingRegistrationBodyEntryStatuses", true);

            this.get("_objSystemService").getRegistrationBodyEntryStatuses(this, "_objGetRegistrationBodyEntryStatusesPromise", sRegistrationBodyId, function (arrData) {
                if (a24Core.isEmpty(arrData)) {
                    arrData = [];
                }
                _this3.set("_bLoadingRegistrationBodyEntryStatuses", false);
                _this3.set("_arrRegistrationBodyEntryStatuses", arrData);
                if (!a24Core.isEmpty(_this3.get("objHeaderItem._objRegistrationBodyEntryStatus"))) {
                    _this3.set("objHeaderItem._objRegistrationBodyEntryStatus", Ember.copy(_this3.get("objHeaderItem._objRegistrationBodyEntryStatus"), true));
                }
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this3.set("_bLoadingRegistrationBodyEntryStatuses", false);
                _this3.set("_arrRegistrationBodyEntryStatuses", []);
            }), {
                items_per_page: {
                    mValue: 1000
                },
                sortBy: {
                    mValue: "name"
                }
            });
        },

        _setupFilterForEdit: function _setupFilterForEdit() {
            var _this4 = this;

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    if (!a24Core.isEmpty(_this4.get("objHeaderItem._objRegistrationBody")) && a24Core.isEmpty(_this4.get("objChildren._objRegistrationBody.mValue"))) {
                        _this4.set("_objRegistrationBody", Ember.copy(_this4.get("objHeaderItem._objRegistrationBody"), true));
                    }
                });
            });
        },

        _setFlags: function _setFlags() {
            if (!a24Core.isEmpty(this.get("objHeaderItem._objRegistrationBody"))) {
                this.set("_bShowRegistrationBodyStatusField", true);
                this.set("_bShowRegistrationBodyEntryField", true);
                this.set("_bShowRegistrationBodyEntryStatusField", true);
                this.customFilterDisplayAddButtonEvent(true);
            } else {
                this.set("_bShowRegistrationBodyStatusField", false);
                this.set("_bShowRegistrationBodyEntryField", false);
                this.set("_bShowRegistrationBodyEntryStatusField", false);
                this.customFilterDisplayAddButtonEvent(false);
            }
        }
    });
});
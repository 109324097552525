define("a24-ember-candidate-profile/components/candidate-status", ["exports", "a24-ember-candidate-profile/templates/components/candidate-status", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _candidateStatus, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _candidateStatus.default,
        objStatusRestService: null,
        objCandidateStatusRestService: null,
        objStatusReasonRestService: null,
        sCandidateId: null,

        _bLoading: true,
        _bPopupLoading: true,
        _bShowStatusHistory: false,
        _bShowEditPopup: false,
        _bShowUnsavedData: false,
        _bShowStatusReason: false,
        _bAllowAddEdit: true,
        _bStatusReasonLoading: true,

        _sHeaderPopup: null,

        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUserSession: Ember.inject.service("user-session"),

        _objCandidateStatusPromise: null,
        _objStatusesPromise: null,
        _objCandidateStatusHistoryPromise: null,
        _objCandidateStatusReasonPromise: null,
        _objCandidateStatusUpdatePromise: null,

        _objRestCandidateStatusProperty: null,

        _arrStatusDropdown: null,
        _arrReasonDropdown: null,
        _arrStatuses: null,
        _arrStatusData: null,
        _arrCandidateStatuses: null,

        _objCandidateStatus: null,

        _bServerError: false,
        _arrReloadActions: null,

        _iResultCount: 0,
        _iPage: 1,
        _iPerPage: 10,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.doServiceCalls();
                }
            }]);

            this.set("_sHeaderPopup", this.get("_objCandidateProfileStringsService").getString("updateStatus"));

            if (this.get("_bAllowAddEdit")) {
                if (this.get("_bIsAgencyGroupContext")) {
                    this.set("_arrHeaderActions", [{
                        sTitle: this.get("_objStringsService").getString("edit"),
                        sIcon: "edit",
                        executeCardAction: function executeCardAction() {
                            _this.setEditPopup();
                        }
                    }]);
                }
            }

            this.set("_objSpecialAddPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.popupAddCancel();
                }
            });

            var objValidationConfig = {};

            objValidationConfig["objChildren.status"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            this.set("objChildConfig", objValidationConfig);

            this.doServiceCalls();
        },

        _bIsAgencyGroupContext: Ember.computed("objCandidateStatusRestService.sContext", function () {
            return this.get("objCandidateStatusRestService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP");
        }),

        doServiceCalls: function doServiceCalls() {
            var _this2 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);
            this.set("_bShowStatusHistory", false);

            var objPromiseCandidateStatus = this.get("objCandidateStatusRestService").getCandidateStatusSummary(this, "_objCandidateStatusPromise", this.get("sCandidateId"), function () {}, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode) {

                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this2.set("_bServerError", true);
                    return;
                }
                //Handle other codes as no status
            }));

            var objServiceCalls = {
                _objRestCandidateStatusProperty: objPromiseCandidateStatus
            };
            var arrRequiredResponses = ["_objRestCandidateStatusProperty"];

            if (this.get("_bIsAgencyGroupContext")) {
                var objPromiseStatuses = this.get("objStatusRestService").getStatusList(this, "_objStatusesPromise", function () {}, // handled in rsvp
                a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode) {

                    if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                        _this2.set("_bServerError", true);
                        return;
                    }

                    //Handle other codes as no status
                }));

                objServiceCalls._objRestStatusesProperty = objPromiseStatuses;
                arrRequiredResponses.push("_objRestStatusesProperty");
            }

            Ember.copy(a24RSVP).configure(this, objServiceCalls, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objRestCandidateStatusProperty) && !a24Core.isEmpty(objResponses._objRestCandidateStatusProperty.objHeaders) && objResponses._objRestCandidateStatusProperty.objHeaders.statusText === "abort" || !a24Core.isEmpty(objResponses._objRestStatusesProperty) && !a24Core.isEmpty(objResponses._objRestStatusesProperty.objHeaders) && objResponses._objRestStatusesProperty.objHeaders.statusText === "abort") {
                        return;
                    }

                    if (!a24Core.isEmpty(objResponses._objRestCandidateStatusProperty) && !a24Core.isEmpty(objResponses._objRestCandidateStatusProperty.objResponse)) {
                        _this2.set("_arrStatusData", _this2._formatCandidateStatusSummary(objResponses._objRestCandidateStatusProperty.objResponse));
                    } else {
                        _this2.set("_arrStatusData", _this2._formatCandidateStatusSummary(null));
                    }

                    if (!a24Core.isEmpty(objResponses._objRestStatusesProperty) && !a24Core.isEmpty(objResponses._objRestStatusesProperty.objResponse)) {
                        _this2.set("_arrStatuses", objResponses._objRestStatusesProperty.objResponse);
                    } else {
                        _this2.set("_arrStatuses", []);
                    }

                    _this2._prepareForDisplay();
                }
            }]);
        },
        _formatCandidateStatusSummary: function _formatCandidateStatusSummary(objCandidateStatusSummary) {

            var arrItems = [];
            if (a24Core.isEmpty(objCandidateStatusSummary)) {
                objCandidateStatusSummary = {};
            }

            // CURRENT STATUS
            var sCurrentStatus = null;
            var sCurrentStatusSince = null;
            if (!a24Core.isEmpty(objCandidateStatusSummary.current_status)) {
                // remove edit if candidate has status
                if (!a24Core.isEmpty(this.get("_arrHeaderActions"))) {
                    var arrHeaderActions = this.get("_arrHeaderActions");
                    for (var i = 0; i < arrHeaderActions.length; i++) {
                        if (arrHeaderActions[i].sTitle === this.get("_objStringsService").getString("edit")) {
                            this.get("_arrHeaderActions").removeObject(arrHeaderActions[i]);
                            break;
                        }
                    }
                }

                sCurrentStatus = objCandidateStatusSummary.current_status.status;
                if (!a24Core.isEmpty(objCandidateStatusSummary.current_status.date)) {
                    var objCurrentStatusDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objCurrentStatusDate.setDateFromRest(objCandidateStatusSummary.current_status.date, true);
                    sCurrentStatusSince = objCurrentStatusDate.getDateFormat();
                }
            }
            arrItems.push({
                sLabel: this.get("_objCandidateProfileStringsService").getString("currentStatus"),
                sValue: sCurrentStatus,
                bText: true
            });
            arrItems.push({
                sLabel: this.get("_objCandidateProfileStringsService").getString("currentStatusSince"),
                sValue: sCurrentStatusSince,
                bText: true
            });

            // FIRST STATUS
            var sFirstStatus = null;
            var sFirstStatusSince = null;
            if (!a24Core.isEmpty(objCandidateStatusSummary.first_status)) {
                sFirstStatus = objCandidateStatusSummary.first_status.status;
                if (!a24Core.isEmpty(objCandidateStatusSummary.first_status.date)) {
                    var objFirstStatusDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objFirstStatusDate.setDateFromRest(objCandidateStatusSummary.first_status.date, true);
                    sFirstStatusSince = objFirstStatusDate.getDateFormat();
                }
            }
            arrItems.push({
                sLabel: this.get("_objCandidateProfileStringsService").getString("firstStatus"),
                sValue: sFirstStatus,
                bText: true
            });
            arrItems.push({
                sLabel: this.get("_objCandidateProfileStringsService").getString("firstStatusSince"),
                sValue: sFirstStatusSince,
                bText: true
            });

            // LINKED TO
            if (this.get("_bIsAgencyGroupContext")) {
                // This is an agency group
                var sLinkedToAgencyGroupDate = null;

                if (!a24Core.isEmpty(objCandidateStatusSummary.linked_to_agency_group_at)) {
                    var objLinkedDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objLinkedDate.setDateFromRest(objCandidateStatusSummary.linked_to_agency_group_at, true);
                    sLinkedToAgencyGroupDate = objLinkedDate.getDateFormat();
                }

                arrItems.push({
                    sLabel: this.get("_objCandidateProfileStringsService").getString("linkedToGroupSince"),
                    sValue: sLinkedToAgencyGroupDate,
                    bText: true
                });
            } else {
                // This is an agency
                var _sLinkedToAgencyGroupDate = null;
                if (!a24Core.isEmpty(objCandidateStatusSummary.linked_to_agency_at)) {
                    var _objLinkedDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    _objLinkedDate.setDateFromRest(objCandidateStatusSummary.linked_to_agency_at, true);
                    _sLinkedToAgencyGroupDate = _objLinkedDate.getDateFormat();
                }

                arrItems.push({
                    sLabel: this.get("_objCandidateProfileStringsService").getString("linkedToAgencySince"),
                    sValue: _sLinkedToAgencyGroupDate,
                    bText: true
                });
            }

            return [{
                arrItems: arrItems
            }];
        },
        _formatSingleCandidateStatus: function _formatSingleCandidateStatus(objCandidateStatus, bExpanded) {
            var sChangedAtFormatted = momentHelper.getTimeFromNow(objCandidateStatus.changed_at, a24Constants.DATE_FORMAT_REST_FORMAT);

            var sExtraLabel = this.get("_objStringsService").getHTMLTokenString("a24-string-html-dot-value", this.get("_objCandidateProfileStringsService").getString("movedToStatus"), sChangedAtFormatted);

            var objDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            objDate.setDateFromRest(objCandidateStatus.changed_at);

            var objDisplayItem = {
                sLabel: objCandidateStatus.status,
                sExtra: sExtraLabel,
                sIcon: "account_box",
                bExpanded: bExpanded,
                arrItems: [{
                    arrItems: [{
                        sLabel: this.get("_objStringsService").getString("status"),
                        sValue: objCandidateStatus.status,
                        bText: true
                    }, {
                        sLabel: this.get("_objCandidateProfileStringsService").getString("sinceDate"),
                        sValue: objDate.getDateTimeFormat(),
                        bText: true
                    }, {
                        sLabel: this.get("_objCandidateProfileStringsService").getString("changedBy"),
                        sValue: objCandidateStatus.changed_by.name,
                        bText: true
                    }, {
                        sLabel: this.get("_objCandidateProfileStringsService").getString("reason"),
                        sValue: objCandidateStatus.reason,
                        bText: true
                    }, {
                        sLabel: this.get("_objStringsService").getString("comment"),
                        sValue: objCandidateStatus.comment,
                        bText: true
                    }]
                }]
            };

            return objDisplayItem;
        },
        _formatCandidateStatus: function _formatCandidateStatus(arrCandidateStatus) {
            var arrStatus = [];
            for (var i = 0; i < arrCandidateStatus.length; i++) {
                arrStatus.push(this._formatSingleCandidateStatus(arrCandidateStatus[i], false));
            }

            return arrStatus;
        },
        _prepareForDisplay: function _prepareForDisplay() {
            if (this.get("_bIsAgencyGroupContext")) {
                var arrStatuses = this.get("_arrStatuses");
                var arrStatusDropdown = [];

                for (var i = 0; i < arrStatuses.length; i++) {
                    if (!arrStatuses[i].disabled) {
                        var objStatusItem = {
                            sValue: arrStatuses[i]._id + "",
                            sLabel: arrStatuses[i].name
                        };
                        // temporarily setting archived as the only option
                        if (arrStatuses[i].name.toLowerCase() === "archived") {
                            arrStatusDropdown.push(objStatusItem);
                        }
                    }
                }

                this.set("_arrStatusDropdown", arrStatusDropdown);
            }
            this.set("_bLoading", false);
        },

        setEditPopup: function setEditPopup() {
            var _this3 = this;

            this.clearAllErrorMessages();
            this.clearForm();
            this.set("_bPopupLoading", false);

            this.set("_bShowEditPopup", true);
            this.set("_bShowStatusReason", false);
            if (!a24Core.isEmpty(this.get("setBreadCrumbAction"))) {
                this.get("setBreadCrumbAction")();
            }

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this3.set("_objOriginalData", _this3.getPlainFormValueObject());
                });
            });
        },
        popupAddCancel: function popupAddCancel() {
            if (this.get("_bPopupLoading")) {
                return;
            }
            // Check if the form has any unsaved data
            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                this.set("_bShowEditPopup", false);
            } else {
                this.set("_bShowUnsavedData", true);
            }
        },

        _getCandidateStatusHistory: function _getCandidateStatusHistory(objQueryParams) {
            var _this4 = this;

            this.set("_bPageLoading", true);

            if (a24Core.isEmpty(objQueryParams)) {
                objQueryParams = {
                    page: { mValue: this.get("_iPage") },
                    items_per_page: { mValue: this.get("_iPerPage") }
                };
            }

            // Save the current query params for later use.
            this.set("_objQueryParams", Ember.copy(objQueryParams, true));

            this.get("objCandidateStatusRestService").getCandidateStatus(this, "_objCandidateStatusHistoryPromise", this.get("sCandidateId"), function (arrData, sStatus, objXHR) {
                _this4.set("_bShowStatusHistory", true);
                if (!a24Core.isEmpty(arrData)) {
                    _this4.set("_arrCandidateStatuses", _this4._formatCandidateStatus(arrData));
                    _this4.set("_iResultCount", a24RestCallHelper.getXResultCount(objXHR));
                } else {
                    _this4.set("_arrCandidateStatuses", []);
                    _this4.set("_iResultCount", 0);
                }
                _this4.set("_bHistoryLoading", false);
                _this4.set("_bPageLoading", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["404"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                // Clear data
                _this4.set("_arrCandidateStatuses", []);
                _this4.set("_iResultCount", 0);
                _this4.set("_bHistoryLoading", false);
                _this4.set("_bPageLoading", false);
            }), objQueryParams);
        },
        _formatStatusReasonForDropdown: function _formatStatusReasonForDropdown(arrStatusReason) {
            var arrStatusReasonDropdown = [];
            if (!a24Core.isEmpty(arrStatusReason)) {
                for (var i = 0; i < arrStatusReason.length; i++) {
                    var objStatusReasonItem = {
                        sValue: arrStatusReason[i].name,
                        sLabel: arrStatusReason[i].name
                    };
                    arrStatusReasonDropdown.push(objStatusReasonItem);
                }
            }

            return arrStatusReasonDropdown;
        },
        onInputValueChanged: function onInputValueChanged(arrChildRef) {
            var _this5 = this;

            this._super.apply(this, arguments);

            // Get the reference of the field that changed
            var sLookup = arrChildRef.join(".");

            // If the reference matches that of the
            if (sLookup === "objChildren.status") {
                this.set("_bShowStatusReason", false);
                if (!a24Core.isEmpty(this.get(sLookup + ".mValue"))) {
                    this.set("_bShowStatusReason", true);
                    this.set("_bStatusReasonLoading", true);
                    var sStatusId = this.get(sLookup + ".mValue.sValue");

                    this.get("objStatusReasonRestService").getStatusReasons(this, "_objCandidateStatusReasonPromise", sStatusId, function (arrData) {
                        if (!a24Core.isEmpty(arrData)) {
                            _this5.set("_arrReasonDropdown", _this5._formatStatusReasonForDropdown(arrData));
                        } else {
                            _this5.set("_arrReasonDropdown", []);
                            _this5.set("_bShowStatusReason", false);
                        }
                        _this5.set("_bStatusReasonLoading", false);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["404"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Clear data
                        _this5.set("_arrReasonDropdown", []);
                        _this5.set("_bShowStatusReason", false);
                        _this5.set("_bStatusReasonLoading", false);
                    }));
                } else {
                    this.set("_arrReasonDropdown", []);
                }
            }
        },
        actions: {
            cardDatagridPagerEvent: function cardDatagridPagerEvent(objPageQuery) {
                objPageQuery = Ember.copy(objPageQuery, true);

                var objQueryParams = this.get("_objQueryParams");
                objQueryParams.page = objPageQuery.page;
                objQueryParams.items_per_page = objPageQuery.per_page;

                this._getCandidateStatusHistory(objQueryParams);
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                this.set("_bShowEditPopup", false);
                this.set("_bShowUnsavedData", false);
            },
            onPopupEditConfirm: function onPopupEditConfirm() {
                var _this6 = this;

                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    this.set("_bPopupLoading", true);

                    var sCandidateId = this.get("sCandidateId");
                    var objData = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());

                    var arrStatuses = this.get("_arrStatuses");
                    for (var i = 0; i < arrStatuses.length; i++) {
                        if (arrStatuses[i]._id === objData.status) {
                            objData.status = arrStatuses[i].name;
                            break;
                        }
                    }

                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("addingItem", this.get("_objStringsService").getString("status")));

                    this.get("objCandidateStatusRestService").updateCandidateCurrentStatus(this, "_objCandidateStatusUpdatePromise", sCandidateId, objData, function () {
                        _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objStringsService").getTokenString("successAddItem", _this6.get("_objStringsService").getString("status")));

                        _this6.set("_bShowEditPopup", false);

                        _this6.doServiceCalls();
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode, sStatus, objErrorThrown, objError) {

                        if (sCode === "AGENCY_GROUP_STATUS_DISABLED") {
                            _this6.setResponseError(objError.arrPath, "validationStatusDisabled", false, false, _this6.get("_objCandidateProfileStringsService"));
                        } else if (sCode === "ALREADY_EXISTS") {
                            _this6.setResponseError(objError.arrPath, "validationCandidateStatusChangeExist", false, false, _this6.get("_objCandidateProfileStringsService"));
                        } else if (sCode === "INVALID_TYPE") {
                            _this6.setResponseError(objError.arrPath, "validationRestUnsupportedValue");
                        } else {
                            _this6.get("_objSnackbarService").showSnackbarMessage(_this6.get("_objStringsService").getTokenString("failAddItem", _this6.get("_objStringsService").getString("status")));
                        }

                        _this6.set("_bPopupLoading", false);
                    }));
                }
            },
            onPopupEditCancel: function onPopupEditCancel() {
                this.popupAddCancel();
            },
            onViewHistoryClicked: function onViewHistoryClicked() {
                this.set("_bHistoryLoading", true);
                this._getCandidateStatusHistory();
            }
        }

    });
});